import { Button, Form, message, Modal } from 'antd';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthService from '../../api/shared/services/AuthService';
import { URLs } from '../../config/enums';
import { useTranslation } from 'react-i18next';

const ConfirmEmail: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const qs = queryString.parse(window.location.search) as { p: string };
  const history = useHistory();
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      await AuthService.confirmEmail(qs.p);
      Modal.success({
        content: t('Your email has been confirmed successfully'),
        onOk: () => history.push(URLs.LOGIN),
        centered: true,
      });
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h1 style={{ marginBottom: '2rem' }}>{t('Confirm your email address')}</h1>
      <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('Confirm Email')}
          </Button>
        </Form.Item>
      </Form>
      <p style={{ textAlign: 'center' }}>
        <Link to={URLs.LOGIN}>{t('Go back to Log In page')}</Link>
      </p>
    </>
  );
};

export default ConfirmEmail;
