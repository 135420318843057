import AuthService from '../../api/shared/services/AuthService';
import {
  LOGIN,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE,
  LOGOUT,
  UPDATE_PROFILE,
  LOGIN_LINKEDIN,
  UPDATE_SESSION_EXPIRED,
} from './constants';
import { IActionsAuth, IAuthState } from './types';

const createInitialState = () => {
  const initialState: IAuthState = {
    user: AuthService.getCurrentUser(),
    token: AuthService.getToken(),
  };
  return initialState;
};

export const authReducer = (
  state: IAuthState = createInitialState(),
  action: IActionsAuth
): IAuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    case LOGIN_FACEBOOK:
    case LOGIN_GOOGLE:
    case LOGIN_LINKEDIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    case LOGOUT:
      return {
        ...state,
        user: undefined,
        token: undefined,
        google: undefined,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_SESSION_EXPIRED:
      return { ...state, sessionExpired: action.payload };
    default:
      return state;
  }
};
