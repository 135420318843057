import { Avatar, List, Typography, Button, Tooltip, Divider } from 'antd';
import React from 'react';
import { JobCandidate } from '../../api/job-candidates/model';
import { useTranslation } from 'react-i18next';

interface Props {
  candidate: JobCandidate;
  onClickRemove?: () => void;
  actions?: React.ReactNode[];
}

const CandidateTile: React.FC<Props> = ({ candidate, onClickRemove, actions }) => {
  const { t } = useTranslation();
  return (
    <List.Item
      key={candidate.id}
      className="contact-list__item"
      actions={actions}
      extra={
        <>
          {onClickRemove && (
            <Tooltip title={t('Remove')}>
              <Button
                type="danger"
                size="small"
                className="contact-list__view-btn"
                onClick={() => onClickRemove()}
                shape="circle"
                icon="delete"
              />
            </Tooltip>
          )}
        </>
      }
    >
      <List.Item.Meta
        avatar={
          <Avatar
            style={{
              width: '6rem',
              height: '6rem',
              backgroundColor: '#ccc',
            }}
            src={candidate.pictureUrl || candidate.publicProfilePictureUrl}
          />
        }
        title={
          <Typography.Paragraph className="contact-list__item-title" ellipsis>
            <span title={`${candidate.firstName} ${candidate.lastName}`}>
              {candidate.firstName} {candidate.lastName}
            </span>
          </Typography.Paragraph>
        }
        description={
          <>
            <Typography.Paragraph className="contact-list__item-job" ellipsis>
              <span title={candidate.jobTitle}>{candidate.jobTitle}</span>
            </Typography.Paragraph>
            <Typography.Paragraph className="contact-list__item-company" ellipsis>
              <span title={candidate.company}>{candidate.company}</span>
            </Typography.Paragraph>
            {candidate.linkedinUrl && (
              <Tooltip title={`${t('View')} ${t('LinkedIn')}`}>
                <Button
                  type="link"
                  href={candidate.linkedinUrl}
                  icon="linkedin"
                  target="_blank"
                  size="small"
                  className="btn btn--linkedin"
                />
              </Tooltip>
            )}
            <Divider type="vertical" />
            {candidate.cvUrl && (
              <Tooltip title={`${t('View')} ${t('Resume')}`}>
                <Button href={candidate.cvUrl} icon="file-text" target="_blank" size="small" />
              </Tooltip>
            )}
          </>
        }
      />
    </List.Item>
  );
};

export default CandidateTile;
