import { Alert, Button, Col, Form, message, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AuthService from '../../api/shared/services/AuthService';
import { URLs } from '../../config/enums';
import { validateAntForm, showValidationMessages } from '../../helpers/ant-form';
import { getSessionExpired, login } from '../../state/auth';
import FloatLabelInputContainer from '../FloatLabelInput/FloatLabelInputContainer';
import LoginWithFacebookButton from './LoginWithFacebookButton';
import LoginWithGoogleButton from './LoginWithGoogleButton';
import LoginWithLinkedInButton from './LoginWithLinkedInButton';
import { useTranslation } from 'react-i18next';
import { IGlobalState } from '../../state';

interface FormValues {
  email: string;
  password: string;
}

interface Props extends FormComponentProps, DispatchProp {
  sessionExpired?: boolean;
}

const Login: React.FC<Props> = ({ form, dispatch, sessionExpired }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const values = await validateAntForm<FormValues>(form);
      const response = await AuthService.loginWithEmailAndPassword(values.email, values.password);
      dispatch(login(response));
    } catch (error) {
      setIsSubmitting(false);
      if (error.message) {
        message.error(error.message);
      } else {
        showValidationMessages(error);
      }
    }
  };

  return (
    <>
      <h1>{t('Log In')}</h1>
      <p>{t('Join to TalentLinkr with your mail and password or your social networks')}</p>
      <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
        <Form.Item
          style={{ margin: 0, padding: 0 }}
          className="hide-val-message"
          hasFeedback={false}
        >
          {form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('Please complete the %s field'),
              },
              {
                type: 'email',
                message: t('The entered email is not valid'),
              },
            ],
          })(
            <FloatLabelInputContainer
              type="email"
              collapse="top"
              label={t('Email Address')}
              icon="mail"
            />
          )}
        </Form.Item>
        <Form.Item
          style={{ margin: 0, padding: 0 }}
          className="hide-val-message"
          hasFeedback={false}
        >
          {form.getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: t('Please complete the %s field'),
              },
            ],
          })(
            <FloatLabelInputContainer
              type="password"
              collapse="bottom"
              label={t('Password')}
              icon="key"
            />
          )}
          <Row gutter={16} style={{ marginBottom: '2rem' }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Link to={URLs.RECOVER_PASSWORD}>{t('I forgot my password')}</Link>
            </Col>
          </Row>
        </Form.Item>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item>
              <Button
                type="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                htmlType="submit"
                block
              >
                {t('Log In')}
              </Button>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item>
              <Button
                ghost
                disabled={isSubmitting}
                type="primary"
                onClick={() => history.push(URLs.SIGN_UP)}
                block
              >
                {t('Sign Up')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {sessionExpired && (
          <Alert
            style={{ marginBottom: '1.5rem' }}
            message={t('Your session has expired. Please log in again')}
            showIcon
            type="warning"
            closable
          />
        )}
        <LoginWithGoogleButton isLoggingApp={isSubmitting} />
        <LoginWithLinkedInButton isLoggingApp={isSubmitting} />
        <LoginWithFacebookButton isLoggingApp={isSubmitting} />
      </Form>
    </>
  );
};

const mapStateToProps = (state: IGlobalState) => ({
  sessionExpired: getSessionExpired(state),
});

export default connect(mapStateToProps)(Form.create()(Login));
