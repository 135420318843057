import { API } from '../../config/api';
import { API_URL } from '../../config/general-config';
import { downloadDataAsCSV } from '../../helpers/file-helpers';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import { Contact } from '../contacts/model';
import { JobCandidate } from '../job-candidates/model';
import { EmailTemplate, FetchedList } from '../shared/models';
import FetchService from '../shared/services/FetchService';
import { Survey, SurveyAddType, SurveyConfig, SurveyInstance, SurveyUpdateType } from './models';

class SurveysService {
  static add(data: SurveyAddType): Promise<number> {
    return FetchService.post<number>({
      url: API.SURVEYS,
      body: data,
    });
  }

  static findAll(): Promise<FetchedList<Survey>> {
    return FetchService.get<FetchedList<Survey>>({ url: API.SURVEYS });
  }

  static find(params: GridParams): Promise<FetchedList<Survey>> {
    const gridParams = transformGridParams(params);
    return FetchService.get<FetchedList<Survey>>({ url: API.SURVEYS, gridParams });
  }

  static findOne(id: number): Promise<Survey> {
    return FetchService.get<Survey>({
      url: `${API.SURVEYS}/${id}`,
    });
  }

  static findInstance(hash: string): Promise<SurveyInstance> {
    return FetchService.get<SurveyInstance>({
      url: `${API.SURVEYS_INSTANCE}/${hash}`,
    });
  }

  static findInstancesByJobId(jobId: number): Promise<SurveyInstance[]> {
    return FetchService.get<SurveyInstance[]>({
      url: API.SURVEYS_JOB.replace(':id', jobId.toString()),
    });
  }

  static findTestInstances(id: number): Promise<SurveyInstance[]> {
    return FetchService.get<SurveyInstance[]>({
      url: `${API.SURVEYS}/test/${id}`,
    });
  }

  static async exportResultsToCSV(id: number): Promise<any> {
    const response = await fetch(
      `${API_URL}${API.SURVEYS_EXPORT_RESULTS.replace(':id',id.toString())}`,
      {
        method: 'GET',
        headers: FetchService.getHeaders(),
      }
    );
    const data: string = await response.text();
    downloadDataAsCSV(data, `survey-${id.toString()}-exported-${new Date().toISOString()}.csv`);
  }

  static async exportAnswersToCSV(id:number): Promise<any>{
    const response = await fetch(
      `${API_URL}${API.SURVEYS_EXPORT_ANSWERS.replace(':id',id.toString())}`,
      {
        method: 'GET',
        headers: FetchService.getHeaders(),
      }
    );
    const data: string = await response.text();
    downloadDataAsCSV(data, `survey-${id.toString()}-answers-exported-${new Date().toISOString()}.csv`);
  }

  static findConfig(): Promise<SurveyConfig> {
    return FetchService.get<SurveyConfig>({
      url: API.SURVEYS_CONFIG,
    });
  }

  static updateEmailTemplates(emailTemplates: EmailTemplate[]): Promise<void> {
    return FetchService.post<void>({
      url: API.SURVEYS_SAVE_EMAIL_TEMPLATES,
      body: emailTemplates,
    });
  }

  static completeSurvey(hash: string, surveyInstance: SurveyInstance): Promise<void> {
    return FetchService.put({
      url: `${API.SURVEYS_INSTANCE}/${hash}`,
      body: surveyInstance,
    });
  }

  static send(
    survey: Survey,
    emailContacts?: Contact[],
    linkedinContacts?: Contact[],
    facebookContacts?: Contact[],
    jobCandidate?: JobCandidate,
    listId?: number,
    isTest: boolean = false,
    sendToAllContacts: boolean = false,
  ): Promise<void> {
    return FetchService.post({
      url: API.SURVEYS_SEND.replace(':id', survey.id.toString()),
      body: {
        emailContactModels: emailContacts,
        linkedinContactModels: linkedinContacts,
        facebookContactModels: facebookContacts,
        jobCandidateModel: jobCandidate,
        listId,
        isTest,
        sendToAllContacts
      },
    });
  }

  static delete(id: number): Promise<void> {
    return FetchService.delete({
      url: `${API.SURVEYS}/${id}`,
    });
  }

  static async update(data: SurveyUpdateType): Promise<number> {
    return FetchService.put<number>({
      url: `${API.SURVEYS}/${data.id}`,
      body: data,
    });
  }
}

export default SurveysService;
