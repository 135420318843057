import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  IJobCustomMessageTemplate,
  IJobCustomMessageTemplateFetched,
} from '../../api/job-custom-message-templates/models';
import { createBaseGridParams, GridFilter, GridParams } from '../../helpers/grid-helpers';
import { JobCustomMessageTemplatesService } from '../../api/job-custom-message-templates/JobCustomMessageTemplatesService';
import { useAppContext } from '../../layout/AppContext';
import { useHistory } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../helpers/date-helpers';
import {
  JobCustomMessageTemplateTypes,
  JobCustomMessageTemplateTypesLabel,
} from '../../api/job-custom-message-templates/enums';
import TextArea from 'antd/lib/input/TextArea';
import ReactQuill from 'react-quill';
import { ROWS_PER_PAGES } from '../../config/general-config';

interface Props {}

const JobCustomMessageTemplatesGrid: React.FC = () => {
  const [
    jobCustomMessageTemplatesFetched,
    setJobCustomMessageTemplatesFetched,
  ] = useState<IJobCustomMessageTemplateFetched>({ count: 0, list: [] });

  const { user } = useAppContext();
  const history = useHistory();
  const { t } = useTranslation();

  const [filters, setFilters] = useState<GridFilter[]>([{ key: 'ownerUserId', value: user?.id }]);
  const [gridState, setGridState] = useState<GridParams>(
    createBaseGridParams({ sortOrder: 'descend', sortField: 'createdDate' })
  );

  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        let gridRequestParamsCompleted = { ...gridState, filters: [...filters] };
        setIsLoadingTemplates(true);

        const response = await JobCustomMessageTemplatesService.fetch(gridRequestParamsCompleted);
        setJobCustomMessageTemplatesFetched(response);
      } catch (error) {
        if (error.message) message.error(error.message);
      } finally {
        setIsLoadingTemplates(false);
      }
    };

    fetchTemplates();
  }, [gridState, filters]);

  const columns = [
    { title: t('Name'), dataIndex: 'name' },
    {
      title: t('Created Date'),
      dataIndex: 'createdDate',
      align: 'center' as 'center',
      render: (cell: any) => (cell ? formatDateTime(new Date(cell)) : '-'),
    },
    {
      title: t('Type'),
      dataIndex: 'subject',
      align: 'center' as 'center',
      render: (cell: any) => (
        <Tag className="tag">
          {cell
            ? t(JobCustomMessageTemplateTypesLabel.EMAIL)
            : t(JobCustomMessageTemplateTypesLabel.SOCIAL_NETWORK_MESSAGE)}
        </Tag>
      ),
    },
    {
      title: t('Actions'),
      align: 'center' as 'center',
      render: (cell: any, row: IJobCustomMessageTemplate) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Tooltip title={t('Edit')}>
            <Button
              icon="edit"
              type="default"
              onClick={() =>
                history.push(
                  `${URLs.JOB_CUSTOM_MESSAGE_TEMPLATES_DETAIL.replace(':id', row.id.toString())}`
                )
              }
            />
          </Tooltip>
          <Tooltip title={t('Details')}>
            <Button
              icon="file-text"
              type="default"
              onClick={() => handleRenderTemplateDataModal(row.id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleRenderTemplateDataModal = (selectedId: number) => {
    let selectedTemplate = jobCustomMessageTemplatesFetched.list.find((i) => i.id === selectedId)!;
    if (selectedTemplate) {
      Modal.info({
        maskClosable: true,
        title: t('Details'),
        width: 800,
        content: (
          <div>
            <Form.Item
              label={t('Name')}
              {...{ labelCol: { xs: 24, sm: 4, md: 3 }, wrapperCol: { xs: 24, sm: 20, md: 21 } }}
            >
              <Input readOnly value={selectedTemplate.name} />
            </Form.Item>

            {selectedTemplate.subject ? (
              <>
                <Form.Item
                  label={t('Subject')}
                  {...{
                    labelCol: { xs: 24, sm: 4, md: 3 },
                    wrapperCol: { xs: 24, sm: 20, md: 21 },
                  }}
                >
                  <Input readOnly value={selectedTemplate.subject} />
                </Form.Item>

                <Form.Item
                  label={t('Email')}
                  {...{
                    labelCol: { xs: 24, sm: 4, md: 3 },
                    wrapperCol: { xs: 24, sm: 20, md: 21 },
                  }}
                >
                  <ReactQuill
                    className="jobs-new__editor"
                    value={selectedTemplate.body}
                    readOnly
                    modules={{
                      toolbar: null,
                    }}
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                label={t('Message')}
                {...{ labelCol: { xs: 24, sm: 4, md: 3 }, wrapperCol: { xs: 24, sm: 20, md: 21 } }}
              >
                <TextArea autoSize readOnly value={selectedTemplate.body} />
              </Form.Item>
            )}
          </div>
        ),
      });
    }
  };

  const renderFilters = () => {
    return (
      <>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={8}>
            <Input.Search
              enterButton
              loading={isLoadingTemplates}
              placeholder={t('Search')}
              onSearch={(value: any) => handleFilterChange('query', value)}
            />
          </Col>
          <Col xs={24} md={8}>
            <Select
              placeholder={t('Select type')}
              style={{ width: '100%' }}
              allowClear
              onChange={(value: string) => handleFilterChange('type', value)}
            >
              {Object.keys(JobCustomMessageTemplateTypes).map((type) => (
                <Select.Option key={type} value={type}>
                  {t(JobCustomMessageTemplateTypesLabel[type as JobCustomMessageTemplateTypes])}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </>
    );
  };

  const handleFilterChange = (key: string, value: any) => {
    const newFilters = filters.filter((i) => i.key !== key);
    if (value) setFilters([...newFilters, { key, value }]);
    else setFilters(newFilters);
  };

  return (
    <div className="container">
      <Card
        title={
          <>
            {t('Job Custom Message Templates')}
            <Tooltip title="Add">
              <Button
                style={{ float: 'right' }}
                shape="circle"
                type="primary"
                icon="plus"
                onClick={() => history.push(URLs.JOB_CUSTOM_MESSAGE_TEMPLATES_NEW)}
              />
            </Tooltip>
          </>
        }
      >
        {renderFilters()}
        <Table
          bordered
          loading={isLoadingTemplates}
          columns={columns}
          dataSource={jobCustomMessageTemplatesFetched.list}
          rowKey="id"
          pagination={{
            hideOnSinglePage: true,
            pageSize: ROWS_PER_PAGES,
            total: jobCustomMessageTemplatesFetched.count,
            onChange: (page, pageSize) => setGridState({ ...gridState, page: page - 1 }),
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
          }}
        />
      </Card>
    </div>
  );
};

export default JobCustomMessageTemplatesGrid;
