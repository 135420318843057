import { message } from 'antd';
import { Dispatch, Middleware } from 'redux';
import { URLs } from '../../config/enums';
import { IActionUnauthorized, logout, UNAUTHORIZED } from '../auth';

export const unauthorizedMiddleware: Middleware = ({ dispatch }) => (next: Dispatch) => (
  action: IActionUnauthorized
) => {
  if (action.type === UNAUTHORIZED) {
    const { url } = action.payload;
    const lastSegmentUrl = url.substr(url.lastIndexOf('/'));
    if (lastSegmentUrl !== URLs.SESSION_VALID && lastSegmentUrl !== URLs.LOGOUT)
      message.error('Unauthorized');
    if (lastSegmentUrl !== URLs.LOGOUT) {
      dispatch(logout());
    }
  }

  next(action);
};
