import React from 'react';
import RecoverPassword from '../../components/RecoverPassword/RecoverPassword';
import SecurityLayout from '../../layout/SecurityLayout';

const RecoverPasswordPage: React.FC = () => {
  return (
    <SecurityLayout>
      <RecoverPassword />
    </SecurityLayout>
  );
};

export default RecoverPasswordPage;
