import { API } from '../../config/api';
import FetchService from '../shared/services/FetchService';
import { ClientCompany, ClientCompanyAddType } from './model';

class ClientCompaniesService {
  static async find(): Promise<ClientCompany[]> {
    const response = await FetchService.get<ClientCompany[]>({
      url: API.CLIENT_COMPANIES,
    });
    return response;
  }

  static async add(data: ClientCompanyAddType): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.CLIENT_COMPANIES,
      body: data,
    });
    return response;
  }

  static async addContact(clientId: number, contactId: number): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.CLIENT_COMPANIES_ADD_CONTACT.replace(':id', clientId.toString()).replace(
        ':contactId',
        contactId.toString()
      ),
      body: {
        id: clientId,
        contactId,
      },
    });
    return response;
  }
}

export default ClientCompaniesService;
