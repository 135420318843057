import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { URLs } from '../../config/enums';
import Profile from '../../components/Profile/Profile';
import Layout from '../../layout/Layout';

const ProfilePage: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path={URLs.PROFILE} component={Profile} />
      </Switch>
    </Layout>
  );
};

export default ProfilePage;
