import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Empty,
  Icon,
  Menu,
  message,
  Spin,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useState } from 'react';
import ContactsService from '../../api/contacts/ContactsService';
import ContactsListsService from '../../api/contacts/lists/ContactsListsService';
import { ContactList } from '../../api/contacts/lists/model';
import { Contact } from '../../api/contacts/model';
import { ReactComponent as AddToListIcon } from '../../assets/icon-addlist.svg';
import { AvatarProps } from 'antd/lib/avatar';

interface Props {
  contact: Contact;
  lists: ContactList[];
  isChecked: boolean;
  fetchContacts: () => void;
  selectedListId?: number;
  onClick?: (contactId: number) => void;
  handleCheck: (contactId: number) => void;
}

const ContactTile: React.FC<Props> = ({
  lists,
  contact,
  fetchContacts,
  selectedListId,
  onClick,
  isChecked,
  handleCheck,
}) => {
  const [isLoadingContactLists, setIsLoadingContactLists] = useState(false);
  const [contactLists, setContactLists] = useState<ContactList[]>([]);
  const [isVisibleMenuList, setIsVisibleMenuList] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchContactLists = async () => {
      try {
        setIsLoadingContactLists(true);
        const response = await ContactsService.findLists(contact.id);
        setContactLists(response);
        setIsLoaded(true);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingContactLists(false);
      }
    };

    if (isVisibleMenuList && !isLoaded) {
      fetchContactLists();
    }
  }, [contact.id, isVisibleMenuList, isLoaded]);

  const onVisibleChange = (isVisible: boolean) => {
    setIsVisibleMenuList(isVisible);
  };

  const handleOnChange = async (checked: boolean, contactListId: number, contactId: number) => {
    try {
      if (checked) {
        await ContactsListsService.addContact(contactListId, contactId);
      } else {
        await ContactsListsService.removeContact(contactListId, contactId);
        if (selectedListId === contactListId) {
          fetchContacts();
        }
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const handleDefaultChecked = (contactListId: number) => {
    return contactLists.map((l) => l.id).includes(contactListId);
  };

  const renderMenuList = () => {
    if (isLoadingContactLists) {
      return (
        <Menu.Item>
          <Spin size="small" />
        </Menu.Item>
      );
    }

    if (!lists.length) {
      return <Empty style={{ padding: '1rem' }} />;
    }

    return lists.map((contactList) => (
      <Menu.Item
        key={contactList.id}
        className="contact-tile__contact-list-item"
        onClick={(e) => e.domEvent.stopPropagation()}
      >
        <Checkbox
          defaultChecked={handleDefaultChecked(contactList.id)}
          onChange={(e: CheckboxChangeEvent) =>
            handleOnChange(e.target.checked, contactList.id, contact.id)
          }
        >
          {contactList.name}
        </Checkbox>
      </Menu.Item>
    ));
  };

  const avatarProps: AvatarProps = {
    className: 'contact-tile__avatar',
  };

  if (contact.pictureUrl) {
    avatarProps.src = contact.pictureUrl;
  } else {
    avatarProps.icon = 'user';
  }

  return (
    <>
      <Card
        onClick={(e) => {
          if (onClick) {
            onClick(contact.id);
          }
        }}
        className="contact-tile"
        bodyStyle={{ padding: 0 }}
      >
        <div className="contact-tile__actions">
          <Dropdown
            visible={isVisibleMenuList}
            onVisibleChange={onVisibleChange}
            overlay={<Menu>{renderMenuList()}</Menu>}
          >
            <Icon component={AddToListIcon} className="contact-tile__btn-add-to-list" />
          </Dropdown>
          <Checkbox
            checked={isChecked}
            onClick={(e) => {
              e.stopPropagation();
              handleCheck(contact.id);
            }}
          />
        </div>

        <Avatar {...avatarProps} />
        <Typography.Paragraph ellipsis className="contact-tile__name">
          {contact.firstName} {contact.lastName}
        </Typography.Paragraph>
        <Typography.Paragraph ellipsis className="contact-tile__job">
          <span title={contact.jobTitle}>{contact.jobTitle}</span>
        </Typography.Paragraph>
        <Typography.Paragraph ellipsis className="contact-tile__company">
          <span title={contact.companyName}>{contact.companyName}</span>
        </Typography.Paragraph>
        <div onClick={(e) => e.stopPropagation()}>
          <Button.Group>
            {contact.linkedinUrl && (
              <Button
                type="link"
                href={contact.linkedinUrl}
                icon="linkedin"
                target="_blank"
                size="small"
                className="btn btn--linkedin"
              />
            )}
            {contact.facebookUrl && (
              <Button
                type="link"
                href={contact.facebookUrl}
                icon="facebook"
                target="_blank"
                size="small"
                className="btn btn--linkedin"
              />
            )}
            {contact.email && (
              <Button
                type="link"
                href={`mailto:${contact.email}`}
                icon="mail"
                target="_blank"
                size="small"
                className="btn btn--linkedin"
              />
            )}
          </Button.Group>
        </div>
      </Card>
    </>
  );
};

export default ContactTile;
