import { API } from '../../config/api';
import FetchService from '../shared/services/FetchService';
import { Job, JobAddType, JobConfig, JobsFetched, JobUpdateType, JobSendModel, JobFM } from './model';
import { EmailTemplate } from '../shared/models';
import { Contact } from '../contacts/model';
import { API_URL } from '../../config/general-config';
import { downloadDataAsCSV } from '../../helpers/file-helpers';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';

class JobsService {
  static async findOne(id: number) {
    const response = await FetchService.get<Job>({
      url: `${API.JOBS}/${id}`,
    });
    return response;
  }

  static async findOneByToken(token: string) {
    const response = await FetchService.get<Job>({
      url: `${API.JOBS}/by-token?token=${token}`,
    });
    return response;
  }

  static async find(fetchParams?: GridParams): Promise<JobsFetched> {
    let gridParams;
    if (fetchParams)
      gridParams = transformGridParams(fetchParams);
    const response = await FetchService.get<JobsFetched>({
      url: API.JOBS,
      gridParams,
    });
    return response;
  }

  static async findAll(fetchParams?: GridParams): Promise<JobsFetched> {
    let gridParams;
    if (fetchParams)
      gridParams = transformGridParams(fetchParams);
    const response = await FetchService.get<JobsFetched>({
      url: API.JOBS_ALL,
      gridParams,
    });
    return response;
  }

  static async findFilemakerJobs(jobTitle: string): Promise<JobFM[]> {
    const response = await FetchService.get<JobFM[]>({
      url: `${API.JOBS_FILEMAKER}?jobTitle=${jobTitle}`
    });
    return response;
  }

  static async findFilemakerJob(fileMakerRecordId: number, jobTitle: string): Promise<JobFM> {
    const response = await FetchService.get<JobFM>({
      url: `${API.JOBS_FILEMAKER_JOB}?fileMakerRecordId=${fileMakerRecordId}&jobTitle=${jobTitle}`,
    });
    return response;
  }

  static async add(data: JobAddType): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.JOBS,
      body: data,
    });
    return response;
  }

  static async duplicate(jobId: number): Promise<number> {
    const response = await FetchService.post<number>({
      url: `${API.JOBS}/${jobId}/duplicate`,
    });
    return response;
  }

  static async update(data: JobUpdateType): Promise<number> {
    const response = await FetchService.put<number>({
      url: `${API.JOBS}/${data.id}`,
      body: data,
    });
    return response;
  }

  static async updateEmailTemplates(jobId: number, templates: EmailTemplate[]) {
    const response = await FetchService.post({
      url: API.JOBS_SAVE_EMAIL_TEMPLATES.replace(':id', jobId.toString()),
      body: templates,
    });
    return response;
  }

  static async findConfig(): Promise<JobConfig> {
    const response = await FetchService.get<JobConfig>({
      url: API.JOBS_CONFIG,
    });
    return response;
  }

  static async sendJob(jobId: number, model: JobSendModel) {
    const response = await FetchService.post({
      url: API.JOBS_SEND.replace(':id', jobId.toString()),
      body: model,
    });
    return response;
  }

  static async sendTestEmail(jobId: number) {
    const response = await FetchService.post({
      url: API.JOBS_SEND_TEST_EMAIL.replace(':id', jobId.toString()),
    });
    return response;
  }

  static async updateSenderContact(jobId: number, contactId?: number): Promise<Contact> {
    const response = await FetchService.put<Contact>({
      url: API.JOBS_UPDATE_SENDER_CONTACT.replace(':id', jobId.toString()),
      body: {
        overrideSenderContactId: contactId,
      },
    });
    return response;
  }

  static async updateClientPage(jobId: number, clientPage: string) {
    const response = await FetchService.put({
      url: API.JOBS_UPDATE_CLIENT_PAGE.replace(':id', jobId.toString()),
      body: {
        clientPage,
      },
    });
    return response;
  }

  static async updateStatus(jobId: number, jobStatus: string) {
    const response = await FetchService.put({
      url: API.JOBS_UPDATE_STATUS.replace(':id', jobId.toString()),
      body: {
        jobStatus
      },
    });
    return response;
  }

  static async createFilemakerJob(jobId: number, jobTitle: string) {
    const response = await FetchService.put({
      url: `${API.JOBS_CREATE_FILEMAKER_JOB.replace(':id', jobId.toString())}`,
      body: {
        jobTitle
      }
    });
    return response;
  }

  static async updateFilemakerJob(jobId: number, fileMakerJobTitle?: string, fileMakerIdMission?: number) {
    const response = await FetchService.put({
      url: API.JOBS_UPDATE_FILEMAKER_JOB.replace(':id', jobId.toString()),
      body: {
        fileMakerJobTitle,
        fileMakerIdMission
      },
    });
    return response;
  }

  static async updateSendEmailDelay(jobId: number, emailSendDelay: number | undefined) {
    const response = await FetchService.put({
      url: API.JOBS_UPDATE_EMAIL_DELAY.replace(':id', jobId.toString()),
      body: { emailSendDelay }
    });
    return response;
  }

  static async updateSuggestPageMessage(
    jobId: number,
    message: string,
    hideSuggestMessage: boolean
  ) {
    const response = await FetchService.put({
      url: API.JOBS_UPDATE_SUGGEST_PAGE_MESSAGE.replace(':id', jobId.toString()),
      body: {
        suggestPageMessage: message,
        hideSuggestMessage: hideSuggestMessage,
      },
    });
    return response;
  }

  static async findInvitedContacts(jobId: number): Promise<Contact[]> {
    const response = await FetchService.get<Contact[]>({
      url: API.JOBS_INVITED_CONTACTS.replace(':id', jobId.toString()),
    });
    return response;
  }

  static async exportToCSV(jobId: number, model: JobSendModel): Promise<any> {
    const response = await fetch(
      `${API_URL}${API.JOBS_EXPORT_SELECTED_CONTACTS_TO_CSV.replace(':id', jobId.toString())}`,
      {
        method: 'POST',
        body: JSON.stringify(model),
        headers: FetchService.getHeaders(),
      }
    );
    const data: string = await response.text();
    downloadDataAsCSV(data, `job-${jobId.toString()}-exported-${new Date().toISOString()}.csv`);
  }

  static async findEmailStatistics(id: any): Promise<any> {
    const response = await FetchService.get<any>({
      url: `${API.EMAIL_STATISTICS}?JobId=${id}`,
    });
    return response;
  }

  static async findSocialMessageSummary(id: number): Promise<any> {
    return FetchService.get<any>({
      url: API.JOB_SOCIAL_MESSAGE_SUMMARY.replace(':id', id.toString()),
    });
  }
  static async findGeneralSummary(id: number): Promise<any> {
    return FetchService.get<any>({
      url: API.JOB_GENERAL_SUMMARY.replace(':id', id.toString()),
    });
  }

  static async emailsData(params: GridParams): Promise<any> {
    let gridParams;
    if (params) {
      gridParams = transformGridParams(params)
    }
    const response = FetchService.get<any>({
      url: API.MESSAGE_EMAILS,
      gridParams
    });
    return response;
  }

  static async socialNetworkData(params: GridParams): Promise<any> {
    const gridParams = transformGridParams(params);
    return FetchService.get<any>({
      url: API.SOCIAL_NETWORK_MESSAGES,
      gridParams,
    });
  }

  static async jobEmailStatus(data: any): Promise<any> {
    const response = await FetchService.post<any>({
      url: API.JOB_EMAIL_STATUS.replace(':id', data?.id.toString()),
      body: data
    });
    return response;
  }
}

export default JobsService;
