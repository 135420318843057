import { Card, Icon, Badge, Tooltip } from 'antd';
import classNames from 'classnames';
import { formatDistance } from 'date-fns';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { JobStatusEnum, JobStatusLabel } from '../../api/jobs/enums';
import { Job } from '../../api/jobs/model';
import { URLs } from '../../config/enums';
import { useTranslation } from 'react-i18next';

interface Props {
  job: Job;
}

const JobTile: React.FC<Props> = ({ job }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const match = useRouteMatch<{ id: string }>([URLs.JOBS_DETAIL, URLs.JOBS_DRAFT, URLs.JOBS_EDIT]);

  const handleClick = () => {
    if (job.jobStatus === JobStatusEnum.DRAFT) {
      history.push(URLs.JOBS_DRAFT.replace(':id', job.id.toString()));
    } else {
      history.push(URLs.JOBS_DETAIL.replace(':id', job.id.toString()));
    }
  };

  const shouldDisplayBadge =
    job.jobStatus === JobStatusEnum.OPEN || job.jobStatus === JobStatusEnum.ARCHIVED;

  return (
    <Card
      className={classNames('tile tile__job', {
        'tile__job--active': match?.params.id === job.id.toString(),
      })}
      onClick={handleClick}
    >
      {shouldDisplayBadge && (
        <Badge count={job.candidatesCount} className="tile__job__candidates-count" showZero />
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="tile__job__title">{job.jobTitle}</span>
        <span
          className={classNames('tile__job__status', {
            'tile__job__status--draft': job.jobStatus === JobStatusEnum.DRAFT,
            'tile__job__status--open': job.jobStatus === JobStatusEnum.OPEN,
            'tile__job__status--archived': job.jobStatus === JobStatusEnum.ARCHIVED,
          })}
        >
          {t(JobStatusLabel[job.jobStatus])}
        </span>
      </div>
      <span className="tile__job__company">{job.clientCompany.name}</span>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip
          title={formatDistance(new Date(job.createdDate), new Date(), {
            addSuffix: true,
          })}
        >
          <span className="tile__job__date">
            <Icon type="clock-circle" style={{ marginRight: 7 }} />{' '}
            {formatDistance(new Date(job.createdDate), new Date(), {
              addSuffix: true,
            })}
          </span>
        </Tooltip>
        <Tooltip title={job.location}>
          <span className="tile__job__location">{job.location}</span>
        </Tooltip>
      </div>
    </Card>
  );
};

export default JobTile;
