import { Button, message, Modal } from 'antd';
import { MessageType } from 'antd/lib/message';
import React from 'react';
import GoogleService from '../../api/shared/services/GoogleService';
import { ReactComponent as IconGmail } from '../../assets/icon-gmail.svg';
import { useAppContext } from '../../layout/AppContext';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { useTranslation } from 'react-i18next';

interface Props {
  onOk: () => void;
}

const ImportContactsGoogle: React.FC<Props> = ({ onOk }) => {
  const { t } = useTranslation();
  const { isGoogleSDKLoaded } = useAppContext();

  const importContacts = async () => {
    let loading: MessageType | undefined;

    try {
      loading = message.loading('Importing contacts...', 0);
      await GoogleService.importContacts();
      Modal.success({
        content: (
          <p style={{ textAlign: 'center' }}>
            {t('Your Google contacts were successfully imported, but only those who had email')}
          </p>
        ),
        onOk: onOk,
      });
      loading();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      if (loading) {
        loading();
      }
    }
  };

  if (!isGoogleSDKLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <Button onClick={() => importContacts()} className="import-contacts__btn">
      <IconGmail />
    </Button>
  );
};

export default ImportContactsGoogle;
