export enum RolesEnum {
  SYSADMIN = 'SYSADMIN',
  ADMIN = 'ADMIN',
  RECRUITER = 'RECRUITER',
  CUSTOMER = 'CUSTOMER',
}

export enum ResourcesEnum {
  DASHBOARD = 'DASHBOARD',
  ROLES = 'ROLES',
  EMAILS = 'EMAILS',
  SOCIAL_NETWORK_MESSAGES = 'SOCIAL_NETWORK_MESSAGES',
  USERS = 'USERS',
  EVENTS = 'EVENTS',
  CONTACTS = 'CONTACTS',
  CONTACTS_LISTS = 'CONTACTS_LISTS',
  JOBS = 'JOBS',
  USER_ACTIVITIES = 'USER_ACTIVITIES',
  SURVEYS = 'SURVEYS',
  RECRUITER_CONFIGURATIONS = 'RECRUITER_CONFIGURATIONS',
  JOB_MESSAGE_TEMPLATE = 'JOB_MESSAGE_TEMPLATE',
  CAMPAIGNS = 'CAMPAIGNS',
}

export enum PatentsEnum {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  VIEW = 'VIEW',
}

export const ResourcesLabels = {
  [ResourcesEnum.DASHBOARD]: 'Dashboard',
  [ResourcesEnum.ROLES]: 'Roles',
  [ResourcesEnum.USERS]: 'Users',
  [ResourcesEnum.EVENTS]: 'Events',
  [ResourcesEnum.CONTACTS]: 'Contacts',
  [ResourcesEnum.JOBS]: 'Jobs',
  [ResourcesEnum.SURVEYS]: 'Surveys',
};
