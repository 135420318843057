import { API } from '../../../config/api';
import FetchService from '../../shared/services/FetchService';
import { ContactList, ContactListAddType, ContactListUpdateType } from './model';
import { Contact } from '../model';
import { GridParams, transformGridParams } from '../../../helpers/grid-helpers';
import { FetchedList } from '../../shared/models';

class ContactsListsService {
  static async findOne(contactListId: string): Promise<ContactList> {
    const response = await FetchService.get<ContactList>({
      url: `${API.CONTACTS_LISTS}/${contactListId}`,
    });
    return response;
  }

  static async findAll(): Promise<ContactList[]> {
    const response = await FetchService.get<ContactList[]>({
      url: API.CONTACTS_LISTS,
    });
    return response;
  }

  static async findAllGrid(): Promise<ContactList[]> {
    const response = await FetchService.get<ContactList[]>({
      url: API.CONTACTS_LISTS_GRID,
    });
    return response;
  }

  static async findContacts(
    contactListId: number,
    params: GridParams
  ): Promise<FetchedList<Contact>> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<FetchedList<Contact>>({
      url: API.CONTACTS_LISTS_CONTACTS.replace(':id', contactListId.toString()),
      gridParams,
    });
    return response;
  }

  static async add(data: ContactListAddType): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.CONTACTS_LISTS,
      body: data,
    });
    return response;
  }

  static async update(data: ContactListUpdateType): Promise<ContactList> {
    const response = await FetchService.put<ContactList>({
      url: `${API.CONTACTS_LISTS}/${data.id}`,
      body: data,
    });
    return response;
  }

  static async delete(contactListId: number): Promise<void> {
    const response = await FetchService.delete<void>({
      url: `${API.CONTACTS_LISTS}/${contactListId}`,
    });
    return response;
  }

  static async addContact(contactListId: number, contactId: number) {
    const response = await FetchService.post({
      url: API.CONTACTS_LISTS_ADD_CONTACT.replace(':id', contactListId.toString()).replace(
        ':contact-id',
        contactId.toString()
      ),
    });
    return response;
  }

  static async removeContact(contactListId: number, contactId: number) {
    const response = await FetchService.delete({
      url: API.CONTACTS_LISTS_ADD_CONTACT.replace(':id', contactListId.toString()).replace(
        ':contact-id',
        contactId.toString()
      ),
    });
    return response;
  }

  static async addContactsToLists(contactsIds: number[], listsIds: number[]) {
    const response = await FetchService.post({
      url: API.CONTACTS_ADD_CONTACTS_TO_LISTS,
      body: {
        contactListIds: listsIds,
        contactIds: contactsIds,
      },
    });
    return response;
  }

  static async addAllContactsToLists(contactListIds: number[], originListId?: number) {
    const response = await FetchService.post({
      url: API.CONTACTS_ADD_ALL_TO_LISTS,
      body: {
        originListId: originListId || undefined,
        contactListIds,
      },
    });
    return response;
  }
}

export default ContactsListsService;
