import { Button, Card, Form, Input, message, Popconfirm, Radio, Tag } from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  JobCustomMessageTemplateTypes,
  JobCustomMessageTemplateTypesLabel,
} from '../../api/job-custom-message-templates/enums';
import { JobCustomMessageTemplatesService } from '../../api/job-custom-message-templates/JobCustomMessageTemplatesService';
import {
  IJobCustomMessageTemplate,
  IJobCustomMessageTemplateAddType,
  IJobCustomMessageTemplateUpdateType,
  JobCustomMessageTemplateConfig,
} from '../../api/job-custom-message-templates/models';
import { validateAntForm } from '../../helpers/ant-form';
import { ReactQuillToolbar } from '../../helpers/react-quill-helpers';

import BackButton from '../BackButton/BackButton';
import LoadingSpinner from '../Loading/LoadingSpinner';

const formItemLayout: FormItemProps = {
  labelCol: {
    xs: 24,
    sm: 4,
    md: 3,
  },
  wrapperCol: {
    xs: 24,
    sm: 20,
    md: 21,
  },
};

interface FormValues extends FormComponentProps {
  templateType: string;
  name: string;
  subject: string;
  emailBody: string;
  message: string;
}

const JobCustomMessageTemplateDetail: React.FC<FormValues> = ({ form }) => {
  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  const [
    jobCustomMessageTemplate,
    setJobCustomMessageTemplate,
  ] = useState<IJobCustomMessageTemplate>({
    name: '',
    body: '',
    subject: '',
    id: 0,
    createdDate: '',
  });

  form.getFieldDecorator('emailBody', {
    initialValue: jobCustomMessageTemplate.body || undefined,
  });

  const templateTypeFormField = form.getFieldValue('templateType');

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const emailEditorRef: RefObject<ReactQuill> = useRef(null);
  const [emailEditor, setEmailEditor] = useState<Quill>();

  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [config, setConfig] = useState<JobCustomMessageTemplateConfig>();

  form.getFieldDecorator('body', {
    initialValue: jobCustomMessageTemplate.body,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const values = await validateAntForm<FormValues>(form);
      const isEmailTemplate = values.templateType === JobCustomMessageTemplateTypes.EMAIL;

      if (match.params.id) {
        const entity: IJobCustomMessageTemplateUpdateType = {
          id: jobCustomMessageTemplate.id,
          name: values.name,
          subject: values.subject,
          body: isEmailTemplate ? values.emailBody : values.message,
        };
        await JobCustomMessageTemplatesService.update(jobCustomMessageTemplate.id, entity);
      } else {
        const entity: IJobCustomMessageTemplateAddType = {
          name: values.name,
          subject: values.subject,
          body: isEmailTemplate ? values.emailBody : values.message,
        };
        await JobCustomMessageTemplatesService.add(entity);
      }
      message.success(t('Success'));
      history.goBack();
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (templateId: number) => {
    try {
      setIsDeleting(true);
      await JobCustomMessageTemplatesService.delete(templateId);
      message.success(t('Success'));
      history.goBack();
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAddFieldEmail = (field: string) => {
    const cursorIndex = emailEditor!.getSelection(true).index;
    if (cursorIndex) emailEditor?.insertText(cursorIndex, `[${field}]`);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const response = await JobCustomMessageTemplatesService.fetchOne(+match.params.id);
        setJobCustomMessageTemplate(response);
        setIsLoading(false);
      } catch (error) {
        if (error.message) message.error(error.message);
      }
    };

    if (match.params.id) fetch();
  }, [match.params.id]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoadingConfig(true);
        const response = await JobCustomMessageTemplatesService.fetchConfig(true);
        setConfig(response);
        setIsLoadingConfig(false);
      } catch (error) {
        if (error.message) message.error(error.message);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (!isLoading && !isLoadingConfig) {
      if (emailEditorRef.current) setEmailEditor(emailEditorRef.current.getEditor());
    }
  }, [isLoading, isLoadingConfig, templateTypeFormField]);

  return (
    <div className="container">
      <Card title={<BackButton title={t('Job Custom Message Templates')} />}>
        {isLoading || isLoadingConfig ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Item label={t('Name')} {...formItemLayout}>
              {form.getFieldDecorator('name', {
                initialValue: jobCustomMessageTemplate.name || undefined,
                rules: [{ required: true, message: t('This field is required') }],
              })(<Input maxLength={100} />)}
            </Form.Item>

            <Form.Item label={t('Type')} {...formItemLayout}>
              {form.getFieldDecorator('templateType', {
                initialValue:
                  jobCustomMessageTemplate?.subject && jobCustomMessageTemplate?.id
                    ? JobCustomMessageTemplateTypes.EMAIL
                    : !jobCustomMessageTemplate?.subject && jobCustomMessageTemplate?.id
                    ? JobCustomMessageTemplateTypes.SOCIAL_NETWORK_MESSAGE
                    : JobCustomMessageTemplateTypes.EMAIL,
              })(
                <Radio.Group
                  buttonStyle="solid"
                  disabled={isLoading || isSubmitting || !!match.params.id}
                >
                  {Object.keys(JobCustomMessageTemplateTypes).map((templateType) => (
                    <Radio.Button value={templateType}>
                      {t(
                        JobCustomMessageTemplateTypesLabel[
                          templateType as JobCustomMessageTemplateTypes
                        ]
                      )}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>

            {form.getFieldValue('templateType') === JobCustomMessageTemplateTypes.EMAIL ? (
              <>
                <Form.Item label={t('Subject')} {...formItemLayout}>
                  {form.getFieldDecorator('subject', {
                    initialValue: jobCustomMessageTemplate?.subject || '',
                    rules: [
                      { required: true, whitespace: true, message: t('This field is required') },
                    ],
                  })(<Input />)}
                </Form.Item>

                <Form.Item label={t('Email')} {...formItemLayout}>
                  <ReactQuill
                    ref={emailEditorRef}
                    className="jobs-new__editor"
                    value={form.getFieldValue('emailBody') || ''}
                    onChange={(value) => form.setFieldsValue({ emailBody: value })}
                    modules={{
                      toolbar: ReactQuillToolbar,
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('Fields')} {...formItemLayout}>
                  {config?.emailFields.map((field) => (
                    <Tag
                      style={{ cursor: 'pointer' }}
                      key={field}
                      onClick={() => handleAddFieldEmail(field)}
                    >
                      [{field}]
                    </Tag>
                  ))}
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item label={t('Message')} {...formItemLayout}>
                  {form.getFieldDecorator('message', {
                    initialValue: jobCustomMessageTemplate?.body || '',
                    rules: [{ required: true, message: t('This field is required') }],
                  })(<TextArea autosize />)}
                </Form.Item>

                <Form.Item label={t('Fields')} {...formItemLayout}>
                  {config?.emailFields.map((field) => (
                    <Tag
                      style={{ cursor: 'pointer' }}
                      key={field}
                      onClick={() =>
                        form.setFieldsValue({
                          message: `${form.getFieldValue('message')} [${field}]`,
                        })
                      }
                    >
                      [{field}]
                    </Tag>
                  ))}
                </Form.Item>
              </>
            )}
            {match.params.id && (
              <Popconfirm
                title={t('Delete this template?')}
                onConfirm={() => handleDelete(jobCustomMessageTemplate.id)}
              >
                <Button type="danger" loading={isLoading || isSubmitting || isDeleting}>
                  {t('Delete')}
                </Button>
              </Popconfirm>
            )}
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: 'right' }}
              loading={isLoading || isSubmitting || isDeleting}
            >
              {t('Save')}
            </Button>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default Form.create()(JobCustomMessageTemplateDetail);
