import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

const JobsSelectOffer: React.FC = () => {
  const { t } = useTranslation();
  return <Empty className="jobs-select-offer" description={t('Select a job to view')} />;
};

export default JobsSelectOffer;
