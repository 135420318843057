import {
  Button,
  Checkbox,
  Comment,
  Descriptions,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Typography,
} from 'antd';
import Avatar, { AvatarProps } from 'antd/lib/avatar';
import { FormComponentProps } from 'antd/lib/form';
import { filter } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import JobCandidateService from '../../api/job-candidates/JobCandidatesService';
import {
  JobCandidate,
  JobCandidateComment,
  JobCandidateCommentAddType,
  JobCandidateRecommendation,
} from '../../api/job-candidates/model';
import { JobStatusEnum } from '../../api/jobs/enums';
import { Job } from '../../api/jobs/model';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { formatDateTime } from '../../helpers/date-helpers';
import { htmlDecode } from '../../helpers/string-helpers';
import { useAppContext } from '../../layout/AppContext';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import ContactReadModal from '../Contacts/ContactReadModal';

interface FormValues {
  comment: string;
  hideComment: boolean;
}

interface Props extends FormComponentProps<FormValues> {
  isVisible: boolean;
  onCancel: () => void;
  jobCandidate?: JobCandidate;
  job: Job;
}

const JobCandidateDetailModal: React.FC<Props> = ({
  form,
  isVisible,
  onCancel,
  jobCandidate,
  job,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAppContext();
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [comments, setComments] = useState<JobCandidateComment[]>([]);
  const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(true);
  const [recommendations, setRecommendations] = useState<JobCandidateRecommendation[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVisibleContactReadModal, setIsVisibleContactReadModal] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<number>();

  const fetchComments = useCallback(() => {
    const fetch = async () => {
      try {
        if (jobCandidate && isVisible) {
          setIsLoadingComments(true);
          const response = await JobCandidateService.findComments(jobCandidate.id);
          if (user && user.id === job.ownerUserId) {
            setComments(response);
          } else {
            setComments(filter(response, { hideComment: false }));
          }
        } else {
          setComments([]);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingComments(false);
      }
    };
    fetch();
  }, [jobCandidate, isVisible, user, job.ownerUserId]);

  const fetchRecommendations = useCallback(() => {
    const fetch = async () => {
      try {
        if (jobCandidate && isVisible) {
          setIsLoadingRecommendations(true);
          const response = await JobCandidateService.findRecommendations(jobCandidate.id);
          setRecommendations(response);
        } else {
          setRecommendations([]);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingRecommendations(false);
      }
    };
    fetch();
  }, [jobCandidate, isVisible]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  useEffect(() => {
    if (selectedContactId) {
      setIsVisibleContactReadModal(true);
    } else {
      setIsVisibleContactReadModal(false);
    }
  }, [selectedContactId]);

  useEffect(() => {
    fetchRecommendations();
  }, [fetchRecommendations]);

  if (!jobCandidate) {
    return null;
  }

  const avatarProps: AvatarProps = {
    className: 'avatar-upload__img avatar-upload__img--no-pointer',
  };

  if (jobCandidate?.pictureUrl) {
    avatarProps.src = jobCandidate.pictureUrl;
  } else if (jobCandidate?.publicProfilePictureUrl) {
    avatarProps.src = jobCandidate.publicProfilePictureUrl;
  } else {
    avatarProps.icon = 'user';
  }

  const onCreateSurveyButtonClick = () => {
    history.push(
      URLs.JOBS_DETAIL_CREATE_SURVEY.replace(':jobId', job.id.toString()).replace(
        ':candidateId',
        jobCandidate.id.toString()
      )
    );
  };

  const handleAddComment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!jobCandidate) {
      return;
    }
    try {
      setIsSubmitting(true);
      const values = await validateAntForm(form);
      const { comment, hideComment } = values;
      const data: JobCandidateCommentAddType = {
        text: comment,
        hideComment,
      };
      if (!user) {
        data.contactId = job.clientContactId;
      }

      await JobCandidateService.addComment(jobCandidate.id, data);
      fetchComments();
      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleHideComment = async (jobCandidateCommentId: number, hide: boolean) => {
    try {
      await JobCandidateService.toggleCommentHide(jobCandidateCommentId, hide);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const toggleHideRecommendation = async (jobCandidateRecommendationId: number, hide: boolean) => {
    try {
      await JobCandidateService.toggleRecommendation(
        jobCandidate.id,
        jobCandidateRecommendationId,
        hide
      );
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const canViewRecommendation = (recommendation: JobCandidateRecommendation) =>
    !recommendation.hideRecommendation || user?.id === job.ownerUserId;

  return (
    <>
      <Modal
        style={{ top: '2rem' }}
        visible={isVisible}
        onCancel={onCancel}
        footer={null}
        title={jobCandidate ? `${jobCandidate.firstName} ${jobCandidate.lastName}` : undefined}
      >
        {!jobCandidate && isVisible && <NotFoundPage hideHeader />}
        {jobCandidate && (
          <>
            <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
              <Avatar {...avatarProps}>
                {jobCandidate.firstName[0]}
                {jobCandidate.lastName[0]}
              </Avatar>
            </div>

            <Descriptions column={2} title="Basic Info">
              <Descriptions.Item label={t('Name')}>
                {jobCandidate.firstName} {jobCandidate.lastName}
              </Descriptions.Item>
              <Descriptions.Item label={t('Email')}>
                {jobCandidate.email ? (
                  <a href={`mailto:${jobCandidate.email}`}>{jobCandidate.email}</a>
                ) : (
                  <em>{t('Not available')}</em>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t('Company')}>{jobCandidate.company}</Descriptions.Item>
              <Descriptions.Item label={t('Position')}>
                {htmlDecode(jobCandidate.jobTitle)}
              </Descriptions.Item>
              <Descriptions.Item label={t('Phone')}>
                {jobCandidate.phone ? (
                  <a href={`tel:${jobCandidate.phone}`}>{jobCandidate.phone}</a>
                ) : (
                  <em>{t('Not available')}</em>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t('LinkedIn URL')}>
                {jobCandidate.linkedinUrl ? (
                  <a href={jobCandidate.linkedinUrl}>{jobCandidate.linkedinUrl}</a>
                ) : (
                  <em>{t('Not available')}</em>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t('Facebook URL')}>
                {jobCandidate.facebookUrl ? (
                  <a href={jobCandidate.facebookUrl}>{jobCandidate.facebookUrl}</a>
                ) : (
                  <em>{t('Not available')}</em>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title={t('Files')}>
              <Descriptions.Item label={t('Resume')}>
                {!jobCandidate.cvUrl ? (
                  t('No resume')
                ) : (
                  <Button type="link" href={jobCandidate.cvUrl} target="_blank">
                    {t('Download')}
                  </Button>
                )}
              </Descriptions.Item>
            </Descriptions>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="primary" onClick={onCreateSurveyButtonClick}>
                {t('Create Survey')}
              </Button>
            </div>

            <Divider />

            <div>
              <Typography.Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.6rem',
                  marginBottom: '2rem',
                  display: 'block',
                }}
              >
                {t('Recommendations')}
              </Typography.Text>
              <List
                dataSource={recommendations.filter(canViewRecommendation)}
                loading={isLoadingRecommendations}
                renderItem={(recommendation) => (
                  <Comment
                    className="job-candidate-comment"
                    actions={[
                      user && user.id === job.ownerUserId && (
                        <Checkbox
                          onChange={(e) =>
                            toggleHideRecommendation(recommendation.id!, e.target.checked)
                          }
                          defaultChecked={recommendation.hideRecommendation}
                        >
                          <span style={{ fontSize: '1.2rem' }}>{t('Hide Recommendation')}</span>
                        </Checkbox>
                      ),
                    ]}
                    author={
                      recommendation.contact
                        ? `${recommendation.contact.firstName} ${recommendation.contact.lastName}`
                        : t('Anonymous')
                    }
                    datetime={
                      recommendation.createdDate
                        ? formatDateTime(new Date(recommendation.createdDate))
                        : ''
                    }
                    avatar={<Avatar src={recommendation.contact?.pictureUrl} icon="user" />}
                    content={
                      <>
                        <p>
                          <span>
                            {recommendation.recommendationText || (
                              <i>
                                {t('The contact has not left any message with his recommendation')}.
                              </i>
                            )}
                          </span>
                          {recommendation.contact && ' '}
                        </p>
                        {recommendation.isRecommendedAware !== null && (
                          <p>
                            <i>
                              {`${t('This person is aware that he/she is recommended')}: ${
                                recommendation.isRecommendedAware ? t('Yes') : t('No')
                              }`}
                            </i>
                          </p>
                        )}
                        {recommendation.canBeContactedOnBehalf !== null && (
                          <p>
                            <i>{`${t('He/She can be contacted on behalf of you')}: ${
                              recommendation.canBeContactedOnBehalf ? t('Yes') : t('No')
                            }`}</i>
                          </p>
                        )}

                        {job.ownerUserId === user?.id && (
                          <div style={{ textAlign: 'center' }}>
                            {recommendation.contact && (
                              <Button.Group size="small">
                                <Button
                                  type="primary"
                                  onClick={() => setSelectedContactId(recommendation.contact?.id)}
                                >
                                  {t('View Contact')}
                                </Button>
                                {recommendation.contact.email && (
                                  <Button
                                    className="btn btn--secondary"
                                    href={`mailto:${recommendation.contact.email}`}
                                  >
                                    {t('Mailback')}
                                  </Button>
                                )}
                              </Button.Group>
                            )}
                          </div>
                        )}
                      </>
                    }
                  />
                )}
              />
            </div>

            <div>
              <Typography.Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.6rem',
                  marginBottom: '2rem',
                  display: 'block',
                }}
              >
                {t('Comments')}
              </Typography.Text>
              <List
                dataSource={comments}
                loading={isLoadingComments}
                renderItem={(comment: JobCandidateComment) => (
                  <Comment
                    className="job-candidate-comment"
                    actions={[
                      user && user.id === job.ownerUserId && (
                        <Checkbox
                          onChange={(e) => toggleHideComment(comment.id, e.target.checked)}
                          defaultChecked={comment.hideComment}
                        >
                          <span style={{ fontSize: '1.2rem' }}>
                            {t('Hide message from client')}
                          </span>
                        </Checkbox>
                      ),
                    ]}
                    author={
                      comment.contact
                        ? `${comment.contact.firstName} ${comment.contact.lastName}`
                        : comment.createdByUser
                        ? `${comment.createdByUser.firstName} ${comment.createdByUser.lastName}`
                        : t('Anonymous')
                    }
                    datetime={formatDateTime(new Date(comment.createdDate))}
                    avatar={
                      <Avatar
                        icon="user"
                        src={
                          comment.createdByUser?.profilePictureUrl || comment.contact?.pictureUrl
                        }
                      />
                    }
                    content={<p>{comment.text}</p>}
                  />
                )}
              />
            </div>

            <Divider />

            <Comment
              avatar={<Avatar icon="user" />}
              content={
                <Form onSubmit={handleAddComment}>
                  <Form.Item>
                    {form.getFieldDecorator('comment', {
                      rules: [
                        {
                          required: true,
                          message: t('This field is required'),
                        },
                      ],
                    })(<Input.TextArea placeholder={`${t('Add comment')}...`} rows={4} />)}
                  </Form.Item>
                  {job.jobStatus === JobStatusEnum.OPEN && (
                    <Form.Item>
                      <Button htmlType="submit" loading={isSubmitting} type="primary">
                        {t('Add comment')}
                      </Button>
                    </Form.Item>
                  )}
                </Form>
              }
            />
          </>
        )}
      </Modal>

      <ContactReadModal
        isVisible={isVisibleContactReadModal}
        onCancel={() => setSelectedContactId(undefined)}
        contactId={selectedContactId}
      />
    </>
  );
};

export default Form.create<Props>()(JobCandidateDetailModal);
