import React, { useContext } from 'react';
import { Duplicate } from '../../api/contacts/model';

interface IContactsContext {
  duplicates: Duplicate[];
  fetchDuplicateContacts?: () => Promise<void>;
  isLoadingDuplicateContacts: boolean;
  setDuplicateContacts?: React.Dispatch<React.SetStateAction<Duplicate[]>>;
}
export const ContactsContext = React.createContext<IContactsContext>({
  duplicates: [],
  fetchDuplicateContacts: undefined,
  isLoadingDuplicateContacts: true,
  setDuplicateContacts: undefined,
});

export const useContactsContext = () => useContext(ContactsContext);
