import { Button, Dropdown, Icon, Menu, message, Popconfirm, Tabs, Tooltip } from 'antd';
import classNames from 'classnames';
import { parseInt } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import CampaignsService from '../../api/campaigns/CampaignsService';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { CampaignsContext } from './CampaignsContext';
import CampaignsSummary from './CampaignsSummary';
import CampaignsResult from './CampaignsResult';
import { CampaignStatusEnum } from '../../api/campaigns/enum';
import { CampaignsGrid } from './CampaignsGrid';
import ReactExport from 'react-export-excel-xlsx-fix';
import { ROWS_PER_PAGES } from '../../config/general-config';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const TabItems = {
  SUMMARY: 'SUMMARY',
  RESULTS: 'RESULTS',
  REPORT: 'REPORT'
};

const CampaignDetail: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match?.params;
  const gridParams = createBaseGridParams({
    sortOrder: 'descend',
    sortField: 'createdDate',
    pageSize: ROWS_PER_PAGES,
    CampaignId: +id,
  });
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    filters: [],
  });
  const history = useHistory();
  const { user } = useAppContext();
  const [isLoadingCampaign, setIsLoadingCampaign] = useState(false);
  const [isDeletingCampaign, setIsDeletingCampaign] = useState(false);
  const [campaign, setCampaign] = useState<any>();
  const [total, setTotal] = useState(0);
  const fetchCampaigns = useContext(CampaignsContext).fetchCampaigns!;
  const fetchArchivedCampaigns = useContext(CampaignsContext).fetchArchivedCampaigns!;
  const [activeTabKey, setActiveTabKey] = useState(TabItems.SUMMARY);
  const [campaignsFetched, setCampaignsFetched] = useState<any>();
  const [emailStatistics, setEmailStatistics] = useState<any>();

  useEffect(() => {
    const path = match.path.toUpperCase();
    if (path.endsWith(TabItems.SUMMARY)) {
      setActiveTabKey(TabItems.SUMMARY);
    } else if (path.endsWith(TabItems.RESULTS)) {
      setActiveTabKey(TabItems.RESULTS);
    } else if (path.endsWith(TabItems.REPORT)) {
      setActiveTabKey(TabItems.REPORT)
    }
  }, [match.path, activeTabKey]);

  // useEffect(() => {
  //   // setGridState({...gridState, CampaignId: +id})
  //   fetchEmailsData();
  // }, []);

  useEffect(() => {
    fetchCampaign();
    fetchEmailStatisticsData()
    fetchEmailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, activeTabKey]);

  const fetchEmailsData = async () => {
    try {
      setIsLoadingCampaign(true);
      const response = await CampaignsService.emailsData(gridState);
      setCampaignsFetched(response?.list);
      setTotal(response?.count);
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsLoadingCampaign(false);
    }
  };

  const fetchCampaign = async () => {
    try {
      if (id) {
        setIsLoadingCampaign(true);
        const response = await CampaignsService.findOne(parseInt(id));
        setCampaign(response);
      } else {
        setCampaign(undefined);
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsLoadingCampaign(false);
    }
  };

  const fetchEmailStatisticsData = async () => {
    try {
      setIsLoadingCampaign(true);
      const response = await CampaignsService.findEmailStatistics(parseInt(id));
      setEmailStatistics(response);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsLoadingCampaign(false);
    }
  };

  useEffect(() => {
    setActiveTabKey(TabItems.SUMMARY)
  }, [id])

  const deleteCampaign = async () => {
    try {
      setIsDeletingCampaign(true);
      await CampaignsService.delete(parseInt(id));
      message.success(t('Campaign deleted successfully'));
      fetchCampaigns();
      history.push(URLs.CAMPAIGNS);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsDeletingCampaign(false);
    }
  };

  const handleCampaignStatus = async (status: any) => {
    try {
      setIsLoadingCampaign(true);
      const data = { id: id, campaignStatus: status }
      const response = await CampaignsService.campaignStatus(data);
      setCampaign(response);
      if (status == CampaignStatusEnum.Archived) {
        history.push(URLs.CAMPAIGNS);
        fetchCampaigns();
        fetchArchivedCampaigns();
      } else {
        fetchCampaigns()
        fetchCampaign()
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsDeletingCampaign(false);
    }
  };

  const handleExportToCSV = async () => {
    try {
      // await CampaignsService.exportToCSV(parseInt(id))
      const response = await CampaignsService.emailsDataToCSV(parseInt(id));
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    }
  }

  if (!user) {
    return <Redirect to={URLs.LOGIN} />;
  }

  if (isLoadingCampaign) {
    return <LoadingSpinner />;
  }

  if (!campaign) {
    return <NotFoundPage hideHeader />;
  }

  return (
    <>
      <div className="job-offer">
        <h1 className="job-offer__header">
          {campaign?.campaignName}
          <span style={{ margin: '5px' }}
            className={classNames('tile__campaigns__headerstatus', {
              'tile__campaigns__headerstatus--draft': campaign.campaignStatus === CampaignStatusEnum.Draft,
              'tile__campaigns__headerstatus--scheduled': campaign.campaignStatus === CampaignStatusEnum.Scheduled,
              'tile__campaigns__headerstatus--started': campaign.campaignStatus === CampaignStatusEnum.Started,
              'tile__campaigns__headerstatus--archived': campaign.campaignStatus === CampaignStatusEnum.Archived,
              'tile__campaigns__headerstatus--paused': campaign.campaignStatus === CampaignStatusEnum.Paused,
              'tile__campaigns__headerstatus--stopped': campaign.campaignStatus === CampaignStatusEnum.Stopped,
              'tile__campaigns__headerstatus--completed': campaign.campaignStatus === CampaignStatusEnum.Completed,
            })}
          >{t(campaign?.campaignStatus)}</span>
          <span style={{ marginLeft: 'auto' }} />
          {
            activeTabKey !== TabItems.REPORT ? (
              <>
                {(campaign?.campaignStatus == CampaignStatusEnum.Draft || campaign?.campaignStatus == CampaignStatusEnum.Scheduled || campaign?.campaignStatus == CampaignStatusEnum.Paused) && (
                  <Tooltip title={t('Edit')} placement="bottom">
                    <Button
                      loading={isDeletingCampaign}
                      icon="edit"
                      shape="circle-outline"
                      type="primary"
                      style={{ marginRight: '1rem' }}
                      // disabled={campaign?.campaignStatus === CampaignStatusEnum.Started}
                      onClick={() => history.push(URLs.CAMPAIGNS_EDIT.replace(':id', id.toString()))}
                    />
                  </Tooltip>
                )}

                {(campaign?.campaignStatus == CampaignStatusEnum.Draft || campaign?.campaignStatus == CampaignStatusEnum.Scheduled) && (
                  <Tooltip title={t('Play')} placement="bottom">
                    <Button
                      loading={isDeletingCampaign}
                      icon="play-circle-o"
                      shape="circle-outline"
                      type="primary"
                      style={{ marginRight: '1rem' }}
                      onClick={() => handleCampaignStatus(CampaignStatusEnum.Started)}
                    />
                  </Tooltip>
                )}

                {(campaign?.campaignStatus == CampaignStatusEnum.Paused) && (
                  <Tooltip title={t('Resume')} placement="bottom">
                    <Button
                      loading={isDeletingCampaign}
                      icon="play-circle-o"
                      shape="circle-outline"
                      type="primary"
                      // disabled={campaign?.campaignStatus !== CampaignStatusEnum.Started}
                      style={{ marginRight: '1rem' }}
                      onClick={() => handleCampaignStatus(CampaignStatusEnum.Started)}
                    />
                  </Tooltip>
                )}

                {(campaign?.campaignStatus == CampaignStatusEnum.Started) && (
                  <Tooltip title={t('Pause')} placement="bottom">
                    <Button
                      loading={isDeletingCampaign}
                      icon="pause-circle-o"
                      shape="circle-outline"
                      type="primary"
                      // disabled={campaign?.campaignStatus !== CampaignStatusEnum.Started}
                      style={{ marginRight: '1rem' }}
                      onClick={() => handleCampaignStatus(CampaignStatusEnum.Paused)}
                    />
                  </Tooltip>
                )}

                {(campaign?.campaignStatus == CampaignStatusEnum.Started || campaign?.campaignStatus == CampaignStatusEnum.Paused) && (
                  <Tooltip title={t('Stop')} placement="bottom">
                    <Button
                      loading={isDeletingCampaign}
                      icon="stop"
                      shape="circle-outline"
                      type="primary"
                      style={{ marginRight: '1rem' }}
                      onClick={() => handleCampaignStatus(CampaignStatusEnum.Stopped)}
                    />
                  </Tooltip>
                )}

                {(campaign?.campaignStatus == CampaignStatusEnum.Draft || campaign?.campaignStatus == CampaignStatusEnum.Scheduled || campaign?.campaignStatus == CampaignStatusEnum.Stopped) && (
                  <Tooltip title={t('Archive')} placement="bottom">
                    <Button
                      loading={isDeletingCampaign}
                      icon="dropbox"
                      shape="circle-outline"
                      type="primary"
                      style={{ marginRight: '1rem' }}
                      onClick={() => handleCampaignStatus(CampaignStatusEnum.Archived)}
                    />
                  </Tooltip>
                )}

                {(campaign?.campaignStatus == CampaignStatusEnum.Draft || campaign?.campaignStatus == CampaignStatusEnum.Scheduled || campaign?.campaignStatus == CampaignStatusEnum.Stopped) && (
                  <Tooltip title={t('Delete Campaign')} placement="bottom">
                    <Popconfirm
                      title={t('Are you sure you want to delete this campaign?')}
                      onConfirm={deleteCampaign}
                      okText={t('Delete')}
                      okType="danger"
                      cancelText={t('Cancel')}
                    >
                      <Button
                        loading={isDeletingCampaign}
                        icon="delete"
                        shape="circle-outline"
                        type="danger"
                        // disabled={campaign?.campaignStatus === CampaignStatusEnum.Started}
                        style={{ marginRight: '1rem' }}
                      />
                    </Popconfirm>
                  </Tooltip>
                )}
              </>) : (
              <>
                {(campaign?.campaignStatus !== CampaignStatusEnum.Archived && campaignsFetched) && (
                  <Dropdown
                    disabled={(campaignsFetched && campaignsFetched.length == 0)}
                    overlay={
                      <Menu>
                        <Menu.Item key={'CSV'} onClick={handleExportToCSV}>
                          <Icon type='file' style={{ marginRight: 5 }} />
                          <span> {t('Export to CSV')} </span>
                        </Menu.Item>
                        <Menu.Item key={'Excel'}>
                          <ExcelFile element={<><Icon type='file-excel' style={{ marginRight: 5 }} /><span>{t('Export to Excel')}</span></>} filename={campaign?.campaignName}>
                            <ExcelSheet data={campaignsFetched && campaignsFetched} name={campaign?.campaignName} >
                              <ExcelColumn label="Send Date" value="createdDate" />
                              <ExcelColumn label="Subject" value="subject" />
                              {/* <ExcelColumn label="Name" value="name" /> */}
                              <ExcelColumn label="Sender Email" value="sender" />
                              <ExcelColumn label="Sender Name" value="senderName" />
                              <ExcelColumn label="Recipients" value="recipients" />
                              <ExcelColumn label="Status" value="emailStatusCode" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Menu.Item>
                      </Menu>
                    }><span>
                      <Button
                        loading={isDeletingCampaign}
                        icon="download"
                        shape="circle-outline"
                        type="primary"
                        disabled={(campaignsFetched && campaignsFetched.length == 0)}
                        style={{ marginRight: '1rem' }}
                      />
                    </span></Dropdown>
                )}
              </>
            )}

        </h1>
        <div className="job-offer__body">
          <Tabs
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
            className={classNames({
              'ant-tabs-bar--bordered-bottom': activeTabKey === TabItems.SUMMARY,
            })}
          >
            {/* Summary Tab */}
            <Tabs.TabPane key={TabItems.SUMMARY} tab={t('Summary')}>
              <CampaignsSummary campaign={campaign} emailStatistics={emailStatistics} />
            </Tabs.TabPane>

            {/* Export Report/Message Tab */}
            <Tabs.TabPane key={TabItems.REPORT} tab={t('Messages')}
              disabled={(campaign?.campaignStatus != CampaignStatusEnum.Started && campaign?.campaignStatus != CampaignStatusEnum.Archived && campaign?.campaignStatus != CampaignStatusEnum.Completed && campaign?.campaignStatus != CampaignStatusEnum.Stopped && campaign?.campaignStatus != CampaignStatusEnum.Paused)}
            >
              <CampaignsGrid />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default CampaignDetail;
