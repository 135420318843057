import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import FetchService from '../shared/services/FetchService';
import { API } from '../../config/api';
import { UserActivitiesFetched } from './model';

export default class UserActivitiesService {
  static async findGrid(params: GridParams, userId?: string): Promise<UserActivitiesFetched> {
    const gridParams = transformGridParams(params);
    let url: string;

    if (!userId) {
      url = API.USER_ACTIVITIES;
    } else {
      url = API.USER_ACTIVITIES_BY_USER.replace(':userId', userId);
    }

    const response = await FetchService.get<UserActivitiesFetched>({
      url,
      gridParams,
    });

    return response;
  }
}
