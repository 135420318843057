import { Avatar, Button, Form, Input, message, Modal, Tooltip, Upload } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Duplicate, SuggestedContactMergeType } from '../../api/contacts/model';
import FilesService, { FORM_DATA_KEY } from '../../api/files/FilesService';
import { IFile } from '../../api/files/model';
import { MimeTypes } from '../../api/shared/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { getBase64 } from '../../helpers/image-helpers';
import ContactsService from '../../api/contacts/ContactsService';

const formItemLayout: FormItemProps = {
  labelCol: {
    xs: 24,
    sm: 6,
  },
  wrapperCol: {
    xs: 24,
    sm: 18,
  },
};

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  jobTitle: string;
}

interface Props extends FormComponentProps {
  isVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
  duplicateContactToMerge?: Duplicate;
}

const ContactMergeModal: React.FC<Props> = ({
  form,
  isVisible,
  onCancel,
  onOk,
  duplicateContactToMerge,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<UploadFile>();
  const [imgPreview, setImgPreview] = useState<string>();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    let dbFile: IFile | undefined;
    if (!duplicateContactToMerge) {
      return;
    }

    try {
      const { suggestedContact } = duplicateContactToMerge;
      const values = await validateAntForm<FormValues>(form);
      const { firstName, lastName, email, jobTitle, companyName } = values;

      setIsSubmitting(true);

      if (file && (!suggestedContact || !suggestedContact.pictureFileId)) {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY, (file as unknown) as File);
        dbFile = await FilesService.add(formData);
      }

      const duplicateToMerge: SuggestedContactMergeType = {
        duplicateId: duplicateContactToMerge.id,
        contactModel: {
          id: suggestedContact.id,
          firstName,
          lastName,
          email,
          companyName: companyName,
          jobTitle: jobTitle,
          pictureFileId: dbFile?.id,
        },
      };

      await ContactsService.merge(duplicateToMerge);

      message.success(t('Merged'));
      onOk();
      form.resetFields();
      setFile(undefined);
      setImgPreview(undefined);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
      if (dbFile) {
        await FilesService.delete(dbFile.id);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setFile(undefined);
      setImgPreview(undefined);
    } else {
      const response = await getBase64((info.file as unknown) as File);
      if (response) {
        setImgPreview(response.toString());
      }
      setFile(info.file);
    }
  };

  const avatarProps: AvatarProps = {
    className: 'avatar-upload__img',
  };

  if (imgPreview) {
    avatarProps.src = imgPreview;
  } else {
    avatarProps.icon = 'user';
    if (duplicateContactToMerge?.suggestedContact.pictureUrl) {
      avatarProps.src = duplicateContactToMerge.suggestedContact.pictureUrl;
    }
  }

  const renderContent = () => {
    if (!duplicateContactToMerge) {
      return null;
    }
    if (!isVisible) {
      return null;
    }

    const { suggestedContact } = duplicateContactToMerge;

    return (
      <Form layout="horizontal">
        <Form.Item>
          <div className="avatar-upload">
            <Upload
              fileList={file ? [file] : []}
              beforeUpload={() => false}
              onChange={handleFileOnChange}
              accept={`${MimeTypes.IMAGE_JPEG}, ${MimeTypes.IMAGE_PNG}`}
              showUploadList={false}
            >
              <Avatar {...avatarProps} />

              {(suggestedContact?.pictureUrl || imgPreview) && (
                <>
                  <Tooltip title={t('Remove')}>
                    <Button
                      className="avatar-upload__btn avatar-upload__btn--remove"
                      icon="delete"
                      shape="circle"
                      type="danger"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFile(undefined);
                        setImgPreview(undefined);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </Upload>
          </div>
        </Form.Item>

        <Form.Item label={t('First Name')} {...formItemLayout}>
          {form.getFieldDecorator('firstName', {
            initialValue: suggestedContact?.firstName,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input autoFocus onPressEnter={handleSubmit} />)}
        </Form.Item>
        <Form.Item label={t('Last Name')} {...formItemLayout}>
          {form.getFieldDecorator('lastName', {
            initialValue: suggestedContact?.lastName,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input onPressEnter={handleSubmit} />)}
        </Form.Item>

        <Form.Item label={t('Email')} {...formItemLayout}>
          {form.getFieldDecorator('email', {
            initialValue: suggestedContact?.email,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
              {
                type: 'email',
                message: t('The entered email is not valid'),
              },
            ],
          })(<Input onPressEnter={handleSubmit} />)}
        </Form.Item>
        <Form.Item label={t('Company')} {...formItemLayout}>
          {form.getFieldDecorator('companyName', {
            initialValue: suggestedContact?.companyName,
            rules: [
              {
                whitespace: true,
                message: t('Field cannot be empty'),
              },
            ],
          })(<Input onPressEnter={handleSubmit} />)}
        </Form.Item>
        <Form.Item label={t('Position')} {...formItemLayout}>
          {form.getFieldDecorator('jobTitle', {
            initialValue: suggestedContact?.jobTitle,
            rules: [
              {
                whitespace: true,
                message: t('Field cannot be empty'),
              },
            ],
          })(<Input onPressEnter={handleSubmit} />)}
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      visible={isVisible}
      title={t('Merge contacts')}
      onCancel={() => {
        setImgPreview(undefined);
        setFile(undefined);
        onCancel();
        form.resetFields();
      }}
      cancelButtonProps={{ disabled: isSubmitting }}
      okButtonProps={{ disabled: isSubmitting }}
      onOk={handleSubmit}
      confirmLoading={isSubmitting}
      okText={t('Merge')}
    >
      {renderContent()}
    </Modal>
  );
};

export default Form.create<Props>()(ContactMergeModal);
