import React from 'react';
import background from '../assets/login-bg3.png';
import { Layout, Row, Col } from 'antd';
import logo from '../assets/logo@2x.png';

interface Props {
  title?: string;
}

const SecurityLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content className="security-layout" style={{ display: 'flex' }}>
        <Row type="flex" style={{ flex: 1 }}>
          <Col
            xs={24}
            md={10}
            lg={9}
            xl={8}
            xxl={6}
            style={{
              flex: 1,
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            className="security-layout__content"
          >
            <img style={{ width: 150, height: 'auto', marginBottom: 20 }} src={logo} alt="logo" />
            {children}
          </Col>

          <Col xs={0} md={14} lg={15} xl={16} xxl={18}>
            <div
              style={{
                height: '100%',
                backgroundColor: '#eee',
                backgroundSize: 'cover',
                backgroundImage: `url(${background})`,
              }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default SecurityLayout;
