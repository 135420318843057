import { Avatar, Button, Col, Icon, List, message, Row, Typography, Card, Tooltip } from 'antd';
import { find, reject, without } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import VirtualList from 'react-tiny-virtual-list';
import ContactsService from '../../api/contacts/ContactsService';
import {
  Duplicate,
  SuggestedContactIgnoreType,
  SuggestedContactMergeType,
} from '../../api/contacts/model';
import { URLs } from '../../config/enums';
import LoadingSpinner from '../Loading/LoadingSpinner';
import ContactMergeModal from './ContactMergeModal';
import ContactReadModal from './ContactReadModal';
import { useContactsContext } from './ContactsContext';

const ContactsDuplicates: React.FC = () => {
  const { t } = useTranslation();
  const { duplicates, isLoadingDuplicateContacts, setDuplicateContacts } = useContactsContext();

  const [mergingDuplicateIds, setMergingDuplicateIds] = useState<number[]>([]);
  const [dismissingDuplicateIds, setDismissingDuplicateIds] = useState<number[]>([]);
  const [dismissingDuplicateContactIds, setDismissingDuplicateContactIds] = useState<number[]>([]);
  const [contactId, setContactId] = useState(0);
  const [duplicateContactToMerge, setDuplicateContactToMerge] = useState<Duplicate>();

  if (isLoadingDuplicateContacts) {
    return <LoadingSpinner />;
  }

  if (!duplicates.length) {
    return <Redirect to={URLs.CONTACTS} />;
  }

  const handleMerge = async (duplicateId: number) => {
    try {
      const duplicate = find(duplicates, { id: duplicateId })!;
      setMergingDuplicateIds([...mergingDuplicateIds, duplicateId]);
      const duplicateToMerge: SuggestedContactMergeType = {
        duplicateId,
        contactModel: duplicate.suggestedContact,
      };
      await ContactsService.merge(duplicateToMerge);
      message.success(t('Merged'));
      removeDuplicate(duplicateId);
    } catch (error) {
      message.error(error.message);
    } finally {
      setMergingDuplicateIds(without(mergingDuplicateIds, duplicateId));
    }
  };

  const handleDismissAll = async (duplicateId: number) => {
    try {
      setDismissingDuplicateIds([...dismissingDuplicateIds, duplicateId]);
      await ContactsService.dismissAll(duplicateId);
      message.success(t('Dismissed'));
      removeDuplicate(duplicateId);
    } catch (error) {
      message.error(error.message);
    } finally {
      setDismissingDuplicateIds(without(dismissingDuplicateIds, duplicateId));
    }
  };

  const handleDismiss = async (duplicateId: number, duplicateContactId: number) => {
    try {
      setDismissingDuplicateContactIds([...dismissingDuplicateContactIds, duplicateContactId]);
      const duplicateToIgnore: SuggestedContactIgnoreType = {
        duplicateId,
        duplicateContactId,
      };
      await ContactsService.dismiss(duplicateToIgnore);
      message.success(t('Dismissed'));
      removeDuplicateContact(duplicateId, duplicateContactId);
    } catch (error) {
      message.error(error.message);
    } finally {
      setDismissingDuplicateContactIds(without(dismissingDuplicateContactIds, duplicateContactId));
    }
  };

  const removeDuplicate = (duplicateId: number) => {
    if (setDuplicateContacts) {
      setDuplicateContacts(reject(duplicates, { id: duplicateId }));
    }
  };

  const removeDuplicateContact = (duplicateId: number, duplicateContactId: number) => {
    if (setDuplicateContacts) {
      const newDuplicates = duplicates.map((duplicate) => {
        if (duplicate.id !== duplicateId) {
          return duplicate;
        }

        return {
          ...duplicate,
          duplicateContactModels: reject(duplicate.duplicateContactModels, {
            id: duplicateContactId,
          }),
        };
      });
      setDuplicateContacts(newDuplicates);
    }
  };

  return (
    <div className="container" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
      <Card
        title={
          <Row gutter={16}>
            <Col xs={8} style={{ textAlign: 'center' }}>
              <Typography.Title level={4}>{t('Contacts')}</Typography.Title>
            </Col>
            <Col xs={2}></Col>
            <Col xs={8} style={{ textAlign: 'center' }}>
              <Typography.Title level={4}>{t('Will merge into')}</Typography.Title>
            </Col>
          </Row>
        }
      >
        <VirtualList
          height={800}
          width="100%"
          itemCount={duplicates.length}
          itemSize={(index) => {
            const duplicate = duplicates[index];
            return duplicate.duplicateContactModels.length * 90;
          }}
          style={{ padding: '.5rem' }}
          renderItem={({ index, style }) => {
            const duplicate = duplicates[index];
            const { suggestedContact } = duplicate;
            return (
              <Row key={index} style={style} gutter={[16, 16]} type="flex" align="middle">
                <Col xs={8}>
                  {duplicate.duplicateContactModels.map((contact) => (
                    <List.Item
                      key={contact.id}
                      className="contact-list__item contact-list__item--smaller-gap"
                      extra={
                        <Button.Group size="small">
                          <Tooltip title={t('Details')}>
                            <Button
                              type="primary"
                              size="small"
                              className="contact-list__view-btn"
                              onClick={() => setContactId(contact.id)}
                              icon="eye"
                            />
                          </Tooltip>
                          <Tooltip title={t('Ignore')}>
                            <Button
                              size="small"
                              type="danger"
                              className="contact-list__view-btn"
                              disabled={dismissingDuplicateContactIds.includes(contact.id)}
                              loading={dismissingDuplicateContactIds.includes(contact.id)}
                              onClick={() => handleDismiss(duplicate.id, contact.id)}
                              icon="stop"
                            />
                          </Tooltip>
                        </Button.Group>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={{
                              width: '6rem',
                              height: '6rem',
                              backgroundColor: '#ccc',
                            }}
                            icon="user"
                          />
                        }
                        title={
                          <Typography.Paragraph className="contact-list__item-title" ellipsis>
                            <span title={`${contact.firstName} ${contact.lastName}`}>
                              {contact.firstName} {contact.lastName}
                            </span>
                          </Typography.Paragraph>
                        }
                        description={
                          <>
                            <Typography.Paragraph className="contact-list__item-job" ellipsis>
                              <span title={contact.jobTitle}>{contact.jobTitle}</span>
                            </Typography.Paragraph>
                            <Typography.Paragraph className="contact-list__item-company" ellipsis>
                              <span title={contact.companyName}>{contact.companyName}</span>
                            </Typography.Paragraph>
                          </>
                        }
                      ></List.Item.Meta>
                    </List.Item>
                  ))}
                </Col>
                <Col xs={2} style={{ textAlign: 'center' }}>
                  <Icon type="fork" style={{ fontSize: '4rem' }} />
                </Col>
                <Col xs={8}>
                  <List.Item
                    extra={
                      <Button
                        type="link"
                        size="small"
                        className="contact-list__view-btn"
                        onClick={() => setDuplicateContactToMerge(duplicate)}
                      >
                        {t('Details')}
                      </Button>
                    }
                    className="contact-list__item contact-list__item--smaller-gap"
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            width: '6rem',
                            height: '6rem',
                            backgroundColor: '#ccc',
                          }}
                          icon="user"
                        />
                      }
                      title={
                        <Typography.Paragraph className="contact-list__item-title" ellipsis>
                          <span
                            title={`${suggestedContact.firstName} ${suggestedContact.lastName}`}
                          >
                            {suggestedContact.firstName} {suggestedContact.lastName}
                          </span>
                        </Typography.Paragraph>
                      }
                      description={
                        <>
                          <Typography.Paragraph className="contact-list__item-job" ellipsis>
                            <span title={suggestedContact.jobTitle}>
                              {suggestedContact.jobTitle}
                            </span>
                          </Typography.Paragraph>
                          <Typography.Paragraph className="contact-list__item-company" ellipsis>
                            <span title={suggestedContact.companyName}>
                              {suggestedContact.companyName}
                            </span>
                          </Typography.Paragraph>
                        </>
                      }
                    />
                  </List.Item>
                </Col>
                <Col xs={6} style={{ textAlign: 'center' }}>
                  <Button.Group>
                    <Button
                      type="primary"
                      disabled={
                        mergingDuplicateIds.includes(duplicate.id) ||
                        dismissingDuplicateIds.includes(duplicate.id)
                      }
                      loading={mergingDuplicateIds.includes(duplicate.id)}
                      onClick={() => handleMerge(duplicate.id)}
                    >
                      {t('Merge')}
                    </Button>
                    <Button
                      type="danger"
                      disabled={
                        mergingDuplicateIds.includes(duplicate.id) ||
                        dismissingDuplicateIds.includes(duplicate.id)
                      }
                      loading={dismissingDuplicateIds.includes(duplicate.id)}
                      onClick={() => handleDismissAll(duplicate.id)}
                    >
                      {t('Ignore All')}
                    </Button>
                  </Button.Group>
                </Col>
              </Row>
            );
          }}
        />
      </Card>

      <ContactReadModal
        isVisible={!!contactId}
        onCancel={() => setContactId(0)}
        contactId={contactId}
      />
      <ContactMergeModal
        isVisible={!!duplicateContactToMerge}
        onCancel={() => setDuplicateContactToMerge(undefined)}
        onOk={() => {
          setDuplicateContactToMerge(undefined);
          if (duplicateContactToMerge) {
            removeDuplicate(duplicateContactToMerge.id);
          }
        }}
        duplicateContactToMerge={duplicateContactToMerge}
      />
    </div>
  );
};

export default ContactsDuplicates;
