import { Divider, Drawer, Icon, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PatentsEnum, ResourcesEnum } from '../api/roles/enums';
import { URLs } from '../config/enums';
import { MenuItems, renderMenuItem } from '../helpers/layout-helpers';
import { hasPermissions } from '../helpers/permissions-helpers';

const HeaderActionsMobile: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          type="menu"
          style={{ cursor: 'pointer', color: '#fff', fontSize: '2rem' }}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      </div>
      <Drawer
        placement="right"
        closable={false}
        visible={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <Menu theme="light">
          {MenuItems.map((item) =>
            renderMenuItem({
              item,
              onClick: () => setIsDrawerOpen(!isDrawerOpen),
              isMobile: true,
              hideCaret: true,
            })
          )}
        </Menu>
        <Divider />
        <Menu>
          <Menu.Item key={URLs.PROFILE}>
            <Link to={URLs.PROFILE}>
              <Icon type="user" style={{ marginRight: 5 }} />
              <span>{t('Profile')}</span>
            </Link>
          </Menu.Item>
          {hasPermissions(ResourcesEnum.RECRUITER_CONFIGURATIONS, PatentsEnum.VIEW) && (
            <Menu.Item key={URLs.RECRUITER_CONFIGURATIONS}>
              <Link to={URLs.RECRUITER_CONFIGURATIONS}>
                <Icon type="setting" style={{ marginRight: 5 }} />
                <span>{t('Configurations')}</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key={URLs.LOGOUT}>
            <Link to={URLs.LOGOUT}>
              <Icon type="logout" style={{ marginRight: 5 }} />
              <span>{t('Logout')}</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default HeaderActionsMobile;
