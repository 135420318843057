import { find } from 'lodash';
import { Card, message, Table, Modal, Button, Input, Tag, Row, Col, Select, Tooltip } from 'antd';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  createBaseGridParams,
  getUrlQueryString,
  GridFilter,
  updateGridUrl,
} from '../../helpers/grid-helpers';
import { IEmail } from '../../api/emails/model';
import EmailsService from '../../api/emails/EmailsService';
import { formatDateTime } from '../../helpers/date-helpers';
import { EmailStatus, EmailStatusColorLabel, EmailStatusLabel } from '../../api/emails/enums';

const gridParams = createBaseGridParams({
  sortField: 'createdDate',
  sortOrder: 'descend',
  filters: [],
});

const EmailsGrid: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const urlParams = getUrlQueryString();
  const [selectedEmailId, setSelectedEmailId] = useState<number | undefined>();
  const [searchInput, setSearchInput] = useState(urlParams.search || '');

  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [total, setTotal] = useState(0);
  const [gridState, setGridState] = useState({
    ...gridParams,
    ...urlParams,
    filters: [] as GridFilter[],
  });

  const columns = [
    {
      title: t('Date'),
      dataIndex: 'createdDate',
      render(createdDate: string, email: IEmail) {
        return (
          <Button
            type="link"
            onClick={() => {
              setSelectedEmailId(email.id);
            }}
          >
            {formatDateTime(new Date(createdDate))}
          </Button>
        );
      },
      align: 'center' as 'center',
      sorter: true,
      defaultSortOrder: gridState.sortField === 'createdDate' ? gridState.sortOrder : undefined,
    },
    {
      title: t('Send Date'),
      dataIndex: 'editedDate',
      align: 'center' as 'center',
      render: (cell: any) => (cell ? formatDateTime(new Date(cell)) : '-'),
    },
    {
      title: t('Subject'),
      dataIndex: 'subject',
      align: 'center' as 'center',
    },
    {
      title: t('Sender'),
      dataIndex: 'sender',
      align: 'center' as 'center',
      render: (text: any, record: any) => (
        <>{record?.senderName && `${record?.senderName}` } <br />
          {record?.sender && `${record?.sender}` }
        </>
      ),
    },
    {
      title: t('Recipients'),
      dataIndex: 'recipients',
      align: 'center' as 'center',
    },
    {
      title: t('Job Name'),
      dataIndex: 'jobName',
      align: 'center' as 'center',
    },
    {
      title: t('Campaign Name'),
      dataIndex: 'campaignName',
      align: 'center' as 'center',
    },
    {
      title: t('Status'),
      dataIndex: 'emailStatusCode',
      align: 'center' as 'center',
      render: (emailStatusCode: string, row: IEmail) => (
        <Tooltip title={row.log}>
          <Tag color={EmailStatusColorLabel[emailStatusCode as EmailStatus]}>
            {t(emailStatusCode)}
          </Tag>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await EmailsService.findGrid(gridState);
        setEmails(response.list);
        setTotal(response.count);
        updateGridUrl(history, match.url, gridState);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [gridState, history, match.url]);

  const handleTableOnChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<any>
  ) => {
    setGridState({
      ...gridState,
      sortField: sorter.columnKey || 'createdDate',
      sortOrder: sorter.order || 'descend',
      page: +pagination.current! - 1,
      pageSize: +pagination.pageSize!,
    });
  };

  const handleSearch = (value: string) => {
    setGridState({ ...gridState, search: value, page: 0 });
  };

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleFilterChange = (filter: string, value: any) => {
    if (!value)
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter)],
      });
    else
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter), { key: filter, value }],
      });
  };

  const renderFilters = () => {
    return (
      <Row gutter={12}>
        <Col xs={24} sm={24} md={7} style={{ marginBottom: 10 }}>
          <Input.Search
            placeholder={`${t('Search')}...`}
            enterButton
            onChange={handleOnSearchChange}
            value={searchInput}
            onSearch={handleSearch}
          />
        </Col>

        <Col xs={24} sm={24} md={6}>
          <Select
            allowClear
            placeholder={t('Status')}
            style={{ width: '100%' }}
            onChange={(value: any) => handleFilterChange('status', value)}
          >
            {Object.keys(EmailStatus).map((status) => (
              <Select.Option key={status} value={status}>
                {t(EmailStatusLabel[status as EmailStatus])}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (selectedEmailId) {
      const email = find(emails, { id: selectedEmailId })!;
      Modal.info({
        title: email.subject,
        content: <div dangerouslySetInnerHTML={{ __html: email.body }} />,
        width: 600,
        onCancel: () => {
          setSelectedEmailId(undefined);
        },
        onOk: () => {
          setSelectedEmailId(undefined);
        },
      });
    }
  }, [selectedEmailId, emails]);

  return (
    <div className="container">
      <Card title={t('Emails')}>
        <Table
          rowKey="id"
          bordered
          showHeader
          loading={isLoading}
          onChange={handleTableOnChange}
          title={renderFilters}
          pagination={{
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            current: gridState.page! + 1,
            pageSize: gridState.pageSize,
            total,
          }}
          columns={columns}
          dataSource={emails}
        />
      </Card>
    </div>
  );
};

export default EmailsGrid;
