import queryString from 'query-string';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';

type QueryStringProps = {
  from: string;
};

interface Props {
  path: string;
  exact: boolean;
}

const PublicRoute: React.FC<Props> = ({ exact, path, children }) => {
  const { user } = useAppContext();
  const isLoggedIn = !!user;

  if (isLoggedIn && window.location.pathname.includes(URLs.LOGIN)) {
    const params = queryString.parse(window.location.search) as QueryStringProps;
    return <Redirect to={params.from || URLs.ROOT} />;
  }

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

export default PublicRoute;
