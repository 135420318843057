import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Typography,
  Upload,
  Row,
  Col,
  Divider,
  Select,
  Alert,
} from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState, useEffect, useCallback } from 'react';
import ContactsService from '../../api/contacts/ContactsService';
import {
  ImportContactsCheckModel,
  ImportContactsCheckModelColumn,
  ImportContactsAddType,
} from '../../api/contacts/model';
import { validateAntForm } from '../../helpers/ant-form';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { MessageType } from 'antd/lib/message';
import { ContactList } from '../../api/contacts/lists/model';
import { ContactDefaultRoute, ContactDefaultRouteLabel } from '../../api/contacts/enums';

interface FormValues {
  listName: string;
  columns: ImportContactsCheckModelColumn[];
  newListName: string;
  defaultCampaignRoute: string;
}

interface Props extends FormComponentProps<FormValues> {
  contactLists: ContactList[];
  isVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const formItemLayout: FormItemProps = {
  labelCol: {
    xs: 24,
    sm: 24,
    md: 13,
  },
  wrapperCol: {
    xs: 24,
    sm: 24,
    md: 11,
  },
};

const ImportContactCSVModal: React.FC<Props> = ({
  form,
  isVisible,
  onCancel,
  onOk,
  contactLists,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<UploadFile>();
  const [fileError, setFileError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [importCheckModel, setImportCheckModel] = useState<ImportContactsCheckModel>();

  const handleSubmit = async () => {
    let loadingMessage: MessageType | undefined;

    try {
      if (!file) {
        setFileError(true);
        return;
      }

      if (!importCheckModel) {
        throw new Error(t('No file uploaded'));
      }

      setIsSubmitting(true);

      const values = await validateAntForm(form);
      const { listName, newListName, columns, defaultCampaignRoute } = values;

      let listToSend = listName || newListName;

      const formData = new FormData();
      formData.append('file', (file as unknown) as File);

      const model: ImportContactsAddType = {
        columns: columns.filter((c) => c.columnNumber !== undefined),
        hasHeader: importCheckModel.hasHeader,
        listName: listToSend,
        defaultCampaignRoute: defaultCampaignRoute,
      };

      formData.append('model', JSON.stringify(model));

      await ContactsService.uploadImportCSV(formData);

      message.success(t('File successfully uploaded'));

      onOk();
      setFileError(false);
      setFile(undefined);
      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);

      if (loadingMessage) {
        loadingMessage();
      }
    }
  };

  const handleChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setFile(undefined);
      setImportCheckModel(undefined);
    } else {
      setFile(info.file);
      setImportCheckModel(undefined);
    }

    setFileError(false);
  };

  const uploadFile = useCallback(() => {
    const upload = async () => {
      try {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', (file as unknown) as File);
        const response = await ContactsService.importCSVCheckColumns(formData);
        setImportCheckModel(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsUploading(false);
      }
    };
    if (file) {
      upload();
    } else {
      setImportCheckModel(undefined);
    }
  }, [file]);

  useEffect(() => {
    uploadFile();
  }, [uploadFile]);

  return (
    <Modal
      visible={isVisible}
      title={t('Import CSV')}
      maskClosable={false}
      onCancel={() => {
        if (!isSubmitting) {
          form.resetFields();
          setFile(undefined);
        }

        onCancel();
      }}
      cancelButtonProps={{ disabled: isUploading || isSubmitting }}
      okButtonProps={{ disabled: isUploading || isSubmitting || !file }}
      onOk={
        form.getFieldValue('listName') || form.getFieldValue('newListName')
          ? handleSubmit
          : () =>
              Modal.info({
                maskClosable: true,
                title: t('No list defined'),
                content: <span>{t('You have not defined a list for the contacts')}</span>,
                onOk: (close) => {
                  close();
                  handleSubmit();
                },
              })
      }
      confirmLoading={isSubmitting}
    >
      <Form layout="vertical">
        <Form.Item
          help={fileError ? t('Please select a file') : undefined}
          validateStatus={fileError ? 'error' : 'success'}
        >
          <Typography.Paragraph>
            {t('Make sure the csv file you want to import has proper column headings')}
          </Typography.Paragraph>
          <div style={{ textAlign: 'center' }}>
            <Upload
              accept="text/csv"
              fileList={file ? [file] : []}
              beforeUpload={() => false}
              onChange={handleChange}
              disabled={isSubmitting}
            >
              {!file && (
                <Button type="primary" icon="plus">
                  {t('Select a file')}
                </Button>
              )}
            </Upload>
          </div>
        </Form.Item>
        <Form.Item label={t('Choose a list to save the new contacts')}>
          {form.getFieldDecorator('listName', {
            initialValue: undefined,
          })(
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              disabled={
                (form.getFieldValue('newListName') === '' ||
                form.getFieldValue('newListName') === undefined
                  ? false
                  : true) || (isSubmitting ? true : false)
              }
            >
              {contactLists.map((list) => (
                <Select.Option key={list.id} value={list.name}>
                  {list.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label={t('Or save them in a new list')}
          help={t('If the list already exists, the contacts will be added to the written list')}
        >
          {form.getFieldDecorator('newListName', {
            initialValue: undefined,
            rules: [
              {
                whitespace: false,
                message: t('Field cannot be empty'),
              },
            ],
          })(
            <Input
              maxLength={250}
              disabled={form.getFieldValue('listName') !== undefined || isSubmitting}
            />
          )}
        </Form.Item>

        <Form.Item label={t('Default campaign route')} {...formItemLayout}>
          {form.getFieldDecorator('defaultCampaignRoute', {
            initialValue: undefined,
            rules: [
              {
                required: true,
                message: t('Field cannot be empty'),
              },
            ],
          })(
            <Select placeholder={t('Select a value')} disabled={isSubmitting}>
              {Object.keys(ContactDefaultRoute).map((route) => (
                <Select.Option key={route} value={route}>
                  {ContactDefaultRouteLabel[route as ContactDefaultRoute]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {isUploading && <LoadingSpinner />}

        {!isSubmitting && importCheckModel && (
          <>
            <Alert
              showIcon
              type="info"
              description={
                !importCheckModel.hasHeader
                  ? t('Some columns are missing. Please select the column position')
                  : undefined
              }
              message={t('Please review the match of the columns')}
            />

            <Divider />

            {importCheckModel.columns.map((column, i) => {
              form.getFieldDecorator(`columns[${i}].name`, {
                initialValue: column.name || undefined,
              });

              return (
                <Row gutter={16} key={column.name}>
                  <Col span={12}>
                    <Form.Item label={t('Our column')}>
                      <Input
                        readOnly
                        title={t(column.name)}
                        defaultValue={t(column.name) as string}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t('Your column')}>
                      {form.getFieldDecorator(`columns[${i}].columnNumber`, {
                        initialValue: column.columnNumber || undefined,
                        rules: [
                          {
                            required: column.required,
                            message: t('This field is required'),
                          },
                        ],
                      })(
                        <Select
                          placeholder={t('Select a value')}
                          onChange={(value) => {
                            const values = form.getFieldsValue() as ImportContactsCheckModel;
                            const fieldIndex = values.columns.findIndex(
                              (c) => c.columnNumber === value
                            );
                            form.setFieldsValue({
                              [`columns[${fieldIndex}].columnNumber`]: undefined,
                            });
                          }}
                        >
                          {importCheckModel.excelColumnNames.map((excelColumn, y) => (
                            <Select.Option value={y + 1} key={(y + 1).toString()}>
                              {`${excelColumn} (${t('column')} #${y + 1})`}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default Form.create<Props>()(ImportContactCSVModal);
