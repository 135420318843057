import React from 'react';
import i18n from 'i18next';
import { ResourcesEnum, PatentsEnum } from '../api/roles/enums';
import { URLs } from '../config/enums';
import { hasPermissions } from './permissions-helpers';
import { Icon, Menu } from 'antd';
import { Link } from 'react-router-dom';

export interface MenuItem {
  key: string;
  path?: string;
  title: string;
  icon?: string;
  resource?: ResourcesEnum;
  subMenu?: MenuItem[];
}

export const MenuItems: MenuItem[] = [
  {
    key: URLs.ROOT,
    path: URLs.ROOT,
    title: 'Dashboard',
  },
  {
    key: URLs.USERS,
    path: URLs.USERS,
    title: 'Users',
    resource: ResourcesEnum.USERS,
  },
  {
    key: URLs.ROLES,
    path: URLs.ROLES,
    title: 'Roles',
    resource: ResourcesEnum.ROLES,
  },
  {
    key: URLs.JOBS,
    path: URLs.JOBS,
    title: 'Jobs',
    resource: ResourcesEnum.JOBS,
  },
  {
    key: URLs.JOB_CUSTOM_MESSAGE_TEMPLATES,
    path: URLs.JOB_CUSTOM_MESSAGE_TEMPLATES,
    title: 'Templates',
    resource: ResourcesEnum.JOB_MESSAGE_TEMPLATE,
  },
  {
    key: URLs.USERS_ACTIVITIES,
    path: URLs.USERS_ACTIVITIES,
    title: 'User Activities',
    resource: ResourcesEnum.USER_ACTIVITIES,
  },
  {
    key: URLs.CONTACTS,
    path: URLs.CONTACTS,
    title: 'Contacts',
    resource: ResourcesEnum.CONTACTS,
  },
  {
    key: URLs.EMAILS,
    path: URLs.EMAILS,
    title: 'Messages',
    resource: ResourcesEnum.EMAILS,
    subMenu: [
      {
        key: URLs.EMAILS,
        path: URLs.EMAILS,
        title: 'Emails',
        resource: ResourcesEnum.EMAILS,
      },
      {
        key: URLs.SOCIAL_NETWORK_MESSAGES,
        path: URLs.SOCIAL_NETWORK_MESSAGES,
        title: 'Social Network Messages',
        resource: ResourcesEnum.SOCIAL_NETWORK_MESSAGES,
      },
    ],
  },
  {
    key: URLs.CONFIGURATIONS,
    path: URLs.CONFIGURATIONS,
    title: 'Configurations',
    resource: ResourcesEnum.USERS,
  },
  {
    key: URLs.SURVEYS,
    path: URLs.SURVEYS,
    title: 'Surveys',
    resource: ResourcesEnum.SURVEYS,
  },
  {
    key: URLs.CAMPAIGNS,
    path: URLs.CAMPAIGNS,
    title: 'Campaigns',
    resource: ResourcesEnum.CAMPAIGNS,
  },
];

export interface RenderMenuItem {
  item: MenuItem;
  className?: string;
  onClick?: () => void;
  isMobile?: boolean;
  hideCaret?: boolean;
}

export const renderMenuItem = ({
  item,
  className,
  onClick,
  isMobile,
  hideCaret,
}: RenderMenuItem) => {

  if (item.resource && !hasPermissions(item.resource, PatentsEnum.VIEW)) {
    return false;
  }

  if (!item.subMenu) {
    const menuItem = (
      <>
        {item.icon && <Icon type={item.icon} />}
        <span>{i18n.t(item.title)}</span>
      </>
    );

    return (
      <Menu.Item key={item.key} className={className}>
        {isMobile ? <Link to={item.path!}>{menuItem}</Link> : menuItem}
      </Menu.Item>
    );
  }

  if (
    !item.subMenu.some(
      (subItem) => subItem.resource && hasPermissions(subItem.resource, PatentsEnum.VIEW)
    )
  ) {
    return false;
  }

  return (
    <Menu.SubMenu
      key={item.key}
      className={className}
      title={
        <>
          {item.icon && <Icon type={item.icon} />}
          <span>{i18n.t(item.title)}</span>
          {!hideCaret && ' '}
          {!hideCaret && <Icon type="caret-down" />}
        </>
      }
    >
      {item.subMenu.map((subItem) =>
        renderMenuItem({
          item: subItem,
          className,
          onClick,
          isMobile,
          hideCaret,
        })
      )}
    </Menu.SubMenu>
  );
};
