import { Factory } from 'fishery';
import faker from 'faker';
import { Contact } from './model';
import { MatchedCustomFieldFactory } from '../custom-fields/factory';

export const ContactFactory = Factory.define<Contact>(() => {
  return {
    id: faker.random.number(),
    pictureFileId: faker.random.number(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    jobTitle: faker.name.jobTitle(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber(),
    location: faker.address.city(),
    companyName: faker.company.companyName(),
    linkedinUrl: faker.internet.url(),
    facebookUrl: faker.internet.url(),
    viadeoURL: faker.internet.url(),
    comment: faker.random.word(),
    tags: faker.random.word(),
    pictureUrl: faker.internet.url(),
    matchedCustomFields: MatchedCustomFieldFactory.buildList(0),
  };
});
