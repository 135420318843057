import { Button, Form, Input, message, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthService from '../../api/shared/services/AuthService';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

interface FormValues {
  password: string;
}

interface Props extends FormComponentProps<FormValues> {}

const ResetPassword: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const qs = queryString.parse(window.location.search) as { p: string };
  const history = useHistory();

  const compareToFirstPassword = (
    rule: any,
    value: string,
    callback: (message?: string) => void
  ) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(t('The passwords do not match'));
    } else {
      callback();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const { password } = await validateAntForm(form);

      await AuthService.resetPassword(qs.p, password);
      Modal.success({
        content: `${t('Your password has changed successfully')}!`,
        onOk: () => history.push(URLs.LOGIN),
        centered: true,
      });
      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h1>{t('Reset password')}</h1>
      <p>{t('Set your new password')}!</p>
      <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
        <Form.Item>
          {form.getFieldDecorator('password', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input.Password placeholder={t('Password')} />)}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('passwordConfirmation', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password placeholder={t('Confirm Password')} />)}
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('Change Password')}
          </Button>
        </Form.Item>
      </Form>
      <p style={{ textAlign: 'center' }}>
        <Link to={URLs.LOGIN}>{t('Go back to Log In page')}</Link>
      </p>
    </>
  );
};

export default Form.create()(ResetPassword);
