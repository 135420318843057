import React from 'react';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import SecurityLayout from '../../layout/SecurityLayout';

const ResetPasswordPage: React.FC = () => {
  return (
    <SecurityLayout>
      <ResetPassword />
    </SecurityLayout>
  );
};

export default ResetPasswordPage;
