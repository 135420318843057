import { message, Modal } from 'antd';
import React, { useState } from 'react';
import ClientCompaniesService from '../../api/client-companies/ClientCompaniesService';
import { Contact } from '../../api/contacts/model';
import ContactsList from './ContactsList';
import { useTranslation } from 'react-i18next';

interface Props {
  isVisible: boolean;
  onOk: (contact: Contact) => void;
  onCancel: () => void;
  clientCompanyId?: number;
  senderContactId?: number;
}

const SelectContactModal: React.FC<Props> = ({
  isVisible,
  onCancel,
  onOk,
  clientCompanyId,
  senderContactId,
}) => {
  const { t } = useTranslation();
  const [currentlySelectedContactId, setCurrentlySelectedContactId] = useState(
    senderContactId || 0
  );

  const selectContact = async (contact: Contact) => {
    try {
      if (clientCompanyId) {
        setCurrentlySelectedContactId(contact.id);
        await ClientCompaniesService.addContact(clientCompanyId, contact.id);
      }
      onOk(contact);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setCurrentlySelectedContactId(0);
    }
  };

  return (
    <Modal
      visible={isVisible}
      title={t('Select or create a contact')}
      onCancel={() => onCancel()}
      footer={null}
    >
      <ContactsList
        currentlySelectedContactId={currentlySelectedContactId}
        onSelect={selectContact}
      />
    </Modal>
  );
};

export default SelectContactModal;
