import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Button, List, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Contact, FacebookCommonContact } from '../../api/contacts/model';
import UsersService from '../../api/users/UsersService';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';
import ContactDetailModal from '../Contacts/ContactDetailModal';
import LoadingSpinner from '../Loading/LoadingSpinner';

const gridParams = createBaseGridParams({
  sortField: 'lastName',
  pageSize: 6,
});

interface Props {
  isVisible: boolean;
  facebookUrl: string;
  onClose: (contacts: Contact[]) => void;
}

const ImportFacebookCommonContactsModal: React.FC<Props> = ({
  isVisible,
  facebookUrl,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [commonFacebookContacts, setCommonFacebookContacts] = useState<FacebookCommonContact[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedFacebookContact, setSelectedFacebookContact] = useState<FacebookCommonContact>();
  const [isContactDetailModalVisible, setIsContactDetailModalVisible] = useState(false);
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
  });
  const [createdContacts, setCreatedContacts] = useState<Contact[]>([]);

  const displayModalToCreateContact = (facebookContact: FacebookCommonContact) => {
    setSelectedFacebookContact(facebookContact);
    setIsContactDetailModalVisible(true);
  };

  const handlePaginationChange = (pageNumber: number, pageSize: number | undefined) => {
    setGridState({
      ...gridState,
      page: pageNumber - 1,
      pageSize: pageSize || 24,
    });
  };

  const handleContactDetailOk = async (contact: Contact) => {
    setSelectedFacebookContact(undefined);
    setIsContactDetailModalVisible(false);
    setCreatedContacts([...createdContacts, contact]);
    await fetchCommonContacts(true);
  };

  const handleContactDetailCancel = () => {
    setSelectedFacebookContact(undefined);
    setCreatedContacts([]);
    setIsContactDetailModalVisible(false);
  };

  const fetchCommonContacts = useCallback(
    async (force: boolean = false) => {
      if (!force && (!isVisible || commonFacebookContacts.length > 0)) {
        return;
      }
      try {
        setIsLoading(true);
        const response = await UsersService.findFacebookCommonContacts(facebookUrl);
        setCommonFacebookContacts(response);
        setTotal(response.length);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [isVisible, commonFacebookContacts.length, facebookUrl]
  );

  const handleClose = () => {
    onClose(createdContacts);
    setCreatedContacts([]);
  };

  useEffect(() => {
    fetchCommonContacts();
  }, [fetchCommonContacts]);

  const selectedContact: Partial<Contact> = {
    firstName: selectedFacebookContact?.firstName,
    lastName: selectedFacebookContact?.lastName,
    facebookUrl: selectedFacebookContact?.facebookUrl,
    pictureUrl: selectedFacebookContact?.profilePicture,
  };
  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={handleClose}
        footer={
          <Button type="primary" onClick={handleClose}>
            {t('Accept')}
          </Button>
        }
      >
        {!isLoading ? (
          <>
            <h1>{t('Contacts in common from Facebook')}</h1>
            <List
              dataSource={commonFacebookContacts}
              pagination={{
                current: gridState.page! + 1,
                total,
                pageSize: gridState.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['6', '12', '24', '36', '48'],
                onChange: handlePaginationChange,
                onShowSizeChange: handlePaginationChange,
                showTotal: (totalRecords, rangeRecords) =>
                  `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
              }}
              renderItem={(commonFacebookContact) => (
                <List.Item
                  key={commonFacebookContact.facebookUrl}
                  extra={
                    !commonFacebookContact.contactId && (
                      <Button onClick={() => displayModalToCreateContact(commonFacebookContact)}>
                        {t('Add contact')}
                      </Button>
                    )
                  }
                >
                  <List.Item.Meta
                    avatar={<Avatar src={commonFacebookContact.profilePicture} />}
                    title={
                      <a
                        href={commonFacebookContact.facebookUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {commonFacebookContact.firstName} {commonFacebookContact.lastName}
                      </a>
                    }
                  ></List.Item.Meta>
                </List.Item>
              )}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Modal>
      <ContactDetailModal
        isVisible={isContactDetailModalVisible}
        contact={selectedContact}
        isEditing={false}
        onOk={handleContactDetailOk}
        onCancel={handleContactDetailCancel}
      />
    </>
  );
};

export default ImportFacebookCommonContactsModal;
