import React from 'react';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import { URLs } from '../../config/enums';
import { Switch } from 'react-router-dom';
import UserActivitiesGrid from '../../components/UserActivity/UserActivityGrid';

const UserActivityPage: React.FC = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={URLs.USERS_ACTIVITIES}
      resource={ResourcesEnum.USER_ACTIVITIES}
      patent={PatentsEnum.VIEW}
    >
      <UserActivitiesGrid />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      exact
      path={URLs.USERS_ACTIVITIES_BY_USER}
      resource={ResourcesEnum.USER_ACTIVITIES}
      patent={PatentsEnum.VIEW}
    >
      <UserActivitiesGrid />
    </AuthenticatedRoute>
  </Switch>
);

export default UserActivityPage;
