import { API } from '../../config/api';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import FetchService from '../shared/services/FetchService';
import { ISocialNetworkMessagesFetched } from './models';

export default class SocialNetworkMessagesService {
  static async findGrid(params: GridParams): Promise<ISocialNetworkMessagesFetched> {
    const gridParams = transformGridParams(params);
    return FetchService.get<ISocialNetworkMessagesFetched>({
      url: API.SOCIAL_NETWORK_MESSAGES,
      gridParams,
    });
  }
}
