import { Button, Checkbox, Form, message, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import UsersService from '../../api/users/UsersService';
import { URLs } from '../../config/enums';

interface FormValues {
  agree: boolean;
}

interface Props extends FormComponentProps<FormValues> {}

const ConfirmVerifyEmail: React.FC<Props> = ({ form }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const match = useRouteMatch<{ hash: string }>();

  const history = useHistory();
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      await UsersService.confirmVerifyEmail(match.params.hash);
      Modal.success({
        content: t('Your email has been confirmed successfully'),
        onOk: () => history.push(URLs.LOGIN),
        centered: true,
      });
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h1 style={{ marginBottom: '2rem' }}>{t('Confirm your email address')}</h1>
      <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
        <Form.Item>
          {form.getFieldDecorator('agree', {
            initialValue: false,
            valuePropName: 'checked',
          })(
            <Checkbox>
              {t('I agree that my email will be used to send emails as me through the platform')}
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting || !form.getFieldValue('agree')}
          >
            {t('Confirm Email')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create()(ConfirmVerifyEmail);
