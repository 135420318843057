import { Button, Card, Col, Form, List, message, Modal, Radio, Row, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import HtmlReactParse from 'html-react-parser';
import { filter, get, map, reject } from 'lodash';
import printJs from 'print-js';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobCandidateStatusEnum } from '../../api/job-candidates/enums';
import JobCandidateService from '../../api/job-candidates/JobCandidatesService';
import { JobCandidate } from '../../api/job-candidates/model';
import { JobStatusEnum } from '../../api/jobs/enums';
import { API } from '../../config/api';
import { API_URL } from '../../config/general-config';
import { validateAntForm } from '../../helpers/ant-form';
import { useJobByToken } from '../../hooks/jobs';
import { useAppContext } from '../../layout/AppContext';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import CandidateTile from '../Candidates/CandidateTile';
import JobCandidateDetailModal from '../Jobs/JobCandidateDetailModal';
import Loading from '../Loading/Loading';

interface FormValues {
  candidates: {
    id: number;
    status: string;
  }[];
}

interface Props extends FormComponentProps<FormValues> {}

const ViewCandidates: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { changeLanguage } = useAppContext();
  const qs = queryString.parse(window.location.search) as { p: string };
  const { isLoadingJob, job } = useJobByToken(qs.p);
  const [acceptedCandidates, setAcceptedCandidates] = useState<JobCandidate[]>([]);
  const [rejectedCandidates, setRejectedCandidates] = useState<JobCandidate[]>([]);
  const [newCandidates, setNewCandidates] = useState<JobCandidate[]>([]);
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<JobCandidate>();
  const [isVisibleCandidateModal, setIsVisibleCandidateModal] = useState(false);
  const [restoringCandidateIds, setRestoringCandidateIds] = useState<number[]>([]);

  const fetchJobCandidates = useCallback(() => {
    const fetchData = async () => {
      try {
        if (job) {
          setIsLoadingCandidates(true);
          let response = await JobCandidateService.find(job.id);
          setNewCandidates(filter(response, { status: JobCandidateStatusEnum.SENT_CLIENT }));
          setAcceptedCandidates(filter(response, { status: JobCandidateStatusEnum.ACCEPTED }));
          setRejectedCandidates(
            filter(response, { status: JobCandidateStatusEnum.REJECTED_CLIENT })
          );
        } else {
          setNewCandidates([]);
          setAcceptedCandidates([]);
          setRejectedCandidates([]);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingCandidates(false);
      }
    };

    fetchData();

    if (job) {
      changeLanguage(job.language);
    }
  }, [job, changeLanguage]);

  useEffect(() => {
    fetchJobCandidates();
  }, [fetchJobCandidates]);

  useEffect(() => {
    if (selectedCandidate) {
      setIsVisibleCandidateModal(true);
    } else {
      setIsVisibleCandidateModal(false);
    }
  }, [selectedCandidate]);

  if (isLoadingJob) {
    return <Loading />;
  }

  if (!job || job.jobStatus !== JobStatusEnum.OPEN) {
    return <NotFoundPage hideHeader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const values = await validateAntForm(form);
      const accepted = filter(values.candidates, {
        status: JobCandidateStatusEnum.ACCEPTED,
      });
      const rejected = filter(values.candidates, {
        status: JobCandidateStatusEnum.REJECTED_CLIENT,
      });

      await JobCandidateService.changeStatus({
        jobId: job.id,
        status: JobCandidateStatusEnum.ACCEPTED,
        jobCandidateIds: map(accepted, 'id'),
      });
      await JobCandidateService.changeStatus({
        jobId: job.id,
        status: JobCandidateStatusEnum.REJECTED_CLIENT,
        jobCandidateIds: map(rejected, 'id'),
      });

      Modal.success({
        centered: true,
        title: `${t('You selected candidates for')} ${job.jobTitle}!`,
        content: `${t('Your selections were sent successfully')}!`,
        onOk: () => {
          fetchJobCandidates();
        },
      });

      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const restoreStatus = async (candidateId: number) => {
    try {
      setRestoringCandidateIds([...restoringCandidateIds, candidateId]);
      await JobCandidateService.changeStatus({
        jobId: job.id,
        status: JobCandidateStatusEnum.SENT_CLIENT,
        jobCandidateIds: [candidateId],
      });
      fetchJobCandidates();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setRestoringCandidateIds(reject(restoringCandidateIds, candidateId));
    }
  };

  const handleStatusClick = (statusClicked: JobCandidateStatusEnum, index: number) => {
    const currentStatus = form.getFieldValue(`candidates[${index}].status`);
    if (statusClicked === currentStatus) {
      form.setFieldsValue({ [`candidates[${index}].status`]: undefined });
    }
  };

  const candidates = get(form.getFieldsValue(), 'candidates', []);
  const candidatesWithStatusPreselected = reject(candidates, { status: undefined });

  return (
    <>
      <div className="view-candidates__client-text">{HtmlReactParse(job.clientPage || '')}</div>

      <div className="container">
        <Row gutter={16} style={{ margin: 0 }}>
          <Col xs={24} md={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 4 }}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <div style={{ textAlign: 'right' }}>
                  <Button.Group>
                    <Tooltip title={t('Export Candidates')}>
                      <Button
                        type="primary"
                        href={`${API_URL}${API.JOB_CANDIDATES_EXPORT.replace(
                          ':id',
                          job.id.toString()
                        )}`}
                        target="_blank"
                        icon="export"
                      />
                    </Tooltip>
                    <Tooltip title={t('Print')}>
                      <Button
                        type="primary"
                        icon="printer"
                        onClick={() => {
                          printJs({
                            printable: [
                              ...newCandidates,
                              ...rejectedCandidates,
                              ...acceptedCandidates,
                            ].map((candidatesToPrint) => ({
                              firstName: candidatesToPrint.firstName,
                              lastName: candidatesToPrint.lastName,
                              email: candidatesToPrint.email || '',
                              phone: candidatesToPrint.phone || '',
                              company: candidatesToPrint.company || '',
                              jobTitle: candidatesToPrint.jobTitle || '',
                            })),
                            header: `Candidates for ${job.jobTitle}`,
                            properties: [
                              {
                                field: 'firstName',
                                displayName: 'First Name',
                              },
                              {
                                field: 'lastName',
                                displayName: 'Last Name',
                              },
                              {
                                field: 'email',
                                displayName: 'Email',
                              },
                              {
                                field: 'phone',
                                displayName: 'Phone',
                              },
                              {
                                field: 'company',
                                displayName: 'Company',
                              },
                              {
                                field: 'jobTitle',
                                displayName: 'Position',
                              },
                            ],
                            type: 'json',
                          });
                        }}
                      />
                    </Tooltip>
                  </Button.Group>
                </div>
                <List
                  header={t('New')}
                  dataSource={newCandidates}
                  loading={isLoadingCandidates}
                  className="contact-list"
                  renderItem={(candidate, i) => {
                    form.getFieldDecorator(`candidates[${i}].id`, { initialValue: candidate.id });
                    return (
                      <CandidateTile
                        candidate={candidate}
                        actions={[
                          form.getFieldDecorator(`candidates[${i}].status`)(
                            <Radio.Group
                              buttonStyle="solid"
                              className="view-candidates__radio-buttons"
                              disabled={isSubmitting}
                            >
                              <Radio.Button
                                value={JobCandidateStatusEnum.ACCEPTED}
                                className="view-candidates__radio view-candidates__radio--accept"
                                onClick={(e: any) => handleStatusClick(e.target.value, i)}
                              >
                                {t('Accept')}
                              </Radio.Button>
                              <Radio.Button
                                value={JobCandidateStatusEnum.REJECTED_CLIENT}
                                className="view-candidates__radio view-candidates__radio--reject"
                                onClick={(e: any) => handleStatusClick(e.target.value, i)}
                              >
                                {t('Reject')}
                              </Radio.Button>
                            </Radio.Group>
                          ),
                          <Button
                            type="primary"
                            size="small"
                            className="contact-list__view-btn"
                            onClick={() => setSelectedCandidate(candidate)}
                          >
                            {t('Details')}
                          </Button>,
                        ]}
                      />
                    );
                  }}
                />
                {!!newCandidates.length && (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}
                      disabled={
                        isLoadingCandidates ||
                        isSubmitting ||
                        !candidatesWithStatusPreselected.length
                      }
                    >
                      {t('Confirm selection')}
                    </Button>
                  </div>
                )}
              </Form>

              <List
                header={t('Accepted')}
                dataSource={acceptedCandidates}
                loading={isLoadingCandidates}
                className="contact-list"
                renderItem={(candidate) => (
                  <CandidateTile
                    candidate={candidate}
                    actions={[
                      <Button
                        type="primary"
                        size="small"
                        className="contact-list__view-btn"
                        onClick={() => restoreStatus(candidate.id)}
                        disabled={restoringCandidateIds.includes(candidate.id)}
                        loading={restoringCandidateIds.includes(candidate.id)}
                        icon="undo"
                      >
                        {t('Move to new')}
                      </Button>,
                      <Button
                        type="primary"
                        size="small"
                        className="contact-list__view-btn"
                        onClick={() => setSelectedCandidate(candidate)}
                      >
                        {t('Details')}
                      </Button>,
                    ]}
                  />
                )}
              />

              <List
                header={t('Rejected')}
                dataSource={rejectedCandidates}
                loading={isLoadingCandidates}
                className="contact-list"
                renderItem={(candidate) => (
                  <CandidateTile
                    candidate={candidate}
                    actions={[
                      <Button
                        type="primary"
                        size="small"
                        className="contact-list__view-btn"
                        onClick={() => restoreStatus(candidate.id)}
                        disabled={restoringCandidateIds.includes(candidate.id)}
                        loading={restoringCandidateIds.includes(candidate.id)}
                        icon="undo"
                      >
                        {t('Move to new')}
                      </Button>,
                      <Button
                        type="primary"
                        size="small"
                        className="contact-list__view-btn"
                        onClick={() => setSelectedCandidate(candidate)}
                      >
                        {t('Details')}
                      </Button>,
                    ]}
                  />
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <JobCandidateDetailModal
        isVisible={isVisibleCandidateModal}
        onCancel={() => {
          setSelectedCandidate(undefined);
          setIsVisibleCandidateModal(false);
        }}
        jobCandidate={selectedCandidate}
        job={job}
      />
    </>
  );
};

export default Form.create()(ViewCandidates);
