import React from 'react';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import { URLs } from '../../config/enums';
import { Switch } from 'react-router-dom';
import EmailsGrid from '../../components/Emails/EmailsGrid';

const EmailsPage: React.FC = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={URLs.EMAILS}
      resource={ResourcesEnum.EMAILS}
      patent={PatentsEnum.VIEW}
    >
      <EmailsGrid />
    </AuthenticatedRoute>
  </Switch>
);

export default EmailsPage;
