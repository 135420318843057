import React, { useCallback, useEffect, useState } from 'react';
import { Affix, Avatar, Button, Card, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../layout/AppContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { JobCandidate } from '../../api/job-candidates/model';
import LoadingSpinner from '../Loading/LoadingSpinner';
import JobCandidateService from '../../api/job-candidates/JobCandidatesService';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import { Contact } from '../../api/contacts/model';
import SelectSurveyModal from '../Surveys/SelectSurveyModal';
import { Survey } from '../../api/surveys/models';
import SurveysService from '../../api/surveys/SurveysService';
import { URLs } from '../../config/enums';
import ImportCommonContacts from '../ImportContacts/ImportCommonContacts';
import ContactsListSend from '../Contacts/ContactsListSend';
import { SurveySendRoute } from '../../api/surveys/enums';
import { filter, map, find } from 'lodash';
import SocialNetworkLimitAlert from '../SocialNetworkLimitAlert/SocialNetworkLimitAlert';

const JobsCreateSurvey: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch<{ jobId: string; candidateId: string }>();
  const { user } = useAppContext();

  const [jobCandidate, setJobCandidate] = useState<JobCandidate>();
  const [isLoadingJobCandidate, setIsLoadingJobCandidate] = useState(false);
  const [shouldRefetchContacts, setShouldRefetchContacts] = useState(false);

  const [listId, setListId] = useState<number | undefined>();

  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [isSelectingAllContacts, setIsSelectingAllContacts] = useState<boolean>(false);

  const [selectedEmailContacts, setSelectedEmailContacts] = useState<Contact[]>([]);
  const [selectedLinkedinContacts, setSelectedLinkedinContacts] = useState<Contact[]>([]);
  const [selectedFacebookContacts, setSelectedFacebookContacts] = useState<Contact[]>([]);

  const [isSelectSurveyModalVisible, setIsSelectSurveyModalVisible] = useState(false);
  const [isSendingSurvey, setIsSendingSurvey] = useState(false);

  const handleRefetchContactsCompleted = () => {
    setShouldRefetchContacts(false);
  };

  const handleContactSelect = (contact: Contact, listName: string, isSelectAll = false) => {
    contact.surveySendRoute = listName;
    let isSelected = !!find(selectedContacts, (item) => item.id === contact.id);

    if (!isSelected) {
      setSelectedContacts((selectedContacts) => [...selectedContacts, contact]);
    } else if (!isSelectAll) {
      setSelectedContacts((selectedContacts) => [
        ...filter(selectedContacts, (c) => c.id !== contact.id),
      ]);
    }
    switch (listName) {
      case SurveySendRoute.EMAIL:
        if (!isSelected) {
          setSelectedEmailContacts((selectedEmailContacts) => [...selectedEmailContacts, contact]);
        } else if (!isSelectAll) {
          setSelectedEmailContacts((selectedEmailContacts) => [
            ...filter(selectedEmailContacts, (c) => c.id !== contact.id),
          ]);
        }
        break;
      case SurveySendRoute.LINKEDIN:
        if (!isSelected) {
          setSelectedLinkedinContacts((selectedLinkedinContacts) => [
            ...selectedLinkedinContacts,
            contact,
          ]);
        } else if (!isSelectAll) {
          setSelectedLinkedinContacts((selectedLinkedinContacts) => [
            ...filter(selectedLinkedinContacts, (c) => c.id !== contact.id),
          ]);
        }
        break;

      case SurveySendRoute.FACEBOOK:
        if (!isSelected) {
          setSelectedFacebookContacts((selectedFacebookContacts) => [
            ...selectedFacebookContacts,
            contact,
          ]);
        } else if (!isSelectAll) {
          setSelectedFacebookContacts((selectedFacebookContacts) => [
            ...filter(selectedFacebookContacts, (c) => c.id !== contact.id),
          ]);
        }
        break;
    }
  };

  const handleSurveySelected = async (survey: Survey) => {
    setIsSelectSurveyModalVisible(false);
    try {
      setIsSendingSurvey(true);
      await SurveysService.send(
        survey,
        selectedEmailContacts,
        selectedLinkedinContacts,
        selectedFacebookContacts,
        jobCandidate!,
        listId,
        false,
        isSelectingAllContacts
      );
      message.success(t('Survey sent successfully!'));
      history.push(URLs.JOBS_DETAIL.replace(':id', params.jobId));
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSendingSurvey(false);
    }
  };

  const handleRemoveAll = () => {
    setSelectedContacts([]);
    setSelectedEmailContacts([]);
    setSelectedLinkedinContacts([]);
    setSelectedFacebookContacts([]);
  };

  const onSelectAllContacts = () => {
    setIsSelectingAllContacts(!isSelectingAllContacts);
    setSelectedContacts([]);
    setSelectedEmailContacts([]);
    setSelectedLinkedinContacts([]);
    setSelectedFacebookContacts([]);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingJobCandidate(true);
        const response = await JobCandidateService.findOne(parseInt(params.candidateId));
        setJobCandidate(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingJobCandidate(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.jobId, params.candidateId]);

  const onSelectContactListId = useCallback((contactListId: number | undefined) => {
    setListId(contactListId);
  }, []);

  if (isSendingSurvey || isLoadingJobCandidate) {
    return <LoadingSpinner />;
  }

  if (params.jobId && params.candidateId && !isLoadingJobCandidate && !jobCandidate) {
    return <NotFoundPage hideHeader />;
  }

  return (
    <Card>
      <div style={{ display: 'flex' }}>
        <Avatar
          icon="user"
          src={jobCandidate?.publicProfilePictureUrl}
          style={{ marginRight: '1rem' }}
        />
        <h1>
          {t('Send survey to jobCandidateFullName contacts', {
            jobCandidateFullName: `${jobCandidate?.firstName} ${jobCandidate?.lastName}`,
            interpolation: { escapeValue: false },
          })}
        </h1>
      </div>
      <SocialNetworkLimitAlert />
      <ContactsListSend
        isSelectingAllContacts={isSelectingAllContacts}
        onSelect={(contact, listName) => handleContactSelect(contact, listName)}
        onSelectAll={(contacts: Contact[]) =>
          contacts.forEach((contact) =>
            handleContactSelect(contact, contact.defaultCampaignSendRoute || 'EMAIL', true)
          )
        }
        onSelectContactListId={onSelectContactListId}
        onSelectAllContacts={onSelectAllContacts}
        onRemoveAll={handleRemoveAll}
        selectedContactIds={map(selectedContacts, 'id')}
        selectedEmailContacts={selectedEmailContacts}
        selectedLinkedinContacts={selectedLinkedinContacts}
        selectedFacebookContacts={selectedFacebookContacts}
        shouldRefetch={shouldRefetchContacts}
        onRefetchCompleted={handleRefetchContactsCompleted}
        extraButtons={
          <ImportCommonContacts
            selectedContacts={selectedContacts}
            isSelectingAllContacts={isSelectingAllContacts}
            jobCandidate={jobCandidate!}
            user={user!}
            addToLinkedInContactList={(commonContact) => {
              handleContactSelect(commonContact, SurveySendRoute.LINKEDIN);
              setShouldRefetchContacts(true);
            }}
          />
        }
      />

      <Affix offsetBottom={20}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={!selectedContacts.length && !isSelectingAllContacts}
            type="primary"
            onClick={() => {
              setIsSelectSurveyModalVisible(true);
            }}
          >
            {t('Select Survey')}
          </Button>
        </div>
      </Affix>

      <SelectSurveyModal
        isSelectingAllContacts={isSelectingAllContacts}
        listId={listId}
        jobId={params.jobId}
        isVisible={isSelectSurveyModalVisible}
        onCancel={() => setIsSelectSurveyModalVisible(false)}
        onOk={handleSurveySelected}
        selectedContacts={selectedContacts.length}
      />
    </Card>
  );
};

export default JobsCreateSurvey;
