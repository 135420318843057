import {
  Button,
  Descriptions,
  message,
  Modal,
  Result,
  Alert,
  Select,
  Form,
  Tooltip,
  Input,
  Row,
  Col,
} from 'antd';
import { filter, find, map } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router-dom';
import { Contact } from '../../api/contacts/model';
import JobsService from '../../api/jobs/JobsService';
import { URLs } from '../../config/enums';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { JobsContext, JobsNewContext } from './JobsContext';
import { JobSendModel } from '../../api/jobs/model';
import { ContactDefaultRoute, ContactDefaultRouteLabel } from '../../api/contacts/enums';
import ContactsListSend from '../Contacts/ContactsListSend';
import { SurveySendRoute } from '../../api/surveys/enums';
import { useLists } from '../../hooks/contacts';
import SocialNetworkLimitAlert from '../SocialNetworkLimitAlert/SocialNetworkLimitAlert';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { SmtpCustomConfigDetailType } from '../../api/recruiter-configurations/models';
import { RecruiterConfigurationsService } from '../../api/recruiter-configurations/RecruiterConfigurationsService';
import { FormComponentProps } from 'antd/lib/form';
import { validateAntForm } from '../../helpers/ant-form';

const formItemLayout: FormItemProps = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

interface FormValues {
  smtpCustomConfigId: number;
  sender: string;
  domainName: string;
  senderName: string;
}

interface JobsEditSelectRecruitersProps extends FormComponentProps<FormValues> { }

const JobsEditSelectRecruiters: React.FC<JobsEditSelectRecruitersProps> = ({ form }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchJobs } = useContext(JobsContext);
  const { config, isLoadingConfig, job } = useContext(JobsNewContext);
  const { lists } = useLists();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVisibleModalSend, setIsVisibleModalSend] = useState(false);
  const [invitedContacts, setInvitedContacts] = useState<Contact[]>([]);
  const [isLoadingInvitedContacts, setIsLoadingInvitedContacts] = useState<boolean>(true);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [listId, setListId] = useState<number | undefined>();
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [isSelectingAllContacts, setIsSelectingAllContacts] = useState<boolean>(false);
  const [excludedContacts, setExcludedContacts] = useState<Contact[]>([]);
  const [shouldRefetchContacts, setShouldRefetchContacts] = useState(false);

  const [defaultRouteOverride, setDefaultRouteOverride] = useState(undefined);
  const [smtpCustomConfigurations, setSmtpCustomConfigurations] = useState<
    SmtpCustomConfigDetailType[]
  >([]);
  const [isLoadingSmtpConfigurations, setIsLoadingSmtpConfigurations] = useState(false);
  const [
    selectedSmtpCustomConfig,
    setSelectedSmtpCustomConfig,
  ] = useState<SmtpCustomConfigDetailType>();

  const [selectedEmailContacts, setSelectedEmailContacts] = useState<Contact[]>([]);
  const [selectedLinkedinContacts, setSelectedLinkedinContacts] = useState<Contact[]>([]);
  const [selectedFacebookContacts, setSelectedFacebookContacts] = useState<Contact[]>([]);

  useEffect(() => {
    const fetchInvitedContacts = async () => {
      try {
        setIsLoadingInvitedContacts(true);
        const response: Contact[] = await JobsService.findInvitedContacts(job!.id);
        setInvitedContacts(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingInvitedContacts(false);
      }
    };

    fetchInvitedContacts();
  }, [job]);

  useEffect(() => {
    defaultRouteOverride && defaultRouteOverride === 'EMAIL' && setSelectedSmtpCustomConfig(
      smtpCustomConfigurations.filter((config) => config.domainNames == `@${job?.senderEmailAddress.split('@')[1]}`)[0]
    );
  }, [defaultRouteOverride])

  useEffect(() => {
    const fetchSmtpCustomConfigurations = async () => {
      if (isVisibleModalSend) {
        setIsLoadingSmtpConfigurations(true);
        try {
          const response = await RecruiterConfigurationsService.findSmtpCustomConfigurations();
          setSmtpCustomConfigurations(response.list);
        } catch (error) {
          if (error.message) message.error(error.message);
        } finally {
          setIsLoadingSmtpConfigurations(false);
        }
      }
    };

    fetchSmtpCustomConfigurations();
  }, [isVisibleModalSend]);

  useEffect(() => {
    setIsSelectingAllContacts(false);
    // setSelectedContacts([]);
  }, [listId]);

  const onSelectContactListId = useCallback((contactListId: number | undefined) => {
    setListId(contactListId);
  }, []);

  if (isLoadingConfig || isLoadingInvitedContacts) {
    return <LoadingSpinner />;
  }

  if (!config) {
    return <Result status="error" title={t('Error fetching the config')} />;
  }

  if (!job) {
    return <NotFoundPage hideHeader />;
  }

  const initialValue = {
    senderName: !(smtpCustomConfigurations.find((config) => config.domainNames == `@${job?.senderEmailAddress.split('@')[1]}`)) ? undefined : `${job?.senderFirstName} ${job?.senderLastName}`,
    sender: !(smtpCustomConfigurations.find((config) => config.domainNames == `@${job?.senderEmailAddress.split('@')[1]}`)) ? undefined : job?.senderEmailAddress?.split('@')[0],
    domainName: !(smtpCustomConfigurations.find((config) => config.domainNames == `@${job?.senderEmailAddress.split('@')[1]}`)) ? undefined : `@${job?.senderEmailAddress?.split('@')[1]} `,
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const values = await validateAntForm(form);
      const model: JobSendModel = {
        emailContacts: selectedEmailContacts,
        linkedinContacts: selectedLinkedinContacts,
        facebookContacts: selectedFacebookContacts,
        listId: listId || null,
        sendToAllContacts: isSelectingAllContacts,
        excludedContactIds: map(excludedContacts, 'id'),
        defaultRouteOverride,
        smtpCustomConfigId: values.smtpCustomConfigId || selectedSmtpCustomConfig?.id,
        fromAddress: (values.smtpCustomConfigId || selectedSmtpCustomConfig) ? `${values.sender}${values.domainName}` : undefined,
        senderName: values.senderName
      };
      await JobsService.sendJob(job.id, model);
      if (fetchJobs) {
        fetchJobs();
      }
      message.success(t('Job sent'));
      history.push(URLs.JOBS_DETAIL.replace(':id', job.id.toString()));
    } catch (error) {
      setIsSubmitting(false);
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const handleContactSelect = (contact: Contact, listName: string, isSelectAll = false) => {
    let isSelected = !!find(selectedContacts, (item) => item.id === contact.id);

    if (!isSelected) {
      setSelectedContacts((selectedContacts) => [...selectedContacts, contact]);
    } else if (!isSelectAll) {
      setSelectedContacts((selectedContacts) => [
        ...filter(selectedContacts, (c) => c.id !== contact.id),
      ]);
    }
    switch (listName) {
      case SurveySendRoute.EMAIL:
        if (!isSelected) {
          setSelectedEmailContacts((selectedEmailContacts) => [...selectedEmailContacts, contact]);
        } else if (!isSelectAll) {
          setSelectedEmailContacts((selectedEmailContacts) => [
            ...filter(selectedEmailContacts, (c) => c.id !== contact.id),
          ]);
        }
        break;
      case SurveySendRoute.LINKEDIN:
        if (!isSelected) {
          setSelectedLinkedinContacts((selectedLinkedinContacts) => [
            ...selectedLinkedinContacts,
            contact,
          ]);
        } else if (!isSelectAll) {
          setSelectedLinkedinContacts((selectedLinkedinContacts) => [
            ...filter(selectedLinkedinContacts, (c) => c.id !== contact.id),
          ]);
        }
        break;

      case SurveySendRoute.FACEBOOK:
        if (!isSelected) {
          setSelectedFacebookContacts((selectedFacebookContacts) => [
            ...selectedFacebookContacts,
            contact,
          ]);
        } else if (!isSelectAll) {
          setSelectedFacebookContacts((selectedFacebookContacts) => [
            ...filter(selectedFacebookContacts, (c) => c.id !== contact.id),
          ]);
        }
        break;
    }
  };

  const handleSelectAllInPage = (contacts: Contact[]) => {
    contacts.forEach((contact) =>
      handleContactSelect(contact, contact.defaultCampaignSendRoute || 'EMAIL', true)
    );
  };

  const handleRemoveAll = () => {
    setSelectedContacts([]);
    setSelectedEmailContacts([]);
    setSelectedLinkedinContacts([]);
    setSelectedFacebookContacts([]);
  };

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const model: JobSendModel = {
        emailContacts: selectedEmailContacts,
        linkedinContacts: selectedLinkedinContacts,
        facebookContacts: selectedFacebookContacts,
        listId: listId || null,
        sendToAllContacts: isSelectingAllContacts,
        excludedContactIds: map(excludedContacts, 'id'),
        defaultRouteOverride,
      };
      await JobsService.exportToCSV(job.id, model);
      setIsVisibleModalSend(false);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsExporting(false);
    }
  };

  const handleRefetchContactsCompleted = () => {
    setShouldRefetchContacts(false);
  };

  const onSelectAllContacts = () => {
    setIsSelectingAllContacts(!isSelectingAllContacts);
    setSelectedContacts([]);
    setSelectedEmailContacts([]);
    setSelectedLinkedinContacts([]);
    setSelectedFacebookContacts([]);
    setExcludedContacts([]);
  };

  return (
    <>
      <SocialNetworkLimitAlert />
      <ContactsListSend
        onSelect={(contact, listName) => handleContactSelect(contact, listName)}
        onSelectAll={(contacts: Contact[]) => handleSelectAllInPage(contacts)}
        onSelectContactListId={onSelectContactListId}
        onSelectAllContacts={onSelectAllContacts}
        isSelectingAllContacts={isSelectingAllContacts}
        selectedContactIds={map(selectedContacts, 'id')}
        selectedEmailContacts={selectedEmailContacts}
        selectedLinkedinContacts={selectedLinkedinContacts}
        selectedFacebookContacts={selectedFacebookContacts}
        shouldRefetch={shouldRefetchContacts}
        onRefetchCompleted={handleRefetchContactsCompleted}
        invitedContactIds={map(invitedContacts, 'id')}
        showDefaultRoute
        onRemoveAll={handleRemoveAll}
      />

      <div style={{ textAlign: 'center' }}>
        <Button
          className="jobs-new__btn"
          style={{ marginLeft: 'auto' }}
          htmlType="submit"
          size="large"
          type="primary"
          disabled={(isSubmitting || selectedContacts.length === 0) && !isSelectingAllContacts}
          onClick={() => setIsVisibleModalSend(true)}
        >
          {t('Send Job Request')}
          {selectedContacts.length > 1 && 's'}
        </Button>
      </div>

      <Modal
        title={t('Confirm selection')}
        visible={isVisibleModalSend}
        destroyOnClose
        onCancel={() => {
          setDefaultRouteOverride(undefined);
          setSelectedSmtpCustomConfig(undefined);
          setIsVisibleModalSend(false);
        }}
        footer={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <Button
                type="link"
                onClick={() => handleExport()}
                loading={isExporting}
                disabled={isExporting}
              >
                {t('Export to CSV')}
              </Button>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={isExporting || isSubmitting}
                onClick={() => setIsVisibleModalSend(false)}
              >
                {t('Cancel')}
              </Button>
              <Button
                disabled={isExporting || isSubmitting}
                loading={isSubmitting}
                type="primary"
                onClick={() => handleSubmit()}
              >
                {t('Ok')}
              </Button>
            </div>
          </div>
        }
        bodyStyle={{ maxHeight: 400, overflowY: 'auto' }}
      >
        <div>
          {
            !(smtpCustomConfigurations.find((config) => config.domainNames == `@${job?.senderEmailAddress.split('@')[1]}`)) && defaultRouteOverride === 'EMAIL' && (
              <Alert
                style={{ marginBottom: '1.5rem' }}
                message={t('Default sender not supporting any SMTP Custom Configuration to send emails')}
                showIcon
                type="warning"
              />
            )
          }
          <Form>
            <Form.Item label={t('Override Send Route')} {...formItemLayout}>
              <Select
                placeholder={t('Select a value')}
                optionFilterProp="children"
                allowClear
                style={{ width: '100%' }}
                onChange={(value: any) => setDefaultRouteOverride(value)}
                value={defaultRouteOverride}
              >
                {Object.keys(ContactDefaultRoute).map((item) => (
                  <Select.Option key={item} value={item}>
                    {ContactDefaultRouteLabel[item as ContactDefaultRoute]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={<span>{t('SMTP Custom Configuration')}</span>} {...formItemLayout}>
              {form.getFieldDecorator('smtpCustomConfigId')(
                <Tooltip title={t('Leave empty to use default configuration')}>
                  <Select
                    placeholder={t('Default configuration')}
                    optionFilterProp="children"
                    allowClear
                    style={{ width: '100%' }}
                    loading={isLoadingSmtpConfigurations}
                    disabled={isLoadingSmtpConfigurations}
                    onChange={(value: any) => {
                      setSelectedSmtpCustomConfig(
                        smtpCustomConfigurations.find((config) => config.id === value)
                      );
                      form.setFieldsValue({
                        sender: undefined,
                        domainName: undefined,
                        senderName: undefined,
                        smtpCustomConfigId: value,
                      });
                    }}
                    value={selectedSmtpCustomConfig?.name}
                  >
                    {smtpCustomConfigurations.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              )}
            </Form.Item>

            {selectedSmtpCustomConfig && (
              <Row gutter={12}>
                <Form.Item label={t('Sender Name')} {...formItemLayout}>
                  {form.getFieldDecorator('senderName', {
                    rules: [{ required: true, message: t('This field is required') }],
                    initialValue: initialValue?.senderName
                  })(<Input placeholder={t("Sender Name")} />)}
                </Form.Item>
                <Col span={12}>
                  <Form.Item>
                    {form.getFieldDecorator('sender', {
                      rules: [{ required: true, message: t('This field is required') }],
                      initialValue: initialValue?.sender
                    })(<Input placeholder={t("Sender")} />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    {form.getFieldDecorator('domainName', {
                      rules: [{ required: true, message: t('This field is required') }],
                      initialValue: initialValue?.domainName
                    })(
                      <Select placeholder={t("Select Domain Name")} style={{ width: '100%' }}>
                        {selectedSmtpCustomConfig.domainNames.split(';').map((domain) => (
                          <Select.Option value={domain}>{domain}</Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </div>
        {!isSelectingAllContacts && (
          <>
            <Alert
              type="info"
              showIcon
              message={`${t('The job will be sent to')} ${selectedContacts.length} ${t('contact')}${selectedContacts.length > 1 ? 's' : ''
                }`}
              style={{ marginBottom: '2rem' }}
            />
            <Descriptions column={1} colon={false}>
              {selectedContacts.map((contact) => (
                <Descriptions.Item
                  key={contact.id}
                  label={`${contact.firstName} ${contact.lastName}`}
                >
                  {contact.email}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </>
        )}

        {isSelectingAllContacts && (
          <Alert
            type="info"
            showIcon
            message={
              listId
                ? t('The job will be sent to all contacts in the list', {
                  listName: find(lists, { id: listId })?.name,
                })
                : t('The job will be sent to all your contacts')
            }
            description={
              !!excludedContacts.length && (
                <>
                  <p>The following contacts will be excluded:</p>
                  <Descriptions column={1}>
                    {excludedContacts.map((excludedContact: Contact) => (
                      <Descriptions.Item
                        key={excludedContact.id.toString()}
                        label={`${excludedContact.firstName} ${excludedContact.lastName}`}
                      >
                        {excludedContact.email}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </>
              )
            }
          />
        )}
      </Modal>
    </>
  );
};

export default Form.create()(JobsEditSelectRecruiters);
