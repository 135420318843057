
export enum ContactImportStatusEnum{
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED'
}

export enum ContactImportLogDescriptionEnum{
    CONTACT_DUPLICATED = 'CONTACT_DUPLICATED',
    CONTACT_SUCCESSFULLY_IMPORTED = 'CONTACT_SUCCESSFULLY_IMPORTED',
    CONTACT_MISSING_OR_INVALID_DATA =  'CONTACT_MISSING_OR_INVALID_DATA'
}

export enum ContactImportLogImportedStatusEnum{
    IMPORTED = 'IMPORTED',
    NOT_IMPORTED = 'NOT_IMPORTED',
}

export const ContactImportLogImportedStatusLabel = {
    [ContactImportLogImportedStatusEnum.IMPORTED] : 'Imported',
    [ContactImportLogImportedStatusEnum.NOT_IMPORTED]: 'Not Imported'
}

export const ContactImportStatusEnumLabel = {
    [ContactImportStatusEnum.IN_PROGRESS] : 'In Progress',
    [ContactImportStatusEnum.FINISHED] : 'Finished',
    [ContactImportStatusEnum.FAILED] : 'Failed',

}

export const ContactImportStatusColorLabel = {
    [ContactImportStatusEnum.IN_PROGRESS] : '#FFB900',
    [ContactImportStatusEnum.FINISHED] : '#7BE56C',
    [ContactImportStatusEnum.FAILED] : '#FF0400'
}

export const ContactImportLogDescriptionEnumLabel = {
    [ContactImportLogDescriptionEnum.CONTACT_DUPLICATED] : 'Contact duplicated',
    [ContactImportLogDescriptionEnum.CONTACT_SUCCESSFULLY_IMPORTED] : 'Contact successfully imported',
    [ContactImportLogDescriptionEnum.CONTACT_MISSING_OR_INVALID_DATA] : 'Contact has missing or invalid data'
}