export enum JobStatusEnum {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  ARCHIVED = 'ARCHIVED',
}

export enum JobEmailStatusEnum {
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  STOP = 'STOP',
  RESTART = 'RESTART',
  START = 'START',
  COMPLETED = "COMPLETED"
}


export enum JobEmailStatusCode {
  QUEUED = 'QUEUED',
  SENT = 'SENT',
  ERROR = 'ERROR',
  OPEN = 'OPEN',
  BOUNCED = 'BOUNCED'
}

export const JobEmailStatusCodeLabel = {
  [JobEmailStatusCode.QUEUED]: 'Queued',
  [JobEmailStatusCode.SENT]: 'Sent',
  [JobEmailStatusCode.ERROR]: 'Error',
  [JobEmailStatusCode.OPEN]: 'Open',
  [JobEmailStatusCode.BOUNCED]: 'Bounced',
};

export const JobStatusLabel = {
  [JobStatusEnum.DRAFT]: 'Draft',
  [JobStatusEnum.OPEN]: 'Open',
  [JobStatusEnum.ARCHIVED]: 'Archived',
};

export enum JobOrdersEnum {
  JOB_TITLE = 'JOB_TITLE',
  CREATED_DATE = 'CREATED_DATE',
}

export const JobOrders = {
  [JobOrdersEnum.JOB_TITLE]: 'Job Title',
  [JobOrdersEnum.CREATED_DATE]: 'Created Date',
};

export const JobOrdersValues = {
  [JobOrdersEnum.JOB_TITLE]: 'jobTitle',
  [JobOrdersEnum.CREATED_DATE]: 'createdDate',
};

export const JobEmailStatusLabel = {
  [JobEmailStatusEnum.PAUSE]: 'PAUSE',
  [JobEmailStatusEnum.RESTART]: 'RESTART',
  [JobEmailStatusEnum.RESUME]: 'RESUME',
  [JobEmailStatusEnum.START]: 'START',
  [JobEmailStatusEnum.STOP]: 'STOP',
  [JobEmailStatusEnum.COMPLETED]: 'COMPLETED',
};

export const JobEmailStatusColorLabel = {
  [JobEmailStatusCode.QUEUED]: '#FFB900',
  [JobEmailStatusCode.SENT]: '#7BE56C',
  [JobEmailStatusCode.OPEN]: '',
  [JobEmailStatusCode.BOUNCED]: '#FF0400',
  [JobEmailStatusCode.ERROR]: '#FF0400',
};
