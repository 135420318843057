import React from 'react';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import { URLs } from '../../config/enums';
import { ResourcesEnum, PatentsEnum } from '../../api/roles/enums';
import Surveys from '../../components/Surveys/Surveys';

const SurveysPage: React.FC = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        path={URLs.SURVEYS}
        resource={ResourcesEnum.SURVEYS}
        patent={PatentsEnum.VIEW}
      >
        <Surveys />
      </AuthenticatedRoute>
    </Switch>
  );
};

export default SurveysPage;
