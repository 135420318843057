import { first } from 'lodash';
import { GOOGLE_API_KEY, GOOGLE_CLIENT_ID } from '../../../config/general-config';
import { ContactImportModel } from '../../contacts/model';
import FetchService from './FetchService';
import { API } from '../../../config/api';

class GoogleService {
  static async insertScript() {
    return new Promise((resolve) => {
      const script = window.document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        resolve();
      };
      script.src = 'https://apis.google.com/js/platform.js';
      document.body.appendChild(script);
    });
  }

  static async init() {
    return new Promise((resolve, reject) => {
      gapi.load('client', async () => {
        try {
          const auth2 = gapi.auth2.getAuthInstance();
          if (!auth2) {
            const scope = 'profile email';
            const discoveryDocs = ['https://people.googleapis.com/$discovery/rest?version=v1'];

            await gapi.client.init({
              apiKey: GOOGLE_API_KEY,
              clientId: GOOGLE_CLIENT_ID,
              scope,
              discoveryDocs,
            });
            resolve();
          } else {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  static async importContacts() {
    return new Promise(async (resolve, reject) => {
      try {
        await gapi.client.load('https://people.googleapis.com/$discovery/rest?version=v1');

        const auth2 = gapi.auth2.getAuthInstance();
        const user = auth2.currentUser.get();

        const scope = 'https://www.googleapis.com/auth/contacts.readonly';
        if (!user.hasGrantedScopes(scope)) {
          await user.grant({ scope });
        }

        gapi.auth.authorize(
          {
            client_id: GOOGLE_CLIENT_ID,
            scope,
            immediate: true,
          },
          async () => {
            try {
              const response = await gapi.client.people.people.connections.list({
                resourceName: 'people/me',
                personFields: 'names,emailAddresses,organizations,phoneNumbers',
                pageSize: 2000,
              });

              const model: ContactImportModel[] = [];

              response.result.connections?.forEach((people) => {
                const contact: ContactImportModel = {
                  firstName: first(people.names)?.givenName,
                  lastName: first(people.names)?.familyName,
                  companyName: first(people.organizations)?.name,
                  email: first(people.emailAddresses)?.value,
                  phone: first(people.phoneNumbers)?.value,
                };
                model.push(contact);
              });

              await FetchService.post({
                url: API.CONTACTS_IMPORT_LIST,
                body: model,
              });
              resolve();
            } catch (error) {
              reject(error);
            }
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  static async login() {
    const auth2 = gapi.auth2.getAuthInstance();
    const googleUser = await auth2.signIn();
    const googleProfile = googleUser.getBasicProfile();
    const authResponse = googleUser.getAuthResponse();

    const profile = {
      userId: googleProfile.getId(),
      firstName: googleProfile.getGivenName(),
      lastName: googleProfile.getFamilyName(),
      email: googleProfile.getEmail(),
      profilePictureUrl: googleProfile.getImageUrl(),
      accessToken: authResponse.id_token,
    };

    return profile;
  }
}

export default GoogleService;
