export enum URLs {
  ROOT = '/',
  LOGIN = '/login',
  LOGIN_LINKEDIN = '/login/linkedin',
  LOGIN_LINKEDIN_GET_ACCESS_TOKEN = '/login/linkedin/access-token',
  LOGOUT = '/logout',
  SESSION_VALID = '/session-valid',
  PROFILE = '/profile',
  RECOVER_PASSWORD = '/recover-password',
  RESET_PASSWORD = '/reset-password',
  CONFIRM_EMAIL = '/confirm-email',
  CONFIRM_VERIFY_EMAIL = '/confirm-verify-email/:hash',
  SIGN_UP = '/sign-up',

  EVENTS = '/events',

  USERS = '/users',
  USERS_NEW = '/users/new',
  USERS_DETAIL = '/users/:id/detail',

  ROLES = '/roles',
  ROLES_DETAIL = '/roles/:id/detail',

  USERS_ACTIVITIES = '/user-activities',
  USERS_ACTIVITIES_BY_USER = '/user-activities/user/:userId',

  EMAILS = '/emails',
  EMAILS_DETAIL = '/emails/:id/detail',

  SOCIAL_NETWORK_MESSAGES = '/social-network-messages',

  CONFIGURATIONS = '/configurations',
  RECRUITER_CONFIGURATIONS = '/recruiter-configurations',
  RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_NEW = '/recruiter-configurations/smtp-custom-configs/new',
  RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_DETAIL = '/recruiter-configurations/smtp-custom-configs/:id/detail',

  JOBS = '/jobs',
  JOBS_ALL = '/jobs/all',
  JOBS_NEW = '/jobs/new',
  JOBS_DETAIL = '/jobs/:id/detail',
  JOBS_EDIT = '/jobs/:id/edit',
  JOBS_DRAFT = '/jobs/:id/draft',
  JOBS_DETAIL_SUGGEST = '/jobs/:id/detail/suggest',
  JOBS_DETAIL_CANDIDATES = '/jobs/:id/detail/candidates',
  JOBS_DETAIL_SURVEYS = '/jobs/:id/detail/surveys',
  JOBS_DETAIL_CREATE_SURVEY = '/jobs/:jobId/candidates/:candidateId/surveys',

  SUGGEST_CANDIDATE = '/suggest-candidate',
  VIEW_CANDIDATES = '/view-candidates',

  CONTACTS = '/contacts',
  CONTACTS_DUPLICATES = '/contacts/duplicates',
  CONTACTS_NEW = '/contacts/new',
  CONTACTS_DETAIL = '/contacts/:id/detail',
  CONTACTS_IMPORTS = '/contacts/contact-imports',

  MANAGE_CONTACT_LISTS = '/manage-lists',
  MANAGE_CONTACT_LISTS_DETAIL = '/manage-lists/:id/detail',

  COMPLETE_SURVEY = '/complete-survey',
  SURVEYS = '/surveys',
  SURVEYS_NEW = '/surveys/new',
  SURVEYS_EDIT = '/surveys/:id/edit',
  SURVEYS_RESULTS = '/surveys/:id/results',
  SURVEYS_CUSTOMIZE_MESSAGES = '/surveys/customize-messages',

  JOB_CUSTOM_MESSAGE_TEMPLATES = '/job-custom-message-templates',
  JOB_CUSTOM_MESSAGE_TEMPLATES_NEW = '/job-custom-message-templates/new',
  JOB_CUSTOM_MESSAGE_TEMPLATES_DETAIL = '/job-custom-message-templates/:id/detail',

  CAMPAIGNS = '/campaigns',
  CAMPAIGNS_NEW = '/campaigns/new',
  CAMPAIGNS_CUSTOMIZE_MESSAGES = '/campaigns/customize-messages',
  CAMPAIGNS_DETAIL = '/campaigns/:id/detail',
  CAMPAIGNS_EDIT = '/campaigns/:id/edit',
  CAMPAIGNS_DRAFT = '/campaigns/:id/draft',
  CAMPAIGNS_COMPLETED = '/campaigns/:id/completed',
}
