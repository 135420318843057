import React, { useState, useEffect, useCallback } from 'react';
import { Menu, message, Tooltip, Button } from 'antd';
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import LoadingSpinner from '../Loading/LoadingSpinner';
import CampaignsNewCustomizeMessage from './CampaignsEditCustomizeMessage';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import { URLs } from '../../config/enums';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../layout/AppContext';
import CampaignsEditInformation from './CampaignsEditInformation';
import { Campaign } from '../../api/campaigns/model';
import CampaignsService from '../../api/campaigns/CampaignsService';
import { CampaignsNewContext } from './CampaignsContext';
import CampaignsEditSelectContacts from './CampaignsEditSelectContacts';
import { CampaignStatusEnum } from '../../api/campaigns/enum';
import classNames from 'classnames';

const MenuItemsKeys = {
    INFORMATION: 'INFORMATION',
    CAMPAIGN_MESSAGE: 'CAMPAIGN_MESSAGE',
    SELECT_CONTACT: 'SELECT_CONTACT',
};

const CampaignsEdit: React.FC = () => {
    const { t } = useTranslation();

    const MenuItemsLabels = {
        [MenuItemsKeys.INFORMATION]: t('Information'),
        [MenuItemsKeys.CAMPAIGN_MESSAGE]: t('Campaign Message'),
        [MenuItemsKeys.SELECT_CONTACT]: t('Select Contacts'),
    };

    const { params } = useRouteMatch<{ id: string }>();
    const history = useHistory();
    const { user } = useAppContext();
    const [campaign, setCampaign] = useState<Campaign>();
    const [isLoadingCampaign, setIsLoadingCampaign] = useState(true);
    const [selectedMenuKey, setSelectedMenuKey] = useState<string>();

    const fetchCampaign = useCallback(() => {
        const fetch = async () => {
            try {
                if (params.id) {
                    setIsLoadingCampaign(true);
                    const response = await CampaignsService.findOne(parseInt(params.id));
                    setCampaign(response);
                } else {
                    setCampaign(undefined);
                }
            } catch (error) {
                if (error.message) {
                    message.error(error.message);
                }
            } finally {
                setIsLoadingCampaign(false);
            }
        };
        fetch();
    }, [params.id]);

    useEffect(() => {
        fetchCampaign();
    }, [fetchCampaign]);

    useEffect(() => {
        setSelectedMenuKey(MenuItemsKeys.INFORMATION);
    }, [params.id]);

    if (!user) {
        return <Redirect to={URLs.LOGIN} />;
    }

    if (params.id && !isLoadingCampaign && !campaign) {
        return <NotFoundPage hideHeader />;
    }

    let title: JSX.Element | string = <LoadingSpinner size="small" align="left" />;

    if (!isLoadingCampaign) {
        if (campaign) {
            title = campaign.campaignName;
        } else {
            title = t('New Campaign');
        }
    }

    return (
        <>
            <div className="jobs-new__header">
                <span className="jobs-new__header-title">
                    {title}
                    <span style={{ lineHeight: '1.4rem' }}
                        className={classNames('tile__campaigns__headerstatus', {
                            'tile__campaigns__headerstatus--draft': campaign?.campaignStatus === CampaignStatusEnum.Draft,
                            'tile__campaigns__headerstatus--scheduled': campaign?.campaignStatus === CampaignStatusEnum.Scheduled,
                            'tile__campaigns__headerstatus--started': campaign?.campaignStatus === CampaignStatusEnum.Started,
                            'tile__campaigns__headerstatus--archived': campaign?.campaignStatus === CampaignStatusEnum.Archived,
                            'tile__campaigns__headerstatus--paused': campaign?.campaignStatus === CampaignStatusEnum.Paused,
                            'tile__campaigns__headerstatus--stopped': campaign?.campaignStatus === CampaignStatusEnum.Stopped,
                            'tile__campaigns__headerstatus--completed': campaign?.campaignStatus === CampaignStatusEnum.Completed,
                        })}
                    >{campaign && t(campaign?.campaignStatus)}</span>
                    {campaign && campaign?.campaignStatus != CampaignStatusEnum.Completed && (
                        <Tooltip title={t('View Detail')}>
                            <Button
                                icon="eye"
                                shape="circle-outline"
                                type="primary"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => history.push(URLs.CAMPAIGNS_DETAIL.replace(':id', params?.id.toString()))}
                            />
                        </Tooltip>
                    )}
                </span>
                <Menu
                    selectedKeys={selectedMenuKey ? [selectedMenuKey] : undefined}
                    mode="horizontal"
                    className="jobs-new__menu"
                    onClick={(e) => setSelectedMenuKey(e.key)}
                >
                    {Object.keys(MenuItemsKeys).map((key) => (
                        <Menu.Item
                            disabled={key !== selectedMenuKey && (!params.id || isLoadingCampaign)}
                            key={key}
                            className="jobs-new__menu-item"
                        >
                            {MenuItemsLabels[key]}
                        </Menu.Item>
                    ))}
                </Menu>
            </div>
            <div className="jobs-new__content">
                {!isLoadingCampaign ? (
                    <CampaignsNewContext.Provider
                        value={{
                            campaign,
                            isLoadingCampaign,
                            fetchCampaign,
                            setCampaign,
                        }}
                    >
                        {selectedMenuKey === MenuItemsKeys.INFORMATION && (
                            <CampaignsEditInformation
                                onSubmit={() => setSelectedMenuKey(MenuItemsKeys.CAMPAIGN_MESSAGE)}

                            />
                        )}
                        {selectedMenuKey === MenuItemsKeys.CAMPAIGN_MESSAGE && (
                            <CampaignsNewCustomizeMessage
                                onSubmit={() => setSelectedMenuKey(MenuItemsKeys.SELECT_CONTACT)}
                            />
                        )}
                        {selectedMenuKey === MenuItemsKeys.SELECT_CONTACT && <CampaignsEditSelectContacts />}
                    </CampaignsNewContext.Provider>

                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </>
    );
};

export default CampaignsEdit;
