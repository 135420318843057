import { message, Row, Col, Button, List } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Route, Switch } from 'react-router-dom';
import { ReactComponent as IconSurveys } from '../../assets/icon-surveys.svg';
import { FetchedList } from '../../api/shared/models';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';
import SurveysEdit from './SurveysEdit';
import SurveysSelectOne from './SurveysSelectOne';
import { Survey, SurveyConfig } from '../../api/surveys/models';
import SurveysService from '../../api/surveys/SurveysService';
import SurveyTile from './SurveyTile';
import { SurveysContext } from './SurveysContext';
import SurveyDetail from './SurveyDetail';
import SurveysCustomizeMessages from './SurveysCustomizeMessages';

const Surveys: React.FC = () => {
  const { mainHeaderHeight, subHeaderHeight, setSubHeaderHeight } = useAppContext();
  const subHeaderRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const history = useHistory();
  const [surveys, setSurveys] = useState<Survey[]>();
  const [, setTotal] = useState(0);
  const [isLoadingSurveys, setIsLoadingSurveys] = useState(true);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [config, setConfig] = useState<SurveyConfig>();

  const fetchSurveys = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingSurveys(true);
        const response: FetchedList<Survey> = await SurveysService.findAll();

        setSurveys(response.list);
        setTotal(response.count);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingSurveys(false);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    fetchSurveys();
  }, [fetchSurveys]);

  useEffect(() => {
    setSubHeaderHeight(subHeaderRef.current!.clientHeight);
  }, [setSubHeaderHeight]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoadingConfig(true);
        const response = await SurveysService.findConfig();
        setConfig(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingConfig(false);
      }
    };
    fetchConfig();
  }, []);

  return (
    <>
      <div className="sub-header" ref={subHeaderRef} style={{ height: '5rem' }} />

      <div className="container" style={{ paddingTop: 0 }}>
        <Row gutter={16}>
          <Col xs={24} md={10} lg={7} xl={7} xxl={6} style={{ padding: 0 }}>
            <div
              className="column-list"
              style={{
                height: `calc(100vh - ${mainHeaderHeight + subHeaderHeight + 10}px)`,
              }}
            >
              <Button
                onClick={() => history.push(URLs.SURVEYS_NEW)}
                block
                className="add-new-button"
              >
                <span className="add-new-button__icon-bg">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <IconSurveys fill="white" width="32px" height="32px" />
                  </div>
                </span>
                <p className="add-new-button__text">{t('Add new survey')}</p>
              </Button>
              <Button
                onClick={() => history.push(URLs.SURVEYS_CUSTOMIZE_MESSAGES)}
                block
                className="add-new-button"
              >
                <span className="add-new-button__icon-bg">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <IconSurveys fill="white" width="32px" height="32px" />
                  </div>
                </span>
                <p className="add-new-button__text">{t('Customize Messages')}</p>
              </Button>
              <List
                dataSource={surveys}
                loading={isLoadingSurveys}
                renderItem={(survey) => (
                  <List.Item style={{ borderBottom: 0, padding: '.5rem 0' }} key={survey.id}>
                    <SurveyTile survey={survey} />
                  </List.Item>
                )}
              ></List>
            </div>
          </Col>
          <Col xs={24} md={14} lg={17} xl={17} xxl={18}>
            <div
              style={{
                paddingTop: '1.5rem',
              }}
            >
              <SurveysContext.Provider value={{ isLoadingConfig, config, fetchSurveys }}>
                <Switch>
                  <Route exact path={URLs.SURVEYS_NEW} component={SurveysEdit} />
                  <Route exact path={URLs.SURVEYS_EDIT} component={SurveyDetail} />
                  <Route exact path={URLs.SURVEYS} component={SurveysSelectOne} />
                  <Route
                    exact
                    path={URLs.SURVEYS_CUSTOMIZE_MESSAGES}
                    component={SurveysCustomizeMessages}
                  />
                </Switch>
              </SurveysContext.Provider>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Surveys;
