import React from 'react';
import { Switch } from 'react-router-dom';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import SocialNetworkMessagesGrid from '../../components/SocialNetworkMessages/SocialNetworkMessagesGrid';
import { URLs } from '../../config/enums';

const SocialNetworkMessagesPage: React.FC = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={URLs.SOCIAL_NETWORK_MESSAGES}
        patent={PatentsEnum.VIEW}
        resource={ResourcesEnum.SOCIAL_NETWORK_MESSAGES}
      >
        <SocialNetworkMessagesGrid />
      </AuthenticatedRoute>
    </Switch>
  );
};

export default SocialNetworkMessagesPage;
