export enum ContactDefaultRoute{
    EMAIL = "EMAIL",
    LINKEDIN = "LINKEDIN",
    FACEBOOK = "FACEBOOK"
}

export const ContactDefaultRouteLabel = {
    [ContactDefaultRoute.EMAIL]: "Email",
    [ContactDefaultRoute.LINKEDIN] : "LinkedIn",
    [ContactDefaultRoute.FACEBOOK] : "Facebook"
}

export const ContactDefaultRouteFormValues = {
    [ContactDefaultRoute.EMAIL]: "email",
    [ContactDefaultRoute.LINKEDIN] : "linkedinUrl",
    [ContactDefaultRoute.FACEBOOK] : "facebookUrl"
}

