import { ConfigurationSmtp, ConfigurationSmtpUpdate } from './interfaces';
import FetchService from '../shared/services/FetchService';
import { API } from '../../config/api';
import { MessageLimitConfigModel } from '../emails/model';

export class ConfigurationsService {
  static async getSmtpConfiguration(): Promise<ConfigurationSmtp> {
    const response: ConfigurationSmtp = await FetchService.get<ConfigurationSmtp>({
      url: API.CONFIGURATIONS_GET_SMTP,
    });
    return response;
  }

  static async getMessageLimitConfig(): Promise<MessageLimitConfigModel> {
    const response: MessageLimitConfigModel = await FetchService.get<MessageLimitConfigModel>({
      url: API.CONFIGURATIONS_MESSAGE_LIMIT,
    });
    return response;
  }

  static async updateSmtpConfiguration(model: ConfigurationSmtpUpdate): Promise<void> {
    await FetchService.put<void>({ url: API.CONFIGURATIONS_GET_SMTP, body: model });
  }
}
