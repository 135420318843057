import React from 'react';
import { Switch } from 'react-router-dom';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import { RecruiterConfigurationsTabs } from '../../components/RecruiterConfigurations/RecruiterConfigurationsTabs';
import RecruiterSmtpCustomConfigsDetail from '../../components/RecruiterConfigurations/RecruiterSmtpCustomConfigsDetail';
import { URLs } from '../../config/enums';

export const RecruiterConfigurationsPage: React.FC = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={URLs.RECRUITER_CONFIGURATIONS}
      resource={ResourcesEnum.RECRUITER_CONFIGURATIONS}
      patent={PatentsEnum.VIEW}
    >
      <RecruiterConfigurationsTabs />
    </AuthenticatedRoute>

    <AuthenticatedRoute
      path={URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_DETAIL}
      resource={ResourcesEnum.RECRUITER_CONFIGURATIONS}
      patent={PatentsEnum.EDIT}
    >
      <RecruiterSmtpCustomConfigsDetail />
    </AuthenticatedRoute>

    <AuthenticatedRoute
      path={URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_NEW}
      resource={ResourcesEnum.RECRUITER_CONFIGURATIONS}
      patent={PatentsEnum.ADD}
    >
      <RecruiterSmtpCustomConfigsDetail />
    </AuthenticatedRoute>
  </Switch>
);
