import { Button, Form, Input, message, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthService from '../../api/shared/services/AuthService';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { useTranslation } from 'react-i18next';

interface FormValues {
  email: string;
}

interface Props extends FormComponentProps<FormValues> {}

const RecoverPassword: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const { email } = await validateAntForm(form);
      await AuthService.sendRecoverEmail(email.trim());
      Modal.success({
        content: t('We sent you an email, please check it to recover your password!'),
        onOk: () => history.push(URLs.LOGIN),
        centered: true,
      });
      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h1>{t('Recover password')}</h1>
      <p>{t('We will send you an e-mail containing a link to reset your password')}</p>
      <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
        <Form.Item>
          {form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
              {
                type: 'email',
                message: t('The entered email is not valid'),
              },
            ],
          })(<Input autoFocus placeholder={t('Email')} />)}
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('Send Email')}
          </Button>
        </Form.Item>
      </Form>
      <p style={{ textAlign: 'center' }}>
        <Link to={URLs.LOGIN}>{t('Go back to Log In page')}</Link>
      </p>
    </div>
  );
};

export default Form.create()(RecoverPassword);
