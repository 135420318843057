import { PatentsEnum, ResourcesEnum } from './enums';

export interface Resource {
  code: ResourcesEnum;
  name: string;
  patents: PatentsEnum[];
}

export const Resources: Resource[] = [
  {
    code: ResourcesEnum.DASHBOARD,
    name: 'Dashboard',
    patents: [PatentsEnum.VIEW],
  },
  {
    code: ResourcesEnum.USERS,
    name: 'Users',
    patents: [PatentsEnum.ADD, PatentsEnum.EDIT, PatentsEnum.DELETE, PatentsEnum.VIEW],
  },
  {
    code: ResourcesEnum.ROLES,
    name: 'Roles',
    patents: [PatentsEnum.EDIT, PatentsEnum.VIEW],
  },
  {
    code: ResourcesEnum.JOBS,
    name: 'Jobs',
    patents: [PatentsEnum.VIEW],
  },
  {
    code: ResourcesEnum.USER_ACTIVITIES,
    name: 'User Activities',
    patents: [PatentsEnum.VIEW],
  },
  {
    code: ResourcesEnum.CONTACTS,
    name: 'Contacts',
    patents: [PatentsEnum.VIEW],
  },
  {
    code: ResourcesEnum.EMAILS,
    name: 'Emails',
    patents: [PatentsEnum.VIEW],
  },
];
