import React from 'react';
import { Card } from 'antd';
import classNames from 'classnames';
import { Survey } from '../../api/surveys/models';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { getQuestionsLabel } from '../../helpers/string-helpers';

interface Props {
  survey: Survey;
}
const SurveyTile: React.FC<Props> = ({ survey }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>([URLs.SURVEYS_EDIT, URLs.SURVEYS_RESULTS]);

  const handleClick = () => {
    history.push(URLs.SURVEYS_EDIT.replace(':id', survey.id.toString()));
  };

  const questionsCount = survey.surveyTemplateQuestionModels.length;
  const questionsLabel = getQuestionsLabel(survey, t);

  return (
    <Card
      className={classNames('tile tile__survey', {
        'tile__survey--active': match?.params.id === survey.id.toString(),
      })}
      onClick={handleClick}
    >
      <span className="tile__survey__title">{survey.title}</span>
      <span className="tile__survey__questions-count">
        {questionsCount} {questionsLabel}
      </span>
    </Card>
  );
};

export default SurveyTile;
