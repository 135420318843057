import React from 'react';
import { Switch } from 'react-router-dom';
import Login from '../../components/Login/Login';
import LoginWithLinkedInRedirect from '../../components/Login/LoginWithLinkedInRedirect';
import PublicRoute from '../../components/PublicRoute/PublicRoute';
import { URLs } from '../../config/enums';
import SecurityLayout from '../../layout/SecurityLayout';

const LoginPage: React.FC = () => (
  <Switch>
    <PublicRoute exact path={URLs.LOGIN}>
      <SecurityLayout>
        <Login />
      </SecurityLayout>
    </PublicRoute>
    <PublicRoute exact path={URLs.LOGIN_LINKEDIN}>
      <LoginWithLinkedInRedirect />
    </PublicRoute>
  </Switch>
);

export default LoginPage;
