import React from 'react';
import { Switch } from 'react-router-dom';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import UsersDetail from '../../components/Users/UsersDetail';
import UsersGrid from '../../components/Users/UsersGrid';
import { URLs } from '../../config/enums';

const UsersPage: React.FC = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={URLs.USERS}
      resource={ResourcesEnum.USERS}
      patent={PatentsEnum.VIEW}
    >
      <UsersGrid />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      exact
      path={URLs.USERS_NEW}
      resource={ResourcesEnum.USERS}
      patent={PatentsEnum.ADD}
    >
      <UsersDetail />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      exact
      path={URLs.USERS_DETAIL}
      resource={ResourcesEnum.USERS}
      patent={PatentsEnum.EDIT}
    >
      <UsersDetail />
    </AuthenticatedRoute>
  </Switch>
);

export default UsersPage;
