import { Icon } from 'antd';
import classNames from 'classnames';
import React from 'react';

interface Props {
  value?: string;
  onChange?: () => void;
  collapse: string;
  label: string;
  autoFocus?: boolean;
  disabled?: boolean;
  type?: string;
  icon?: string;
  focus: boolean;
  showPassword: boolean;
  handleOnBlur: () => void;
  handleOnFocus: () => void;
  handleTogglePass: () => void;
}

const FloatLabelInput: React.FC<Props> = ({
  value = '',
  onChange,
  label,
  collapse,
  autoFocus = false,
  disabled = false,
  type = 'text',
  icon,
  focus,
  showPassword,
  handleOnBlur,
  handleOnFocus,
  handleTogglePass,
}) => {
  return (
    <div
      className={classNames(`float-label-input float-label-input--collapse-${collapse}`, {
        'float-label-input--dirty': !!value,
        'float-label-input--pristine': !value,
        'float-label-input--focus': focus,
        'float-label-input--unfocus': !focus,
        'float-label-input--disabled': disabled,
      })}
    >
      <span
        className={classNames('float-label-input__label', {
          'float-label-input__label--with-icon': icon!!,
        })}
      >
        <span className="float-label-input__label-text">{label}</span>
      </span>

      {type === 'password' && (
        <Icon
          className="float-label-input__toggle-pass"
          onClick={handleTogglePass}
          type={showPassword ? 'eye-invisible' : 'eye'}
        />
      )}
      {icon && <Icon className="float-label-input__icon" type={icon} />}
      <input
        aria-label={label}
        className={classNames('float-label-input__input', {
          'float-label-input__input--with-icon': icon!!,
        })}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        type={showPassword ? 'text' : type}
        disabled={disabled}
      />
    </div>
  );
};

export default FloatLabelInput;
