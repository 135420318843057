export enum API {
  LOGIN = '/auth/login',
  LOGIN_WITH_FACEBOOK = '/auth/login/facebook',
  LOGIN_WITH_GOOGLE = '/auth/login/google',
  LOGIN_WITH_LINKEDIN = '/auth/login/linkedin',
  LOGIN_WITH_LINKEDIN_ACCESS_TOKEN = '/auth/login/linkedin/access-token',
  LOGOUT = '/auth/logout',
  REGISTER = '/auth/register',
  SEND_RECOVER_PASSWORD = '/auth/recover-password-email',
  RESET_PASSWORD = '/auth/recover-password',
  CONFIRM_EMAIL = '/auth/confirm-email',

  // Business
  CONTACTS = '/contacts',
  CONTACTS_UPLOAD_IMPORT_FILE = '/contacts/upload-import-file',
  CONTACTS_IMPORT_LIST = '/contacts/import-list',
  CONTACTS_IMPORT_CHECK_COLUMNS = '/contacts/import-check-columns',
  CONTACTS_CONTACT_LISTS = '/contacts/:id/contact-lists',
  CONTACTS_DUPLICATES = '/contacts/duplicates',
  CONTACTS_DUPLICATES_MERGE = '/contacts/duplicates/merge',
  CONTACTS_DUPLICATES_DISMISS = '/contacts/duplicates/dismiss',
  CONTACTS_DUPLICATES_DISMISS_ALL = '/contacts/duplicates/dismiss/all',
  CONTACTS_DELETE = '/contacts/:id',
  CONTACTS_DELETE_BULK = '/contacts/bulk',

  CONTACTS_LISTS = '/contact-lists',
  CONTACTS_LISTS_GRID = '/contact-lists/contacts',
  CONTACTS_LISTS_CONTACTS = '/contact-lists/:id/contacts',
  CONTACTS_LISTS_ADD_CONTACT = '/contact-lists/:id/contact/:contact-id',
  CONTACTS_ADD_CONTACTS_TO_LISTS = '/contact-lists/contacts',
  CONTACTS_ADD_ALL_TO_LISTS = '/contact-lists/add-all-contacts',

  CONTACTS_IMPORTS = '/contact-imports',
  CONTACTS_IMPORT_LOGS = '/contact-imports/:id/logs',

  JOBS = '/jobs',
  JOBS_ALL = '/jobs/all',
  JOBS_FILEMAKER = '/jobs/filemaker-jobs',
  JOBS_FILEMAKER_JOB = '/jobs/filemaker-job',
  JOBS_UPDATE_FILEMAKER_RECORD = '/jobs/:id/update-filemaker-record',
  JOBS_CREATE_FILEMAKER_JOB = '/jobs/:id/create-filemaker-job',

  JOBS_SAVE_EMAIL_TEMPLATES = '/jobs/:id/save-email-templates',
  JOBS_CONFIG = '/jobs/config',
  JOBS_SEND = '/jobs/:id/add-contacts',
  JOBS_SEND_TEST_EMAIL = '/jobs/:id/send-test-email',
  JOBS_UPDATE_SENDER_CONTACT = '/jobs/:id/update-sender-contact',
  JOBS_UPDATE_CLIENT_PAGE = '/jobs/:id/update-client-page',
  JOBS_CANDIDATES = '/jobs/:id/candidates',
  JOBS_UPDATE_SUGGEST_PAGE_MESSAGE = '/jobs/:id/suggest-page-message',
  JOBS_INVITED_CONTACTS = '/jobs/:id/contacts',
  JOBS_EXPORT_SELECTED_CONTACTS_TO_CSV = '/jobs/:id/add-contacts-export',
  JOBS_UPDATE_STATUS = '/jobs/:id/update-status',
  JOBS_UPDATE_FILEMAKER_JOB = '/jobs/:id/update-filemaker-job',
  JOBS_UPDATE_EMAIL_DELAY = '/jobs/:id/update-email-delay',

  JOB_CANDIDATES = '/job-candidates',
  JOB_CANDIDATES_GET_ONE = '/job-candidates/:id',
  JOB_CANDIDATES_UPDATE = '/job-candidates/:id',
  JOB_CANDIDATES_DELETE = '/job-candidates/:id',
  JOB_CANDIDATES_LIST = '/job-candidates/list',
  JOB_CANDIDATES_COMMENTS = '/job-candidates/:id/comments',
  JOB_CANDIDATES_COMMENTS_HIDE = '/job-candidates/:id/comments/hide',
  JOB_CANDIDATES_RECOMMENDATIONS = '/job-candidates/:id/recommendations',
  JOB_CANDIDATES_RECOMMENDATION_HIDE = '/job-candidates/:id/recommendations/:recommendationId/hide',
  JOB_CANDIDATES_SEARCH = '/job-candidates/web-search',
  JOB_CANDIDATES_CHANGE_STATUS = '/job-candidates/update-status',
  JOB_CANDIDATES_EXPORT = '/job-candidates/export',
  JOB_CANDIDATES_FILEMAKER_EXPORT = '/job-candidates/:jobId/filemaker-export',
  JOB_CANDIDATES_ADD_CONTACT_TO_JOB = '/job-candidates/add-new-contact-by-job/:jobId',
  JOB_CANDIDATES_LINK_RECOMMENDATIONS_TO_CONTACT = '/job-candidates/link-recommendations-to-contact/:contactId',
  JOB_SOCIAL_MESSAGE_SUMMARY = '/jobs/:id/socialMessageSummary',
  JOB_GENERAL_SUMMARY = '/jobs/:id/generalSummary',
  JOB_EMAIL_STATUS = '/jobs/:id/UpdateJobEmailStatus',

  CLIENT_COMPANIES = '/client-companies',
  CLIENT_COMPANIES_CONTACTS = '/client-companies/:id/contacts',
  CLIENT_COMPANIES_ADD_CONTACT = '/client-companies/:id/contact/:contactId',

  CUSTOM_FIELDS = '/customfield',
  CUSTOM_FIELDS_ALL = '/customfield/all',

  SURVEYS = '/surveys',
  SURVEYS_SEND = '/surveys/:id/send',
  SURVEYS_SEND_TEST = '/surveys/:id/send-test',
  SURVEYS_INSTANCE = '/surveys/instance',
  SURVEYS_JOB = '/surveys/job/:id',
  SURVEYS_CONFIG = '/surveys/config',
  SURVEYS_SAVE_EMAIL_TEMPLATES = '/surveys/save-email-templates',
  SURVEYS_EXPORT_RESULTS = '/surveys/:id/export-results',
  SURVEYS_EXPORT_ANSWERS = '/surveys/:id/export-answers',

  CONFIGURATIONS_GET_SMTP = '/configs/smtp',
  CONFIGURATIONS_MESSAGE_LIMIT = '/configs/message-limit',

  JOB_CUSTOM_MESSAGE_TEMPLATES = '/job-custom-message-templates',
  JOB_CUSTOM_MESSAGE_TEMPLATES_CONFIG = '/job-custom-message-templates/config',

  RECRUITER_CONFIGURATIONS = '/recruiter-configurations',
  RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS = '/recruiter-configurations/smtp-custom-configs',
  RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS_SEND_TEST_EMAILS = '/recruiter-configurations/smtp-custom-configs/:id/send-test-emails',

  CAMPAIGNS = '/campaigns',
  CAMPAIGNS_GET_ONE = '/campaigns/:id',
  CAMPAIGNS_ARCHIVED = '/campaigns/archived-campaigns',
  CAMPAIGNS_STATUS = '/campaigns/{id}/UpdateCampaignStatus',
  CAMPAIGNS_SEND = '/campaigns/:id/add-contacts',
  CAMPAIGNS_INVITED_CONTACTS = '/campaigns/:id/contacts',
  CAMPAIGNS_INVITED_CONTACTS_EMAIL_STATUS = '/campaigns/:id/Campaigncontacts',
  CAMPAIGN_EXPORT = '/campaigns/:id/reportCampaigns',

  EMAIL_STATISTICS = '/emails/emailStatistics',
  MESSAGE_EMAILS = '/emails',

  // General
  DASHBOARD = '/dashboard',
  RESOURCES = '/resources',
  USERS = '/users',
  USERS_UPDATE_PROFILE = '/users/update-profile',
  USERS_UPDATE_PASSWORD = '/users/update-password',
  USERS_DELETE = '/users/:id',
  USERS_REQUEST_EMAIL_VALIDATION = '/users/request-email-validation',
  USERS_VALIDATE_SEND_EMAIL = '/users/validate-send-email',
  USERS_FIND_MUTUAL_LINKEDIN = '/users/find-mutual-linkedin',
  USERS_FIND_MUTUAL_FACEBOOK = '/users/find-mutual-facebook',
  USERS_VALIDATE_TOKEN = '/users/session-valid',

  ROLES = '/roles',
  USER_ACTIVITIES = '/user-activities',
  USER_ACTIVITIES_BY_USER = '/user-activities/user/:userId',
  EMAILS = '/emails',
  ACTIVATE_ACCOUNT = '/activate-account',
  EMAIL_TOKEN = '/email-token',
  FILES = '/files',

  SOCIAL_NETWORK_MESSAGES = '/social-network-messages',
}
