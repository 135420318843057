import { Button, Form, Input, message, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactFactory } from '../../api/contacts/factory';
import { Contact } from '../../api/contacts/model';
import { Survey } from '../../api/surveys/models';
import SurveysService from '../../api/surveys/SurveysService';
import { validateAntForm } from '../../helpers/ant-form';

interface FormValues {
  email: string;
}

interface Props extends FormComponentProps<FormValues> {
  isVisible: boolean;
  onCancel: () => void;
  survey?: Survey;
}

const SurveyTestModal: React.FC<Props> = ({ form, isVisible, onCancel, survey }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTestSurvey = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!survey) {
      return;
    }

    try {
      setIsSubmitting(true);
      const values = await validateAntForm(form);
      const { email } = values;

      const contacts: Contact[] = [];

      let contact = ContactFactory.build();
      contact.id = 0;
      contact.email = email;

      contacts.push(contact);

      await SurveysService.send(survey, contacts);
      cancelModal();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelModal = () => {
    form.resetFields();
    onCancel();
  };

  if (!survey) {
    return null;
  }

  return (
    <Modal visible={isVisible} onCancel={() => cancelModal()} footer={null}>
      <Form layout="horizontal" onSubmit={handleTestSurvey}>
        <Form.Item label={t('Email')}>
          {form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: t('This field is required'),
              },
              {
                type: 'email',
                message: t('The entered email is not valid'),
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            style={{ float: 'right' }}
            loading={isSubmitting}
            type="primary"
          >
            {t('Send Test Email')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<Props>()(SurveyTestModal);
