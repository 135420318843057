import { ConfigProvider, message } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import frFR from 'antd/lib/locale-provider/fr_FR';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { connect } from 'react-redux';
import { LanguagesEnum } from '../api/shared/enums';
import GoogleService from '../api/shared/services/GoogleService';
import { User } from '../api/users/model';
import { useHeader } from '../hooks/header';
import { useSubHeader } from '../hooks/subHeader';
import store, { IGlobalState } from '../state';
import { getAuthUser, getIsLoggedIn, getSessionExpired, updateSessionExpired } from '../state/auth';
import en from '../translations/en.json';
import fr from '../translations/fr.json';
import { AppContext } from './AppContext';
import Routes from './Routes';
import Loading from '../components/Loading/Loading';
import { mustCheckToken } from '../helpers/session-helpers';
import AuthService from '../api/shared/services/AuthService';

const locales = {
  [LanguagesEnum.EN]: enUS,
  [LanguagesEnum.FR]: frFR,
};

interface Props {
  user?: User;
  isLoggedIn: boolean;
}

const App: React.FC<Props> = ({ user, isLoggedIn }) => {
  const [isGoogleSDKLoaded, setIsGoogleSDKLoaded] = useState(false);
  const { headerHeight, setHeaderHeight } = useHeader();
  const { subHeaderHeight, setSubHeaderHeight } = useSubHeader();
  const [antLocale, setAntLocale] = useState(enUS);
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(true);
  const [isLoadingAntLocale, setIsLoadingAntLocale] = useState(true);
  const [isValidatingToken, setIsValidatingToken] = useState(false);

  useEffect(() => {
    const loadGoogleSDK = async () => {
      try {
        await GoogleService.insertScript();
        await GoogleService.init();
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsGoogleSDKLoaded(true);
      }
    };
    loadGoogleSDK();
  }, []);

  useEffect(() => {
    const loadI18n = async () => {
      try {
        await i18n.use(initReactI18next).init({
          resources: { en, fr },
          lng: user?.language?.toLowerCase() || LanguagesEnum.EN.toLowerCase(),
          fallbackLng: LanguagesEnum.EN.toLowerCase(),
        });
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingLanguage(false);
      }
    };

    const loadAntLocale = async () => {
      try {
        let locale: any;
        if (user) {
          locale = locales[user.language as LanguagesEnum];
        } else {
          locale = locales[LanguagesEnum.EN];
        }
        setAntLocale(locale);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingAntLocale(false);
      }
    };

    loadI18n();
    loadAntLocale();
  }, [user]);

  useEffect(() => {
    const validateToken = async () => {
      try {
        setIsValidatingToken(true);
        await AuthService.validateSession();
        store.dispatch(updateSessionExpired(undefined));
      } catch (error) {
        store.dispatch(updateSessionExpired(true));
      } finally {
        setIsValidatingToken(false);
      }
    };
    if (isLoggedIn && mustCheckToken(window.location.pathname)) {
      validateToken();
    }
  }, [isLoggedIn]);

  if (isLoadingLanguage || isLoadingAntLocale || isValidatingToken) {
    return <Loading />;
  }

  const changeLanguage = async (language: string) => {
    await i18n.use(initReactI18next).init({
      resources: { en, fr },
      lng: language.toLowerCase(),
      fallbackLng: LanguagesEnum.EN.toLowerCase(),
    });
    const locale: any = locales[language as LanguagesEnum];
    setAntLocale(locale);
  };

  return (
    <AppContext.Provider
      value={{
        isGoogleSDKLoaded,
        user,
        isLoggedIn,
        mainHeaderHeight: headerHeight,
        setMainHeaderHeight: setHeaderHeight,
        subHeaderHeight,
        setSubHeaderHeight,
        changeLanguage,
      }}
    >
      <ConfigProvider locale={antLocale}>
        <Routes />
      </ConfigProvider>
    </AppContext.Provider>
  );
};

const mapStateToProps = (state: IGlobalState) => ({
  user: getAuthUser(state),
  isLoggedIn: getIsLoggedIn(state),
  sessionExpired: getSessionExpired(state),
});

export default connect(mapStateToProps)(App);
