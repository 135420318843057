import { Col, Icon, Input, message, Modal, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactImportsService from '../../api/contacts/contact-imports/ContactImportsService';
import {
  ContactImportLogDescriptionEnum,
  ContactImportLogDescriptionEnumLabel,
  ContactImportLogImportedStatusEnum,
  ContactImportLogImportedStatusLabel,
} from '../../api/contacts/contact-imports/enums';
import { ContactImportLog } from '../../api/contacts/model';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';

interface ContactImportLogsModalProps {
  onCancel: () => void;
  visible: boolean;
  selectedContactImportId: number;
  selectedContactImportFileName: string;
}

const ContactImportLogsModal: React.FC<ContactImportLogsModalProps> = ({
  visible,
  onCancel,
  selectedContactImportId,
  selectedContactImportFileName,
}) => {
  const { t } = useTranslation();

  const [logs, setLogs] = useState<ContactImportLog[]>([]);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [gridState, setGridState] = useState<GridParams>(createBaseGridParams({}));

  const handleClose = () => {
    setGridState({ ...gridState, filters: [] });
    onCancel();
  };

  useEffect(() => {
    const fetchLogs = async () => {
      setIsLoadingLogs(true);
      try {
        const response = await ContactImportsService.fetchContactImportLogs(
          selectedContactImportId,
          gridState
        );
        setLogs(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingLogs(false);
      }
    };
    if (selectedContactImportId !== 0) {
      fetchLogs();
    }
  }, [selectedContactImportId, gridState]);

  const handleFilterChange = (key: string, value: any) => {
    let newFilters = [...gridState.filters!.filter((i) => i.key !== key)];
    if (!value) {
      setGridState({ ...gridState, filters: [...newFilters] });
    } else {
      setGridState({ ...gridState, filters: [...newFilters, { key, value }] });
    }
  };

  const renderFilters = () => (
    <>
      <Row gutter={50}>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            onChange={(value: any) => handleFilterChange('imported', value)}
            placeholder={t('Import result')}
            allowClear
          >
            {Object.values(ContactImportLogImportedStatusEnum).map((status, index) => (
              <Select.Option
                key={index.toString()}
                value={status === ContactImportLogImportedStatusEnum.IMPORTED ? 'true' : 'false'}
              >
                {t(
                  ContactImportLogImportedStatusLabel[status as ContactImportLogImportedStatusEnum]
                )}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={16}>
          <Input.Search
            allowClear
            placeholder={t('Search by name or email')}
            style={{ marginBottom: '1rem' }}
            onSearch={(value: any) => handleFilterChange('query', value)}
          />
        </Col>
      </Row>
    </>
  );

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'contactFirstName',
      render: (cell: any, row: ContactImportLog) =>
        `${row.contactFirstName} ${row.contactLastName}`,
    },
    {
      title: t('Email'),
      dataIndex: 'contactEmail',
    },
    {
      title: t('Imported'),
      dataIndex: 'imported',
      align: 'center' as 'center',
      render: (cell: boolean) => <Icon type={cell ? 'check' : 'close'} />,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      render: (cell: any) =>
        t(ContactImportLogDescriptionEnumLabel[cell as ContactImportLogDescriptionEnum]),
    },
  ];

  return (
    <Modal
      destroyOnClose
      width={800}
      title={`${selectedContactImportFileName} Logs`}
      visible={visible}
      onCancel={handleClose}
      footer={null}
    >
      {renderFilters()}

      <Table
        loading={isLoadingLogs}
        columns={columns}
        rowKey="id"
        bordered
        dataSource={logs}
        pagination={{
          showTotal: (totalRecords, rangeRecords) =>
            `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
          showSizeChanger: true,
        }}
      />
    </Modal>
  );
};

export default ContactImportLogsModal;
