import i18n from 'i18next';
import { first } from 'lodash';
import { API_URL } from '../../../config/general-config';
import store from '../../../state';
import { getAuthToken, logout, unauthorized } from '../../../state/auth';
import { ApiResponse } from '../models';

interface FetchParams {
  url: string;
  body?: object;
  gridParams?: FetchGridParams;
}

export type FetchGridParams = {
  sortField: string;
  sortDirection: string;
  pageIndex: number;
  pageSize: number;
  filters?: string;
  query?: string;
  JobId?: number;
  CampaignId?: number;
};

class FetchService {
  static async get<T>(params: FetchParams): Promise<T> {
    let { url } = params;
    if (params.gridParams) {
      params.gridParams.sortDirection = FetchService._adjustSortDirection(
        params.gridParams.sortDirection
      );
      const initialQueryCharacter = url.includes('?') ? '&' : '?';
      let paramName = '';
      if(params.gridParams.JobId) {
        paramName = `JobId=${params.gridParams.JobId}`;
      } else if(params.gridParams.CampaignId) {
        paramName = `CampaignId=${params?.gridParams.CampaignId}`
      }
      const pageParams = `${initialQueryCharacter}${paramName}&pageIndex=${params.gridParams.pageIndex}&pageSize=${params.gridParams.pageSize}`;
      const sortParams = `&sortField=${params.gridParams.sortField}&sortDirection=${params.gridParams.sortDirection}`;
      const query = params.gridParams.query ? `&query=${params.gridParams.query}` : '';
      const queryParams = params.gridParams.filters ? `${params.gridParams.filters}` : '';
      url = `${url}${pageParams}${sortParams}${query}${queryParams}`;
    }

    const response = await fetch(`${API_URL}${url}`, {
      method: 'GET',
      headers: FetchService.getHeaders(),
    });
    return FetchService.processResponse<T>(response);
  }

  static _adjustSortDirection(sortDirection: string): string {
    if (sortDirection === 'ascend') {
      return 'ASC';
    } else if (sortDirection === 'descend') {
      return 'DESC';
    } else {
      throw new Error('No SortDirection defined');
    }
  }

  static async post<T>(params: FetchParams, isTokenCheck: boolean = false): Promise<T> {
    const { url } = params;

    const response = await fetch(`${API_URL}${url}`, {
      method: 'POST',
      body: JSON.stringify(params.body),
      headers: FetchService.getHeaders(),
    });

    return FetchService.processResponse<T>(response);
  }

  static async put<T>(params: FetchParams): Promise<T> {
    const { url } = params;

    const response = await fetch(`${API_URL}${url}`, {
      method: 'PUT',
      body: JSON.stringify(params.body),
      headers: FetchService.getHeaders(),
    });

    return FetchService.processResponse<T>(response);
  }

  static async delete<T>(params: FetchParams): Promise<T> {
    const { url } = params;

    const response = await fetch(`${API_URL}${url}`, {
      method: 'DELETE',
      headers: FetchService.getHeaders(),
      body: JSON.stringify(params.body),
    });

    return FetchService.processResponse<T>(response);
  }

  static getHeaders() {
    const jwtToken = getAuthToken(store.getState());
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    if (jwtToken) {
      headers.append('Authorization', `Bearer ${jwtToken}`);
    }

    return headers;
  }

  static async processResponse<T>(response: Response) {
    if (response.status === 200) {
      return (await response.json()) as T;
    }

    if (response.status === 400) {
      const apiResponse = (await response.json()) as ApiResponse;
      const firstError = first(apiResponse.errors);

      if (firstError) {
        throw new Error(i18n.t(firstError.errorCode) || i18n.t(firstError.errorMessage));
      }
      throw new Error('Bad Request [400]');
    }

    if (response.status === 401) {
      store.dispatch(unauthorized(response));
      throw new Error();
    }

    if (response.status === 415) {
      throw new Error('Unsupported Media Type [415]');
    }

    if (response.status === 404) {
      throw new Error('Endpoint not found [404]');
    }

    if (response.status === 405) {
      throw new Error('Method Not Allowed [405]');
    }

    if (response.status === 500) {
      throw new Error('Internal Server Error [500]');
    }

    return {} as T;
  }

  static async validateSession(params: FetchParams) {
    const { url } = params;
    const response = await fetch(`${API_URL}${url}`, {
      method: 'POST',
      headers: FetchService.getHeaders(),
    });
    if (response.status === 401) {
      store.dispatch(logout());
      throw new Error();
    }

    if (response.status === 500) throw new Error('Internal Server Error [500]');

    return {};
  }

  static async logout(params: FetchParams) {
    const { url } = params;
    const response = await fetch(`${API_URL}${url}`, {
      method: 'POST',
      headers: FetchService.getHeaders(),
    });

    if (response.status === 500) throw new Error('Internal Server Error [500]');

    return {};
  }
}

export default FetchService;
