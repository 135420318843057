import React from 'react';
import FloatLabelInput from './FloatLabelInput';

interface Props {
  value?: string;
  onChange?: () => void;
  collapse: string;
  label: string;
  autoFocus?: boolean;
  disabled?: boolean;
  type?: string;
  icon?: string;
}

interface State {
  focus: boolean;
  showPassword: boolean;
}

class FloatLabelInputContainer extends React.Component<Props, State> {
  state: State = {
    focus: false,
    showPassword: false,
  };

  handleFocus = () => {
    this.setState({ focus: true });
  };

  handleBlur = () => {
    this.setState({ focus: false });
  };

  handleTogglePass = () => {
    this.setState(({ showPassword }) => ({
      showPassword: !showPassword,
    }));
  };

  render() {
    return (
      <FloatLabelInput
        {...this.props}
        {...this.state}
        handleOnBlur={this.handleBlur}
        handleOnFocus={this.handleFocus}
        handleTogglePass={this.handleTogglePass}
      />
    );
  }
}

export default FloatLabelInputContainer;
