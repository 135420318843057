import { Button, Card, Icon, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SurveyThankYouInfoDisposition } from '../../api/surveys/enums';
import { SurveyInstance } from '../../api/surveys/models';
import { URLs } from '../../config/enums';
import { applyFieldsToQuestions } from '../../helpers/string-helpers';
import { useAppContext } from '../../layout/AppContext';
import Layout from '../../layout/Layout';

interface Props {
  hideHeader?: boolean;
  surveyInstance?: SurveyInstance;
}

const SurveyCompletedPage: React.FC<Props> = ({ hideHeader = false, surveyInstance }) => {
  const history = useHistory();
  const { isLoggedIn } = useAppContext();
  const { t } = useTranslation();

  const content = (
    <div className="container">
      <Card>
        {surveyInstance?.thankYouPictureUrl ? (
          <Result
            icon={
              surveyInstance?.thankYouMessageDisposition ===
              SurveyThankYouInfoDisposition.IMAGE_FIRST ? (
                <img
                  src={surveyInstance?.thankYouPictureUrl}
                  style={{ width: '50%' }}
                  alt={t('Thank you picture')}
                />
              ) : (
                <h1>
                  {applyFieldsToQuestions(surveyInstance!, surveyInstance?.thankYouMessage) ||
                    t('Thank you for answering the survey!')}
                </h1>
              )
            }
            status="success"
            title={
              surveyInstance?.thankYouMessageDisposition ===
              SurveyThankYouInfoDisposition.TEXT_FIRST ? (
                <img
                  src={surveyInstance?.thankYouPictureUrl}
                  style={{ width: '50%' }}
                  alt={t('Thank you picture')}
                />
              ) : (
                <h1>
                  {applyFieldsToQuestions(surveyInstance!, surveyInstance?.thankYouMessage) ||
                    t('Thank you for answering the survey!')}
                </h1>
              )
            }
            extra={
              isLoggedIn && (
                <Button type="primary" onClick={() => history.push(URLs.ROOT)}>
                  {t('Go Home')}
                </Button>
              )
            }
          />
        ) : (
          <Result
            icon={
              surveyInstance?.thankYouMessageDisposition ===
              SurveyThankYouInfoDisposition.IMAGE_FIRST ? (
                <Icon
                  type="check-circle"
                  className="anticon anticon-check-circle"
                  style={{ fontSize: '72px' }}
                />
              ) : (
                <h1>
                  {applyFieldsToQuestions(surveyInstance!, surveyInstance?.thankYouMessage) ||
                    t('Thank you for answering the survey!')}
                </h1>
              )
            }
            status="success"
            title={
              surveyInstance?.thankYouMessageDisposition ===
              SurveyThankYouInfoDisposition.TEXT_FIRST ? (
                <Icon
                  type="check-circle"
                  className="anticon anticon-check-circle"
                  style={{ fontSize: '72px', color: '#52c41a' }}
                />
              ) : (
                <h1>
                  {applyFieldsToQuestions(surveyInstance!, surveyInstance?.thankYouMessage) ||
                    t('Thank you for answering the survey!')}
                </h1>
              )
            }
            extra={
              isLoggedIn && (
                <Button type="primary" onClick={() => history.push(URLs.ROOT)}>
                  {t('Go Home')}
                </Button>
              )
            }
          />
        )}
      </Card>
    </div>
  );

  if (!isLoggedIn || hideHeader) {
    return content;
  }

  return <Layout>{content}</Layout>;
};

export default SurveyCompletedPage;
