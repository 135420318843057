import { API } from "../../config/api";
import { GridParams, transformGridParams } from "../../helpers/grid-helpers";
import FetchService from "../shared/services/FetchService";
import { SmtpCustomConfig, SmtpCustomConfigAddType, SmtpCustomConfigFetched, SmtpCustomConfigTestEmailModel, SmtpCustomConfigUpdateType } from "./models";


export class RecruiterConfigurationsService{

    static async findSmtpCustomConfigurations(params?: GridParams): Promise<SmtpCustomConfigFetched> {
        let gridParams;
        if (params)
            gridParams = transformGridParams(params);
        const response = await FetchService.get<SmtpCustomConfigFetched>({
          url: API.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS,
         gridParams: gridParams || undefined,
        });
        return response;
    }

    static async findSmtpCustomConfig(id: number): Promise<SmtpCustomConfig>{
        const response = await FetchService.get<SmtpCustomConfig>({url: 
        `${API.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS}/${id}`});
        return response;
    }

    static async addSmtpCustomConfig(entity: SmtpCustomConfigAddType): Promise<number>{
        const response = await FetchService.post<number>({
            url: API.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS,
            body: entity
        });
        return response;
    }

    static async sendTestEmails(id: number, model: SmtpCustomConfigTestEmailModel): Promise<number>{
        const response = await FetchService.post<number>({
            url:API.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS_SEND_TEST_EMAILS.replace(":id",id.toString()), 
            body: model,
        });
        return response;
    }

    static async deleteSmtpCustomConfig(id: number): Promise<void>{
        return await FetchService.delete<void>({url : `${API.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS}/${id}`});
    }

    static async updateSmtpCustomConfig(id: number, entity: SmtpCustomConfigUpdateType): Promise<number>{
        const response = await FetchService.put<number>({
            url: `${API.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIGS}/${id}`,
            body: entity
        });
        return response;

    }

}