import { Col, Layout, Row } from 'antd';
import React, { useRef, useEffect } from 'react';
import { useAppContext } from './AppContext';
import HeaderActions from './HeaderActions';
import HeaderActionsMobile from './HeaderActionsMobile';
import HeaderLogo from './HeaderLogo';
import HeaderMenu from './HeaderMenu';

const Header: React.FC = () => {
  const { user, setMainHeaderHeight: setHeaderHeight } = useAppContext();
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeaderHeight(headerRef.current?.clientHeight || 0);
  }, [setHeaderHeight]);

  if (!user) {
    return null;
  }

  return (
    <Layout.Header className="header">
      <div ref={headerRef} className="container">
        <Row gutter={16} type="flex" align="bottom">
          <Col xs={20} sm={4}>
            <HeaderLogo />
          </Col>
          <Col xs={0} sm={16}>
            <HeaderMenu />
          </Col>
          <Col xs={0} sm={4} style={{ textAlign: 'right' }}>
            <HeaderActions user={user} />
          </Col>
          <Col xs={4} sm={0} style={{ textAlign: 'center', alignSelf: 'center' }}>
            <HeaderActionsMobile />
          </Col>
        </Row>
      </div>
    </Layout.Header>
  );
};

export default Header;
