import { Avatar, Checkbox, List, Typography, Button, Badge, Tooltip, Popconfirm, Icon } from 'antd';
import React from 'react';
import { JobCandidate } from '../../api/job-candidates/model';
import { ReactComponent as IconSurveys } from '../../assets/icon-surveys.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Job } from '../../api/jobs/model';
import { JobStatusEnum } from '../../api/jobs/enums';
import { htmlDecode } from '../../helpers/string-helpers';
import moment from 'moment';

interface Props {
  selectedCandidateIds?: number[];
  deletingCandidateIds?: number[];
  jobCandidate: JobCandidate;
  job: Job;
  onSelect?: (checked: boolean, candidateId: number) => void;
  onClickView?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickCreateSurvey?: () => void;
}

const JobCandidateTile: React.FC<Props> = ({
  selectedCandidateIds = [],
  deletingCandidateIds = [],
  jobCandidate,
  job,
  onSelect,
  onClickView,
  onClickEdit,
  onClickDelete,
  onClickCreateSurvey,
}) => {
  const { t } = useTranslation();
  const actions = [];

  if (jobCandidate.linkedinUrl) {
    actions.push(
      <Tooltip key="action_linkedUrl" title={`${t('View')} ${t('LinkedIn')}`}>
        <Button
          type="link"
          href={jobCandidate.linkedinUrl}
          icon="linkedin"
          target="_blank"
          size="small"
          className="btn btn--linkedin"
        />
      </Tooltip>
    );
  }

  if (jobCandidate.cvKey) {
    actions.push(
      <Tooltip key="action_cvUrl" title={`${t('View')} ${t('Resume')}`}>
        <Button href={jobCandidate.cvUrl} icon="file-text" target="_blank" size="small" />
      </Tooltip>
    );
  }

  if (onClickView) {
    actions.push(
      <Tooltip key="action_onClickView" title={t('Details')}>
        <Button type="primary" onClick={() => onClickView()} size="small" icon="eye" />
      </Tooltip>
    );
  }

  if (onClickCreateSurvey) {
    actions.push(
      <Tooltip key="action_onClickCreateSurvey" title={t('Create Survey')}>
        <Button type="primary" onClick={() => onClickCreateSurvey()} size="small">
          <Icon component={IconSurveys} />
        </Button>
      </Tooltip>
    );
  }

  if (onClickEdit && job.jobStatus === JobStatusEnum.OPEN) {
    actions.push(
      <Tooltip key="action_onClickEdit" title={t('Edit')}>
        <Button type="primary" onClick={() => onClickEdit()} size="small" icon="edit" />
      </Tooltip>
    );
  }

  if (onClickDelete && job.jobStatus === JobStatusEnum.OPEN) {
    actions.push(
      <Popconfirm
        key="action_onClickDelete"
        title={t('Are you sure you want to delete this candidate?')}
        onConfirm={() => onClickDelete()}
      >
        <Tooltip title={t('Delete')}>
          <Button
            size="small"
            type="danger"
            htmlType="button"
            loading={deletingCandidateIds.includes(jobCandidate.id)}
            disabled={deletingCandidateIds.includes(jobCandidate.id)}
            icon="delete"
          />
        </Tooltip>
      </Popconfirm>
    );
  }

  return (
    <List.Item
      className={classNames('job-offer__candidates-list-item', {
        'job-offer__candidates-list-item--selected': selectedCandidateIds.includes(jobCandidate.id),
      })}
      actions={[<Button.Group>{actions}</Button.Group>]}
    >
      <Badge
        count={jobCandidate.recommendations?.length}
        className="job-offer__candidates-list-badge"
      />
      <List.Item.Meta
        avatar={
          jobCandidate.pictureUrl || jobCandidate.publicProfilePictureUrl ? (
            <Avatar
              style={{ backgroundColor: '#b1b1b1' }}
              src={jobCandidate.pictureUrl || jobCandidate.publicProfilePictureUrl}
            />
          ) : (
            <Avatar>
              {jobCandidate.firstName[0]} {jobCandidate.lastName[0]}
            </Avatar>
          )
        }
        title={
          <Typography.Text className="job-offer__candidates-list-item-title" ellipsis>
            <span title={`${jobCandidate.firstName} ${jobCandidate.lastName}`}>
              {jobCandidate.firstName} {jobCandidate.lastName}
            </span>
          </Typography.Text>
        }
        description={
          <>
            <Typography.Paragraph className="job-offer__candidates-list-item-job" ellipsis>
              <span title={htmlDecode(jobCandidate.jobTitle)}>
                {htmlDecode(jobCandidate.jobTitle)}
              </span>
            </Typography.Paragraph>
            <Typography.Paragraph className="job-offer__candidates-list-item-company" ellipsis>
              <span title={jobCandidate.company}>{jobCandidate.company}</span>
            </Typography.Paragraph>
          </>
        }
      />
      {onSelect && (
        <Checkbox
          disabled={job.jobStatus === JobStatusEnum.ARCHIVED}
          checked={selectedCandidateIds.includes(jobCandidate.id)}
          onChange={(e) => onSelect(e.target.checked, jobCandidate.id)}
          className="job-offer__candidates-list-item-checkbox"
        />
      )}
      {jobCandidate.jobCandidateFM?.exportDate && (
        <div style={{ position: 'absolute', right: '0.5rem', top: '8.4rem' }}>
          <Tooltip
            title={t('Exported to Filemaker at filemakerExportDate', {
              filemakerExportDate: moment(jobCandidate.jobCandidateFM.exportDate).format(
                'MM/DD/YYYY hh:mm'
              ),
              interpolation: { escapeValue: false },
            })}
          >
            <Icon type="check-circle-o" style={{ fontSize: 25, color: '#7BE56C' }} />
          </Tooltip>
        </div>
      )}
    </List.Item>
  );
};

export default JobCandidateTile;
