import { Button, Drawer, Form, Input, Modal, Table, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { IJobCustomMessageTemplate } from '../../api/job-custom-message-templates/models';
import { ROWS_PER_PAGES } from '../../config/general-config';
import { formatDateTime } from '../../helpers/date-helpers';

interface JobCustomMessageTemplateProps {
  visible: boolean;
  templates: IJobCustomMessageTemplate[];
  selectedCustomMessage: string;
  handleCancel: () => void;
  handleSetTemplate: (templateId: number, selectedCustomMessage: string) => void;
}

const JobCustomMessageTemplateModal: React.FC<JobCustomMessageTemplateProps> = ({
  visible,
  templates,
  selectedCustomMessage,
  handleCancel,
  handleSetTemplate,
}) => {
  const { t } = useTranslation();
  const [isOpenDetailDrawer, setIsOpenDetailDrawer] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(0);
  return (
    <>
      <Modal
        visible={visible}
        title={`${t('Select template')}`}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <Table
          dataSource={templates}
          columns={[
            { title: t('Name'), dataIndex: 'name' },
            {
              title: t('Created Date'),
              dataIndex: 'createdDate',
              render: (cell: any) => formatDateTime(new Date(cell)),
            },
            {
              title: t('Actions'),
              render: (cell: any, row: IJobCustomMessageTemplate) => (
                <>
                  <Tooltip title={t('Details')}>
                    <Button
                      type="default"
                      icon="file-text"
                      onClick={() => {
                        setSelectedTemplateId(row.id);
                        setIsOpenDetailDrawer(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={t('Select template')}>
                    <Button
                      style={{ marginLeft: 5 }}
                      type="default"
                      icon="enter"
                      onClick={() => handleSetTemplate(row.id, selectedCustomMessage)}
                    />
                  </Tooltip>
                </>
              ),
            },
          ]}
          pagination={{
            hideOnSinglePage: true,
            pageSize: ROWS_PER_PAGES,
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            total: templates.length,
          }}
        />
      </Modal>
      <Drawer
        width={300}
        visible={isOpenDetailDrawer}
        onClose={() => setIsOpenDetailDrawer(!isOpenDetailDrawer)}
        title={t('Details')}
      >
        <>
          {templates.find((i) => i.id === selectedTemplateId)?.subject ? (
            <Form layout="horizontal">
              <Form.Item label={t('Subject')}>
                <Input
                  readOnly
                  value={templates.find((i) => i.id === selectedTemplateId)?.subject}
                />
              </Form.Item>

              <Form.Item label={t('Email')}>
                <ReactQuill
                  className="jobs-new__editor"
                  value={templates.find((i) => i.id === selectedTemplateId)?.body}
                  readOnly
                  modules={{
                    toolbar: null,
                  }}
                />
              </Form.Item>
            </Form>
          ) : (
            <Form.Item label={t('Message')}>
              <TextArea
                autoSize
                readOnly
                value={templates.find((i) => i.id === selectedTemplateId)?.body}
              />
            </Form.Item>
          )}
        </>
      </Drawer>
    </>
  );
};

export default JobCustomMessageTemplateModal;
