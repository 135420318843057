import { Button, message, Popconfirm, Tabs, Tooltip } from 'antd';
import classNames from 'classnames';
import { parseInt } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Survey } from '../../api/surveys/models';
import SurveysService from '../../api/surveys/SurveysService';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';
import SurveyDetailResultsList from './SurveyDetailResultsList';
import { SurveysContext } from './SurveysContext';
import SurveysEdit from './SurveysEdit';
import SurveyTestModal from './SurveyTestModal';

export const TabItems = {
  QUESTIONS: 'QUESTIONS',
  RESULTS: 'RESULTS',
};

const SurveyDetail: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;

  const history = useHistory();
  const { user } = useAppContext();
  const [isLoadingSurvey, setIsLoadingSurvey] = useState(false);
  const [isTestSurveyModalVisible, setIsTestSurveyModalVisible] = useState(false);
  const [isDeletingSurvey, setIsDeletingSurvey] = useState(false);
  const [survey, setSurvey] = useState<Survey>();
  const fetchSurveys = useContext(SurveysContext).fetchSurveys!;
  const [activeTabKey, setActiveTabKey] = useState(TabItems.QUESTIONS);

  useEffect(() => {
    const path = match.path.toUpperCase();
    if (path.endsWith(TabItems.QUESTIONS)) {
      setActiveTabKey(TabItems.QUESTIONS);
    } else if (path.endsWith(TabItems.RESULTS)) {
      setActiveTabKey(TabItems.RESULTS);
    }
  }, [match.path]);

  const fetchSurvey = async () => {
    try {
      if (id) {
        setIsLoadingSurvey(true);
        const response = await SurveysService.findOne(parseInt(id));
        setSurvey(response);
      } else {
        setSurvey(undefined);
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsLoadingSurvey(false);
    }
  };

  useEffect(() => {
    fetchSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteSurvey = async () => {
    try {
      setIsDeletingSurvey(true);
      await SurveysService.delete(parseInt(id));
      message.success(t('Survey deleted successfully'));
      fetchSurveys();
      history.push(URLs.SURVEYS);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsDeletingSurvey(false);
    }
  };

  const exportSurveyResults = async () => {
    try {
      setIsDeletingSurvey(true);
      await SurveysService.exportResultsToCSV(survey!.id);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsDeletingSurvey(false);
    }
  };

  const exportSurveyAnswers = async () => {
    try {
      setIsDeletingSurvey(true);
      await SurveysService.exportAnswersToCSV(survey!.id);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsDeletingSurvey(false);
    }
  };

  if (!user) {
    return <Redirect to={URLs.LOGIN} />;
  }

  if (isLoadingSurvey) {
    return <LoadingSpinner />;
  }

  if (!survey) {
    return <NotFoundPage hideHeader />;
  }

  return (
    <>
      <div className="job-offer">
        <h1 className="job-offer__header">
          {survey.title}
          <Tooltip title={t('Export complete results')} placement="bottom">
            <Button
              loading={isDeletingSurvey}
              icon="file"
              shape="circle-outline"
              type="default"
              style={{ marginLeft: 'auto', marginRight: '1rem' }}
              onClick={exportSurveyResults}
            />
          </Tooltip>
          <Tooltip title={t('Export answers')} placement="bottom">
            <Button
              loading={isDeletingSurvey}
              icon="download"
              shape="circle-outline"
              type="default"
              style={{ marginRight: '1rem' }}
              onClick={exportSurveyAnswers}
            />
          </Tooltip>
          <Tooltip title={t('Send Test Email')} placement="bottom">
            <Button
              loading={isDeletingSurvey}
              icon="mail"
              shape="circle-outline"
              type="default"
              style={{ marginRight: '1rem' }}
              onClick={() => setIsTestSurveyModalVisible(true)}
            />
          </Tooltip>
          <Tooltip title={t('Delete Survey')} placement="bottom">
            <Popconfirm
              title={t('Are you sure you want to delete this survey?')}
              onConfirm={deleteSurvey}
              okText={t('Delete')}
              okType="danger"
              cancelText={t('Cancel')}
            >
              <Button
                loading={isDeletingSurvey}
                icon="minus-circle-o"
                shape="circle-outline"
                type="danger"
                style={{ marginRight: '1rem' }}
              />
            </Popconfirm>
          </Tooltip>
        </h1>
        <div className="job-offer__body">
          <Tabs
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
            className={classNames({
              'ant-tabs-bar--bordered-bottom': activeTabKey === TabItems.QUESTIONS,
            })}
          >
            {/* Questions Tab */}
            <Tabs.TabPane key={TabItems.QUESTIONS} tab={t('questions')}>
              <SurveysEdit />
            </Tabs.TabPane>

            {/* Results Tab */}
            {user.id === survey.ownerId && (
              <Tabs.TabPane key={TabItems.RESULTS} tab={t('Results')}>
                <SurveyDetailResultsList surveyTemplateId={survey.id} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </div>
      <SurveyTestModal
        isVisible={isTestSurveyModalVisible}
        survey={survey}
        onCancel={() => setIsTestSurveyModalVisible(false)}
      />
    </>
  );
};

export default SurveyDetail;
