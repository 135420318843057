import { API } from '../../config/api';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import FetchService from '../shared/services/FetchService';
import {
  IJobCustomMessageTemplate,
  IJobCustomMessageTemplateAddType,
  IJobCustomMessageTemplateFetched,
  IJobCustomMessageTemplateUpdateType,
  JobCustomMessageTemplateConfig,
} from './models';

export class JobCustomMessageTemplatesService {
  static async fetch(params: GridParams): Promise<IJobCustomMessageTemplateFetched> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<IJobCustomMessageTemplateFetched>({
      url: API.JOB_CUSTOM_MESSAGE_TEMPLATES,
      gridParams,
    });
    return response;
  }

  static async fetchOne(id: number): Promise<IJobCustomMessageTemplate> {
    const response = await FetchService.get<IJobCustomMessageTemplate>({
      url: `${API.JOB_CUSTOM_MESSAGE_TEMPLATES}/${id}`,
    });
    return response;
  }

  static async add(model: IJobCustomMessageTemplateAddType): Promise<number> {
    return await FetchService.post<number>({
      url: API.JOB_CUSTOM_MESSAGE_TEMPLATES,
      body: model,
    });
  }

  static async update(id: number, model: IJobCustomMessageTemplateUpdateType): Promise<number> {
    return await FetchService.put<number>({
      url: `${API.JOB_CUSTOM_MESSAGE_TEMPLATES}/${id}`,
      body: model,
    });
  }

  static async delete(id: number): Promise<void> {
    await FetchService.delete<void>({
      url: `${API.JOB_CUSTOM_MESSAGE_TEMPLATES}/${id}`,
    });
  }

  static async fetchConfig(includeCustomFields: boolean): Promise<JobCustomMessageTemplateConfig> {
    return await FetchService.get<JobCustomMessageTemplateConfig>({
      url: `${API.JOB_CUSTOM_MESSAGE_TEMPLATES_CONFIG}/${includeCustomFields}`,
    });
  }
}
