import { Form } from 'antd';
import { parseInt } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useJob } from '../../hooks/jobs';
import SuggestCandidate from '../SuggestCandidate/SuggestCandidate';

const JobSuggestCandidate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoadingJob, job } = useJob({ jobId: parseInt(id) });

  return <SuggestCandidate showBack isLoadingJob={isLoadingJob} job={job} isPublic={false} />;
};

export default Form.create()(JobSuggestCandidate);
