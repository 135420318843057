import { first } from 'lodash';
import { API } from '../../config/api';
import { API_URL } from '../../config/general-config';
import FetchService from '../shared/services/FetchService';
import { IFile } from './model';

export const FORM_DATA_KEY = 'files';
class FilesService {
  static async add(data: FormData): Promise<IFile> {
    const files = await this.addMultiple(data);

    return first(files)!;
  }

  static async addMultiple(data: FormData): Promise<IFile[]> {
    const headers = FetchService.getHeaders();
    headers.delete('Content-Type');
    const response = await fetch(`${API_URL}${API.FILES}`, {
      method: 'POST',
      body: data,
      headers,
    });

    return FetchService.processResponse<IFile[]>(response);
  }

  static async addFilesRecommendation(data: FormData, token: string) {
    const headers = new Headers();
    headers.append('JobContactTokenAuthorization', token);

    const response = await fetch(`${API_URL}${API.FILES}/recommendation`, {
      method: 'POST',
      body: data,
      headers,
    });

    return first(await FetchService.processResponse<IFile[]>(response))!;
  }

  static async delete(fileId: number) {
    const response = FetchService.delete({
      url: `${API.FILES}/${fileId.toString()}`,
    });
    return response;
  }

  static async deleteFileRecommendation(fileKey: string, token: string) {
    const headers = new Headers();
    headers.append('JobContactTokenAuthorization', token);

    const response = FetchService.delete({
      url: `${API.FILES}/recommendation/${fileKey}`,
    });
    return response;
  }
}

export default FilesService;
