import { API } from '../../../config/api';
import {
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_PROFILE,
  LOCAL_STORAGE_GOOGLE_TOKEN,
} from '../../../config/general-config';
import { ILoginResponse } from '../../../state/auth';
import { User } from '../../users/model';
import { FacebookRequestModel, GoogleRequestModel } from '../models';
import FetchService from './FetchService';
import { message } from 'antd';
import { URLs } from '../../../config/enums';

class AuthService {
  static async loginWithEmailAndPassword(email: string, password: string): Promise<ILoginResponse> {
    const response = await FetchService.post<ILoginResponse>({
      url: API.LOGIN,
      body: {
        email,
        password,
      },
    });

    const { user } = response;
    localStorage.setItem(LOCAL_STORAGE_JWT, response.token);
    AuthService.saveUserToLocalStorage(user);

    return { user, token: response.token };
  }

  static async loginWithFacebook(model: FacebookRequestModel): Promise<ILoginResponse> {
    const response = await FetchService.post<ILoginResponse>({
      url: API.LOGIN_WITH_FACEBOOK,
      body: model,
    });

    const { user } = response;
    localStorage.setItem(LOCAL_STORAGE_JWT, response.token);
    AuthService.saveUserToLocalStorage(user);

    return { user, token: response.token };
  }

  static async loginWithGoogle(values: GoogleRequestModel): Promise<ILoginResponse> {
    const response = await FetchService.post<ILoginResponse>({
      url: API.LOGIN_WITH_GOOGLE,
      body: values,
    });

    const { user } = response;
    localStorage.setItem(LOCAL_STORAGE_JWT, response.token);
    localStorage.setItem(LOCAL_STORAGE_GOOGLE_TOKEN, values.accessToken);
    AuthService.saveUserToLocalStorage(user);

    return { user, token: response.token };
  }

  static async loginWithLinkedIn(code: string): Promise<ILoginResponse> {
    const response = await FetchService.post<ILoginResponse>({
      url: API.LOGIN_WITH_LINKEDIN,
      body: {
        code,
        redirectUri: `${window.location.origin}${URLs.LOGIN_LINKEDIN}`,
      },
    });

    const { user } = response;
    localStorage.setItem(LOCAL_STORAGE_JWT, response.token);
    AuthService.saveUserToLocalStorage(user);
    return { user, token: response.token };
  }

  static async register(values: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) {
    await FetchService.post({ url: API.REGISTER, body: values });
  }

  static async sendRecoverEmail(email: string) {
    await FetchService.post({
      url: API.SEND_RECOVER_PASSWORD,
      body: {
        email,
      },
    });
  }

  static async resetPassword(token: string, password: string) {
    await FetchService.post({
      url: API.RESET_PASSWORD,
      body: {
        token,
        password,
      },
    });
  }

  static async confirmEmail(token: string) {
    await FetchService.post({
      url: API.CONFIRM_EMAIL,
      body: {
        token,
      },
    });
  }

  static async logout() {
    try {
      AuthService.removeLocalStorage();
      await FetchService.logout({ url: API.LOGOUT });
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    }
  }

  static async validateSession() {
    await FetchService.validateSession({ url: API.USERS_VALIDATE_TOKEN });
  }

  static removeLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_JWT);
    localStorage.removeItem(LOCAL_STORAGE_GOOGLE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_PROFILE);
  }

  static getCurrentUser(): User | undefined {
    const jsonProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
    if (!jsonProfile) {
      return undefined;
    }
    const user = JSON.parse(jsonProfile) as User;
    return user;
  }

  static getToken(): string | undefined {
    const token = localStorage.getItem(LOCAL_STORAGE_JWT);
    return token || undefined;
  }

  static getGoogleToken(): string | undefined {
    const token = localStorage.getItem(LOCAL_STORAGE_GOOGLE_TOKEN);
    return token || undefined;
  }

  static saveUserToLocalStorage(user: User) {
    localStorage.setItem(LOCAL_STORAGE_PROFILE, JSON.stringify(user));
  }
}

export default AuthService;
