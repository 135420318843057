import {
  Alert,
  Button,
  Col,
  Empty,
  Input,
  Row,
  Tag,
  List,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
  message,
  Tooltip,
  Popconfirm,
  Modal,
} from 'antd';
import { map, find, parseInt, without } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { URLs } from '../../config/enums';
import { createBaseGridParams, GridParams, getUrlQueryString } from '../../helpers/grid-helpers';
import { useContacts, useLists } from '../../hooks/contacts';
import ImportContacts from '../ImportContacts/ImportContacts';
import FormListModal from '../Lists/FormListModal';
import LoadingSpinner from '../Loading/LoadingSpinner';
import ContactDetailModal from './ContactDetailModal';
import ContactTile from './ContactTile';
import { useContactsContext } from './ContactsContext';
import ContactsListsService from '../../api/contacts/lists/ContactsListsService';
import classNames from 'classnames';
import ContactsService from '../../api/contacts/ContactsService';
import CustomFieldsModal from './CustomFieldsModal';
import { Contact } from '../../api/contacts/model';

const gridParams = createBaseGridParams({
  sortField: 'firstName',
  pageSize: 24,
});

const Contacts: React.FC = () => {
  const { t } = useTranslation();
  const { duplicates, isLoadingDuplicateContacts, fetchDuplicateContacts } = useContactsContext();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>(URLs.CONTACTS_DETAIL);
  const urlParams = getUrlQueryString();

  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearch] = useDebounce(searchInput, 750);
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    ...urlParams,
  });

  const [selectedListId, setSelectedListId] = useState(0);
  const [selectedListEditId, setSeletedListEditId] = useState(0);

  const { isLoadingContacts, contacts, total, fetchContacts } = useContacts({
    listId: selectedListId,
    params: gridState,
    search: debouncedSearch,
  });
  const { isLoadingLists, lists, fetchLists } = useLists();
  const [isVisibleFormListModal, setIsVisibleFormListModal] = useState(false);
  const [isVisibleContactDetailModal, setIsVisibleContactDetailModal] = useState(false);
  const [isVisibleCustomFieldsModal, setIsVisibleCustomFieldsModal] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<number>(
    match?.params.id ? parseInt(match.params.id) : 0
  );
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [isLoadingSelectedContact, setIsLoadingSelectedContact] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedContactsIds, setCheckedContactsIds] = useState<number[]>([]);
  const [checkedListsIds, setCheckedListsIds] = useState<number[]>([]);
  const [isVisibleAddToListDropdown, setIsVisibleAddToListDropdown] = useState<boolean>(false);
  const [isAddingContactsToLists, setIsAddingContactsToLists] = useState<boolean>(false);
  const [isDeletingContacts, setIsDeletingContacts] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (selectedContactId && !selectedContact) {
        try {
          setIsLoadingSelectedContact(true);
          const response = await ContactsService.findOne(selectedContactId);
          setSelectedContact(response);
          setIsVisibleContactDetailModal(true);
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        } finally {
          setIsLoadingSelectedContact(false);
        }
      } else {
        setIsVisibleContactDetailModal(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContactId]);

  useEffect(() => {
    if (selectedListId) {
      setIsCheckedAll(false);
      setCheckedContactsIds([]);
      setCheckedListsIds([]);
    }
  }, [selectedListId]);

  useEffect(() => {
    const addContactsToLists = async () => {
      if (!isVisibleAddToListDropdown && checkedContactsIds.length && checkedListsIds.length) {
        try {
          setIsAddingContactsToLists(true);

          if (isCheckedAll) {
            await ContactsListsService.addAllContactsToLists(checkedListsIds, selectedListId);
          } else {
            await ContactsListsService.addContactsToLists(checkedContactsIds, checkedListsIds);
          }

          setCheckedContactsIds([]);
          setCheckedListsIds([]);
          setIsCheckedAll(false);
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        } finally {
          setIsAddingContactsToLists(false);
        }
      }
    };
    addContactsToLists();
  }, [
    isVisibleAddToListDropdown,
    checkedContactsIds.length,
    checkedContactsIds,
    checkedListsIds,
    isCheckedAll,
    selectedListId,
  ]);

  const handlePaginationChange = (pageNumber: number, pageSize: number | undefined) => {
    setGridState({
      ...gridState,
      page: pageNumber - 1,
      pageSize: pageSize || 24,
    });
    if (pageSize !== gridState.pageSize) {
      setIsCheckedAll(false);
      setCheckedContactsIds([]);
    }
  };

  const handleCheckContact = (contactId: number): void => {
    if (checkedContactsIds.includes(contactId)) {
      setCheckedContactsIds(without(checkedContactsIds, contactId));
      if (isCheckedAll) {
        setIsCheckedAll(false);
      }
    } else {
      setCheckedContactsIds([...checkedContactsIds, contactId]);
    }
  };

  const handleToggleCheckAllContacts = () => {
    setIsCheckedAll(!isCheckedAll);

    if (!isCheckedAll) {
      setCheckedContactsIds(map(contacts, 'id'));
    } else {
      setCheckedContactsIds([]);
    }
  };

  const handleCheckListIdToAddContacts = (listId: number) => {
    if (checkedListsIds.includes(listId)) {
      setCheckedListsIds(without(checkedListsIds, listId));
    } else {
      setCheckedListsIds([...checkedListsIds, listId]);
    }
  };

  const handleDeleteBulk = async () => {
    try {
      setIsDeletingContacts(true);
      const response = await ContactsService.deleteBulk({
        contactIds: checkedContactsIds,
        deleteAll: isCheckedAll,
        listId: selectedListId || undefined,
      });

      if (response.length) {
        Modal.warning({
          title: t('Some contacts were not deleted'),
          content: t('One or more contacts were not deleted because they are in use in jobs'),
        });
      }

      fetchContacts();
      setIsCheckedAll(false);
      setCheckedContactsIds([]);
      setSelectedListId(0);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsDeletingContacts(false);
    }
  };

  const listName = selectedListId ? find(lists, { id: selectedListId })!.name : '';

  return (
    <>
      <div className="contacts-header">
        <div className="container">
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              sm={12}
              lg={6}
              xl={6}
              xxl={4}
              style={{ textAlign: 'center', display: 'none' }}
            >
              {!isLoadingDuplicateContacts && !!duplicates.length && (
                <Button type="primary" ghost>
                  <Link to={URLs.CONTACTS_DUPLICATES}>
                    Duplicate Contacts ({duplicates.length})
                  </Link>
                </Button>
              )}
              {isLoadingDuplicateContacts && <LoadingSpinner />}
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Input.Search
                disabled={isLoadingContacts}
                style={{ width: '60%' }}
                placeholder={`${t('Type to search a contact')}...`}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                allowClear
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: 'center' }}>
              <Button
                style={{ marginRight: '1rem' }}
                icon="plus"
                className="btn btn--secondary"
                onClick={() => {
                  setIsVisibleCustomFieldsModal(true);
                }}
              >
                {t('Add custom field')}
              </Button>
              <ImportContacts
                contactLists={lists}
                onOk={async () => {
                  await fetchLists();
                  fetchContacts();
                }}
              />
              <Button
                type="primary"
                icon="file"
                onClick={() => history.push(URLs.CONTACTS_IMPORTS)}
                style={{ marginRight: '1rem' }}
              >
                {t('Import results')}
              </Button>

              <Button
                icon="plus"
                className="btn btn--secondary"
                onClick={() => setIsVisibleContactDetailModal(true)}
              >
                {t('Add Contact')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        {isLoadingLists && <LoadingSpinner />}
        {!isLoadingLists && (
          <div
            style={{
              margin: '1rem 0',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <span
              style={{
                display: 'inline-block',
                fontSize: '2rem',
                lineHeight: '2.4rem',
                fontWeight: 'bold',
                marginRight: '2rem',
                marginBottom: '0.5rem',
              }}
            >
              {!selectedListId
                ? t('All Contacts')
                : find(lists, {
                    id: selectedListId,
                  })?.name}{' '}
              ({isLoadingContacts ? '-' : total})
              {!!selectedListId && (
                <Tooltip title={t('Edit')}>
                  <Button
                    size="small"
                    style={{ marginLeft: '.5rem' }}
                    icon="edit"
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      setIsVisibleFormListModal(true);
                      setSeletedListEditId(selectedListId);
                    }}
                  />
                </Tooltip>
              )}
            </span>
            <Tag
              className={classNames('tag', {
                'tag--primary': selectedListId === 0,
              })}
              onClick={() => setSelectedListId(0)}
            >
              {t('All')}
            </Tag>
            {lists.map((list) => (
              <Tag
                className={classNames('tag', {
                  'tag--primary': selectedListId === list.id,
                })}
                key={list.id}
                onClick={() => setSelectedListId(list.id)}
              >
                {list.name}
              </Tag>
            ))}
            <Tag className="tag tag--secondary" onClick={() => setIsVisibleFormListModal(true)}>
              {`+ ${t('New list')}`}
            </Tag>
          </div>
        )}

        {(isLoadingContacts || isLoadingSelectedContact) && <LoadingSpinner />}

        {!(isLoadingContacts && isLoadingSelectedContact) && !!contacts.length && (
          <>
            <div
              style={{
                marginBottom: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Checkbox
                disabled={!contacts.length || isAddingContactsToLists}
                checked={isCheckedAll}
                onChange={() => handleToggleCheckAllContacts()}
              >
                {!!selectedListId ? (
                  <Trans i18nKey="Select all in">
                    Select all in <strong>{{ listName }}</strong>
                  </Trans>
                ) : (
                  t('Select all')
                )}
              </Checkbox>
              <Button.Group size="small">
                <Popconfirm
                  title={t('Delete selected Contacts?')}
                  onConfirm={() => handleDeleteBulk()}
                >
                  <Tooltip title={t('Delete')}>
                    <Button
                      style={{
                        borderWidth: '1px',
                        width: '3.2rem',
                      }}
                      disabled={
                        !checkedContactsIds.length || isAddingContactsToLists || isDeletingContacts
                      }
                      loading={isDeletingContacts}
                      type="danger"
                      icon="delete"
                    />
                  </Tooltip>
                </Popconfirm>
                <Dropdown
                  disabled={
                    !checkedContactsIds.length || isAddingContactsToLists || isDeletingContacts
                  }
                  visible={isVisibleAddToListDropdown}
                  onVisibleChange={(isVisible) => {
                    setIsVisibleAddToListDropdown(isVisible);
                  }}
                  overlay={
                    <Menu>
                      {lists.map((list) => {
                        if (selectedListId && selectedListId === list.id) {
                          return null;
                        }
                        return (
                          <Menu.Item key={list.id} onClick={(e) => e.domEvent.stopPropagation()}>
                            <Checkbox
                              defaultChecked={selectedListId === list.id}
                              style={{ width: '100%' }}
                              checked={checkedListsIds.includes(list.id)}
                              onClick={() => {
                                handleCheckListIdToAddContacts(list.id);
                              }}
                            >
                              {list.name}
                            </Checkbox>
                          </Menu.Item>
                        );
                      })}
                      <Menu.Item
                        onClick={() => {
                          setIsVisibleFormListModal(true);
                        }}
                      >
                        + {t('New')} {t('List')}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    type="primary"
                    loading={isAddingContactsToLists}
                    disabled={isAddingContactsToLists}
                  >
                    {t('Add to lists')} <Icon type="down" />
                  </Button>
                </Dropdown>
              </Button.Group>
            </div>

            {!!(isCheckedAll || checkedContactsIds.length) && (
              <Alert
                style={{ marginBottom: '1rem', textAlign: 'center' }}
                message={
                  <>
                    <span style={{ display: 'inline-block', padding: '0 2rem' }}>
                      {t('Selected contacts', {
                        contactsCount: isCheckedAll ? total : checkedContactsIds.length,
                      })}
                    </span>
                  </>
                }
              />
            )}

            <List
              grid={{ gutter: 16, xs: 2, md: 4, lg: 6 }}
              dataSource={contacts}
              style={{ marginBottom: '4rem' }}
              pagination={{
                onChange: handlePaginationChange,
                current: gridState.page! + 1,
                total,
                pageSize: gridState.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['12', '24', '36', '48'],
                onShowSizeChange: handlePaginationChange,
                showTotal: (totalRecords, rangeRecords) =>
                  `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
              }}
              renderItem={(contact) => {
                return (
                  <List.Item key={contact.id}>
                    <ContactTile
                      contact={contact}
                      lists={lists}
                      fetchContacts={fetchContacts}
                      selectedListId={selectedListId}
                      isChecked={checkedContactsIds.includes(contact.id) || isCheckedAll}
                      handleCheck={handleCheckContact}
                      onClick={(contactId) => {
                        setSelectedContactId(contactId);
                        history.push(URLs.CONTACTS_DETAIL.replace(':id', contactId.toString()));
                      }}
                    />
                  </List.Item>
                );
              }}
            />
          </>
        )}

        {!isLoadingContacts && searchInput && contacts.length === 0 && <Empty />}

        <FormListModal
          list={selectedListEditId ? find(lists, { id: selectedListId }) : undefined}
          isVisible={isVisibleFormListModal}
          onCancel={() => {
            setIsVisibleFormListModal(false);
            setSeletedListEditId(0);
          }}
          onOk={(newList) => {
            setIsVisibleFormListModal(false);
            setSeletedListEditId(0);

            if (checkedContactsIds.length) {
              setCheckedListsIds([newList.id]);
            }

            fetchLists();
          }}
          onDeleted={() => {
            fetchLists();
            setIsVisibleFormListModal(false);
            setSelectedListId(0);
          }}
        />

        <ContactDetailModal
          isVisible={isVisibleContactDetailModal}
          onCancel={() => {
            setSelectedContactId(0);
            setSelectedContact(undefined);
            setIsVisibleContactDetailModal(false);
            history.push(URLs.CONTACTS);
          }}
          onOk={() => {
            setSelectedContactId(0);
            setSelectedContact(undefined);
            setIsVisibleContactDetailModal(false);
            fetchContacts();
            if (fetchDuplicateContacts) {
              fetchDuplicateContacts();
            }
            history.push(URLs.CONTACTS);
          }}
          contactListId={selectedListId}
          contact={selectedContact}
          onDelete={() => {
            setSelectedContactId(0);
            setSelectedContact(undefined);
            setIsVisibleContactDetailModal(false);
            fetchContacts();
            history.push(URLs.CONTACTS);
          }}
        />

        <CustomFieldsModal
          isVisible={isVisibleCustomFieldsModal}
          onCancel={() => setIsVisibleCustomFieldsModal(false)}
          onOk={() => setIsVisibleCustomFieldsModal(false)}
        />
      </div>
    </>
  );
};

export default Contacts;
