import { Col, Layout, Row, Typography } from 'antd';
import React from 'react';
import SuggestCandidatePublic from '../../components/SuggestCandidate/SuggestCandidatePublic';
import Content from '../../layout/Content';
import { APP_DISPLAY_NAME } from '../../config/general-config';

const SuggestCandidatePage: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="container suggest-candidate">
        <Row gutter={16}>
          <Col
            xs={24}
            md={{ span: 20, offset: 2 }}
            lg={{ span: 16, offset: 4 }}
            xxl={{ span: 12, offset: 6 }}
          >
            <SuggestCandidatePublic />
          </Col>
        </Row>
      </Content>
      <Layout.Footer>
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          Powered by {APP_DISPLAY_NAME}
        </Typography.Paragraph>
      </Layout.Footer>
    </Layout>
  );
};

export default SuggestCandidatePage;
