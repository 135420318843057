import React, { useState, useEffect, useCallback } from 'react';
import { Menu, message, Tooltip, Button } from 'antd';
import JobsEditOffer from './JobsEditOffer';
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import LoadingSpinner from '../Loading/LoadingSpinner';
import JobsService from '../../api/jobs/JobsService';
import { Job, JobConfig } from '../../api/jobs/model';
import { JobsNewContext } from './JobsContext';
import JobsNewCustomizeMessages from './JobsEditCustomizeMessages';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import JobsEditClientPage from './JobsEditClientPage';
import JobsEditSelectRecruiters from './JobsEditSelectRecruiters';
import { URLs } from '../../config/enums';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../layout/AppContext';
import { JobStatusEnum } from '../../api/jobs/enums';

const MenuItemsKeys = {
  OFFER: 'OFFER',
  CUSTOMIZE_MESSAGES: 'CUSTOMIZE_MESSAGES',
  CLIENT_PAGE: 'CLIENT_PAGE',
  SELECT_RECRUITERS: 'SELECT_RECRUITERS',
};

const JobsEdit: React.FC = () => {
  const { t } = useTranslation();

  const MenuItemsLabels = {
    [MenuItemsKeys.OFFER]: t('Offer'),
    [MenuItemsKeys.CUSTOMIZE_MESSAGES]: t('Customize Messages'),
    [MenuItemsKeys.CLIENT_PAGE]: t('Client Page'),
    [MenuItemsKeys.SELECT_RECRUITERS]: t('Select Recruiters'),
  };

  const { params } = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const { user } = useAppContext();
  const [job, setJob] = useState<Job>();
  const [isLoadingJob, setIsLoadingJob] = useState(true);
  const [selectedMenuKey, setSelectedMenuKey] = useState<string>();
  const [config, setConfig] = useState<JobConfig>();
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoadingConfig(true);
        const response = await JobsService.findConfig();
        setConfig(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingConfig(false);
      }
    };
    fetchConfig();
  }, []);

  const fetchJob = useCallback(() => {
    const fetch = async () => {
      try {
        if (params.id) {
          setIsLoadingJob(true);
          const response = await JobsService.findOne(parseInt(params.id));
          setJob(response);
        } else {
          setJob(undefined);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingJob(false);
      }
    };
    fetch();
  }, [params.id]);

  useEffect(() => {
    fetchJob();
  }, [fetchJob]);

  useEffect(() => {
    setSelectedMenuKey(MenuItemsKeys.OFFER);
  }, [params.id]);

  if (!user) {
    return <Redirect to={URLs.LOGIN} />;
  }

  if (user && job && user.id !== job.ownerUserId) {
    return <NotFoundPage hideHeader />;
  }

  if (params.id && !isLoadingJob && !job) {
    return <NotFoundPage hideHeader />;
  }

  let title: JSX.Element | string = <LoadingSpinner size="small" align="left" />;

  if (!isLoadingJob) {
    if (job) {
      title = job.jobTitle;
    } else {
      title = t('New Job');
    }
  }

  if (!isLoadingJob && job && job.jobStatus === JobStatusEnum.ARCHIVED) {
    return <Redirect to={URLs.JOBS} />;
  }

  return (
    <>
      <div className="jobs-new__header">
        <span className="jobs-new__header-title">
          {title}
          {job && (
            <Tooltip title={t('View Detail')}>
              <Button
                icon="eye"
                shape="circle-outline"
                type="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => history.push(URLs.JOBS_DETAIL.replace(':id', job?.id.toString()))}
              />
            </Tooltip>
          )}
        </span>
        <Menu
          selectedKeys={selectedMenuKey ? [selectedMenuKey] : undefined}
          mode="horizontal"
          className="jobs-new__menu"
          onClick={(e) => setSelectedMenuKey(e.key)}
        >
          {Object.keys(MenuItemsKeys).map((key) => (
            <Menu.Item
              disabled={key !== selectedMenuKey && (!params.id || isLoadingJob)}
              key={key}
              className="jobs-new__menu-item"
            >
              {MenuItemsLabels[key]}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className="jobs-new__content">
        {!isLoadingJob ? (
          <JobsNewContext.Provider
            value={{
              isLoadingConfig,
              config,
              job,
              isLoadingJob,
              fetchJob,
              setJob,
            }}
          >
            {selectedMenuKey === MenuItemsKeys.OFFER && (
              <JobsEditOffer
                onSubmit={() => setSelectedMenuKey(MenuItemsKeys.CUSTOMIZE_MESSAGES)}
              />
            )}
            {selectedMenuKey === MenuItemsKeys.CUSTOMIZE_MESSAGES && (
              <JobsNewCustomizeMessages
                onSubmit={() => setSelectedMenuKey(MenuItemsKeys.CLIENT_PAGE)}
              />
            )}
            {selectedMenuKey === MenuItemsKeys.CLIENT_PAGE && (
              <JobsEditClientPage
                onSubmit={() => setSelectedMenuKey(MenuItemsKeys.SELECT_RECRUITERS)}
              />
            )}
            {selectedMenuKey === MenuItemsKeys.SELECT_RECRUITERS && <JobsEditSelectRecruiters />}
          </JobsNewContext.Provider>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </>
  );
};

export default JobsEdit;
