import { WrappedFormUtils } from 'antd/lib/form/Form';
import { message } from 'antd';

export function validateAntForm<T>(form: WrappedFormUtils<T>) {
  return new Promise<T>((resolve, reject) => {
    form.validateFieldsAndScroll((error, values: T) => {
      if (error) {
        reject(error);
      } else {
        resolve(values);
      }
    });
  });
}

export function validateAntFormFields<T>(form: WrappedFormUtils, fields: string[]) {
  return new Promise<T>((resolve, reject) => {
    form.validateFields(fields, (error, values: T) => {
      if (error) {
        reject(error);
      } else {
        resolve(values);
      }
    });
  });
}

export const showValidationMessages = (fields: any) => {
  Object.values(fields).forEach((field: any) => {
    if (field && field.errors && field.errors.length) {
      field.errors.forEach((error: any) => {
        const messageText = error.message.replace('%s', error.field);
        message.error(messageText);
      });
    }
  });
};
