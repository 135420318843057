import { Layout } from 'antd';
import React from 'react';
import ViewCandidates from '../../components/ViewCandidates/ViewCandidates';
import Content from '../../layout/Content';

const ViewCandidatesPage: React.FC = () => {
  return (
    <Layout>
      <Content style={{ backgroundColor: '#fff' }}>
        <ViewCandidates />
      </Content>
    </Layout>
  );
};

export default ViewCandidatesPage;
