import { API } from "../../config/api";
import { API_URL } from "../../config/general-config";
import { downloadDataAsCSV } from "../../helpers/file-helpers";
import { GridParams, transformGridParams } from "../../helpers/grid-helpers";
import { Contact } from "../contacts/model";
import { FetchedList } from '../shared/models';
import FetchService from "../shared/services/FetchService";
import { Campaign, CampaignAddType, CampaignSendModel, CampaignUpdateType } from "./model";

class CampaignsService {
  static async findAll(): Promise<FetchedList<Campaign>> {
    return FetchService.get<FetchedList<Campaign>>({ url: API.CAMPAIGNS });
  }

  static async findOne(id: number): Promise<Campaign> {
    return FetchService.get<Campaign>({
      url: API.CAMPAIGNS_GET_ONE.replace(':id', id.toString()),
    });
  }

  static async add(data: CampaignAddType): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.CAMPAIGNS,
      body: data
    });
    return response;
  }

  static async update(data: CampaignUpdateType): Promise<number> {
    const response = await FetchService.put<number>({
      url: `${API.CAMPAIGNS}/${data.id}`,
      body: data
    });
    return response;
  }

  static async campaignStatus(data: any): Promise<any> {
    const response = await FetchService.post<any>({
      url: `${API.CAMPAIGNS}/${data.id}/UpdateCampaignStatus`,
      body: data
    });
    return response;
  }

  static async findArchiveCampaigns(): Promise<FetchedList<Campaign>> {
    return FetchService.get<FetchedList<Campaign>>({ url: API.CAMPAIGNS_ARCHIVED });
  }


  static delete(id: number): Promise<void> {
    return FetchService.delete({
      url: `${API.CAMPAIGNS}/${id}`,
    });
  }

  static async sendCampaign(campaignId: any, model: CampaignSendModel) {
    const response = await FetchService.post({
      url: API.CAMPAIGNS_SEND.replace(':id', campaignId.toString()),
      body: model,
    });
    return response;
  }

  static async findInvitedContacts(campaignId: any): Promise<Contact[]> {
    const response = await FetchService.get<Contact[]>({
      url: API.CAMPAIGNS_INVITED_CONTACTS.replace(':id', campaignId.toString()),
    });
    return response;
  }

  static async emailsData(params: GridParams): Promise<any> {
    let gridParams;
    if(params) {
      gridParams = transformGridParams(params)
    }
    const response = FetchService.get<any>({
      url: API.MESSAGE_EMAILS,
      gridParams
    });
    return response;
  }

  static async emailsDataToCSV(id: number): Promise<any> {
    const response = await fetch(
      `${API_URL}${API.MESSAGE_EMAILS}?CampaignId=${id}`,
      {
        method: 'GET',
        headers: FetchService.getHeaders(),
        
      }
    );
    const data: any = await response.json();
    let headersData = ["Send Date,Subject,Sender Email,Sender Name,Recipients,Status"];

    let usersCsv = data?.list?.reduce((acc: any, user: any) => {
      const { createdDate, subject, sender, senderName, recipients, emailStatusCode } = user;
      acc.push([createdDate, subject, sender, senderName, recipients, emailStatusCode].join(","));
      return acc;
    }, []);
    const CSVData = [...headersData, ...usersCsv].join('\n')

    downloadDataAsCSV(CSVData, `campaign-${id.toString()}-exported-${new Date().toISOString()}.csv`);
  }

  static async findInvitedCampaignContactsEmailStatus(campaignId: any): Promise<Contact[]> {
    const response = await FetchService.get<Contact[]>({
      url: API.CAMPAIGNS_INVITED_CONTACTS_EMAIL_STATUS.replace(':id', campaignId.toString()),
    });
    return response;
  }

  static async findEmailStatistics(id: any): Promise<Campaign> {
    
    const response = await FetchService.get<Campaign>({
      url: `${API.EMAIL_STATISTICS}?CampaignId=${id}`,
    });
    return response;
  }

}

export default CampaignsService;