import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Modal,
  Tooltip,
  Upload,
  Popconfirm,
  Divider,
  Select,
  Row,
  Col,
} from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import ContactsService from '../../api/contacts/ContactsService';
import { Contact, ContactAddType, ContactUpdateType } from '../../api/contacts/model';
import FilesService, { FORM_DATA_KEY } from '../../api/files/FilesService';
import { IFile } from '../../api/files/model';
import { validateAntForm } from '../../helpers/ant-form';
import { getBase64 } from '../../helpers/image-helpers';
import { useAllFields } from '../../hooks/custom-fields';
import { MimeTypes } from '../../api/shared/enums';
import { useTranslation } from 'react-i18next';
import { MatchedCustomField } from '../../api/custom-fields/model';
import { without } from 'lodash';
import {
  ContactDefaultRoute,
  ContactDefaultRouteFormValues,
  ContactDefaultRouteLabel,
} from '../../api/contacts/enums';

const formItemLayout: FormItemProps = {
  labelCol: {
    xs: 24,
    sm: 6,
  },
  wrapperCol: {
    xs: 24,
    sm: 18,
  },
};

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  facebookUrl: string;
  linkedinUrl: string;
  companyName: string;
  jobTitle: string;
  defaultCampaignSendRoute: string;
  matchedCustomFields: MatchedCustomField[];
}

interface Props extends FormComponentProps {
  isVisible: boolean;
  contact?: Partial<Contact>;
  contactListId?: number;
  isEditing?: boolean;
  onOk: (contact: Contact) => void;
  onCancel: () => void;
  onDelete?: () => void;
  contactId?: number;
}

let nextMatchedCustomFieldsKey = 0;

const ContactDetailModal: React.FC<Props> = ({
  form,
  isVisible,
  contactListId,
  contact,
  contactId,
  isEditing = !!contact,
  onCancel,
  onOk,
  onDelete,
}) => {
  const { isLoadingFields, fetchFields, fields } = useAllFields();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [file, setFile] = useState<UploadFile>();
  const [imgPreview, setImgPreview] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    if (nextMatchedCustomFieldsKey === 0) {
      nextMatchedCustomFieldsKey = contact?.matchedCustomFields?.length || 0;
    }
    if (isVisible) {
      fetchFields();
    }
  }, [contact, fetchFields, isVisible]);

  const handleSubmit = async () => {
    let dbFile: IFile | undefined;

    try {
      const values = await validateAntForm<FormValues>(form);
      const {
        firstName,
        lastName,
        email,
        linkedinUrl,
        facebookUrl,
        jobTitle,
        companyName,
        defaultCampaignSendRoute,
        matchedCustomFields,
      } = values;
      setIsSubmitting(true);
      let dbContact: Contact;

      if (file && (!contact || !contact.pictureFileId)) {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY, (file as unknown) as File);
        dbFile = await FilesService.add(formData);
      }

      if (!isEditing) {
        const data: ContactAddType = {
          firstName,
          lastName,
          email,
          linkedinUrl,
          facebookUrl,
          companyName,
          jobTitle,
          addToContactListId: contactListId || undefined,
          pictureFileId: dbFile?.id,
          defaultCampaignSendRoute,
          matchedCustomFields: matchedCustomFields?.filter(Boolean),
          profilePicture: contact?.pictureUrl,
        };

        const response = await ContactsService.add(data);
        dbContact = await ContactsService.findOne(response);
      } else {
        const data: ContactUpdateType = {
          id: contact!.id!,
          firstName,
          lastName,
          email,
          linkedinUrl,
          facebookUrl,
          companyName,
          jobTitle,
          pictureFileId: dbFile?.id || contact?.pictureFileId,
          defaultCampaignSendRoute,
          matchedCustomFields: matchedCustomFields?.filter(Boolean),
        };
        await ContactsService.update(data);
        dbContact = await ContactsService.findOne(contact!.id!);
      }

      onOk(dbContact);
      form.resetFields();
      setFile(undefined);
      setImgPreview(undefined);
    } catch (error) {
      if (error.message) {
        message.error(t(`${error.message}`));
      }
      if (dbFile) {
        await FilesService.delete(dbFile.id);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDefaultRouteChange = (value: string, route: string) => {
    const selectedRoute = form.getFieldValue('defaultCampaignSendRoute');
    if (!value && selectedRoute === route) {
      form.setFieldsValue({ defaultCampaignSendRoute: undefined });
    }
  };

  const handleFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setFile(undefined);
      setImgPreview(undefined);
    } else {
      const response = await getBase64((info.file as unknown) as File);
      if (response) {
        setImgPreview(response.toString());
      }
      setFile(info.file);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const response = await ContactsService.delete(contact!.id!);
      setIsDeleting(false);
      if (response.length) {
        Modal.warning({
          title: t('Contact not deleted'),
          content: t(`We could not delete contact`, {
            contactName: `${contact?.firstName} ${contact?.lastName}`,
          }),
        });
      }
      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      setIsDeleting(false);
    }
  };

  let title = '';

  if (isEditing && isVisible) {
    if (contact) {
      title = t('Update Contact');
    } else {
      title = t('Add Contact');
    }
  }

  const avatarProps: AvatarProps = {
    className: 'avatar-upload__img',
  };

  if (imgPreview) {
    avatarProps.src = imgPreview;
  } else {
    avatarProps.icon = 'user';
    if (contact?.pictureUrl) {
      avatarProps.src = contact.pictureUrl;
    }
  }

  const renderContent = () => {
    if (!isVisible) {
      return null;
    }

    form.getFieldDecorator('matchedCustomFieldsKeys', {
      initialValue: contact?.matchedCustomFields?.map((item, index) => index) || [],
    });

    const matchedCustomFieldsKeys: number[] = form.getFieldValue('matchedCustomFieldsKeys');

    const handleRemoveField = (customFieldKey: number) => {
      form.setFieldsValue({
        matchedCustomFieldsKeys: without(matchedCustomFieldsKeys, customFieldKey),
      });
    };

    const handleAddNewField = () => {
      form.setFieldsValue({
        matchedCustomFieldsKeys: [...matchedCustomFieldsKeys, nextMatchedCustomFieldsKey],
      });

      nextMatchedCustomFieldsKey += 1;
    };

    return (
      <>
        <Form layout="horizontal">
          <Form.Item>
            <div className="avatar-upload">
              <Upload
                fileList={file ? [file] : []}
                beforeUpload={() => false}
                onChange={handleFileOnChange}
                accept={`${MimeTypes.IMAGE_JPEG}, ${MimeTypes.IMAGE_PNG}`}
                showUploadList={false}
              >
                <Avatar {...avatarProps} />

                {(contact?.pictureUrl || imgPreview) && (
                  <>
                    <Tooltip title={t('Remove')}>
                      <Button
                        className="avatar-upload__btn avatar-upload__btn--remove"
                        icon="delete"
                        shape="circle"
                        type="danger"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setFile(undefined);
                          setImgPreview(undefined);
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </Upload>
            </div>
          </Form.Item>

          <Form.Item label={t('First Name')} {...formItemLayout}>
            {form.getFieldDecorator('firstName', {
              initialValue: contact?.firstName,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            })(<Input autoFocus onPressEnter={handleSubmit} />)}
          </Form.Item>
          <Form.Item label={t('Last Name')} {...formItemLayout}>
            {form.getFieldDecorator('lastName', {
              initialValue: contact?.lastName,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            })(<Input onPressEnter={handleSubmit} />)}
          </Form.Item>

          <Form.Item label={t('Email')} {...formItemLayout}>
            {form.getFieldDecorator('email', {
              initialValue: contact?.email,
              rules: [
                {
                  type: 'email',
                  message: t('The entered email is not valid'),
                },
              ],
            })(
              <Input
                onPressEnter={handleSubmit}
                onChange={(e) => handleDefaultRouteChange(e.target.value, 'EMAIL')}
              />
            )}
          </Form.Item>

          <Form.Item label={t('LinkedIn URL')} {...formItemLayout}>
            {form.getFieldDecorator('linkedinUrl', {
              initialValue: contact?.linkedinUrl,
            })(
              <Input
                onPressEnter={handleSubmit}
                onChange={(e) => handleDefaultRouteChange(e.target.value, 'LINKEDIN')}
              />
            )}
          </Form.Item>

          <Form.Item label={t('Facebook URL')} {...formItemLayout}>
            {form.getFieldDecorator('facebookUrl', {
              initialValue: contact?.facebookUrl,
            })(
              <Input
                onPressEnter={handleSubmit}
                onChange={(e) => handleDefaultRouteChange(e.target.value, 'FACEBOOK')}
              />
            )}
          </Form.Item>

          {form.getFieldValue('email') ||
          form.getFieldValue('linkedinUrl') ||
          form.getFieldValue('facebookUrl') ? (
            <Form.Item
              label={<span style={{ whiteSpace: 'pre-wrap' }}>{t('Default campaign route')}</span>}
              {...formItemLayout}
            >
              {form.getFieldDecorator('defaultCampaignSendRoute', {
                initialValue:
                  contact?.defaultCampaignSendRoute &&
                  form.getFieldValue(
                    ContactDefaultRouteFormValues[
                      contact?.defaultCampaignSendRoute as ContactDefaultRoute
                    ]
                  )
                    ? contact?.defaultCampaignSendRoute
                    : undefined,
              })(
                <Select allowClear placeholder={t('Select a value')}>
                  {Object.keys(ContactDefaultRoute).map(
                    (route) =>
                      form.getFieldValue(
                        ContactDefaultRouteFormValues[route as ContactDefaultRoute]
                      ) && (
                        <Select.Option value={route} key={route}>
                          {t(ContactDefaultRouteLabel[route as ContactDefaultRoute])}
                        </Select.Option>
                      )
                  )}
                </Select>
              )}
            </Form.Item>
          ) : null}

          <Form.Item label={t('Company')} {...formItemLayout}>
            {form.getFieldDecorator('companyName', {
              initialValue: contact?.companyName,
              rules: [
                {
                  whitespace: true,
                  message: t('Field cannot be empty'),
                },
              ],
            })(<Input onPressEnter={handleSubmit} />)}
          </Form.Item>
          <Form.Item label={t('Position')} {...formItemLayout}>
            {form.getFieldDecorator('jobTitle', {
              initialValue: contact?.jobTitle,
              rules: [
                {
                  whitespace: true,
                  message: t('Field cannot be empty'),
                },
              ],
            })(<Input onPressEnter={handleSubmit} />)}
          </Form.Item>

          {matchedCustomFieldsKeys.map((matchedFieldKey, index) => (
            <React.Fragment key={matchedFieldKey}>
              <Row>
                <Form.Item style={{ display: 'none' }}>
                  {form.getFieldDecorator(`matchedCustomFields[${matchedFieldKey}].contactId`, {
                    initialValue: contact?.id || 0,
                  })(<Input />)}
                </Form.Item>
                <Col span={6}>
                  <Form.Item>
                    {form.getFieldDecorator(
                      `matchedCustomFields[${matchedFieldKey}].customFieldId`,
                      {
                        initialValue:
                          contact?.matchedCustomFields?.find(
                            (item, index) => index === matchedFieldKey
                          )?.customField.id || undefined,
                        rules: [
                          {
                            required: true,
                            message: t('Field cannot be empty'),
                          },
                        ],
                      }
                    )(
                      <Select
                        placeholder={t('Select a value')}
                        loading={isLoadingFields}
                        disabled={isLoadingFields}
                        onChange={(value: number) => {
                          const values = form.getFieldsValue() as Contact;
                          const formItem = values.matchedCustomFields
                            .filter(Boolean)
                            .find((i) => i.customFieldId === value);
                          if (formItem) {
                            const formItemIndex =
                              values.matchedCustomFields.indexOf(formItem, 0) || 0;
                            form.setFieldsValue({
                              [`matchedCustomFields[${formItemIndex}].customFieldId`]: undefined,
                            });
                          }
                        }}
                      >
                        {fields.map((field) => (
                          <Select.Option value={field.id} key={field.id}>
                            {field.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item>
                    {form.getFieldDecorator(`matchedCustomFields[${matchedFieldKey}].value`, {
                      initialValue: contact?.matchedCustomFields?.find(
                        (item, index) => index === matchedFieldKey
                      )?.value,
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: t('Field cannot be empty'),
                        },
                      ],
                    })(
                      <Input
                        disabled={isLoadingFields}
                        style={{ width: '91%', marginLeft: '10px', marginRight: '5px' }}
                      />
                    )}
                    <Button
                      type="danger"
                      icon="delete"
                      disabled={isSubmitting || isLoadingFields}
                      onClick={() => handleRemoveField(matchedFieldKey)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          ))}

          <Button
            type="primary"
            icon="plus"
            disabled={!fields.length}
            onClick={() => {
              handleAddNewField();
            }}
          >
            {t('Add custom field')}
          </Button>
        </Form>

        <Divider />

        {onDelete && (
          <Popconfirm title={t('Delete this contact?')} onConfirm={() => handleDelete()}>
            <Button
              disabled={isDeleting || isSubmitting}
              loading={isDeleting}
              htmlType="button"
              type="danger"
            >
              {t('Delete')}
            </Button>
          </Popconfirm>
        )}
      </>
    );
  };

  return (
    <Modal
      visible={isVisible}
      title={title}
      width={750}
      onCancel={() => {
        setImgPreview(undefined);
        setFile(undefined);
        form.resetFields();
        onCancel();
      }}
      cancelButtonProps={{ disabled: isSubmitting || isDeleting }}
      okButtonProps={{ disabled: isSubmitting || isDeleting }}
      onOk={handleSubmit}
      confirmLoading={isSubmitting}
    >
      {renderContent()}
    </Modal>
  );
};

export default Form.create<Props>()(ContactDetailModal);
