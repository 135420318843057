import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import Avatar, { AvatarProps } from 'antd/lib/avatar';
import { FormComponentProps } from 'antd/lib/form';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FilesService, { FORM_DATA_KEY } from '../../api/files/FilesService';
import { IFile } from '../../api/files/model';
import { MimeTypes } from '../../api/shared/enums';
import AuthService from '../../api/shared/services/AuthService';
import { UpdateProfile, User } from '../../api/users/model';
import UsersService from '../../api/users/UsersService';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { getBase64 } from '../../helpers/image-helpers';
import { useAppContext } from '../../layout/AppContext';
import { updateProfile } from '../../state/auth';
import { useTranslation } from 'react-i18next';
import {
  SmtpConfigurationLimitType,
  SmtpConfigurationLimitTypeLabel,
} from '../../api/recruiter-configurations/enums';

interface FormValues {
  firstName: string;
  lastName: string;
  linkedinSessionCookie?: string;
  facebookSessionCookie_c_user?: string;
  facebookSessionCookie_xs?: string;
  linkedinJSessionId?: string;
  facebookMessageLimitType: string;
  linkedinMessageLimitType: string;
  facebookMessageLimitAmount?: number;
  linkedinMessageLimitAmount?: number;
}

interface Props extends FormComponentProps<FormValues>, DispatchProp {}

const ProfileBasicInfo: React.FC<Props> = ({ form, dispatch }) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<UploadFile>();
  const [imgPreview, setImgPreview] = useState<string>();
  const [profilePictureId, setProfilePictureId] = useState(user?.profilePictureId);
  const [profilePictureUrl, setProfilePictureUrl] = useState(user?.profilePictureUrl);

  if (!user) {
    return <Redirect to={URLs.LOGOUT} />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    let dbFile: IFile | undefined;

    try {
      e.preventDefault();
      const values = await validateAntForm(form);
      setIsSubmitting(true);

      if (!profilePictureId && file) {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY, (file as unknown) as File);
        dbFile = await FilesService.add(formData);
      }

      const {
        firstName,
        lastName,
        linkedinSessionCookie,
        facebookSessionCookie_c_user,
        facebookSessionCookie_xs,
        linkedinJSessionId,
        facebookMessageLimitAmount,
        linkedinMessageLimitAmount,
        linkedinMessageLimitType,
        facebookMessageLimitType,
      } = values;

      const model: UpdateProfile = {
        firstName,
        lastName,
        profilePictureId: dbFile?.id || profilePictureId,
        language: user.language,
        linkedinSessionCookie,
        facebookSessionCookie_c_user,
        facebookSessionCookie_xs,
        linkedinJSessionId,
        facebookMessageLimitType,
        linkedinMessageLimitType,
        facebookMessageLimitAmount,
        linkedinMessageLimitAmount,
      };
      await UsersService.updateProfile(model);

      const updatedUser: User = {
        ...user,
        firstName,
        lastName,
        profilePictureId: dbFile?.id || profilePictureId,
        profilePictureUrl: dbFile?.location || profilePictureUrl,
        linkedinSessionCookie,
        facebookSessionCookie_c_user,
        facebookSessionCookie_xs,
        linkedinJSessionId,
        facebookMessageLimitAmount,
        facebookMessageLimitType,
        linkedinMessageLimitType,
        linkedinMessageLimitAmount,
      };

      AuthService.saveUserToLocalStorage(updatedUser);
      dispatch(updateProfile(updatedUser));
      message.success(`${t('Profile updated')}!`);
      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setFile(undefined);
      setImgPreview(undefined);
    } else {
      const response = await getBase64((info.file as unknown) as File);
      if (response) {
        setImgPreview(response.toString());
      }
      setFile(info.file);
    }
  };

  const avatarProps: AvatarProps = {
    className: 'avatar-upload__img',
  };

  if (imgPreview) {
    avatarProps.src = imgPreview;
  } else {
    avatarProps.icon = 'user';
    if (profilePictureUrl) {
      avatarProps.src = profilePictureUrl;
    }
  }

  return (
    <>
      <Typography.Paragraph
        style={{
          marginBottom: '2rem',
          fontWeight: 'bold',
          fontSize: '1.6rem',
          color: '#000',
        }}
      >
        {t('Profile Picture')}
      </Typography.Paragraph>
      <div className="avatar-upload">
        <Upload
          fileList={file ? [file] : []}
          beforeUpload={() => false}
          onChange={handleFileOnChange}
          accept={`${MimeTypes.IMAGE_JPEG}, ${MimeTypes.IMAGE_PNG}`}
          showUploadList={false}
        >
          <Avatar {...avatarProps} />
          {(imgPreview || profilePictureUrl) && (
            <Tooltip title={t('Remove')}>
              <Button
                className="avatar-upload__btn avatar-upload__btn--remove"
                icon="delete"
                shape="circle"
                type="danger"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setFile(undefined);
                  setImgPreview(undefined);
                  setProfilePictureId(undefined);
                  setProfilePictureUrl(undefined);
                }}
              />
            </Tooltip>
          )}
        </Upload>
      </div>

      <Form onSubmit={handleSubmit}>
        <Typography.Paragraph
          style={{
            marginBottom: '2rem',
            fontWeight: 'bold',
            fontSize: '1.6rem',
            color: '#000',
          }}
        >
          {t('Basic Info')}
        </Typography.Paragraph>
        <Form.Item label={t('First Name')}>
          {form.getFieldDecorator('firstName', {
            initialValue: user.firstName,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('Last Name')}>
          {form.getFieldDecorator('lastName', {
            initialValue: user.lastName,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label={t('LinkedIn Session Cookie (li_at)')}>
          {form.getFieldDecorator('linkedinSessionCookie', {
            initialValue: user.linkedinSessionCookie,
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('LinkedIn JS Session Id (JSESSIONID)')}>
          {form.getFieldDecorator('linkedinJSessionId', {
            initialValue: user.linkedinJSessionId,
          })(<Input />)}
        </Form.Item>

        <Form.Item label={t('Facebook Session Cookie (c_user)')}>
          {form.getFieldDecorator('facebookSessionCookie_c_user', {
            initialValue: user.facebookSessionCookie_c_user,
          })(<Input />)}
        </Form.Item>

        <Form.Item label={t('Facebook Session Cookie (xs)')}>
          {form.getFieldDecorator('facebookSessionCookie_xs', {
            initialValue: user.facebookSessionCookie_xs,
          })(<Input />)}
        </Form.Item>

        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={t('LinkedIn Message Limit')}>
              {form.getFieldDecorator('linkedinMessageLimitType', {
                initialValue: user.linkedinMessageLimitType || undefined,
              })(
                <Select allowClear placeholder={t('No limit')}>
                  {Object.keys(SmtpConfigurationLimitType).map((limitType) => (
                    <Select.Option value={limitType} key={limitType}>
                      {t(SmtpConfigurationLimitTypeLabel[limitType as SmtpConfigurationLimitType])}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {form.getFieldValue('linkedinMessageLimitType') && (
              <Form.Item label={t('Quantity')}>
                {form.getFieldDecorator('linkedinMessageLimitAmount', {
                  initialValue: user.linkedinMessageLimitAmount,
                  rules: [{ required: true, message: t('This field is required') }],
                })(<InputNumber style={{ width: '100%' }} min={1} />)}
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={t('Facebook Message Limit')}>
              {form.getFieldDecorator('facebookMessageLimitType', {
                initialValue: user.facebookMessageLimitType || undefined,
              })(
                <Select allowClear placeholder={t('No limit')}>
                  {Object.keys(SmtpConfigurationLimitType).map((limitType) => (
                    <Select.Option value={limitType} key={limitType}>
                      {t(SmtpConfigurationLimitTypeLabel[limitType as SmtpConfigurationLimitType])}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {form.getFieldValue('facebookMessageLimitType') && (
              <Form.Item label={t('Quantity')}>
                {form.getFieldDecorator('facebookMessageLimitAmount', {
                  initialValue: user.facebookMessageLimitAmount,
                  rules: [{ required: true, message: t('This field is required') }],
                })(<InputNumber style={{ width: '100%' }} min={1} />)}
              </Form.Item>
            )}
          </Col>
        </Row>

        <Button.Group>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            htmlType="submit"
            type="primary"
            data-testid="submit"
          >
            {t('Update')}
          </Button>
        </Button.Group>
      </Form>
    </>
  );
};

export default connect()(Form.create()(ProfileBasicInfo));
