import React from 'react';
import ConfirmVerifyEmail from '../../components/ConfirmVerifyEmail/ConfirmVerifyEmail';
import SecurityLayout from '../../layout/SecurityLayout';

const ConfirmVerifyEmailPage: React.FC = () => {
  return (
    <SecurityLayout>
      <ConfirmVerifyEmail />
    </SecurityLayout>
  );
};

export default ConfirmVerifyEmailPage;
