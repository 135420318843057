import { Button, Col, Icon, Input, message, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  SmtpConfigurationGroupingEmaisLabel,
  SmtpConfigurationLimitType,
  SmtpConfigurationLimitTypeLabel,
  SmtpConfigurationGroupingEmailsType,
} from '../../api/recruiter-configurations/enums';
import {
  SmtpCustomConfig,
  SmtpCustomConfigFetched,
} from '../../api/recruiter-configurations/models';
import { RecruiterConfigurationsService } from '../../api/recruiter-configurations/RecruiterConfigurationsService';
import { URLs } from '../../config/enums';
import { ROWS_PER_PAGES } from '../../config/general-config';
import { formatDateTime } from '../../helpers/date-helpers';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';
import RecruiterSmtpCustomConfigTestEmailModal from './RecruiterSmtpCustomConfigTestEmailModal';

interface RecruiterSmtpCustomConfigsProps {}

export const RecruiterSmtpCustomConfigsGrid: React.FC<RecruiterSmtpCustomConfigsProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [smtpCustomConfigsFetched, setSmtpCustomConfigsFetched] = useState<SmtpCustomConfigFetched>(
    { count: 0, list: [] }
  );
  const gridParams = createBaseGridParams({
    sortOrder: 'descend',
    sortField: 'createdDate',
    pageSize: ROWS_PER_PAGES,
  });

  const history = useHistory();
  const { t } = useTranslation();

  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    filters: [],
  });

  const [isTestEmailModalOpen, setIsTestEmailModalOpen] = useState(false);
  const [selectedSmtpCustomConfig, setSelectedSmtpCustomConfig] = useState<
    SmtpCustomConfig | undefined
  >();

  useEffect(() => {
    fetchSmtpCustomConfigurations(gridState);
  }, [gridState]);

  const fetchSmtpCustomConfigurations = async (gridState: GridParams) => {
    try {
      setIsLoading(true);
      const response = await RecruiterConfigurationsService.findSmtpCustomConfigurations(gridState);
      setSmtpCustomConfigsFetched(response);
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (key: string, value: any) => {
    let newFilters;
    if (!value) {
      newFilters = [...gridState.filters!.filter((i) => i.key !== key)];
    } else {
      newFilters = [...gridState.filters!.filter((i) => i.key !== key), { key, value }];
    }
    setGridState({ ...gridState, filters: [...newFilters] });
  };

  const renderFilters = () => {
    return (
      <>
        <Row gutter={12}>
          <Col xs={20} sm={20} md={20} lg={10} style={{ marginBottom: '1.5rem' }}>
            <Input.Search
              disabled={isLoading}
              placeholder={t('Search by name')}
              onSearch={(value: any) => handleFilterChange('query', value)}
              allowClear
            />
          </Col>

          <Col xs={4} sm={4} md={4} lg={14} style={{ marginBottom: '1.5rem' }}>
            <Tooltip title={t('New')}>
              <Button
                size="default"
                style={{ float: 'right', marginRight: '5px' }}
                type="primary"
                shape="circle"
                icon="plus"
                onClick={() => history.push(URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_NEW)}
              />
            </Tooltip>
          </Col>
        </Row>
      </>
    );
  };

  const getLimitColumnText = (limitType: string, emailsSent: number) => {
    let text = `${emailsSent} ${emailsSent === 1 ? t('Email lowercase') : t('Emails lowercase')
      } ${t('Sent lowercase')}`;

    if (limitType === SmtpConfigurationLimitType.DAILY) text += ` ${t('In the last day')}`;
    else text += ` ${t('In the last hour')}`;

    return text;
  };

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      align: 'center' as 'center',
    },
    {
      title: t('Created Date'),
      dataIndex: 'createdDate',
      align: 'center' as 'center',
      render: (cell: any) => (cell ? formatDateTime(new Date(cell)) : '-'),
    },
    {
      title: t('Domain Names'),
      dataIndex: 'domainNames',
      align: 'center' as 'center',
      render: (cell: string) => (cell ? cell.split(';').map((domain) => <p>{domain}</p>) : '-'),
    },
    {
      title: t('Limit'),
      dataIndex: 'limitType',
      align: 'center' as 'center',
      render: (cell: string, row: SmtpCustomConfig | any) =>
        row.limitType ? (
          <>
            <p>{`${row.limitAmount} ${row.limitAmount > 1 ? 'emails' : 'email'} every ${row.intervalLimit} ${t(SmtpConfigurationGroupingEmaisLabel[row.limitType as SmtpConfigurationGroupingEmailsType]) || t(
              SmtpConfigurationLimitTypeLabel[row.limitType as SmtpConfigurationLimitType]
            )}`}</p>
            <p>{getLimitColumnText(row.limitType, row.emailsSent)}</p>
          </>
        ) : (
          <Icon type="close" />
        ),
    },

    {
      title: t('Actions'),
      align: 'center' as 'center',
      render: (cell: any, row: SmtpCustomConfig) => (
        <>
          <Tooltip title={t('Edit')}>
            <Button
              style={{ marginRight: '1rem' }}
              onClick={() =>
                history.push(
                  URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_DETAIL.replace(
                    ':id',
                    row.id.toString()
                  )
                )
              }
            >
              <Icon type="edit" />
            </Button>
          </Tooltip>
          <Tooltip title={t('Send Test Email')}>
            <Button
              disabled={!row.domainNames}
              onClick={() => {
                setIsTestEmailModalOpen(true);
                setSelectedSmtpCustomConfig(row);
              }}
            >
              <Icon type="mail" />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      {renderFilters()}
      <Table
        key="id"
        rowKey="id"
        loading={isLoading}
        bordered
        columns={columns}
        dataSource={smtpCustomConfigsFetched.list}
        pagination={{
          total: smtpCustomConfigsFetched.count,
          pageSize: gridState.pageSize || ROWS_PER_PAGES,
          showTotal: (totalRecords, rangeRecords) =>
            `${rangeRecords[0]} - ${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
          onChange: (pageNumber: number) => setGridState({ ...gridState, page: pageNumber - 1 }),
        }}
      />
      <RecruiterSmtpCustomConfigTestEmailModal
        visible={isTestEmailModalOpen}
        setVisible={(visible) => setIsTestEmailModalOpen(visible)}
        smtpCustomConfiguration={selectedSmtpCustomConfig}
      />
    </>
  );
};
