import { Avatar, Button, Card, List } from 'antd';
import { formatDistance } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyInstance } from '../../api/surveys/models';
import { getQuestionsLabel } from '../../helpers/string-helpers';
import SurveyInstanceDetailModal from './SurveyInstanceDetailModal';
import SurveyTestInstancesList from './SurveyTestInstancesList';

interface Props {
  surveyTemplateId: number;
}

const SurveyDetailResultsList: React.FC<Props> = ({ surveyTemplateId }) => {
  const { t } = useTranslation();

  const [isSurveyInstanceDetailModalVisible, setIsSurveyInstanceDetailModalVisible] = useState(
    false
  );
  const [selectedSurveyInstance, setSelectedSurveyInstance] = useState<SurveyInstance>();

  const handleSurveyTestInstancesListItemClick = (surveyInstance: SurveyInstance) => {
    setIsSurveyInstanceDetailModalVisible(true);
    setSelectedSurveyInstance(surveyInstance);
  };

  const handleSurveyInstanceDetailModalCancel = () => {
    setIsSurveyInstanceDetailModalVisible(false);
    setSelectedSurveyInstance(undefined);
  };

  return (
    <>
      <Card className="job-offer__surveys">
        <h1>{t('Surveys list')}</h1>
        <SurveyTestInstancesList
          surveyTemplateId={surveyTemplateId}
          completedOnly={true}
          renderItem={(surveyInstance) => (
            <List.Item
              key={surveyInstance.id}
              extra={
                <Button
                  type="link"
                  onClick={() => handleSurveyTestInstancesListItemClick(surveyInstance)}
                >
                  {t('Details')}
                </Button>
              }
            >
              <List.Item.Meta
                avatar={<Avatar icon="user" src={surveyInstance.contactModel.pictureUrl} />}
                title={`${t('Answered by contactFullName', {
                  contactFullName: `${surveyInstance.contactModel.firstName || 'John'} ${
                    surveyInstance.contactModel.lastName || 'Doe'
                  }`,
                  interpolation: { escapeValue: false },
                })} (${surveyInstance.surveyInstanceQuestionModels.length} ${getQuestionsLabel(
                  surveyInstance,
                  t
                )})`}
                description={`${formatDistance(
                  new Date(surveyInstance.completedDate!),
                  new Date(),
                  {
                    addSuffix: true,
                  }
                )}`}
              ></List.Item.Meta>
            </List.Item>
          )}
        />
      </Card>

      <SurveyInstanceDetailModal
        isVisible={isSurveyInstanceDetailModalVisible}
        surveyInstance={selectedSurveyInstance}
        onCancel={handleSurveyInstanceDetailModalCancel}
      />
    </>
  );
};

export default SurveyDetailResultsList;
