import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Job } from '../api/jobs/model';
import JobsService from '../api/jobs/JobsService';

interface UseJobProps {
  jobId?: number;
}

export const useJob = ({ jobId }: UseJobProps) => {
  const [isLoadingJob, setIsLoadingJob] = useState(true);
  const [job, setJob] = useState<Job>();

  const fetchJob = useCallback(() => {
    const fetch = async () => {
      try {
        if (jobId) {
          setIsLoadingJob(true);
          const response = await JobsService.findOne(jobId);
          setJob(response);
        } else {
          setJob(undefined);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingJob(false);
      }
    };

    fetch();
  }, [jobId]);

  useEffect(() => {
    fetchJob();
  }, [fetchJob]);

  return { isLoadingJob, job, fetchJob };
};

export const useJobByToken = (p: string) => {
  const [isLoadingJob, setIsLoadingJob] = useState(true);
  const [job, setJob] = useState<Job>();

  const fetchJob = useCallback(() => {
    const fetch = async () => {
      try {
        if (p) {
          setIsLoadingJob(true);
          const response = await JobsService.findOneByToken(p);
          setJob(response);
        } else {
          setJob(undefined);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingJob(false);
      }
    };

    fetch();
  }, [p]);

  useEffect(() => {
    fetchJob();
  }, [fetchJob]);

  return { isLoadingJob, job, fetchJob };
};
