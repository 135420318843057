import { Avatar, Descriptions, Modal } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContact } from '../../hooks/contacts';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';

interface Props {
  isVisible: boolean;
  onCancel: () => void;
  contactId?: number;
}

const ContactReadModal: React.FC<Props> = ({ isVisible, onCancel, contactId }) => {
  const { isLoadingContact, contact } = useContact({ contactId });
  const { t } = useTranslation();

  let title = '';

  if (!isLoadingContact && isVisible) {
    if (contact) {
      title = `${contact.firstName} ${contact.lastName}`;
    }
  }

  const avatarProps: AvatarProps = {
    className: 'avatar-upload__img avatar-upload__img--no-pointer',
  };

  if (contact?.pictureUrl) {
    avatarProps.src = contact.pictureUrl;
  } else {
    avatarProps.icon = 'user';
  }

  const renderContent = () => {
    if (!isVisible) {
      return null;
    }

    if (isLoadingContact) {
      return <LoadingSpinner />;
    }

    if (contactId && !contact) {
      return <NotFoundPage hideHeader />;
    } else if (!contact) {
      return <NotFoundPage hideHeader />;
    }

    return (
      <div>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Avatar {...avatarProps} />
        </div>

        <Descriptions column={2}>
          <Descriptions.Item label={t('Name')}>
            {contact.firstName} {contact.lastName}
          </Descriptions.Item>
          <Descriptions.Item label={t('Email')}>
            {contact.email && <a href={`mailto:${contact.email}`}>{contact.email}</a>}
          </Descriptions.Item>
          <Descriptions.Item label={t('Company')}>{contact.companyName}</Descriptions.Item>
          <Descriptions.Item label={t('Position')}>{contact.jobTitle}</Descriptions.Item>
          <Descriptions.Item label={t('Phone')}>
            {contact.phone && <a href={`tel:${contact.phone}`}>{contact.phone}</a>}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  return (
    <Modal
      visible={isVisible}
      title={title}
      footer={null}
      onCancel={() => {
        onCancel();
      }}
    >
      {renderContent()}
    </Modal>
  );
};

export default ContactReadModal;
