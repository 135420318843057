import React, { useCallback, useEffect, useState } from 'react';
import { message, Form, Rate, Radio, Divider, Button, Card, Tag } from 'antd';
import { SurveyInstance } from '../../api/surveys/models';
import SurveysService from '../../api/surveys/SurveysService';
import { getUrlQueryString, GridParams } from '../../helpers/grid-helpers';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { FormComponentProps } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { SCALE_MAX_VALUE, SurveyQuestionType } from '../../api/surveys/enums';
import TextArea from 'antd/lib/input/TextArea';
import { validateAntForm } from '../../helpers/ant-form';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import SurveyCompletedPage from '../../pages/SurveyCompletedPage/SurveyCompletedPage';
import JobsService from '../../api/jobs/JobsService';
import { useAppContext } from '../../layout/AppContext';
import { Job } from '../../api/jobs/model';
import { applyFieldsToQuestions } from '../../helpers/string-helpers';

interface QueryString extends GridParams {
  p?: string;
}

interface FormValues {
  surveyInstanceQuestion: string[];
}

interface Props extends FormComponentProps<FormValues> {}
const CompleteSurvey: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { changeLanguage } = useAppContext();
  const urlParams: QueryString = getUrlQueryString();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [surveyCompletedSuccessfully, setsurveyCompletedSuccessfully] = useState(false);
  const [isLoadingSurveyInstance, setIsLoadingSurveyInstance] = useState(true);
  const [surveyInstance, setSurveyInstance] = useState<SurveyInstance>();
  const [job, setJob] = useState<Job>();

  const fetchSurveyInstance = useCallback(async () => {
    const hash = urlParams.p;

    if (!hash) {
      message.error(t('Could not found survey'));
      return;
    }

    try {
      setIsLoadingSurveyInstance(true);
      const encodedHash = encodeURIComponent(encodeURIComponent(urlParams.p!));
      const response = await SurveysService.findInstance(encodedHash);
      if (response.jobCandidateModel.jobId !== 0) {
        const job = await JobsService.findOne(response.jobCandidateModel.jobId);
        changeLanguage(job.language);
        setJob(job);
      }
      setSurveyInstance(response);
    } catch (error) {
    } finally {
      setIsLoadingSurveyInstance(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.p]);

  useEffect(() => {
    fetchSurveyInstance();
  }, [fetchSurveyInstance]);

  if (!isLoadingSurveyInstance && !surveyInstance) {
    return <NotFoundPage hideHeader />;
  }

  if (surveyInstance?.isCompleted || surveyCompletedSuccessfully) {
    return <SurveyCompletedPage surveyInstance={surveyInstance} hideHeader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmittingForm(true);

      const values = await validateAntForm(form);

      surveyInstance?.surveyInstanceQuestionModels.forEach((surveyInstanceQuestion) => {
        const { id, typeCode } = surveyInstanceQuestion;
        const answer = values.surveyInstanceQuestion[id].toString();

        if (typeCode === SurveyQuestionType.PICTURE) {
          const selectedItem = surveyInstanceQuestion.surveyInstanceQuestionItemModels?.find(
            (surveyInstanceQuestionItem) => surveyInstanceQuestionItem.id.toString() === answer
          );
          selectedItem!.selected = true;
          surveyInstanceQuestion.answer = null;
        } else {
          surveyInstanceQuestion.answer = answer;
        }
      });

      const encodedHash = encodeURIComponent(encodeURIComponent(urlParams.p!));
      await SurveysService.completeSurvey(encodedHash, surveyInstance!);
      message.success(t('Survey completed successfully'));
      setsurveyCompletedSuccessfully(true);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
      setIsSubmittingForm(false);
    }
  };

  const jobCandidateFullName = `${surveyInstance?.jobCandidateModel.firstName || 'John'} ${
    surveyInstance?.jobCandidateModel.lastName || 'Doe'
  }`;
  const isSurveyCompleted = surveyInstance?.isCompleted || false;

  return (
    <div className="container">
      <Card>
        {!isLoadingSurveyInstance ? (
          <>
            <h1>
              {t('Completing survey for jobCandidateFullName', {
                jobCandidateFullName,
                interpolation: { escapeValue: false },
              })}
            </h1>
            <p className="job-offer__description-content">{surveyInstance?.introductionText}</p>

            <Form onSubmit={handleSubmit}>
              {surveyInstance?.surveyInstanceQuestionModels.map((surveyInstanceQuestion, index) => (
                <React.Fragment key={`surveyInstanceQuestion[${surveyInstanceQuestion.id}]`}>
                  <Form.Item
                    label={
                      <label style={{ whiteSpace: 'normal' }}>
                        {' '}
                        {`${index + 1}) ${
                          applyFieldsToQuestions(
                            surveyInstance,
                            surveyInstanceQuestion.title,
                            job
                          ) || '[TITLE]'
                        }`}
                      </label>
                    }
                  >
                    <i>
                      <p>{surveyInstanceQuestion.explanation}</p>
                    </i>
                    {surveyInstanceQuestion.typeCode === SurveyQuestionType.SCALE && (
                      <>
                        {form.getFieldDecorator(
                          `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`,
                          {
                            rules: [{ required: true, message: t('This field is required') }],
                          }
                        )(
                          <Radio.Group buttonStyle="solid" disabled={isSurveyCompleted}>
                            {Array(SCALE_MAX_VALUE)
                              .fill(null)
                              .map((_, index) => (
                                <Radio.Button
                                  key={`radio_button_${index}`}
                                  value={(index + 1).toString()}
                                >
                                  {(index + 1).toString()}
                                </Radio.Button>
                              ))}
                          </Radio.Group>
                        )}
                      </>
                    )}
                    {surveyInstanceQuestion.typeCode === SurveyQuestionType.RATING && (
                      <>
                        {form.getFieldDecorator(
                          `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`,
                          {
                            rules: [{ required: true, message: t('This field is required') }],
                          }
                        )(<Rate allowHalf disabled={isSurveyCompleted} />)}
                        {form.getFieldValue(
                          `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`
                        ) > 0 && (
                          <Tag style={{ marginLeft: '1rem' }}>
                            {' '}
                            {`${form.getFieldValue(
                              `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`
                            )}`}
                            {form.getFieldValue(
                              `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`
                            ) === 1
                              ? ` ${t('Star')}`
                              : ` ${t('Stars')}`}
                          </Tag>
                        )}
                      </>
                    )}
                    {surveyInstanceQuestion.typeCode === SurveyQuestionType.TEXT && (
                      <>
                        {form.getFieldDecorator(
                          `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`,
                          {
                            rules: [{ required: true, message: t('This field is required') }],
                          }
                        )(<TextArea rows={4} disabled={isSurveyCompleted} />)}
                      </>
                    )}
                    {surveyInstanceQuestion.typeCode === SurveyQuestionType.YESNO && (
                      <>
                        {form.getFieldDecorator(
                          `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`,
                          {
                            rules: [{ required: true, message: t('This field is required') }],
                          }
                        )(
                          <Radio.Group disabled={isSurveyCompleted}>
                            <Radio value="YES">{t('Yes')}</Radio>
                            <Radio value="NO">{t('No')}</Radio>
                          </Radio.Group>
                        )}
                      </>
                    )}
                    {surveyInstanceQuestion.typeCode === SurveyQuestionType.PICTURE && (
                      <>
                        {form.getFieldDecorator(
                          `surveyInstanceQuestion[${surveyInstanceQuestion.id}]`,
                          {
                            rules: [{ required: true, message: t('This field is required') }],
                          }
                        )(
                          <Radio.Group disabled={isSurveyCompleted}>
                            {surveyInstanceQuestion.surveyInstanceQuestionItemModels?.map(
                              (surveyInstanceQuestionItem) => (
                                <Radio
                                  value={surveyInstanceQuestionItem.id}
                                  key={surveyInstanceQuestionItem.id}
                                >
                                  <span style={{ marginRight: '1rem' }}>
                                    {surveyInstanceQuestionItem.title}
                                  </span>
                                  <img
                                    src={surveyInstanceQuestionItem.pictureUrl}
                                    height={128}
                                    width={128}
                                    alt={t('Question item image')}
                                  />
                                </Radio>
                              )
                            )}
                          </Radio.Group>
                        )}
                      </>
                    )}
                  </Form.Item>
                  {index < surveyInstance?.surveyInstanceQuestionModels.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                <Button
                  loading={isSubmittingForm}
                  htmlType="submit"
                  size="large"
                  type="primary"
                  data-testid="submit"
                >
                  {t('Send')}
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Card>
    </div>
  );
};

export default Form.create()(CompleteSurvey);
