import React from 'react';
import CompleteSurvey from '../../components/CompleteSurvey/CompleteSurvey';
import Layout from '../../layout/Layout';

const CompleteSurveyPage: React.FC = () => {
  return (
    <Layout>
      <CompleteSurvey />;
    </Layout>
  );
};

export default CompleteSurveyPage;
