import { Button, Card, Checkbox, Form, Input, List, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormComponentProps } from 'antd/lib/form';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Resource, Resources } from '../../api/roles/definition';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import { Role, RoleResource, RoleUpdate } from '../../api/roles/model';
import RolesService from '../../api/roles/RolesService';
import { validateAntForm } from '../../helpers/ant-form';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import BackButton from '../BackButton/BackButton';

interface MatchProps {
  id: string;
}

interface FormValues {
  name: string;
  permissions: any;
}

interface Props extends FormComponentProps<FormValues> {}

const RolesDetail: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<MatchProps>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [role, setRole] = useState<Role>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const role = await RolesService.findOne(match.params.id);
        setRole(role);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [match.params.id]);

  if (isLoading) {
    return (
      <div className="container">
        <Card loading />
      </div>
    );
  }

  if (!role) {
    return <NotFoundPage />;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const values = await validateAntForm(form);

      const resources = Object.keys(values.permissions)
        .map((resourceKey: string) => {
          return {
            resourceCode: resourceKey,
            description: '',
            patents: Object.keys(values.permissions[resourceKey])
              .filter((patentKey) => !!values.permissions[resourceKey][patentKey])
              .map((patent) => ({
                patentCode: patent,
              })),
          } as RoleResource;
        })
        .filter((key) => !!key.patents.length);

      const entity: RoleUpdate = {
        roleId: role.roleId,
        code: role.code,
        name: values.name,
        resources,
        defaultPath: role.defaultPath,
        isActive: role.isActive,
      };

      await RolesService.update(entity);
      history.goBack();
    } catch (error) {
      setIsSubmitting(false);
      message.error(error.message);
    }
  };

  const defaultCheckAll = (resourceCode: ResourcesEnum) => {
    const dbResource = (role ? find(role.resources, { resourceCode }) : undefined) as RoleResource;
    if (!dbResource) {
      return false;
    }

    const baseResource = find(Resources, { code: resourceCode });
    if (!baseResource) {
      return false;
    }

    return dbResource.patents.length === baseResource.patents.length;
  };

  const handleCheckAll = (e: CheckboxChangeEvent, resource: Resource) => {
    resource.patents.forEach((patent) => {
      form.setFieldsValue({
        [`permissions.${resource.code}.${patent}`]: e.target.checked,
      });
    });
  };

  const checkIfHasPatent = (resource: Resource, patent: PatentsEnum) => {
    const rolePermission = (role
      ? find(role.resources, { resourceCode: resource.code })
      : undefined) as RoleResource;
    if (!rolePermission) {
      return false;
    }

    return rolePermission.patents.some((item) => item.patentCode === patent);
  };

  const handleCheck = (resourceCode: ResourcesEnum, patent: PatentsEnum, checked: boolean) => {
    const values = form.getFieldsValue();
    const resource = values.permissions[resourceCode];
    resource[patent] = checked;

    const patentValues = Object.keys(resource).map((key) => resource[key]);

    form.setFieldsValue({
      [`checkAll.${resourceCode}`]: patentValues.every(Boolean),
    });
  };

  return (
    <div className="container">
      <Card title={<BackButton title={role.name} />}>
        <Form onSubmit={handleSubmit}>
          <Form.Item label={t('Code')} {...formItemLayout}>
            <Input disabled value={role.code} />
          </Form.Item>
          <Form.Item label={t('Name')} {...formItemLayout}>
            {form.getFieldDecorator('name', {
              initialValue: role.name,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            })(<Input />)}
          </Form.Item>

          <List
            grid={{ gutter: 16, sm: 4 }}
            dataSource={Resources}
            renderItem={(resource) => (
              <List.Item>
                <Card
                  title={form.getFieldDecorator(`checkAll.${resource.code}`, {
                    initialValue: defaultCheckAll(resource.code),
                    valuePropName: 'checked',
                  })(
                    <Checkbox onChange={(e: CheckboxChangeEvent) => handleCheckAll(e, resource)}>
                      {resource.name}
                    </Checkbox>
                  )}
                  style={{ height: 350, overflowY: 'auto' }}
                >
                  {resource.patents.map((patent) => (
                    <Form.Item key={`${resource.code}.${patent}`} style={{ marginBottom: 0 }}>
                      {form.getFieldDecorator(`permissions.${resource.code}.${patent}`, {
                        valuePropName: 'checked',
                        initialValue: checkIfHasPatent(resource, patent),
                      })(
                        <Checkbox
                          onChange={(e) => handleCheck(resource.code, patent, e.target.checked)}
                        >
                          {PatentsEnum[patent]}
                        </Checkbox>
                      )}
                    </Form.Item>
                  ))}
                </Card>
              </List.Item>
            )}
          />
          <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>
            {t('Save')}
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Form.create()(RolesDetail);
