import {
  Button,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Icon,
  Input,
  Menu,
  message,
  Result,
  Row,
  Tag,
} from 'antd';
import Form, { FormComponentProps, FormItemProps } from 'antd/lib/form';
import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailTemplate } from '../../api/shared/models';
import { useAppContext } from '../../layout/AppContext';
import { find, isArray, reject } from 'lodash';
import { EmailTemplateEnum, Languages, LanguagesEnum } from '../../api/shared/enums';
import ReactQuill, { Quill } from 'react-quill';
import { ReactQuillToolbar } from '../../helpers/react-quill-helpers';
import { SurveysContext } from './SurveysContext';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { validateAntFormFields } from '../../helpers/ant-form';
import SurveysService from '../../api/surveys/SurveysService';
import TextArea from 'antd/lib/input/TextArea';
import { useAllFields } from '../../hooks/custom-fields';
import JobCustomMessageTemplateModal from '../Jobs/JobCustomMessageTemplateModal';
import { IJobCustomMessageTemplate } from '../../api/job-custom-message-templates/models';
import { JobCustomMessageTemplatesService } from '../../api/job-custom-message-templates/JobCustomMessageTemplatesService';
import { JobCustomMessageTemplateTypes } from '../../api/job-custom-message-templates/enums';

interface SurveysCustomizeMessagesProps extends FormComponentProps {}

const formItemLayout: FormItemProps = {
  labelCol: { sm: 4, md: 3 },
  wrapperCol: { sm: 20, md: 21 },
};

const CollapseKeys = {
  SEND_SURVEY_EMAIL: 'SEND_SURVEY_EMAIL',
};

const SurveysCustomizeMessages: React.FC<SurveysCustomizeMessagesProps> = ({ form }) => {
  /**
   * Contexts
   */
  const { isLoadingConfig, config } = useContext(SurveysContext);
  const { user } = useAppContext();
  const { t } = useTranslation();

  /**Loader States */
  const [isSubmittingSendSurveyEmail, setIsSubmittingSendSurveyEmail] = useState<boolean>(false);

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [isVisibleTemplateModal, setIsVisibleTemplateModal] = useState(false);

  /**
   * Editors states
   */
  const [sendSurveyEmailEditor, setSendSurveyEmailEditor] = useState<Quill>();
  const [sendSurveyEmailLang, setSendSurveyEmailLang] = useState(
    (user?.language as LanguagesEnum) || LanguagesEnum.EN
  );
  const surveyEmailEditorRef: RefObject<ReactQuill> = useRef(null);

  /** Others */
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[] | undefined>();
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([CollapseKeys.SEND_SURVEY_EMAIL]);
  const { fields } = useAllFields();

  const [templatesFetched, setTemplatesFetched] = useState<IJobCustomMessageTemplate[]>([]);
  const [selectedCustomMessage, setSelectedCustomMessage] = useState<string>('');

  useEffect(() => {
    if (!isLoadingConfig) {
      if (surveyEmailEditorRef.current) {
        setSendSurveyEmailEditor(surveyEmailEditorRef.current.getEditor());
      }

      if (config?.userEmailTemplates.length) {
        setEmailTemplates(config.userEmailTemplates);
      } else if (config?.defaultEmailTemplates.length) {
        setEmailTemplates(config.defaultEmailTemplates);
      }
    }
  }, [isLoadingConfig, config]);

  if (isLoadingConfig) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return null;
  }

  if (!config) {
    return <Result status="error" title={t('Error fetching the config')} />;
  }

  const getEmailTemplateDefault = (
    code: EmailTemplateEnum,
    lang: LanguagesEnum
  ): EmailTemplate | undefined => {
    const emailDefault = find(config.defaultEmailTemplates, { code, lang });
    return emailDefault;
  };

  const getEmailTemplate = (
    code: EmailTemplateEnum,
    lang: LanguagesEnum
  ): EmailTemplate | undefined => {
    let email: EmailTemplate | undefined;
    email = find(emailTemplates, { code, lang });

    if (!email) {
      email = find(config.userEmailTemplates, { code, lang });
    }

    if (!email) {
      email = find(config.defaultEmailTemplates, { code, lang });
    }

    return email;
  };

  const handleAddField = (field: string, editor: Quill) => {
    const cursorIndex = editor.getSelection(true)?.index;
    if (field === '[survey-link]') {
      editor.insertText(cursorIndex, t('Click here to give a recommendation'), 'link', field);
    } else {
      editor.insertText(cursorIndex, field);
    }
  };

  const createEmailTemplate = (templateCode: string, field: string, lang: LanguagesEnum) => {
    let body: string = form.getFieldValue(`${field}.body`);

    const template: EmailTemplate = {
      subject: form.getFieldValue(`${field}.subject`),
      body,
      code: templateCode,
      lang,
      cleanBody: form.getFieldValue(`${field}.cleanBody`),
      message: '',
    };
    return template;
  };

  const fetchTemplates = async (type: string) => {
    try {
      setIsLoadingTemplates(true);
      const response = await JobCustomMessageTemplatesService.fetch({
        page: 0,
        pageSize: 0,
        sortField: 'createdDate',
        sortOrder: 'descend',
        filters: [{ key: 'type', value: type }],
      });
      setTemplatesFetched(response.list);
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  const setTemplateMessageInField = (templateId: number, selectedCustomMessage: string) => {
    const selectedTemplate = templatesFetched.find((i) => i.id === templateId);
    if (selectedTemplate) {
      switch (selectedCustomMessage) {
        case EmailTemplateEnum.SEND_SURVEY:
          form.setFieldsValue({ 'sendSurveyEmail.subject': selectedTemplate.subject });
          form.setFieldsValue({ 'sendSurveyEmail.body': selectedTemplate.body });
          break;
        case EmailTemplateEnum.SEND_SURVEY_MESSAGE:
          form.setFieldsValue({ 'sendSurveyEmail.cleanBody': selectedTemplate.body });
          break;
      }
      setIsVisibleTemplateModal(false);
      setSelectedCustomMessage('');
    }
  };

  const saveEmail = async (
    templateCode: string,
    field: string,
    isSubmitting: (value: boolean) => void,
    lang: LanguagesEnum
  ) => {
    try {
      await validateAntFormFields(form, [`${field}.subject`]);
      isSubmitting(true);
      const template = createEmailTemplate(templateCode, field, lang);
      await SurveysService.updateEmailTemplates([template]);
      setEmailTemplates([...reject(emailTemplates, { code: templateCode, lang: lang }), template]);
      message.success(t('Email successfully saved'));
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      isSubmitting(false);
    }
  };

  const sendSurveyEmail = getEmailTemplate(EmailTemplateEnum.SEND_SURVEY, sendSurveyEmailLang);

  form.getFieldDecorator('sendSurveyEmail.subject');
  form.getFieldDecorator('sendSurveyEmail.body', { initialValue: sendSurveyEmail?.body });
  form.getFieldDecorator('sendSurveyEmail.cleanBody', { initialValue: sendSurveyEmail?.cleanBody });

  return (
    <>
      <Form hideRequiredMark>
        <Collapse
          activeKey={collapseActiveKeys}
          expandIconPosition="right"
          className="job-email-collapse"
          onChange={(keys) => setCollapseActiveKeys(isArray(keys) ? keys : [keys])}
        >
          <Collapse.Panel
            key={CollapseKeys.SEND_SURVEY_EMAIL}
            className="job-email-collapse__item"
            header={
              <>
                <span className="job-email-collapse__subject">{t('Survey Email')}:</span>
                <span className="job-email-collapse__subject-help">
                  {t('Sent to candidate contacts')}{' '}
                </span>
              </>
            }
            extra={
              <Dropdown
                overlay={
                  <Menu
                    selectedKeys={[sendSurveyEmailLang]}
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      setSendSurveyEmailLang(e.key as LanguagesEnum);
                      const email = getEmailTemplate(
                        EmailTemplateEnum.SEND_SURVEY,
                        e.key as LanguagesEnum
                      );
                      form.setFieldsValue({
                        'sendSurveyEmail.subject': email?.subject,
                        'sendSurveyEmail.body': email?.body || '',
                        'sendSurveyEmail.cleanBody': email?.cleanBody || '',
                      });
                    }}
                  >
                    {Object.keys(LanguagesEnum).map((key) => (
                      <Menu.Item key={key}>{Languages[key as LanguagesEnum]}</Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <span>
                  <Icon type="flag" onClick={(e) => e.stopPropagation()} />{' '}
                  {Languages[sendSurveyEmailLang as LanguagesEnum]}
                </span>
              </Dropdown>
            }
          >
            <Form.Item label={t('Subject')} {...formItemLayout}>
              {form.getFieldDecorator('sendSurveyEmail.subject', {
                initialValue: sendSurveyEmail?.subject,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label={t('Email')} {...formItemLayout}>
              <ReactQuill
                ref={surveyEmailEditorRef}
                className="jobs-new__editor"
                value={form.getFieldValue('sendSurveyEmail.body')}
                onChange={(value) => form.setFieldsValue({ 'sendSurveyEmail.body': value })}
                modules={{
                  toolbar: ReactQuillToolbar,
                }}
              />
            </Form.Item>

            <Form.Item label={t('Fields')} {...formItemLayout}>
              {config.emailFields.map((field) => (
                <Tag
                  onClick={() => handleAddField(`[${field}]`, sendSurveyEmailEditor!)}
                  style={{ cursor: 'pointer' }}
                  key={field}
                >
                  {`[${field}]`}
                </Tag>
              ))}
              {fields.map((field) => (
                <Tag
                  onClick={() => handleAddField(`[${field.name}]`, sendSurveyEmailEditor!)}
                  style={{ cursor: 'pointer' }}
                  key={field.name}
                >
                  {`[${field.name}]`}
                </Tag>
              ))}
            </Form.Item>

            <Row gutter={16} type="flex">
              <Col xs={12} sm={11} md={8}>
                <Button
                  disabled={isSubmittingSendSurveyEmail}
                  className="jobs-new__btn"
                  onClick={() => {
                    const defaultEmail = getEmailTemplateDefault(
                      EmailTemplateEnum.SEND_SURVEY,
                      sendSurveyEmailLang
                    );
                    form.setFieldsValue({
                      'sendSurveyEmail.subject': defaultEmail?.subject,
                      'sendSurveyEmail.body': defaultEmail?.body || '',
                    });
                  }}
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col xs={12} sm={11} md={8}>
                <Button
                  disabled={isSubmittingSendSurveyEmail}
                  className="jobs-new__btn"
                  loading={isLoadingTemplates}
                  onClick={async () => {
                    await fetchTemplates(JobCustomMessageTemplateTypes.EMAIL);
                    setIsVisibleTemplateModal(true);
                    setSelectedCustomMessage(EmailTemplateEnum.SEND_SURVEY);
                  }}
                >
                  {t('Load Template')}
                </Button>
              </Col>
              <Col xs={12} sm={11} md={8}>
                <Button
                  disabled={isSubmittingSendSurveyEmail}
                  className="jobs-new__btn"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    const email = getEmailTemplate(
                      EmailTemplateEnum.SEND_SURVEY,
                      sendSurveyEmailLang
                    );
                    form.setFieldsValue({
                      'sendSurveyEmail.subject': email?.subject,
                      'sendSurveyEmail.body': email?.body || '',
                    });
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Col>
            </Row>

            <Divider />
            <div style={{ marginBottom: '1.5rem' }}>
              <span className="job-email-collapse__subject">
                {t('LinkedIn and Facebook message')}:
              </span>
              <span className="job-email-collapse__subject-help">
                {t('Sent to candidate contacts')}{' '}
              </span>
            </div>
            <Form.Item label={t('Message')} {...formItemLayout}>
              {form.getFieldDecorator('sendSurveyEmail.cleanBody', {
                initialValue: sendSurveyEmail?.cleanBody,
              })(<TextArea rows={6} />)}
            </Form.Item>
            <Form.Item label={t('Fields')} {...formItemLayout}>
              {config.emailFields.map((field) => (
                <Tag
                  onClick={() => {
                    form.setFieldsValue({
                      'sendSurveyEmail.cleanBody': `${form.getFieldValue(
                        'sendSurveyEmail.cleanBody'
                      )} [${field}]`,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                  key={field}
                >
                  {`[${field}]`}
                </Tag>
              ))}
              {fields.map((field) => (
                <Tag
                  onClick={() => {
                    form.setFieldsValue({
                      'sendSurveyEmail.cleanBody': `${form.getFieldValue(
                        'sendSurveyEmail.cleanBody'
                      )} [${field.name}]`,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                  key={field.name}
                >
                  {`[${field.name}]`}
                </Tag>
              ))}
            </Form.Item>
            <Row gutter={16} type="flex">
              <Col xs={10} sm={12} md={6}>
                <Button
                  disabled={isSubmittingSendSurveyEmail}
                  className="jobs-new__btn"
                  onClick={() => {
                    const defaultEmail = getEmailTemplateDefault(
                      EmailTemplateEnum.SEND_SURVEY,
                      sendSurveyEmailLang
                    );
                    form.setFieldsValue({
                      'sendSurveyEmail.cleanBody': defaultEmail?.cleanBody || '',
                    });
                  }}
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col xs={14} sm={12} md={6}>
                <Button
                  disabled={isSubmittingSendSurveyEmail}
                  className="jobs-new__btn"
                  loading={isLoadingTemplates}
                  onClick={async () => {
                    await fetchTemplates(JobCustomMessageTemplateTypes.SOCIAL_NETWORK_MESSAGE);
                    setIsVisibleTemplateModal(true);
                    setSelectedCustomMessage(EmailTemplateEnum.SEND_SURVEY_MESSAGE);
                  }}
                >
                  {t('Load Template')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Button
                  disabled={isSubmittingSendSurveyEmail}
                  className="jobs-new__btn"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    const email = getEmailTemplate(
                      EmailTemplateEnum.SEND_SURVEY,
                      sendSurveyEmailLang
                    );
                    form.setFieldsValue({
                      'sendSurveyEmail.cleanBody': email?.cleanBody || '',
                    });
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Button
                  disabled={isSubmittingSendSurveyEmail || isLoadingConfig}
                  loading={isSubmittingSendSurveyEmail}
                  htmlType="submit"
                  type="primary"
                  className="jobs-new__btn"
                  onClick={() =>
                    saveEmail(
                      EmailTemplateEnum.SEND_SURVEY,
                      'sendSurveyEmail',
                      setIsSubmittingSendSurveyEmail,
                      sendSurveyEmailLang
                    )
                  }
                >
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Form>
      <JobCustomMessageTemplateModal
        templates={templatesFetched}
        visible={isVisibleTemplateModal}
        handleCancel={() => setIsVisibleTemplateModal(!isVisibleTemplateModal)}
        handleSetTemplate={(templateId: number, selectedCustomMessage: string) =>
          setTemplateMessageInField(templateId, selectedCustomMessage)
        }
        selectedCustomMessage={selectedCustomMessage}
      />
    </>
  );
};

export default Form.create<SurveysCustomizeMessagesProps>()(SurveysCustomizeMessages);
