import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './layout/AppWrapper';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import 'react-quill/dist/quill.snow.css';
import { LOCAL_STORAGE_JWT } from './config/general-config';
import AuthService from './api/shared/services/AuthService';

window.addEventListener('storage', (event) => {
  if (event.key === LOCAL_STORAGE_JWT && event.oldValue && !event.newValue) {
    AuthService.removeLocalStorage();
  }
});

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
