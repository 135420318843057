import { Alert, Col, Form, Icon, Input, message, Modal, Row, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  SmtpCustomConfig,
  SmtpCustomConfigTestEmailModel,
} from '../../api/recruiter-configurations/models';
import { RecruiterConfigurationsService } from '../../api/recruiter-configurations/RecruiterConfigurationsService';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';

interface FormValues {
  senders: string[];
  recipient: string;
}

interface RecruiterSmtpCustomConfigTestEmailModalProps extends FormComponentProps<FormValues> {
  smtpCustomConfiguration?: SmtpCustomConfig;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const RecruiterSmtpCustomConfigTestEmailModal: React.FC<RecruiterSmtpCustomConfigTestEmailModalProps> = ({
  smtpCustomConfiguration,
  visible,
  setVisible,
  form,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const history = useHistory()
  const match = useRouteMatch<{ id: string }>();

  const handleSubmit = async () => {
    try {
      let values = await validateAntForm(form);
      setIsSubmitting(true);

      const filteredDomains = smtpCustomConfiguration?.domainNames
        .split(';')
        .filter((domain, index) => values.senders[index]);

      if (filteredDomains?.length) {
        const filteredSenders = values.senders
          .filter(Boolean)
          .map((value, index) => value && `${value}${filteredDomains[index]}`);

        const model: SmtpCustomConfigTestEmailModel = {
          senders: filteredSenders,
          recipient: values.recipient,
        };
        await RecruiterConfigurationsService.sendTestEmails(smtpCustomConfiguration?.id!, model);

        message.success('Success!');
        setVisible(false);
        if(match.path == URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_NEW) {
          history.goBack();
        } else if(match.path == URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_DETAIL) {
          history.push(URLs.RECRUITER_CONFIGURATIONS)
        }
      } else {
        message.error(t('Please provide a minimum of 1 Email to test'));
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      title={t('Send Test Email')}
      onOk={handleSubmit}
      destroyOnClose
      maskClosable={!isSubmitting}
      okButtonProps={{ loading: isSubmitting, disabled: isSubmitting }}
      cancelButtonProps={{ loading: isSubmitting, disabled: isSubmitting }}
    >
      <Form>
        {smtpCustomConfiguration?.domainNames?.split(';')?.map((domain, index) => (
          <React.Fragment key={index}>
            <Row gutter={5} style={{ marginBottom: '1.5rem' }}>
              <Col xs={24} md={24} xl={24}>
                {form.getFieldDecorator(`senders[${index}]`)(
                  <Input
                    placeholder={t('Sender')}
                    onInput={() =>
                      form.setFieldsValue({
                        [`senders[${index}]`]: `${form.getFieldValue(
                          `senders[${index}]`
                        )}${domain}`,
                      })
                    }
                    addonAfter={<span style={{ fontSize: '1.7rem' }}>{domain}</span>}
                  />
                )}
              </Col>
            </Row>
          </React.Fragment>
        ))}
        <Form.Item label={t('Recipient')} {...{ labelCol: { span: 5 }, wrapperCol: { span: 19 } }}>
          {form.getFieldDecorator('recipient', {
            rules: [
              { required: true, message: t('This field is required') },
              { type: 'email', message: t('The entered email is not valid') },
            ],
          })(<Input placeholder={t('Recipient')} type="email" />)}
        </Form.Item>
      </Form>
      <Alert
        message={t('Emails will be sent using the following data')}
        description={
          <>
            <Typography.Text strong>{t('SMTP Server')}</Typography.Text>
            <span style={{ float: 'right' }}>{smtpCustomConfiguration?.smtpServer}</span>
            <br />
            <Typography.Text strong>{t('SMTP Port')}</Typography.Text>
            <span style={{ float: 'right' }}>{smtpCustomConfiguration?.smtpPort}</span>
            <br />
            <Typography.Text strong>{t('SMTP Username')}</Typography.Text>
            <span style={{ float: 'right' }}>{smtpCustomConfiguration?.smtpUsername}</span>
            <br />
            <Typography.Text strong>{t('SMTP Password')}</Typography.Text>
            <span style={{ float: 'right' }}>
              {passwordVisible
                ? smtpCustomConfiguration?.smtpPassword
                : smtpCustomConfiguration?.smtpPassword?.replace(/./g, '*')}
              <Icon
                style={{ paddingLeft: '10px' }}
                type={passwordVisible ? 'eye' : 'eye-invisible'}
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            </span>

            <br />
            <Typography.Text strong>{t('SSL Enabled')}</Typography.Text>
            <span style={{ float: 'right' }}>
              {<Icon type={smtpCustomConfiguration?.sslEnabled ? 'check' : 'close'} />}
            </span>
          </>
        }
        type="info"
        showIcon
      />
    </Modal>
  );
};

export default Form.create<RecruiterSmtpCustomConfigTestEmailModalProps>()(
  RecruiterSmtpCustomConfigTestEmailModal
);
