import { IGlobalState } from '..';
import { User } from '../../api/users/model';

export const getAuthUser = (state: IGlobalState): User | undefined => state.auth.user;

export const getAuthToken = (state: IGlobalState): string | undefined => state.auth.token;

export const getIsLoggedIn = (state: IGlobalState): boolean => !!getAuthToken(state);

export const getSessionExpired = (state: IGlobalState): boolean | undefined =>
  state.auth.sessionExpired;
