import { find } from 'lodash';
import { PatentsEnum, ResourcesEnum } from '../api/roles/enums';
import { RoleResource } from '../api/roles/model';
import AuthService from '../api/shared/services/AuthService';

export const hasPermissions = (resourceCode: ResourcesEnum, patentCode: PatentsEnum): boolean => {
  const user = AuthService.getCurrentUser();
  if (!user || !user.role) {
    return false;
  }

  const resource = find(user.role.resources, { resourceCode }) as RoleResource;
  if (!resource) {
    return false;
  }

  return resource.patents.map((p) => p.patentCode).indexOf(patentCode) > -1;
};
