import { Button, Col, Collapse, Dropdown, Form, Icon, Input, Menu, message, Row, Tag } from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import React, { RefObject, useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Languages, LanguagesEnum } from '../../api/shared/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { ReactQuillToolbar } from '../../helpers/react-quill-helpers';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { CampaignsNewContext } from './CampaignsContext';
import { IJobCustomMessageTemplate } from '../../api/job-custom-message-templates/models';
import { JobCustomMessageTemplatesService } from '../../api/job-custom-message-templates/JobCustomMessageTemplatesService';
import { JobCustomMessageTemplateTypes } from '../../api/job-custom-message-templates/enums';
import JobCustomMessageTemplateModal from '../Jobs/JobCustomMessageTemplateModal';
import CampaignsService from '../../api/campaigns/CampaignsService';
import { useRouteMatch } from 'react-router-dom';
import { CampaignStatusEnum } from '../../api/campaigns/enum';

const formItemLayout: FormItemProps = {
    labelCol: { sm: 4, md: 5 },
    wrapperCol: { sm: 20, md: 19 },
};

interface FormValues {
    message: string;
    subject: string;
}

interface Props extends FormComponentProps<FormValues> {
    onSubmit: () => void;
}

const emailFields = ['contact-first-name', 'contact-last-name', 'contact-company', 'campaign-name']

const CampaignsEditCusomizeMessages: React.FC<Props> = ({ form, onSubmit }) => {
    const { t } = useTranslation();
    const { params } = useRouteMatch<{ id: string }>();
    const { campaign, isLoadingCampaign, fetchCampaign } = useContext(CampaignsNewContext)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const editorRef: RefObject<ReactQuill> = useRef(null);
    const [language, setLanguage] = useState((campaign?.language as LanguagesEnum) || LanguagesEnum.EN);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [isVisibleTemplateModal, setIsVisibleTemplateModal] = useState(false);
    const [templatesFetched, setTemplatesFetched] = useState<IJobCustomMessageTemplate[]>([]);
    const [selectedCustomMessage, setSelectedCustomMessage] = useState<string>('');
    const [sendCampaignEmailEditor, setSendCampaignEmailEditor] = useState<Quill>();

    //   if (isLoadingConfig || isLoadingJob) {
    //     return <LoadingSpinner />;
    //   }

    //   if (!config) {
    //     return <Result status="error" title={t('Error fetching the config')} />;
    //   }

    //   if (!job) {
    //     return <NotFoundPage hideHeader />;
    //   }

    useEffect(() => {
        if (editorRef.current) {
            setSendCampaignEmailEditor(editorRef.current.getEditor());
        }
    }, []);

    const fetchTemplates = async (type: string) => {
        try {
            setIsLoadingTemplates(true);
            const response = await JobCustomMessageTemplatesService.fetch({
                page: 0,
                pageSize: 0,
                sortField: 'createdDate',
                sortOrder: 'descend',
                filters: [{ key: 'type', value: type }],
            });
            setTemplatesFetched(response.list);
        } catch (error) {
            if (error.message) message.error(error.message);
        } finally {
            setIsLoadingTemplates(false);
        }
    };

    const setTemplateMessageInField = (templateId: number, selectedCustomMessage: string) => {
        const selectedTemplate = templatesFetched.find((i) => i.id === templateId);

        form.setFieldsValue({
            message: selectedTemplate?.body
        })
        setIsVisibleTemplateModal(false);
        setSelectedCustomMessage('');
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            const values = await validateAntForm(form);
            const data = {
                id: campaign && campaign?.campaignId || params.id,
                subject: values.subject,
                message: values.message,
                language: language,
            }
            setIsSubmitting(true);
            await CampaignsService.update(data)
            if (fetchCampaign) {
                fetchCampaign();
            }
            onSubmit();
        } catch (error) {
            setIsSubmitting(false);
            if (error.message) {
                message.error(error.message);
            }
        }
    };

    form.getFieldDecorator('message', {
        initialValue: campaign?.message || '',
    });

    const handleAddField = (field: string, editor: Quill) => {
        const cursorIndex = editor.getSelection(true)?.index;
        editor.insertText(cursorIndex, field);
    };

    return (
        <>
            <Form onSubmit={handleSubmit} hideRequiredMark>
                <Collapse defaultActiveKey="1" expandIconPosition="right" className="job-email-collapse">
                    <Collapse.Panel
                        key="1"
                        className="job-email-collapse__item"
                        forceRender
                        extra={
                            <Dropdown
                                overlay={
                                    <Menu
                                        selectedKeys={[language]}
                                        onClick={(e) => {
                                            e.domEvent.stopPropagation();
                                            setLanguage(e.key as LanguagesEnum);
                                            form.setFieldsValue({ language: '' });
                                        }}
                                    >
                                        {Object.keys(LanguagesEnum).map((key) => (
                                            <Menu.Item key={key}>{Languages[key as LanguagesEnum]}</Menu.Item>
                                        ))}
                                    </Menu>
                                }
                            >
                                <span>
                                    <Icon type="flag" onClick={(e) => e.stopPropagation()} />{' '}
                                    {Languages[language as LanguagesEnum]}
                                </span>
                            </Dropdown>
                        }
                        header={
                            <>
                                <span className="job-email-collapse__subject">{t("Campaign message")}</span>
                            </>
                        }
                    >
                        <Form.Item label={t('Subject')} {...formItemLayout}>
                            {form.getFieldDecorator('subject', {
                                initialValue: campaign?.subject,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('This field is required'),
                                    },
                                ],
                            })(<Input readOnly={campaign?.campaignStatus == CampaignStatusEnum.Completed} />)}
                        </Form.Item>
                        <Form.Item label={t("Message")} {...formItemLayout}>
                            <ReactQuill
                                ref={editorRef}
                                className="jobs-new__editor"
                                value={form.getFieldValue('message') || ''}
                                onChange={(value) => form.setFieldsValue({ message: value })}
                                modules={{
                                    toolbar: ReactQuillToolbar,
                                }}
                                readOnly={campaign?.campaignStatus == CampaignStatusEnum.Completed}
                            />
                        </Form.Item>
                        <Form.Item label={t('Fields')} {...formItemLayout}>
                            {emailFields.map((field) => (
                                <Tag
                                    onClick={() => handleAddField(`[${field}]`, sendCampaignEmailEditor!)}
                                    style={{ cursor: 'pointer' }}
                                    key={field}
                                >
                                    {`[${field}]`}
                                </Tag>
                            ))}
                        </Form.Item>
                    </Collapse.Panel>
                </Collapse>

                <Row gutter={16} type="flex">
                    <Col xs={24} sm={18}>
                        <Button
                            // disabled={isSubmittingInviteEmail}
                            className="jobs-new__btn"
                            style={{ display: campaign?.campaignStatus == CampaignStatusEnum.Completed ? 'none' : "" }}

                            loading={isLoadingTemplates}
                            onClick={async () => {
                                await fetchTemplates(JobCustomMessageTemplateTypes.EMAIL);
                                setIsVisibleTemplateModal(true);
                                // setSelectedCustomMessage(EmailTemplateEnum.INVITE_RECRUITER);
                            }}
                        >
                            {t('Load Template')}
                        </Button>
                    </Col>
                    <Col xs={24} sm={6}>
                        <Button
                            className="jobs-new__btn"
                            style={{ display: campaign?.campaignStatus == CampaignStatusEnum.Completed ? 'none' : "" }}
                            htmlType="submit"
                            size="large"
                            type="primary"
                            loading={isSubmitting}
                        >
                            {t('Continue')}
                        </Button>
                    </Col>
                </Row>
            </Form>

            <JobCustomMessageTemplateModal
                templates={templatesFetched}
                visible={isVisibleTemplateModal}
                handleCancel={() => setIsVisibleTemplateModal(!isVisibleTemplateModal)}
                handleSetTemplate={(templateId: number, selectedCustomMessage: string) =>
                    setTemplateMessageInField(templateId, selectedCustomMessage)
                }
                selectedCustomMessage={selectedCustomMessage}
            />
        </>
    );
};

export default Form.create<Props>()(CampaignsEditCusomizeMessages);
