import {
    Button,
    Col,
    Form,
    Input,
    message,
    Row,
    DatePicker,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { useTranslation } from 'react-i18next';
import { CampaignAddType, CampaignUpdateType } from '../../api/campaigns/model';
import CampaignsService from '../../api/campaigns/CampaignsService';
import { CampaignsNewContext, CampaignsContext } from './CampaignsContext';
import moment from 'moment';
import { CampaignStatusEnum } from '../../api/campaigns/enum';

interface Props extends FormComponentProps {
    onSubmit: () => void;
}

interface FormValues {
    name: string;
    smtpConfigurationid: string;
    campaignStartDateTime: string;
}



const CampaignsEditInformation: React.FC<Props> = ({ form, onSubmit }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { fetchCampaigns } = useContext(CampaignsContext);
    const { campaign, fetchCampaign } = useContext(CampaignsNewContext)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDate, setSelectedDate] = useState<any>();

    function handleChange(date: any, dateString: any) {
        setSelectedDate(dateString)
    }

    const prevHr = moment().subtract(1, "hours").hours();
    const disHrs: any | number = [];
    for (let i = 0; i <= prevHr; i++) {
        disHrs.push(i);
    }

    const prevMin = moment().minutes();
    const disMins: any | number = [];
    for (let i = 0; i <= prevMin; i++) {
        disMins.push(i);
    }

    const prevSec = moment().seconds();
    const disSecs: any | number = [];
    for (let i = 0; i <= prevSec; i++) {
        disSecs.push(i);
    }
    const campaignDate = campaign ? moment.utc(campaign?.campaignStartDate) : moment().add(5, 'minutes');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setIsSubmitting(true);
            const {
                campaignStartDateTime,
                name,
            } = await validateAntForm<FormValues>(form);

            if (!campaign) {
                const data: CampaignAddType = {
                    campaignStartDateTime: moment(campaignStartDateTime).format('YYYY/MM/DD hh:mm:ss A'),
                    name,
                    smtpConfigurationid: "0",
                };
                const campaignId = await CampaignsService.add(data);
                setIsSubmitting(false);
                history.push(URLs.CAMPAIGNS_DRAFT.replace(':id', campaignId.toString()));
            } else {
                const {
                    campaignId,
                    campaignName,
                } = campaign;

                const data: CampaignUpdateType = {
                    id: campaignId,
                    name: name || campaignName,
                    campaignStartDateTime: moment(campaignStartDateTime).format('YYYY/MM/DD hh:mm:ss A'),
                    smtpConfigurationid: "0",
                };
                if (campaign?.campaignStatus != CampaignStatusEnum.Paused) {
                    await CampaignsService.update(data);
                }
                if (fetchCampaign) {
                    fetchCampaign();
                }
            }

            onSubmit();
            if (fetchCampaigns) {
                fetchCampaigns();
            }
        } catch (error) {
            setIsSubmitting(false);
            if (error.message) {
                message.error(error.message);
            }
        }
    };


    return (
        <div style={{ padding: '4rem 2rem' }}>
            <Form onSubmit={handleSubmit} hideRequiredMark>
                <Form.Item>
                    {form.getFieldDecorator('name' || 'campaignName', {
                        initialValue: campaign?.campaignName,
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: t('This field is required'),
                            },
                        ],
                    })(<Input size="large" placeholder={t('Campaign Name')} readOnly={campaign?.campaignStatus == CampaignStatusEnum.Paused} />)}
                </Form.Item>

                <Row gutter={16}>
                    <Col>
                        <Form.Item>
                            {
                                campaign?.campaignStatus == CampaignStatusEnum.Paused ? (
                                    <Input size='large' value={moment(campaignDate).format('YYYY/MM/DD hh:mm:ss A')} readOnly={campaign?.campaignStatus == CampaignStatusEnum.Paused} />
                                ) : (
                                    <>
                                        {form.getFieldDecorator('campaignStartDateTime', {
                                            initialValue: moment(campaignDate),
                                            rules: [
                                                {
                                                    required: true,
                                                    message:t('This field is required'),     
                                                }
                                            ]
                                        })(
                                            <DatePicker
                                                size='large'
                                                style={{ width: '100%' }}
                                                placeholder={t('Start campaign date')}
                                                disabledDate={(current: any) => {
                                                    // return current && current <= moment().subtract(1, "days");
                                                    return current && moment().subtract(5, 'minutes') >= current
                                                }}
                                                format='YYYY/MM/DD hh:mm:ss A'
                                                showTime={{
                                                    hideDisabledOptions: true,
                                                    disabledHours: () => {
                                                        if (moment().date() == moment(selectedDate)?.date()) return disHrs;
                                                        else return [];
                                                    },
                                                    disabledMinutes: () => {
                                                        if (
                                                            moment().hour() == moment(selectedDate)?.hour() &&
                                                            moment().date() == moment(selectedDate)?.date()
                                                        )
                                                            return disMins;
                                                        else return [];
                                                    },
                                                    disabledSeconds: () => {
                                                        if (
                                                            moment().hour() == moment(selectedDate)?.hour() &&
                                                            moment().minute() == moment(selectedDate)?.minute() &&
                                                            moment().date() == moment(selectedDate)?.date()
                                                        )
                                                            return disMins;
                                                        else return [];
                                                    }
                                                }}
                                                onChange={handleChange}
                                            />
                                        )}</>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        htmlType="submit"
                        size="large"
                        type="primary"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        className="jobs-new__btn"
                    >
                        {t('Continue')}
                    </Button>
                </div>
            </Form>

        </div>
    );
};

export default Form.create<Props>()(CampaignsEditInformation);
