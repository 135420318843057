import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import { URLs } from '../../config/enums';
import { hasPermissions } from '../../helpers/permissions-helpers';
import Layout from '../../layout/Layout';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import { useAppContext } from '../../layout/AppContext';

interface Props {
  path: string;
  exact?: boolean;
  patent: PatentsEnum;
  resource: ResourcesEnum;
}

const AuthenticatedRoute: React.FC<Props> = ({
  path,
  exact = false,
  patent,
  resource,
  children,
}) => {
  const { user } = useAppContext();
  if (!user) {
    return <Redirect to={URLs.LOGOUT} />;
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        if (hasPermissions(resource, patent)) {
          return <Layout>{children}</Layout>;
        }
        return <NotFoundPage />;
      }}
    />
  );
};

export default AuthenticatedRoute;
