import {
  Button,
  Form,
  Input,
  Modal,
  Tag,
  Tooltip,
  Table,
  Popconfirm,
  message,
  Row,
  Col,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import CustomFieldsService from '../../api/custom-fields/CustomFieldsService';
import { Contact } from '../../api/contacts/model';
import { useTranslation } from 'react-i18next';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';
import { CustomField } from '../../api/custom-fields/model';
import { useFields } from '../../hooks/custom-fields';

interface Props extends FormComponentProps {
  isVisible: boolean;
  onOk: (contact: Contact) => void;
  onCancel: () => void;
  onDelete?: () => void;
  contactId?: number;
}

interface EditingIndex {
  index: number;
  isEditing: boolean;
}

const CustomFieldsModal: React.FC<Props> = ({ isVisible, onCancel, form }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [editing, setIsEditing] = useState<EditingIndex>({ index: 0, isEditing: false });
  const [searchInput, setSearchInput] = useState('');

  const [gridState, setGridState] = useState<GridParams>(createBaseGridParams({}));

  const { isLoadingFields, fields, fetchFields } = useFields(gridState);

  const handleOnSearch = () => {
    setGridState({ ...gridState, filters: [{ key: 'name', value: searchInput }] });
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await CustomFieldsService.add({ name: newFieldName, id: 0 });
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      fetchFields();
      setIsSubmitting(false);
    }
  };

  const handleIsEditing = (field: CustomField, index: number) => {
    if (editing.isEditing) {
      setIsEditing({ index: 0, isEditing: false });
    } else {
      setIsEditing({ index: index, isEditing: true });
    }
  };

  const handleEdit = async (field: CustomField, index: number) => {
    try {
      setIsSubmitting(true);

      field.name = newFieldName;

      await CustomFieldsService.edit(field);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      fetchFields();

      setIsEditing({ index: 0, isEditing: false });

      setIsSubmitting(false);
    }
  };

  const handleDelete = async (field: CustomField, index: number) => {
    try {
      setIsDeleting(true);
      await CustomFieldsService.delete(field);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsEditing({ index: 0, isEditing: false });
      fetchFields();

      setIsDeleting(false);
    }
  };

  return (
    <Modal
      visible={isVisible}
      title={t('Add custom field')}
      onCancel={onCancel}
      footer={null}
      confirmLoading={isSubmitting}
    >
      {
        <>
          <Table
            rowKey="id"
            bordered
            showHeader
            loading={isLoadingFields || isDeleting}
            style={{ marginBottom: '1rem' }}
            title={() => (
              <Input.Search
                disabled={isLoadingFields || editing.isEditing || isDeleting}
                placeholder={`${t('Search')}...`}
                enterButton
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                value={searchInput}
                onSearch={handleOnSearch}
              />
            )}
            pagination={{
              showTotal: (totalRecords, rangeRecords) =>
                `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            }}
            columns={[
              {
                title: t('Name'),
                dataIndex: 'name',
                align: 'center',
                sorter: (a, b) => a.name.localeCompare(b.name),
                render(name, customField, index) {
                  if (editing.isEditing && editing.index === index) {
                    return (
                      <Input
                        defaultValue={customField.name}
                        onChange={(e) => setNewFieldName(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.charCode === 13) handleEdit(customField, index);
                        }}
                        size="default"
                        style={{ textAlign: 'center' }}
                      ></Input>
                    );
                  } else {
                    return <Tag>[{name}]</Tag>;
                  }
                },
              },
              {
                title: t('Actions'),
                align: 'center',
                render(name, customField, index) {
                  if (editing.isEditing && editing.index === index) {
                    return (
                      <>
                        <Row>
                          <Col span={6} offset={4}>
                            <Tooltip title={t('Ok')}>
                              <Button
                                type="primary"
                                size="default"
                                onClick={() => handleEdit(customField, index)}
                                disabled={isSubmitting}
                                icon="plus"
                              />
                            </Tooltip>
                          </Col>
                          <Col span={6} offset={4}>
                            <Tooltip title={t('Cancel')}>
                              <Button
                                type="danger"
                                size="default"
                                onClick={() => setIsEditing({ index: 0, isEditing: false })}
                                disabled={isSubmitting}
                                icon="cross"
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Row>
                          <Col span={6} offset={6}>
                            <Tooltip title={t('Edit')}>
                              <Button
                                type="primary"
                                size="default"
                                disabled={editing.isEditing || isSubmitting}
                                onClick={() => handleIsEditing(customField, index)}
                                icon="edit"
                              />
                            </Tooltip>
                          </Col>
                          <Col span={6}>
                            <Popconfirm
                              title={t('Delete this field?')}
                              onConfirm={() => handleDelete(customField, index)}
                            >
                              <Tooltip title={t('Delete')}>
                                <Button
                                  size="default"
                                  type="danger"
                                  disabled={editing.isEditing || isSubmitting}
                                  icon="delete"
                                />
                              </Tooltip>
                            </Popconfirm>
                          </Col>
                        </Row>
                      </>
                    );
                  }
                },
              },
            ]}
            dataSource={fields}
          />
          <div style={{ display: 'flex' }}>
            <Input
              disabled={isLoadingFields || isDeleting || editing.isEditing}
              placeholder={t('Add custom field')}
              style={{ marginRight: '3px' }}
              autoFocus
              onChange={(e) => setNewFieldName(e.target.value)}
              onKeyPress={(e) => {
                if (e.charCode === 13) handleSubmit();
              }}
            />
            <Tooltip title={t('Add')}>
              <Button
                size="default"
                type="primary"
                disabled={editing.isEditing || isSubmitting || isLoadingFields || isDeleting}
                icon="plus"
                onClick={handleSubmit}
              />
            </Tooltip>
          </div>
        </>
      }
    </Modal>
  );
};

export default Form.create<Props>()(CustomFieldsModal);
