import React from 'react';
import './Loading.scss';
import { Layout, Spin } from 'antd';

const Loading: React.FC = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <div className="Loading">
      <Spin size="large" />
    </div>
  </Layout>
);

export default Loading;
