import { Button, Collapse, Divider, Dropdown, Form, Icon, Menu, message, Result } from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import React, { RefObject, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import JobsService from '../../api/jobs/JobsService';
import { Languages, LanguagesEnum } from '../../api/shared/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { ReactQuillToolbar } from '../../helpers/react-quill-helpers';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { JobsNewContext } from './JobsContext';

const formItemLayout: FormItemProps = {
  labelCol: { sm: 4, md: 5 },
  wrapperCol: { sm: 20, md: 19 },
};

interface FormValues {
  clientPage: string;
}

interface Props extends FormComponentProps<FormValues> {
  onSubmit: () => void;
}

const JobsEditClientPage: React.FC<Props> = ({ form, onSubmit }) => {
  const { t } = useTranslation();
  const { config, isLoadingConfig, isLoadingJob, job, fetchJob } = useContext(JobsNewContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const editorRef: RefObject<ReactQuill> = useRef(null);
  const [language, setLanguage] = useState(LanguagesEnum.EN);

  if (isLoadingConfig || isLoadingJob) {
    return <LoadingSpinner />;
  }

  if (!config) {
    return <Result status="error" title={t('Error fetching the config')} />;
  }

  if (!job) {
    return <NotFoundPage hideHeader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const values = await validateAntForm(form);
      setIsSubmitting(true);
      await JobsService.updateClientPage(job.id, values.clientPage);
      if (fetchJob) {
        fetchJob();
      }
      onSubmit();
    } catch (error) {
      setIsSubmitting(false);
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  form.getFieldDecorator('clientPage', {
    initialValue: job.clientPage || '',
  });

  return (
    <Form onSubmit={handleSubmit} hideRequiredMark>
      <Collapse defaultActiveKey="1" expandIconPosition="right" className="job-email-collapse">
        <Collapse.Panel
          key="1"
          className="job-email-collapse__item"
          forceRender
          extra={
            <Dropdown
              overlay={
                <Menu
                  selectedKeys={[language]}
                  onClick={(e) => {
                    e.domEvent.stopPropagation();
                    setLanguage(e.key as LanguagesEnum);
                    form.setFieldsValue({ clientPage: '' });
                  }}
                >
                  {Object.keys(LanguagesEnum).map((key) => (
                    <Menu.Item key={key}>{Languages[key as LanguagesEnum]}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Icon type="flag" onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          }
          header={
            <>
              <span className="job-email-collapse__subject">{t("Client's page message")}</span>
              <span className="job-email-collapse__subject-help">
                {t('When the client enters to see the candidates')}
              </span>
            </>
          }
        >
          <Form.Item label={t("Page's message")} {...formItemLayout}>
            <ReactQuill
              ref={editorRef}
              className="jobs-new__editor"
              value={form.getFieldValue('clientPage')}
              onChange={(value) => form.setFieldsValue({ clientPage: value })}
              modules={{
                toolbar: ReactQuillToolbar,
              }}
            />
          </Form.Item>

          <div>
            <Button
              disabled={isSubmitting}
              className="jobs-new__btn"
              style={{ marginRight: '1rem' }}
              onClick={() => form.setFieldsValue({ clientPage: job.clientPage })}
            >
              Cancel
            </Button>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Divider />

      <div style={{ display: 'flex' }}>
        <Button
          className="jobs-new__btn"
          style={{ marginLeft: 'auto' }}
          htmlType="submit"
          size="large"
          type="primary"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t('Continue')}
        </Button>
      </div>
    </Form>
  );
};

export default Form.create<Props>()(JobsEditClientPage);
