import { message } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { CustomField } from '../api/custom-fields/model';
import CustomFieldsService from '../api/custom-fields/CustomFieldsService';
import { GridParams } from '../helpers/grid-helpers';

export const useAllFields = () => {
  const [isLoadingFields, setIsLoadingFields] = useState(true);
  const [fields, setFields] = useState<CustomField[]>([]);

  const fetchFields = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingFields(true);
        const response = await CustomFieldsService.findAll();
        setFields(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingFields(false);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  return {
    isLoadingFields,
    fields,
    fetchFields,
  };
};

export const useFields = (gridRequestParams: GridParams) => {
  
  const [isLoadingFields, setIsLoadingFields] = useState(true);
  const [fields, setFields] = useState<CustomField[]>([]);

  const fetchFields = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingFields(true);
        const response = await CustomFieldsService.find(gridRequestParams);
        setFields(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingFields(false);
      }
    };
    fetch();
  }, [gridRequestParams]);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  return {
    isLoadingFields,
    fields,
    fetchFields,
  };
};

