import React from 'react';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import { URLs } from '../../config/enums';
import { Switch } from 'react-router-dom';
import ConfigurationsTabs from '../../components/Configurations/ConfigurationsTabs';

const ConfigurationsPage: React.FC = () => (
  <Switch>
    <AuthenticatedRoute
      path={URLs.CONFIGURATIONS}
      resource={ResourcesEnum.USERS}
      patent={PatentsEnum.VIEW}
    >
      <ConfigurationsTabs />
    </AuthenticatedRoute>
  </Switch>
);

export default ConfigurationsPage;
