import { Spin } from 'antd';
import React from 'react';
import { SpinSize } from 'antd/lib/spin';

interface Props {
  size?: SpinSize;
  align?:
    | 'left'
    | 'center'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'end'
    | 'justify'
    | 'match-parent'
    | 'right'
    | 'start'
    | undefined;
}

const LoadingSpinner: React.FC<Props> = ({ size, align = 'center' }) => {
  return (
    <div style={{ textAlign: align }}>
      <Spin spinning size={size} />
    </div>
  );
};

export default LoadingSpinner;
