import React from 'react';
import { Button, Divider, Modal, Radio, Rate } from 'antd';
import { useTranslation } from 'react-i18next';
import { SCALE_MAX_VALUE, SurveyQuestionType } from '../../api/surveys/enums';
import { SurveyInstance } from '../../api/surveys/models';
import { applyFieldsToQuestions } from '../../helpers/string-helpers';

interface Props {
  isVisible: boolean;
  surveyInstance?: SurveyInstance;
  onCancel: () => void;
}
const SurveyInstanceDetailModal: React.FC<Props> = ({ isVisible, surveyInstance, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={isVisible}
      footer={[
        <Button key="close" onClick={onCancel}>
          {t('Cancel')}
        </Button>,
      ]}
      onCancel={onCancel}
    >
      {surveyInstance?.surveyInstanceQuestionModels.map((surveyInstanceQuestion, index) => (
        <React.Fragment key={surveyInstanceQuestion.id}>
          <p style={{ fontWeight: 'bold' }}>{`${index + 1}) ${
            applyFieldsToQuestions(surveyInstance, surveyInstanceQuestion.title) || '[TITLE]'
          }`}</p>
          {surveyInstanceQuestion.typeCode === SurveyQuestionType.SCALE && (
            <em>{`${parseInt(surveyInstanceQuestion.answer!)}/${SCALE_MAX_VALUE}`}</em>
          )}
          {surveyInstanceQuestion.typeCode === SurveyQuestionType.RATING && (
            <Rate disabled value={parseInt(surveyInstanceQuestion.answer!)} />
          )}
          {surveyInstanceQuestion.typeCode === SurveyQuestionType.TEXT && (
            <em>{surveyInstanceQuestion.answer!}</em>
          )}
          {surveyInstanceQuestion.typeCode === SurveyQuestionType.YESNO && (
            <em>{surveyInstanceQuestion.answer!}</em>
          )}
          {surveyInstanceQuestion.typeCode === SurveyQuestionType.PICTURE && (
            <Radio.Group
              disabled
              value={
                surveyInstanceQuestion.surveyInstanceQuestionItemModels?.find(
                  (item) => item.selected
                )?.id
              }
            >
              {surveyInstanceQuestion.surveyInstanceQuestionItemModels?.map(
                (surveyInstanceQuestionItem) => (
                  <Radio value={surveyInstanceQuestionItem.id} key={surveyInstanceQuestionItem.id}>
                    <span style={{ marginRight: '1rem' }}>{surveyInstanceQuestionItem.title}</span>
                    <img
                      src={surveyInstanceQuestionItem.pictureUrl}
                      height={128}
                      width={128}
                      alt={t('Question item image')}
                    />
                  </Radio>
                )
              )}
            </Radio.Group>
          )}
          {index < surveyInstance?.surveyInstanceQuestionModels.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Modal>
  );
};

export default SurveyInstanceDetailModal;
