import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LinkedInIcon } from '../../assets/icon-linkedin.svg';
import { URLs } from '../../config/enums';
import { LINKEDIN_CLIENT_ID, LINKEDIN_STATE_CODE } from '../../config/general-config';
import { getUrlWithQueryParams } from '../../helpers/url-helpers';

interface Props {
  isLoggingApp: boolean;
}

const LoginWithLinkedInButton: React.FC<Props> = ({ isLoggingApp }) => {
  const { t } = useTranslation();

  const handleLogin = async () => {
    const url = getUrlWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
      response_type: 'code',
      client_id: LINKEDIN_CLIENT_ID,
      redirect_uri: `${window.location.origin}${URLs.LOGIN_LINKEDIN}`,
      scope: 'r_liteprofile r_emailaddress',
      state: LINKEDIN_STATE_CODE,
    });
    window.location.href = url;
  };

  return (
    <Button
      onClick={handleLogin}
      disabled={isLoggingApp}
      block
      size="large"
      style={{ marginBottom: '1.5rem' }}
    >
      <LinkedInIcon
        style={{
          height: '2rem',
          position: 'absolute',
          left: '2rem',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
      {t('Enter with LinkedIn')}
    </Button>
  );
};

export default LoginWithLinkedInButton;
