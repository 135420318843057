import { Card, PageHeader, Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResourcesEnum } from '../../api/roles/enums';
import { RecruiterSmtpCustomConfigsGrid } from './RecruiterSmtpCustomConfigsGrid';

export const RecruiterConfigurationsTabs: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Card title={<PageHeader title={t('Configurations')} />}>
        <Tabs defaultActiveKey={ResourcesEnum.RECRUITER_CONFIGURATIONS}>
          <Tabs.TabPane
            tab={t('SMTP Custom Configuration')}
            key={ResourcesEnum.RECRUITER_CONFIGURATIONS}
          >
            <RecruiterSmtpCustomConfigsGrid />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
};
