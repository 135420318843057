import { SortOrder } from 'antd/lib/table';
import { History } from 'history';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { FetchGridParams } from '../api/shared/services/FetchService';
import { ROWS_PER_PAGES } from '../config/general-config';

export interface GridFilter {
  key: string;
  value: any;
}

export interface GridParams {
  sortField?: string;
  sortOrder?: SortOrder;
  page?: number;
  pageSize?: number;
  search?: string;
  total?: number;
  filters?: GridFilter[];
  JobId?: number;
  CampaignId?: number
}

export const getUrlQueryString = (defaults: GridParams = {}) => {
  let params = (queryString.parse(window.location.search) as unknown) as GridParams;

  if (isEmpty(params)) {
    return defaults;
  }

  if (params.page) {
    params.page = +params.page;
  }
  if (params.pageSize) {
    params.pageSize = +params.pageSize;
  }
  if (params.filters) {
    params.filters = JSON.parse(params.filters.toString());
  }
  if(params.JobId) {
    params.JobId = +params.JobId
  }
  if(params.CampaignId) {
    params.CampaignId = +params.CampaignId
  }

  return params;
};

export const createBaseGridParams = ({
  sortField = '',
  sortOrder = 'ascend',
  search = '',
  page = 0,
  pageSize = ROWS_PER_PAGES,
  total = 0,
  filters = [],
  JobId = 0,
  CampaignId = 0,
}: GridParams): GridParams => {
  return {
    sortField,
    sortOrder,
    search,
    page,
    pageSize,
    total,
    filters,
    JobId,
    CampaignId,
  };
};

export const updateGridUrl = (history: History, url: string, gridState: GridParams) => {
  const objToStringify = {
    search: gridState.search,
    sortField: gridState.sortField,
    sortDirection: gridState.sortOrder,
    pageSize: gridState.pageSize,
    pageIndex: gridState.page,
  };

  const location = queryString.stringify(objToStringify);

  history.push(`${url}?${location}`);
};

export const transformGridParams = (params: GridParams): FetchGridParams => ({
  sortDirection: params.sortOrder!,
  sortField: params.sortField!,
  pageSize: params.pageSize!,
  pageIndex: params.page!,
  query: params.search,
  filters: params
    .filters!.map((item) =>
      item.value instanceof Array
        ? item.value.map((i) => `&${item.key}=${i}`).join('')
        : `&${item.key}=${item.value}`
    )
    .join(''),
    JobId: params.JobId,
    CampaignId: params.CampaignId,
});
