import {
  Button,
  Card,
  Col,
  DatePicker,
  message,
  Progress,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactImportsService from '../../api/contacts/contact-imports/ContactImportsService';
import {
  ContactImportStatusColorLabel,
  ContactImportStatusEnum,
  ContactImportStatusEnumLabel,
} from '../../api/contacts/contact-imports/enums';
import { ContactImport } from '../../api/contacts/model';
import { FetchedList } from '../../api/shared/models';
import { API } from '../../config/api';
import { API_URL, ROWS_PER_PAGES } from '../../config/general-config';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';
import BackButton from '../BackButton/BackButton';
import ContactImportLogsModal from './ContactImportLogsModal';

interface ContactImportsGridProps {}

const { RangePicker } = DatePicker;

const gridParams = createBaseGridParams({
  sortOrder: 'descend',
  sortField: 'file.createdDate',
  pageSize: ROWS_PER_PAGES,
});

const ContactImportsGrid: React.FC<ContactImportsGridProps> = () => {
  const { t } = useTranslation();
  const [contactImportsFetched, setContactImportsFetched] = useState<FetchedList<ContactImport>>({
    count: 0,
    list: [],
  });

  const [isLoadingContactImports, setIsLoadingContactImports] = useState(false);
  const [isVisibleContactImportLogsModal, setIsVisibleContactImportLogsModal] = useState(false);
  const [selectedContactImportId, setSelectedContactImportId] = useState<number>(0);
  const [selectedContactImportFileName, setSelectedContactImportFileName] = useState('');

  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    filters: [
      {
        key: 'importDateFrom',
        value: moment().subtract(1, 'week').format('MM/DD/YYYY'),
      },
      {
        key: 'importDateTo',
        value: moment().format('MM/DD/YYYY'),
      },
    ],
  });
  useEffect(() => {
    const fetchContactImports = async () => {
      setIsLoadingContactImports(true);
      try {
        const response = await ContactImportsService.fetchContactImports(gridState);
        setContactImportsFetched(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingContactImports(false);
      }
    };

    fetchContactImports();
  }, [gridState]);

  const handleFilterChange = (key: string, value: any) => {
    let newFilters;
    if (!value) {
      newFilters = [...gridState.filters!.filter((i) => i.key !== key)];
    } else {
      newFilters = [...gridState.filters!.filter((i) => i.key !== key), { key, value }];
    }
    setGridState({ ...gridState, filters: [...newFilters] });
  };

  const handleDateFilterChange = (dateStrings: [string, string]) => {
    const sinceFilter = { key: 'importDateFrom', value: dateStrings[0] };
    const untilFilter = { key: 'importDateTo', value: dateStrings[1] };
    const newFilters = [
      ...gridState.filters!.filter((i) => i.key !== 'importDateFrom' && i.key !== 'importDateTo'),
      sinceFilter,
      untilFilter,
    ];

    setGridState({ ...gridState, filters: [...newFilters] });
  };

  const renderFilters = () => {
    return (
      <>
        <Row gutter={50}>
          <Col span={6}>
            <RangePicker
              style={{ width: '100%' }}
              format="MM/DD/YYYY"
              allowClear={false}
              onChange={(date: any, dateStrings: [string, string]) =>
                handleDateFilterChange(dateStrings)
              }
              defaultValue={[
                moment(
                  gridState.filters![
                    gridState.filters!.findIndex((i) => i.key === 'importDateFrom')
                  ].value,
                  'MM/DD/YYYY'
                ) || moment(),
                moment(
                  gridState.filters![gridState.filters!.findIndex((i) => i.key === 'importDateTo')]
                    .value,
                  'MM/DD/YYYY'
                ) || moment(),
              ]}
            />
          </Col>
          <Col span={7}>
            <Select
              optionFilterProp="children"
              style={{ width: '75%' }}
              placeholder={t('Select status')}
              allowClear
              onChange={(value: any) => handleFilterChange('status', value)}
            >
              {Object.keys(ContactImportStatusEnum).map((status, index) => (
                <Select.Option key={index.toString()} value={status}>
                  {t(ContactImportStatusEnumLabel[status as ContactImportStatusEnum])}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: t('File'),
      dataIndex: 'fileName',
      width: 120,
      render: (cell: any) => cell || '-',
    },
    {
      title: t('Import date'),
      dataIndex: 'importDate',
      width: 100,
      render: (cell: any) => (cell ? moment(cell).format('MM/DD/YYYY HH:mm:ss') : '-'),
    },
    {
      title: t('List name'),
      dataIndex: 'listName',
      width: 120,
      align: 'center' as 'center',
      render: (cell: any) => cell || '-',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      width: 150,
      align: 'center' as 'center',
      render: (cell: any, row: ContactImport) => (
        <>
          <Tooltip title={row.errorLog}>
            <Tag color={ContactImportStatusColorLabel[cell as ContactImportStatusEnum]}>
              {t(ContactImportStatusEnumLabel[cell as ContactImportStatusEnum])}
            </Tag>
          </Tooltip>
          {row.status === ContactImportStatusEnum.IN_PROGRESS && (
            <p style={{ marginTop: '20px' }}>
              <Tooltip title={`${row.handledRows} ${t('of')} ${row.rowCount} ${t('Processed')}`}>
                <Progress
                  status="active"
                  strokeWidth={10}
                  strokeColor={ContactImportStatusColorLabel[cell as ContactImportStatusEnum]}
                  percent={row.importProgressPercentage}
                />
              </Tooltip>
            </p>
          )}
        </>
      ),
    },
    {
      title: t('Actions'),
      align: 'center' as 'center',
      width: 140,
      render: (cell: any, row: ContactImport) => (
        <>
          <Tooltip
            title={
              row.status === ContactImportStatusEnum.IN_PROGRESS
                ? t('Log will be available after export is finished')
                : t('Log detail')
            }
          >
            <Button
              icon="file-text"
              onClick={() => {
                setSelectedContactImportId(row.id);
                setSelectedContactImportFileName(row.fileName);
                setIsVisibleContactImportLogsModal(true);
              }}
              disabled={row.status === ContactImportStatusEnum.IN_PROGRESS}
            />
          </Tooltip>
          <Tooltip title={t('Download file')}>
            <Button
              style={{ marginLeft: '1rem' }}
              icon="download"
              disabled={!row.fileKey}
              target="_blank"
              href={`${API_URL}${API.FILES}/${row.fileKey}`}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div className="container">
      <Card
        title={
          <>
            <BackButton />
            <span>{t('Contact Imports')}</span>
          </>
        }
      >
        {renderFilters()}
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={contactImportsFetched.list}
          style={{ marginTop: '2rem' }}
          loading={isLoadingContactImports}
          pagination={{
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]} - ${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            total: contactImportsFetched.count,
            pageSize: gridState.pageSize || ROWS_PER_PAGES,
            onChange: (page, pageSize) => setGridState({ ...gridState, page: page - 1 }),
          }}
        />
      </Card>
      <ContactImportLogsModal
        selectedContactImportId={selectedContactImportId}
        selectedContactImportFileName={selectedContactImportFileName}
        visible={isVisibleContactImportLogsModal}
        onCancel={() => {
          setIsVisibleContactImportLogsModal(false);
          setSelectedContactImportId(0);
          setSelectedContactImportFileName('');
        }}
      />
    </div>
  );
};

export default ContactImportsGrid;
