import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

const SurveysSelectOne: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Empty
      description={t('Select a survey to view')}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 13.5rem)',
      }}
    />
  );
};

export default SurveysSelectOne;
