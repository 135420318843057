import { Alert, Divider, message, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next/';
import { ConfigurationsService } from '../../api/configurations/ConfigurationsService';
import { MessageLimitConfigModel } from '../../api/emails/model';
import {
  SmtpConfigurationLimitType,
  SmtpConfigurationLimitTypeLabel,
} from '../../api/recruiter-configurations/enums';
import { SurveySendRoute } from '../../api/surveys/enums';
import { ReactComponent as IconInfo } from '../../assets/icon-info.svg';
import { useAppContext } from '../../layout/AppContext';
import LoadingSpinner from '../Loading/LoadingSpinner';

const SocialNetworkLimitAlert: React.FC = () => {
  const [messageLimit, setMessageLimit] = useState<MessageLimitConfigModel>({
    lkdNewAccountLimit: '',
    lkdPremiumAccountLimit: '',
    lkdWarmedUpAccountLimit: '',
    lkdSentMessages: 0,
    fbSentMessages: 0,
  });

  const { t } = useTranslation();

  const { user } = useAppContext();

  const [isLoadingMessageLimit, setIsLoadingMessageLimit] = useState(false);

  useEffect(() => {
    const fetchMessageLimit = async () => {
      try {
        setIsLoadingMessageLimit(true);
        const response = await ConfigurationsService.getMessageLimitConfig();
        setMessageLimit(response);
      } catch (error) {
        if (error.message) {
          message.error(message.error);
        }
      } finally {
        setIsLoadingMessageLimit(false);
      }
    };
    fetchMessageLimit();
  }, []);

  const buildLimitText = (socialNetwork: string) => {
    const limitTypeToCheck =
      socialNetwork === SurveySendRoute.LINKEDIN
        ? user?.linkedinMessageLimitType
        : user?.facebookMessageLimitType;

    const socialNetworkName = socialNetwork === SurveySendRoute.LINKEDIN ? 'LinkedIn' : 'Facebook';
    if (!limitTypeToCheck)
      return `${t('You have not set a limit for messages', {
        socialNetwork: socialNetworkName,
      })}`;

    const limitType =
      socialNetwork === SurveySendRoute.LINKEDIN
        ? user?.linkedinMessageLimitType
        : user?.facebookMessageLimitType;
    const limitAmount =
      socialNetwork === SurveySendRoute.LINKEDIN
        ? user?.linkedinMessageLimitAmount
        : user?.facebookMessageLimitAmount;

    return `${t('Your limit is messages', {
      limitAmount,
      limitType: (t(
        SmtpConfigurationLimitTypeLabel[limitType as SmtpConfigurationLimitType]
      ) as string).toLowerCase(),
    })}`.replace('messages', limitAmount !== 1 ? 'messages' : 'message');
  };

  return (
    <>
      {isLoadingMessageLimit ? (
        <LoadingSpinner />
      ) : (
        <Alert
          message={t('LinkedIn and Facebook daily message limit')}
          description={
            <>
              <Typography>
                {t(
                  'LinkedIn limit is lkdNewAccountLimit messages for new accounts, lkdWarmedUpAccountLimit messages for warmed up accounts, and lkdPremiumAccountLimit messages for premium accounts',
                  {
                    lkdNewAccountLimit: messageLimit.lkdNewAccountLimit,
                    lkdWarmedUpAccountLimit: messageLimit.lkdWarmedUpAccountLimit,
                    lkdPremiumAccountLimit: messageLimit.lkdPremiumAccountLimit,
                  }
                )}
                .<br />
                <b>
                  {buildLimitText(SurveySendRoute.LINKEDIN)}
                  <br />
                  {`${t('You have sent')} ${messageLimit.lkdSentMessages} LinkedIn message${
                    messageLimit.lkdSentMessages !== 1 ? 's' : ''
                  } ${
                    user?.linkedinMessageLimitType === SmtpConfigurationLimitType.HOURLY
                      ? t('In the last hour')
                      : t('in the last 24 hours')
                  }.`}
                </b>
              </Typography>
              <Divider style={{ margin: '10px 0px 10px 0px' }} />
              <Typography>
                {t(
                  'Facebook limit depends on timespan, so it is recommended to start slow, and gradually increase messages sent, to avoid flagging of your account'
                )}
                .<br />
                <b>
                  {buildLimitText(SurveySendRoute.FACEBOOK)}
                  <br />
                  {`${t('You have sent')} ${messageLimit.fbSentMessages} Facebook message${
                    messageLimit.fbSentMessages !== 1 ? 's' : ''
                  } ${
                    user?.facebookMessageLimitType === SmtpConfigurationLimitType.HOURLY
                      ? t('In the last hour')
                      : t('in the last 24 hours')
                  }.`}
                </b>
              </Typography>
            </>
          }
          type="info"
          showIcon
          icon={<IconInfo />}
          className="job-alert"
        />
      )}
    </>
  );
};

export default SocialNetworkLimitAlert;
