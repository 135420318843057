import React from 'react';
import { Job, JobConfig } from '../../api/jobs/model';

interface IJobsContext {
  fetchJobs?: () => void;
}
export const JobsContext = React.createContext<IJobsContext>({});

interface IJobsNewContext {
  isLoadingConfig: boolean;
  config?: JobConfig;
  fetchJob?: () => void;
  job?: Job;
  isLoadingJob: boolean;
  setJob?: React.Dispatch<React.SetStateAction<Job | undefined>>;
}
export const JobsNewContext = React.createContext<IJobsNewContext>({
  isLoadingConfig: true,
  isLoadingJob: true,
});
