import React, { useState, useEffect } from 'react';
import {
  Button,
  Card, Col, Input, message, Row, Select, Table, Tag, Tooltip
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Job } from '../../api/jobs/model';
import { createBaseGridParams, getUrlQueryString, GridParams } from '../../helpers/grid-helpers';
import { ROWS_PER_PAGES } from '../../config/general-config';
import { useRouteMatch } from 'react-router-dom';
import JobsService from '../../api/jobs/JobsService';
import { JobEmailStatusCode, JobEmailStatusCodeLabel, JobEmailStatusColorLabel, JobEmailStatusEnum } from '../../api/jobs/enums';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table';

interface Props {
  job: Job;
  fetchJob: () => void;
}

const JobMessagesEmails: React.FC<Props> = ({ job, fetchJob }) => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match?.params;
  const urlParams = getUrlQueryString();
  const gridParams = createBaseGridParams({
    sortOrder: 'descend',
    sortField: 'createdDate',
    pageSize: ROWS_PER_PAGES,
    JobId: +id,
  });
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    filters: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(urlParams.search || '');
  const [emailsData, setEmailsData] = useState<any>();
  const [total, setTotal] = useState(0);

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };
  const handleSearch = (value: string) => {
    setGridState({ ...gridState, search: value, page: 0 });
  };

  const fetchEmailsData = async () => {
    try {
      setIsLoading(true);
      const response = await JobsService.emailsData(gridState);
      setEmailsData(response?.list);
      setTotal(response?.count)
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailsData()
  }, [id, gridState])

  const handleFilterChange = (filter: string, value: any) => {
    if (!value)
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter)],
      });
    else
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter), { key: filter, value }],
      });
  };

  const renderFilters = () => {
    return (
      <Row gutter={12}>
        <Col xs={24} sm={24} md={7} style={{ marginBottom: 10 }}>
          <Input.Search
            placeholder={`${t('Search')}...`}
            enterButton
            onChange={handleOnSearchChange}
            value={searchInput}
            onSearch={handleSearch}
          />
        </Col>

        <Col xs={24} sm={24} md={6}>
          <Select
            allowClear
            placeholder={t('Status')}
            style={{ width: '100%' }}
            onChange={(value: any) => handleFilterChange('status', value)}
          >
            {Object.keys(JobEmailStatusCode).map((status) => (
              <Select.Option key={status} value={status}>
                {t(JobEmailStatusCodeLabel[status as JobEmailStatusCode])}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      title: t('Send Date'),
      dataIndex: 'createdDate',
      align: 'center' as 'center',
      render: (cell: any) => (cell ? cell?.toString()?.replace('T', " ")?.replace('Z', '')?.replaceAll('-', '/')?.split('.')[0] : '-'),
    },
    {
      title: t('Subject'),
      dataIndex: 'subject',
      align: 'center' as 'center',
    },
    {
      title: t('Sender'),
      dataIndex: 'sender',
      align: 'center' as 'center',
      render: (text: any, record: any) => (
        <>{record?.senderName ? `${record?.senderName}` : '-'} <br />
          {record?.sender ? `${record?.sender}` : '-'}
        </>
      ),
    },
    {
      title: t('Recipients'),
      dataIndex: 'recipients',
      align: 'center' as 'center',
    },
    {
      title: t('Status'),
      dataIndex: 'emailStatusCode',
      align: 'center' as 'center',
      render: (status: string) => (
        <Tag color={JobEmailStatusColorLabel[status as JobEmailStatusCode]}>
          {t(status)}
        </Tag>
      ),
    },

  ];

  const updateJobEmailStatus = async (status: any) => {
    try {
      setIsLoading(true);
      const updatedData = { id: id, jobEmailStatus: status };
      await JobsService.jobEmailStatus(updatedData)
      if (fetchJob && fetchEmailsData) {
        fetchJob();
        fetchEmailsData();
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleTableOnChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<any>
  ) => {
    setGridState({
      ...gridState,
      sortField: sorter.columnKey || 'createdDate',
      sortOrder: sorter.order || 'descend',
      page: +pagination.current! - 1,
      pageSize: +pagination.pageSize!,
    });
  };

  return (
    <Card className="job-offer__description">
      {(job?.jobEmailStatus !== JobEmailStatusEnum?.COMPLETED) && (

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {(job && job?.jobEmailStatus === JobEmailStatusEnum.START || job?.jobEmailStatus === JobEmailStatusEnum.RESUME || job?.jobEmailStatus === JobEmailStatusEnum?.RESTART) && (
            <Tooltip title={t('Pause')}>
              <Button
                icon="pause-circle-o"
                shape="circle-outline"
                type="primary"
                style={{ marginLeft: 'auto', marginRight: '1rem' }}
                onClick={() => updateJobEmailStatus(JobEmailStatusEnum.PAUSE)}
              />
            </Tooltip>
          )}

          {(job?.jobEmailStatus === JobEmailStatusEnum?.PAUSE) && (
            <>
              <Tooltip title={t('Resume')} placement="bottom">
                <Button
                  icon="play-circle-o"
                  shape="circle-outline"
                  type="primary"
                  // disabled={campaign?.campaignStatus !== CampaignStatusEnum.Started}
                  style={{ marginRight: '1rem' }}
                  onClick={() => updateJobEmailStatus(JobEmailStatusEnum.RESUME)}

                />
              </Tooltip>
              <Tooltip title={t('Stop')} placement="bottom">
                <Button
                  icon="stop"
                  shape="circle-outline"
                  type="primary"
                  style={{ marginRight: '1rem' }}
                  onClick={() => updateJobEmailStatus(JobEmailStatusEnum.STOP)}
                />
              </Tooltip>
            </>
          )}

          {(job?.jobEmailStatus === JobEmailStatusEnum?.STOP) && (
            <Tooltip title={t('Restart')} placement="bottom">
              <Button
                icon="play-circle-o"
                shape="circle-outline"
                type="primary"
                style={{ marginRight: '1rem' }}
                onClick={() => updateJobEmailStatus(JobEmailStatusEnum.RESTART)}
              />
            </Tooltip>
          )}

        </div>
      )}
      <Table
        style={{ marginTop: '15px' }}
        rowKey="id"
        bordered
        loading={isLoading}
        showHeader
        title={renderFilters}
        onChange={handleTableOnChange}
        pagination={{
          showTotal: (totalRecords, rangeRecords) =>
            `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
          current: gridState.page! + 1,
          pageSize: gridState.pageSize,
          total,
        }}
        columns={columns}
        dataSource={emailsData}
      />
    </Card>
  );
};

export default JobMessagesEmails;
