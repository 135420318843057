import React, { useContext } from 'react';
import { User } from '../api/users/model';

export interface IHeaderContext {
  mainHeaderHeight: number;
  subHeaderHeight: number;
  setMainHeaderHeight: (height: number) => void;
  setSubHeaderHeight: (height: number) => void;
  changeLanguage: (language: string) => void;
}

export interface IAppContext extends IHeaderContext {
  isGoogleSDKLoaded: boolean;
  user?: User;
  isLoggedIn: boolean;
}

export const AppContext = React.createContext<IAppContext>({
  isGoogleSDKLoaded: false,
  user: undefined,
  isLoggedIn: false,
  mainHeaderHeight: 0,
  subHeaderHeight: 0,
  setMainHeaderHeight: () => {},
  setSubHeaderHeight: () => {},
  changeLanguage: () => {},
});

export const useAppContext = () => useContext(AppContext);
