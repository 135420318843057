import {
  Alert,
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Tooltip,
  Upload,
} from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { FormComponentProps } from 'antd/lib/form';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilesService, { FORM_DATA_KEY } from '../../api/files/FilesService';
import { IFile } from '../../api/files/model';
import { JobCandidate, JobCandidateAddType } from '../../api/job-candidates/model';
import { Job } from '../../api/jobs/model';
import { MimeTypes } from '../../api/shared/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { getBase64 } from '../../helpers/image-helpers';
import { useAppContext } from '../../layout/AppContext';

const { confirm } = Modal;

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  jobTitle: string;
  phone: string;
  facebookUrl: string;
  linkedinUrl: string;
  isAwareOfRecommendation?: boolean;
  canBeContactedOnBehalf?: boolean;
  recommendation: string;
  hideRecommendation: boolean;
}

interface Props extends FormComponentProps<FormValues> {
  isVisible: boolean;
  onOk: (model: JobCandidateAddType) => void;
  onCancel: () => void;
  job: Job;
  jobCandidate?: JobCandidate;
  token: string;
}

const JobCandidateSelectModal: React.FC<Props> = ({
  form,
  isVisible,
  onCancel,
  onOk,
  job,
  jobCandidate,
  token,
}) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<UploadFile>();
  const [resumeFile, setResumeFile] = useState<UploadFile>();
  const [imgPreview, setImgPreview] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [forceSubmit, setForceSubmit] = useState(false);
  const { isLoggedIn } = useAppContext();

  const handleSelect = async () => {
    let dbPicture: IFile | undefined;
    let dbResume: IFile | undefined;

    setIsSubmitting(true);
    if (imageFile && !jobCandidate?.pictureFileId) {
      const formData = new FormData();
      formData.append(FORM_DATA_KEY, (imageFile as unknown) as File);
      dbPicture = isLoggedIn
        ? await FilesService.add(formData)
        : await FilesService.addFilesRecommendation(formData, token);
    }

    if (resumeFile && !jobCandidate?.cvFileId) {
      const formData = new FormData();
      formData.append(FORM_DATA_KEY, (resumeFile as unknown) as File);
      dbResume = isLoggedIn
        ? await FilesService.add(formData)
        : await FilesService.addFilesRecommendation(formData, token);
    }

    try {
      const values = await validateAntForm(form);
      const {
        firstName,
        lastName,
        email,
        phone,
        facebookUrl,
        linkedinUrl,
        jobTitle,
        companyName,
        isAwareOfRecommendation,
        canBeContactedOnBehalf,
        recommendation,
        hideRecommendation,
      } = values;

      if (!email && !phone && !forceSubmit) {
        showConfirm();
        return;
      }

      const model: JobCandidateAddType = {
        uuid: jobCandidate?.uuid,
        jobId: job.id,
        firstName,
        lastName,
        email,
        phone,
        facebookUrl,
        company: companyName,
        jobTitle,
        recommendation: {
          canBeContactedOnBehalf,
          isRecommendedAware: isAwareOfRecommendation,
          recommendationText: recommendation,
          hideRecommendation,
        },
        linkedinUrl: linkedinUrl || jobCandidate?.linkedinUrl,
        pictureFileId: dbPicture?.id || jobCandidate?.pictureFileId,
        cvFileId: dbResume?.id || jobCandidate?.cvFileId,
        publicProfilePictureUrl: jobCandidate?.pictureUrl,
        imageFile,
      };

      onOk(model);
      form.resetFields();
      setImageFile(undefined);
      setImgPreview(undefined);
      setResumeFile(undefined);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setForceSubmit(false);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (forceSubmit) {
      handleSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceSubmit]);

  const showConfirm = () => {
    confirm({
      title: t('Oops!'),
      content: t('You did not provide a way to contact your recommendation'),
      cancelText: t('Back'),
      okText: t('I know, continue'),
      onOk() {
        setForceSubmit(true);
      },
      onCancel() {
        setForceSubmit(false);
      },
    });
  };

  const handleImageFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setImageFile(undefined);
      setImgPreview(undefined);
    } else {
      const base64Image = await getBase64((info.file as unknown) as File);
      if (base64Image) {
        setImgPreview(base64Image.toString());
      }
      setImageFile(info.file);
    }
  };

  const handleResumeFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setResumeFile(undefined);
    } else {
      setResumeFile(info.file);
    }
  };

  const avatarProps: AvatarProps = {
    className: 'avatar-upload__img',
  };

  if (imgPreview) {
    avatarProps.src = imgPreview;
  } else if (jobCandidate) {
    avatarProps.src = jobCandidate.pictureUrl;
  } else {
    avatarProps.icon = 'user';
  }

  return (
    <Modal
      style={{ top: '2rem' }}
      visible={isVisible}
      title={`${t('Suggest candidate for')} ${job.jobTitle}`}
      onCancel={() => {
        form.resetFields();
        setImgPreview(undefined);
        setImageFile(undefined);
        setResumeFile(undefined);
        onCancel();
      }}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={handleSelect}
            className="jobs-new__btn"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {t('Validate')}
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        <Form.Item>
          <div className="avatar-upload">
            <Upload
              fileList={imageFile ? [imageFile] : []}
              beforeUpload={() => false}
              onChange={handleImageFileOnChange}
              accept={`${MimeTypes.IMAGE_JPEG}, ${MimeTypes.IMAGE_PNG}`}
              showUploadList={false}
            >
              {imgPreview && (
                <Tooltip title={t('Remove')}>
                  <Button
                    className="avatar-upload__btn avatar-upload__btn--remove"
                    size="small"
                    type="danger"
                    shape="circle"
                    icon="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageFile(undefined);
                      setImgPreview(undefined);
                    }}
                  />
                </Tooltip>
              )}
              <Avatar {...avatarProps}>
                {jobCandidate?.firstName[0]} {jobCandidate?.lastName[0]}
              </Avatar>
            </Upload>
          </div>
          <Alert
            message={t('We do not have all the data on this contact')}
            showIcon
            type="info"
            style={{ textAlign: 'center' }}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label={t('First Name')}>
              {form.getFieldDecorator('firstName', {
                initialValue: jobCandidate?.firstName,
                rules: [
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Last Name')}>
              {form.getFieldDecorator('lastName', {
                initialValue: jobCandidate?.lastName,
                rules: [
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item className="help-red help-smaller" label={t('Email')}>
              {form.getFieldDecorator('email', {
                initialValue: jobCandidate?.email,
                rules: [
                  {
                    type: 'email',
                    message: t('The entered email is not valid'),
                  },
                ],
              })(<Input type="email" onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Company')}>
              {form.getFieldDecorator('companyName', {
                initialValue: jobCandidate?.company,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                ],
              })(<Input onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Position')}>
              {form.getFieldDecorator('jobTitle', {
                initialValue: jobCandidate?.jobTitle,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                ],
              })(<Input onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item className="help-red help-smaller" label={t('Phone')}>
              {form.getFieldDecorator('phone', {
                initialValue: jobCandidate?.phone,
              })(<Input onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item className="help-red help-smaller" label={t('LinkedIn URL')}>
              {form.getFieldDecorator('linkedinUrl', {
                initialValue: jobCandidate?.linkedinUrl,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                  {
                    type: 'url',
                    message: t('Field must be a URL'),
                  },
                ],
              })(<Input onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item className="help-red help-smaller" label={t('Facebook URL')}>
              {form.getFieldDecorator('facebookUrl', {
                initialValue: jobCandidate?.facebookUrl,
                rules: [
                  {
                    type: 'url',
                    message: t('Field must be a URL'),
                  },
                ],
              })(<Input onPressEnter={handleSelect} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label={t('If you have a resume, can you please upload it')}>
              <Upload
                fileList={resumeFile ? [resumeFile] : []}
                beforeUpload={() => false}
                onChange={handleResumeFileOnChange}
                accept={`${MimeTypes.DOCX}, ${MimeTypes.PDF}`}
              >
                <Button icon="upload">{t('Select a file')}</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={t('This person is aware that he/she is recommended')}
            >
              {form.getFieldDecorator('isAwareOfRecommendation')(
                <Radio.Group>
                  <Radio value={true}>{t('Yes')}</Radio>
                  <Radio value={false}>{t('No')}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label={t('He/She can be contacted on behalf of you')}>
              {form.getFieldDecorator('canBeContactedOnBehalf')(
                <Radio.Group>
                  <Radio value={true}>{t('Yes')}</Radio>
                  <Radio value={false}>{t('No')}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={t('Why do you think he/she would be a good fit')}>
          {form.getFieldDecorator('recommendation')(<Input.TextArea rows={5} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<Props>()(JobCandidateSelectModal);
