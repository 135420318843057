import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tag,
  Typography,
} from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import { reject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JobsService from '../../api/jobs/JobsService';
import { Job, JobFM } from '../../api/jobs/model';
import { validateAntForm } from '../../helpers/ant-form';

interface Props extends FormComponentProps<FormValues> {
  isVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  job: Job;
  fetchJob: () => void;
}

interface FormValues {
  jobManualTitle: string;
}

const formItemLayout: FormItemProps = {
  labelCol: {
    xs: 24,
    sm: 24,
    md: 5,
  },
  wrapperCol: {
    xs: 24,
    sm: 24,
    md: 19,
  },
};

const JobFileMakerAssociationModal: React.FC<Props> = ({
  isVisible,
  handleOk,
  handleCancel,
  job,
  fetchJob,
  form,
}) => {
  const { t } = useTranslation();

  const [isLoadingJobsFM, setIsLoadingJobsFM] = useState(true);
  const [jobsFM, setJobsFM] = useState<JobFM[]>([]);
  const [isUpdatingFilemakerJobTitle, setIsUpdatingFilemakerJobTitle] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    const fetchJobsFM = async () => {
      setIsLoadingJobsFM(true);
      const response = await JobsService.findFilemakerJobs(searchValue);
      setJobsFM(reject(response, { id: job.fileMakerIdMission }));
      setIsLoadingJobsFM(false);
    };
    if (isVisible) {
      fetchJobsFM();
    }
  }, [job.fileMakerIdMission, job.jobTitle, isVisible, searchValue]);

  const renderFilemakerJobActions = (item: JobFM) => {
    return (
      <Popconfirm
        title={t('Associate with this job?')}
        onConfirm={() => updateFilemakerJob(item.id, item.title)}
      >
        <Button icon="link" disabled={item.id === job.fileMakerIdMission} />
      </Popconfirm>
    );
  };

  const updateFilemakerJob = async (idMission: number, jobTitle: string) => {
    try {
      setIsLoadingJobsFM(true);
      await JobsService.updateFilemakerJob(job.id, jobTitle, idMission);
      message.success(t('Job successfully associated!'));
      fetchJob();
      handleCancel();
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoadingJobsFM(false);
      handleOk();
    }
  };

  const createFilemakerRecord = async (jobId: number, jobTitle: string) => {
    try {
      setIsLoadingJobsFM(true);
      await JobsService.createFilemakerJob(jobId, jobTitle);
      message.success(t('Job successfully created and associated!'));
      fetchJob();
      handleCancel();
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoadingJobsFM(false);
    }
  };

  const updateFilemakerJobTitle = async (deleteAssociation: boolean = false) => {
    setIsUpdatingFilemakerJobTitle(true);
    try {
      const values = await validateAntForm(form);
      const titleValue = deleteAssociation ? undefined : values.jobManualTitle;

      await JobsService.updateFilemakerJob(job.id, titleValue);
      message.success(t('Success'));

      handleCancel();
      fetchJob();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsUpdatingFilemakerJobTitle(false);
    }
  };

  const renderLinkStatus = () => {
    return (
      <>
        <Alert
          style={{ marginBottom: '1rem' }}
          message={
            <>
              <Typography.Text style={{ fontSize: '1.5rem' }}>
                {t('Association Status')}:
              </Typography.Text>
              {job.fileMakerJobTitle && job.fileMakerIdMission && (
                <Tag style={{ float: 'right', textTransform: 'uppercase' }} color="#7BE56C">
                  {t('Full Association')}
                </Tag>
              )}
              {job.fileMakerJobTitle && !job.fileMakerIdMission && (
                <Tag style={{ float: 'right', textTransform: 'uppercase' }} color="#FFB900">
                  {t('Half Association')}
                </Tag>
              )}
              {!job.fileMakerJobTitle && !job.fileMakerIdMission && (
                <Tag style={{ float: 'right', textTransform: 'uppercase' }} color="#FF0400">
                  {t('No Association')}
                </Tag>
              )}
            </>
          }
        />
        {job.fileMakerIdMission && job.fileMakerJobTitle && (
          <Alert
            style={{ marginBottom: '1rem' }}
            message={
              <>
                <Typography.Text style={{ fontSize: '1.5rem' }}>
                  {t('Associated Job')}:
                </Typography.Text>
                <span style={{ float: 'right', fontWeight: 'bold' }}>
                  {job.fileMakerIdMission} - {job.fileMakerJobTitle}
                </span>
              </>
            }
          />
        )}
      </>
    );
  };

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      width={700}
      visible={isVisible}
      title={t('Assign FileMaker Pro Job')}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={
        <>
          {job.fileMakerJobTitle !== null && (
            <Popconfirm
              onConfirm={() => updateFilemakerJobTitle(true)}
              title={t('Delete association with FMPRO Job?')}
            >
              <Button
                disabled={isLoadingJobsFM || isUpdatingFilemakerJobTitle}
                loading={isLoadingJobsFM || isUpdatingFilemakerJobTitle}
                type="danger"
              >
                {t('Delete association')}
              </Button>
            </Popconfirm>
          )}

          <Popconfirm
            onConfirm={() => createFilemakerRecord(job.id, job.jobTitle)}
            title={t("Create a new Job in FMPRO with this job's title?")}
          >
            <Button
              disabled={isLoadingJobsFM || isUpdatingFilemakerJobTitle}
              loading={isLoadingJobsFM || isUpdatingFilemakerJobTitle}
              type="primary"
            >
              {t('Add new job')}
            </Button>
          </Popconfirm>
        </>
      }
    >
      {renderLinkStatus()}
      <Collapse expandIconPosition="right" className="job-email-collapse">
        <Collapse.Panel
          key="1"
          header={<span className="job-email-collapse__subject">{t('Select FileMaker Job')}</span>}
          className="job-email-collapse__item"
        >
          <Input.Search
            style={{ marginBottom: '1rem' }}
            onSearch={(value) => setSearchValue(value)}
            disabled={isLoadingJobsFM}
            placeholder={t('Type to search a job')}
            allowClear
          />
          <Table
            rowKey="id"
            bordered
            showHeader
            loading={isLoadingJobsFM}
            dataSource={jobsFM}
            pagination={{
              showTotal: (totalRecords, rangeRecords) =>
                `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            }}
            columns={[
              {
                title: t('Id Mission'),
                dataIndex: 'id',
                align: 'center',
              },
              { title: t('Title'), dataIndex: 'title' },
              {
                title: t('Date'),
                dataIndex: 'dateMission',
                align: 'center',
                render: (text: string) => text || '-',
              },
              {
                title: t('Actions'),
                align: 'center',
                render: (text, record, index) => renderFilemakerJobActions(record),
              },
            ]}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key="2"
          header={<span className="job-email-collapse__subject">{t('Job manual input')}</span>}
          className="job-email-collapse__item"
        >
          <Form>
            <Form.Item
              label={t('Mission title')}
              extra={
                job.fileMakerIdMission &&
                `${t('Setting this value will override the fully associated FileMaker Job')}.`
              }
              {...formItemLayout}
            >
              {form.getFieldDecorator('jobManualTitle', {
                initialValue:
                  !job.fileMakerIdMission && job.fileMakerJobTitle ? job.fileMakerJobTitle : '',
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                ],
              })(<Input maxLength={350} />)}
            </Form.Item>
            <Row>
              <Col span={12}>
                <Button
                  onClick={() =>
                    form.setFieldsValue({
                      jobManualTitle:
                        !job.fileMakerIdMission && job.fileMakerJobTitle
                          ? job.fileMakerJobTitle
                          : '',
                    })
                  }
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{ float: 'right' }}
                  type="primary"
                  onClick={() => updateFilemakerJobTitle()}
                  disabled={isUpdatingFilemakerJobTitle || !!!form.getFieldValue('jobManualTitle')}
                >
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
};

export default Form.create<Props>()(JobFileMakerAssociationModal);
