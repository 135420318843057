import { Avatar, Button, List, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchedCandidate, JobCandidateAddType } from '../../api/job-candidates/model';
import LinkedInLogo from '../../assets/linkedin-logo.png';
import { AvatarProps } from 'antd/lib/avatar';
import { getBase64 } from '../../helpers/image-helpers';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { htmlDecode } from '../../helpers/string-helpers';

interface Props {
  searchedCandidate: SearchedCandidate | JobCandidateAddType;
  isSuggested: boolean;
  onClickSelect?: () => void;
}

const SearchedCandidateTile: React.FC<Props> = ({
  onClickSelect,
  searchedCandidate,
  isSuggested: isSelected,
}) => {
  const { t } = useTranslation();
  const [pictureUrl, setPictureUrl] = useState<string>();
  const [isLoadingPicture, setIsLoadingPicture] = useState(true);

  useEffect(() => {
    const imageFile = (searchedCandidate as JobCandidateAddType).imageFile;
    const publicProfilePictureUrl = (searchedCandidate as JobCandidateAddType)
      .publicProfilePictureUrl;
    const loadPicture = async () => {
      if (imageFile) {
        const base64Image = await getBase64((imageFile as unknown) as File);
        if (base64Image) {
          setPictureUrl(base64Image.toString());
        }
      } else if (publicProfilePictureUrl) {
        setPictureUrl(publicProfilePictureUrl);
      } else if (searchedCandidate.pictureUrl) {
        setPictureUrl(searchedCandidate.pictureUrl);
      }

      setIsLoadingPicture(false);
    };
    loadPicture();
  }, [searchedCandidate]);

  const actions = [];
  if (searchedCandidate.linkedinUrl) {
    actions.push(
      <Tooltip title={`${t('View')} ${t('LinkedIn')}`}>
        <Button
          type="link"
          href={searchedCandidate.linkedinUrl}
          icon="linkedin"
          target="_blank"
          size="small"
          className="btn btn--linkedin"
        />
      </Tooltip>
    );
  }

  if (onClickSelect && !isSelected) {
    actions.push(
      <Button type="primary" onClick={() => onClickSelect()} size="small">
        {t('Select')}
      </Button>
    );
  }

  if (onClickSelect && isSelected) {
    actions.push(
      <Button type="primary" disabled size="small">
        {t('Selected')}
      </Button>
    );
  }

  const avatarProps: AvatarProps = {
    style: {
      width: '6rem',
      height: '6rem',
      backgroundColor: '#ccc',
    },
    src: pictureUrl,
  };

  return (
    <List.Item className="searched-candidate" actions={actions}>
      <List.Item.Meta
        avatar={
          <>
            {isLoadingPicture && <LoadingSpinner size="small" />}
            {!isLoadingPicture && (
              <Avatar {...avatarProps}>
                {searchedCandidate.firstName?.[0]}
                {searchedCandidate.lastName?.[0]}
              </Avatar>
            )}

            {searchedCandidate.linkedinUrl && (
              <img
                src={LinkedInLogo}
                alt="LinkedIn Logo"
                className="searched-candidate__linkedin-logo"
              />
            )}
          </>
        }
        title={
          <span
            className="searched-candidate__name"
            title={`${searchedCandidate.firstName} ${searchedCandidate.lastName}`}
          >
            {searchedCandidate.firstName} {searchedCandidate.lastName}
          </span>
        }
        description={
          <>
            <span
              className="searched-candidate__job-title"
              title={htmlDecode(searchedCandidate.jobTitle)}
            >
              {htmlDecode(searchedCandidate.jobTitle)}
            </span>
            <span className="searched-candidate__company-name" title={searchedCandidate.company}>
              {searchedCandidate.company}
            </span>
          </>
        }
      />
    </List.Item>
  );
};

export default SearchedCandidateTile;
