import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import FetchService from '../shared/services/FetchService';
import { API } from '../../config/api';
import { IEmailsFetched } from './model';

export default class EmailsService {
  static async findGrid(params: GridParams): Promise<IEmailsFetched> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<IEmailsFetched>({
      url: API.EMAILS,
      gridParams,
    });
    return response;
  }
}
