import { API } from '../../config/api';
import FetchService from '../shared/services/FetchService';
import { ClientCompanyContact } from './model';

class ClientCompanyContactService {
  static async find(clientCompanyId: number): Promise<ClientCompanyContact[]> {
    const response = await FetchService.get<ClientCompanyContact[]>({
      url: API.CLIENT_COMPANIES_CONTACTS.replace(':id', clientCompanyId.toString()),
    });
    return response;
  }
}

export default ClientCompanyContactService;
