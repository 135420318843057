import React, { useState } from 'react';
import { Dropdown, Button, Icon, Row, Col, Tooltip } from 'antd';
import { ReactComponent as IconImport } from '../../assets/icon-import.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icon-linkedin.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icon-facebook.svg';
import { useTranslation } from 'react-i18next';
import { JobCandidate } from '../../api/job-candidates/model';
import { User } from '../../api/users/model';
import ImportFacebookCommonContactsModal from './ImportFacebookCommonContactsModal';
import ImportLinkedInCommonContactsModal from './ImportLinkedInCommonContactsModal';
import { Contact } from '../../api/contacts/model';

interface Props {
  jobCandidate: JobCandidate;
  user: User;
  selectedContacts: Contact[];
  isSelectingAllContacts: boolean;
  addToLinkedInContactList: (linkedinContact: Contact) => void;
}

const ImportCommonContacts: React.FC<Props> = ({
  jobCandidate,
  user,
  addToLinkedInContactList,
  selectedContacts,
  isSelectingAllContacts,
}) => {
  const { t } = useTranslation();
  const [
    isImportLinkedInCommonConctactsModalVisible,
    setIsImportLinkedInCommonConctactsModalVisible,
  ] = useState(false);
  const [
    isImportFacebookCommonContactsModalVisible,
    setIsImportFacebookCommonContactsVisible,
  ] = useState(false);
  const [createdContacts, setCreatedContacts] = useState<Contact[]>([]);

  let linkedInButtonTooltipTitle = '';
  let facebookButtonTooltipTitle = '';

  if (!jobCandidate?.linkedinUrl || !user?.linkedinSessionCookie) {
    linkedInButtonTooltipTitle = t(
      "Either you haven't set up your LinkedIn cookie in your profile or job candidate does not have a LinkedIn URL"
    );
  }

  if (
    !jobCandidate?.facebookUrl ||
    !user?.facebookSessionCookie_c_user ||
    !user?.facebookSessionCookie_xs
  ) {
    facebookButtonTooltipTitle = t(
      "Either you haven't set up your Facebook cookies in your profile or job candidate does not have a Facebook URL"
    );
  }

  const shouldDisableImportFromLinkedInButton = !!linkedInButtonTooltipTitle.length;
  const shouldDisableImportFromFacebookButton = !!facebookButtonTooltipTitle.length;

  return (
    <>
      <Dropdown
        disabled={isSelectingAllContacts}
        overlay={
          <div className="import-contacts">
            <p className="import-contacts__title">{t('Import contacts in common from')}...</p>
            <Row gutter={[30, 30]}>
              <Col xs={8} sm={6}>
                <Tooltip title={linkedInButtonTooltipTitle}>
                  <Button
                    disabled={shouldDisableImportFromLinkedInButton}
                    style={{
                      width: '7rem',
                      height: '7rem',
                      boxShadow: '0 2px 10px -3px rgba(0, 0, 0, 0.23)',
                    }}
                    onClick={() => setIsImportLinkedInCommonConctactsModalVisible(true)}
                  >
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <LinkedInIcon height="48px" width="48px" />
                    </div>
                  </Button>
                </Tooltip>
              </Col>
              <Col xs={8} sm={6}>
                <Tooltip title={facebookButtonTooltipTitle}>
                  <Button
                    disabled={shouldDisableImportFromFacebookButton}
                    style={{
                      width: '7rem',
                      height: '7rem',
                      boxShadow: '0 2px 10px -3px rgba(0, 0, 0, 0.23)',
                    }}
                    onClick={() => setIsImportFacebookCommonContactsVisible(true)}
                  >
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <FacebookIcon />
                    </div>
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </div>
        }
      >
        <Button type="primary" style={{ marginRight: '2rem' }}>
          <Icon component={IconImport} />
          <span>{t('Import contacts in common')}</span>
        </Button>
      </Dropdown>

      <ImportLinkedInCommonContactsModal
        selectedContacts={selectedContacts}
        linkedinUrl={jobCandidate?.linkedinUrl!}
        isVisible={isImportLinkedInCommonConctactsModalVisible}
        addToLinkedInContactList={(commonContact) => addToLinkedInContactList(commonContact)}
        onClose={() => setIsImportLinkedInCommonConctactsModalVisible(false)}
      />

      <ImportFacebookCommonContactsModal
        facebookUrl={jobCandidate?.facebookUrl!}
        isVisible={isImportFacebookCommonContactsModalVisible}
        onClose={(contacts) => {
          setCreatedContacts([...createdContacts, ...contacts]);
          setIsImportFacebookCommonContactsVisible(false);
        }}
      />
    </>
  );
};

export default ImportCommonContacts;
