import AuthService from '../../api/shared/services/AuthService';
import { User } from '../../api/users/model';
import {
  LOGIN,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE,
  LOGOUT,
  UNAUTHORIZED,
  UPDATE_PROFILE,
  LOGIN_LINKEDIN,
  UPDATE_SESSION_EXPIRED,
} from './constants';
import { ILoginResponse } from './models';
import {
  IActionLogin,
  IActionLoginFacebook,
  IActionLoginGoogle,
  IActionLogout,
  IActionUnauthorized,
  IActionUpdateProfile,
  IActionLoginLinkedIn,
  IActionSessionExpired,
} from './types';

export const login = (data: ILoginResponse): IActionLogin => ({
  type: LOGIN,
  payload: data,
});

export const loginWithFacebook = (data: ILoginResponse): IActionLoginFacebook => ({
  type: LOGIN_FACEBOOK,
  payload: data,
});

export const loginWithGoogle = (data: ILoginResponse): IActionLoginGoogle => ({
  type: LOGIN_GOOGLE,
  payload: data,
});

export const loginWithLinkedIn = (data: ILoginResponse): IActionLoginLinkedIn => ({
  type: LOGIN_LINKEDIN,
  payload: data,
});

export const logout = (): IActionLogout => {
  AuthService.logout();
  return {
    type: LOGOUT,
  };
};

export const updateProfile = (data: User): IActionUpdateProfile => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const updateSessionExpired = (sessionExpired?: boolean): IActionSessionExpired => ({
  type: UPDATE_SESSION_EXPIRED,
  payload: sessionExpired,
});

export const unauthorized = (response: Response, callback?: () => void): IActionUnauthorized => {
  if (callback) {
    callback();
  }
  return {
    type: UNAUTHORIZED,
    payload: response,
  };
};
