import { Menu } from 'antd';
import React from 'react';
import { MenuItems, renderMenuItem } from '../helpers/layout-helpers';
import { useHistory } from 'react-router-dom';

const HeaderMenu: React.FC = () => {
  const history = useHistory();

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[`/${window.location.pathname.split('/')[1]}`]}
      onClick={({ key }) => history.push(key)}
      className="header__menu"
    >
      {MenuItems.map((item) => renderMenuItem({ item, className: 'header__menu-item' }))}
    </Menu>
  );
};

export default HeaderMenu;
