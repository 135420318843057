import { Button } from 'antd';
import React, { useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { GoogleRequestModel } from '../../api/shared/models';
import AuthService from '../../api/shared/services/AuthService';
import GoogleService from '../../api/shared/services/GoogleService';
import { ReactComponent as GoogleIcon } from '../../assets/icon-google.svg';
import { useAppContext } from '../../layout/AppContext';
import { loginWithGoogle } from '../../state/auth';
import { useTranslation } from 'react-i18next';

interface Props extends DispatchProp {
  isLoggingApp: boolean;
}

const LoginWithGoogleButton: React.FC<Props> = ({ dispatch, isLoggingApp }) => {
  const { t } = useTranslation();
  const { isGoogleSDKLoaded } = useAppContext();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = async () => {
    try {
      setIsLoggingIn(true);

      const profile = await GoogleService.login();

      const { accessToken, email, firstName, userId, lastName, profilePictureUrl } = profile;

      const data: GoogleRequestModel = {
        accessToken,
        email,
        firstName,
        lastName,
        profilePictureUrl,
        userId,
      };

      const response = await AuthService.loginWithGoogle(data);
      dispatch(loginWithGoogle(response));
    } catch (error) {
      setIsLoggingIn(false);
    }
  };

  return (
    <Button
      onClick={handleLogin}
      disabled={isLoggingIn || isLoggingApp || !isGoogleSDKLoaded}
      loading={isLoggingIn}
      block
      size="large"
      style={{ marginBottom: '1.5rem' }}
    >
      <GoogleIcon
        style={{
          height: '2rem',
          position: 'absolute',
          left: '2rem',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
      {t('Enter with Google')}
    </Button>
  );
};

export default connect()(LoginWithGoogleButton);
