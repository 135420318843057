import {
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip,
  Upload,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import arrayMove from 'array-move';
import { last } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import FilesService, { FORM_DATA_KEY } from '../../api/files/FilesService';
import { IFile } from '../../api/files/model';
import { MimeTypes } from '../../api/shared/enums';
import { SurveyQuestionType, SurveyThankYouInfoDisposition } from '../../api/surveys/enums';
import {
  Survey,
  SurveyAddType,
  SurveyAddTypeQuestionModel,
  SurveyUpdateType,
} from '../../api/surveys/models';
import SurveysService from '../../api/surveys/SurveysService';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { getBase64 } from '../../helpers/image-helpers';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { SurveysContext, SurveysEditContext } from './SurveysContext';
import SurveysQuestionsFormItems from './SurveysQuestionsFormItems';

export interface FormValues {
  title: string;
  introductionText: string;
  thankYouMessageDisposition: string;
  thankYouMessage: string;
  keys: number[];
  questions: [
    {
      title: string;
      explanation: string;
      typeCode: SurveyQuestionType;
      surveyTemplateQuestionItemModelKeys?: number[];
      surveyTemplateQuestionItemModels?: {
        title: string;
        images: UploadFile<any>[];
        fileId?: number;
      }[];
    }
  ];
}
interface Props extends FormComponentProps<FormValues> {}

const MenuItemsKeys = {
  SURVEY: 'SURVEY',
};

export const QUESTION_KEYS_FORM_FIELD_VALUE_KEY = 'keys';
const SurveysEdit: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isLoadingSurvey, setIsLoadingSurvey] = useState(true);
  const [survey, setSurvey] = useState<Survey>();
  const fetchSurveys = useContext(SurveysContext).fetchSurveys!;
  const { params } = useRouteMatch<{ id: string }>();
  const questionsCount = useRef(0);
  const [, setSelectedMenuKey] = useState<string>();
  const [isDeletingSurvey, setIsDeletingSurvey] = useState(false);

  const [thankYouPictureFile, sethankYouPictureFile] = useState<UploadFile>();
  const [thankYouImgPreview, setThankYouImgPreview] = useState<string>();

  const [thankYouPictureId, setThankYouPictureId] = useState<number>();

  const ThankYouMessageFields = ['contact-first-name', 'contact-last-name'];

  const removeQuestion = (k: number) => {
    const keys = form.getFieldValue(QUESTION_KEYS_FORM_FIELD_VALUE_KEY) as number[];
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  };

  const sortEnd = (obj: any) => {
    const keys = form.getFieldValue(QUESTION_KEYS_FORM_FIELD_VALUE_KEY) as number[];
    const nextKeys = arrayMove(keys, obj.oldIndex, obj.newIndex);
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  const addQuestion = () => {
    const keys = form.getFieldValue(QUESTION_KEYS_FORM_FIELD_VALUE_KEY) as number[];
    const nextKeys = keys.concat(questionsCount.current++);

    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  const handleFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      sethankYouPictureFile(undefined);
      setThankYouImgPreview(undefined);
      setThankYouPictureId(undefined);
    } else {
      if (info.file.type !== 'image/jpeg' && info.file.type !== 'image/png') {
        message.error(t('File must be of JPEG or PNG extension'));
      } else {
        const response = await getBase64((info.file as unknown) as File);
        if (response) {
          setThankYouImgPreview(response.toString());
        }
        sethankYouPictureFile(info.file);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const fileIds: number[] = [];
    let thankYouPictureDbFile: IFile | undefined;
    try {
      e.preventDefault();
      setIsSubmittingForm(true);
      const values = await validateAntForm(form);

      if (!thankYouPictureId && thankYouPictureFile) {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY, (thankYouPictureFile as unknown) as File);
        thankYouPictureDbFile = await FilesService.add(formData);
      }

      const questions = values.keys.map((key) => values.questions[key]);
      const questionModels: SurveyAddTypeQuestionModel[] = await Promise.all(
        questions.map(async (question, index) => {
          const model: SurveyAddTypeQuestionModel = {
            title: question.title,
            explanation: question.explanation,
            typeCode: question.typeCode,
            order: index,
          };

          const questionItems = question.surveyTemplateQuestionItemModelKeys?.map(
            (questionItemKey) => question.surveyTemplateQuestionItemModels?.[questionItemKey]
          );

          if (questionItems) {
            const surveyTemplateQuestionItemModels = await Promise.all(
              questionItems.map(async (questionItem) => {
                if (questionItem?.fileId && !questionItem?.images) {
                  return {
                    title: questionItem!.title,
                    fileId: questionItem!.fileId,
                  };
                }

                const formData = new FormData();
                formData.append(FORM_DATA_KEY, last(questionItem?.images)?.originFileObj as File);
                const uploadedFile = await FilesService.add(formData);
                fileIds.push(uploadedFile.id);
                return {
                  title: questionItem!.title,
                  fileId: uploadedFile.id,
                };
              })
            );

            model.surveyTemplateQuestionItemModels = surveyTemplateQuestionItemModels;
          }

          return model;
        })
      );
      let surveyId: number;
      if (!survey) {
        const surveyToAdd: SurveyAddType = {
          title: values.title,
          introductionText: values.introductionText,
          thankYouMessage: values.thankYouMessage,
          thankYouPictureId: thankYouPictureId || thankYouPictureDbFile?.id,
          thankYouMessageDisposition: values.thankYouMessageDisposition,

          surveyTemplateQuestionModels: questionModels,
        };
        surveyId = await SurveysService.add(surveyToAdd);
        message.success(t('Survey created successfully'));
      } else {
        const surveyToUpdate: SurveyUpdateType = {
          id: survey.id,
          title: values.title,
          introductionText: values.introductionText,
          thankYouMessage: values.thankYouMessage,
          thankYouPictureId: thankYouPictureId || thankYouPictureDbFile?.id,
          thankYouMessageDisposition: values.thankYouMessageDisposition,

          surveyTemplateQuestionModels: questionModels,
        };
        surveyId = survey.id;
        await SurveysService.update(surveyToUpdate);
        message.success(t('Survey updated successfully'));
      }

      fetchSurveys();
      fetchSurvey();
      history.push(URLs.SURVEYS_EDIT.replace(':id', surveyId.toString()));
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
      if (fileIds.length) {
        await Promise.all(fileIds.map((fileId) => FilesService.delete(fileId)));
      }
    } finally {
      setIsSubmittingForm(false);
    }
  };

  const deleteSurvey = async () => {
    try {
      setIsDeletingSurvey(true);
      await SurveysService.delete(parseInt(params.id));
      message.success(t('Survey deleted successfully'));
      fetchSurveys();
      history.push(URLs.SURVEYS);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsDeletingSurvey(false);
    }
  };

  const fetchSurvey = useCallback(() => {
    const fetch = async () => {
      try {
        form.resetFields();
        questionsCount.current = 0;
        if (params.id) {
          setIsLoadingSurvey(true);
          const response = await SurveysService.findOne(parseInt(params.id));
          response?.surveyTemplateQuestionModels?.forEach(addQuestion);
          setSurvey(response);
          setThankYouPictureId(response.thankYouPictureId);
          setThankYouImgPreview(response.thankYouPictureUrl);
        } else {
          setSurvey(undefined);
          addQuestion();
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingSurvey(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    fetchSurvey();
  }, [fetchSurvey]);

  useEffect(() => {
    setSelectedMenuKey(MenuItemsKeys.SURVEY);
  }, [params.id]);

  form.getFieldDecorator(QUESTION_KEYS_FORM_FIELD_VALUE_KEY, { initialValue: [] });

  return !isLoadingSurvey ? (
    <>
      <Card className="job-offer__description">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
          <h1>{survey ? t('Edit Survey') : t('New Survey')}</h1>
          <div>
            {survey && (
              <Tooltip title={t('Delete Survey')} placement="bottom">
                <Popconfirm
                  title={t('Are you sure you want to delete this survey?')}
                  onConfirm={deleteSurvey}
                  okText={t('Delete')}
                  okType="danger"
                  cancelText={t('Cancel')}
                >
                  <Button
                    loading={isSubmittingForm || isDeletingSurvey}
                    icon="minus-circle-o"
                    shape="circle-outline"
                    type="danger"
                    style={{ marginRight: '1rem' }}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        </div>

        <SurveysEditContext.Provider value={{ isSubmittingForm, form, survey }}>
          <Form onSubmit={handleSubmit}>
            <Form.Item label={t('Survey Title')}>
              {form.getFieldDecorator('title', {
                initialValue: survey?.title,
                rules: [{ required: true, message: t('This field is required') }],
              })(<Input size="large" placeholder={t('Survey Title')} />)}
            </Form.Item>

            <Form.Item label={t('Introduction text')}>
              {form.getFieldDecorator('introductionText', {
                initialValue: survey?.introductionText,
              })(<TextArea rows={5} placeholder={t('Introduction text')} />)}
            </Form.Item>

            <Row>
              <Col span={7}>
                <Form.Item label={t('Thank you message disposition')}>
                  {form.getFieldDecorator('thankYouMessageDisposition', {
                    initialValue: survey?.thankYouMessageDisposition || undefined,
                  })(
                    <Select
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      placeholder={t('Select a value')}
                    >
                      <Select.Option
                        key={SurveyThankYouInfoDisposition.IMAGE_FIRST}
                        value={SurveyThankYouInfoDisposition.IMAGE_FIRST}
                      >
                        {t('Image first')}
                      </Select.Option>
                      <Select.Option
                        key={SurveyThankYouInfoDisposition.TEXT_FIRST}
                        value={SurveyThankYouInfoDisposition.TEXT_FIRST}
                      >
                        {t('Text first')}
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={t('Thank you message picture')} style={{ float: 'right' }}>
                  {form.getFieldDecorator('thankYouMessagePicture', { initialValue: undefined })(
                    <Upload
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={() => false}
                      onChange={(info) => handleFileOnChange(info)}
                      accept={`${MimeTypes.IMAGE_JPEG},${MimeTypes.IMAGE_PNG}`}
                    >
                      {thankYouImgPreview ? (
                        <>
                          <img
                            src={thankYouImgPreview}
                            style={{ width: '100%' }}
                            alt={t('Thank you picture')}
                          />
                          <Tooltip title={t('Remove')}>
                            <Button
                              shape="circle"
                              icon="delete"
                              type="danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                sethankYouPictureFile(undefined);
                                setThankYouImgPreview(undefined);
                                setThankYouPictureId(undefined);
                              }}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <div>
                          <Icon type="plus" />
                          <div className="ant-upload-text">{t('Upload')}</div>
                        </div>
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={t('Thank you message')}>
              {form.getFieldDecorator('thankYouMessage', {
                initialValue: survey?.thankYouMessage,
              })(<TextArea rows={5} placeholder={t('Thank you message')} />)}
            </Form.Item>

            <Form.Item label={t('Fields')}>
              {ThankYouMessageFields.map((field) => (
                <Tag
                  key={field}
                  onClick={() => {
                    form.setFieldsValue({
                      thankYouMessage: `${form.getFieldValue('thankYouMessage')} [${field}]`,
                    });
                  }}
                >{`[${field}]`}</Tag>
              ))}
            </Form.Item>

            <Card style={{ marginBottom: '2rem' }}>
              <SurveysQuestionsFormItems onRemoveQuestion={removeQuestion} onSortEnd={sortEnd} />
            </Card>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button size="large" onClick={addQuestion}>
                {t('Add question')}
              </Button>
              <Button
                loading={isSubmittingForm}
                htmlType="submit"
                size="large"
                type="primary"
                data-testid="submit"
              >
                {(survey && t('Update Survey')) || t('Create Survey')}
              </Button>
            </div>
          </Form>
        </SurveysEditContext.Provider>
      </Card>
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default Form.create()(SurveysEdit);
