import React, { useState, useEffect } from 'react';
import {
    Button,
    Card, Col, message, Row, Tabs, Tooltip
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Job } from '../../api/jobs/model';
import classNames from 'classnames';
import JobMessageSummary from './JobMessageSummary';
import JobMessagesEmails from './JobMessagesEmails';
import JobMessagesSocialMessages from './JobMessagesSocialMessages';
import JobsService from '../../api/jobs/JobsService';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { createBaseGridParams, getUrlQueryString, GridFilter } from '../../helpers/grid-helpers';
export const TabItems = {
    SUMMARY: 'SUMMARY',
    EMAILS: 'EMAILS',
    SOCIALMESSAGES: 'SOCIALMESSAGES',
};

interface Props {
    job: Job;
    fetchJob: () => void;

}

const JobDetailMessagesTab: React.FC<Props> = ({ job, fetchJob }) => {
    const { t } = useTranslation();
    const gridParams = createBaseGridParams({
        sortField: 'createdDate',
        sortOrder: 'descend',
        JobId: +job?.id,
        filters: [],
    });
    const urlParams = getUrlQueryString();
    const [activeTabKey, setActiveTabKey] = useState(TabItems.SUMMARY);
    const [emailStatistics, setEmailStatistics] = useState<any>();
    const [socialMessageStatistics, setSocialMessageStatistics] = useState<any>();
    const [generalSummary, setGeneralSummary] = useState<any>();
    const [emailsData, setEmailsData] = useState<any>();
    const [socialNetworkData, setSocialNetworkData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [gridState, setGridState] = useState({
        ...gridParams,
        ...urlParams,
        filters: [] as GridFilter[],
    });


    useEffect(() => {
        setGridState({ ...gridState, JobId: job?.id })
        fetchGeneralSummaryData();
        fetchEmailStatisticsData();
        fetchSocialMessageData();
        // fetchEmailsData();
        // fetchSocialNetworkData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job?.id]);

    const fetchGeneralSummaryData = async () => {
        try {
            setIsLoading(true);
            const response = await JobsService.findGeneralSummary(job?.id);
            setGeneralSummary(response);
        } catch (error) {
            if (error.message) {
                message.error(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const fetchEmailStatisticsData = async () => {
        try {
            setIsLoading(true);
            const response = await JobsService.findEmailStatistics(job?.id);
            setEmailStatistics(response);
        } catch (error) {
            if (error.message) {
                message.error(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSocialMessageData = async () => {
        try {
            setIsLoading(true);
            const response = await JobsService.findSocialMessageSummary(job?.id);
            setSocialMessageStatistics(response);
        } catch (error) {
            if (error.message) {
                message.error(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchEmailsData = async () => {
        try {
            setIsLoading(true);
            const response = await JobsService.emailsData(gridState);
            setEmailsData(response?.list);
        } catch (error) {
            if (error.message) message.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSocialNetworkData = async () => {
        try {
            setIsLoading(true);
            const response = await JobsService.socialNetworkData(gridState);
            // const response = await SocialNetworkMessagesService.findGrid(gridState);
            const socialNetwork = response.list?.filter((obj: any) => { return obj.jobId === job?.id })
            setSocialNetworkData(socialNetwork);
        } catch (error) {
            if (error.message) message.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div className="job-offer" style={{marginTop: '30px'}}>
                <div className="job-offer__body">
                    <Tabs
                        activeKey={activeTabKey}
                        onChange={(key) => setActiveTabKey(key)}
                        className={classNames({
                            'ant-tabs-bar--bordered-bottom': activeTabKey === TabItems.SUMMARY,
                        })}
                    >
                        {/* Summary Tab */}
                        <Tabs.TabPane key={TabItems.SUMMARY} tab={t('Summary')}>
                            <JobMessageSummary emailStatistics={emailStatistics} socialMessageStatistics={socialMessageStatistics} generalSummary={generalSummary} />
                        </Tabs.TabPane>

                        {/* Emails Tab */}
                        <Tabs.TabPane key={TabItems.EMAILS} tab={t('Emails')}>
                            <JobMessagesEmails fetchJob={fetchJob} job={job} />
                        </Tabs.TabPane>

                        {/* Social Messages Tab */}
                        <Tabs.TabPane key={TabItems.SOCIALMESSAGES} tab={t('Social Messages')}>
                            <JobMessagesSocialMessages />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default JobDetailMessagesTab;
