import { Button, message, Tabs, Tooltip } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import classNames from 'classnames';
import { parseInt } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { JobStatusEnum } from '../../api/jobs/enums';
import JobsService from '../../api/jobs/JobsService';
import { URLs } from '../../config/enums';
import { useJob } from '../../hooks/jobs';
import { useAppContext } from '../../layout/AppContext';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';
import JobDetailCandidatesTab from './JobDetailCandidatesTab';
import JobDetailDescriptionTab from './JobDetailDescriptionTab';
import JobDetailMessagesTab from './JobDetailMessagesTab';
import JobDetailSurveysListTab from './JobDetailSurveysList';
import { JobsContext } from './JobsContext';

export const TabItems = {
  DESCRIPTION: 'DESCRIPTION',
  CANDIDATES: 'CANDIDATES',
  SURVEYS: 'SURVEYS',
  MESSAGES: 'MESSAGES',
};

const JobDetail: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;

  const history = useHistory();
  const { user } = useAppContext();
  const { isLoadingJob, job, fetchJob } = useJob({ jobId: parseInt(id) });
  const [activeTabKey, setActiveTabKey] = useState(TabItems.DESCRIPTION);
  const { fetchJobs } = useContext(JobsContext);
  const [isDuplicating, setIsDuplicating] = useState(false);

  useEffect(() => {
    const path = match.path.toUpperCase();
    if (path.endsWith(TabItems.CANDIDATES)) {
      setActiveTabKey(TabItems.CANDIDATES);
    } else if (path.endsWith(TabItems.SURVEYS)) {
      setActiveTabKey(TabItems.SURVEYS);
    } else if (path.endsWith(TabItems.MESSAGES)) {
      setActiveTabKey(TabItems.MESSAGES);
    }
  }, [match.path]);

  useEffect(() => {
    if (activeTabKey === TabItems.CANDIDATES) {
      history.push(URLs.JOBS_DETAIL_CANDIDATES.replace(':id', id));
    } else if (activeTabKey === TabItems.SURVEYS) {
      history.push(URLs.JOBS_DETAIL_SURVEYS.replace(':id', id));
    } else {
      history.push(URLs.JOBS_DETAIL.replace(':id', id));
    }
  }, [activeTabKey, history, id]);

  if (!user) {
    return <Redirect to={URLs.LOGIN} />;
  }

  if (isLoadingJob || isDuplicating) {
    return <LoadingSpinner />;
  }

  if (!job) {
    return <NotFoundPage hideHeader />;
  }

  const avatarProps: AvatarProps = {
    size: 'large',
  };

  if (job.imageUrl) {
    avatarProps.src = job.imageUrl;
  } else {
    avatarProps.icon = 'project';
  }

  const duplicate = async () => {
    setIsDuplicating(true);
    try {
      await JobsService.duplicate(job.id);
      message.success(t('Job successfully duplicated'));
      if (fetchJobs) {
        fetchJobs();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsDuplicating(false);
    }
  };

  return (
    <>
      <div className="job-offer">
        <h1 className="job-offer__header">
          {job.jobTitle}
          {user.id === job.ownerUserId && job.jobStatus !== JobStatusEnum.ARCHIVED && (
            <Tooltip title={t('Edit')}>
              <Button
                icon="edit"
                shape="circle-outline"
                type="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => history.push(URLs.JOBS_EDIT.replace(':id', job.id.toString()))}
              />
            </Tooltip>
          )}
          {user.id === job.ownerUserId && (
            <Tooltip title={t('Duplicate')}>
              <Button
                icon="copy"
                shape="circle-outline"
                type="primary"
                style={{ marginLeft: job.jobStatus === JobStatusEnum.ARCHIVED ? 'auto' : '1rem' }}
                onClick={duplicate}
              />
            </Tooltip>
          )}
        </h1>
        <div className="job-offer__body">
          <Tabs
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
            className={classNames({
              'ant-tabs-bar--bordered-bottom': activeTabKey === TabItems.DESCRIPTION,
            })}
          >
            {/* Description Tab */}
            <Tabs.TabPane key={TabItems.DESCRIPTION} tab={t('Description')}>
              <JobDetailDescriptionTab job={job} fetchJob={fetchJob} />
            </Tabs.TabPane>

            {/* Candidates Tab */}
            {user.id === job.ownerUserId && (
              <Tabs.TabPane key={TabItems.CANDIDATES} tab={t('Candidates')}>
                <JobDetailCandidatesTab job={job} />
              </Tabs.TabPane>
            )}

            {/* Surveys Tab */}
            <Tabs.TabPane key={TabItems.SURVEYS} tab={t('Surveys')}>
              <JobDetailSurveysListTab job={job} />
            </Tabs.TabPane>

            {/* Messages Tab */}
            {job.jobStatus === JobStatusEnum.OPEN && (
              <Tabs.TabPane key={TabItems.MESSAGES} tab={t('Messages')}>
                <JobDetailMessagesTab job={job} fetchJob={fetchJob} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default JobDetail;
