import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Tooltip,
  Typography,
  Alert,
  Affix,
} from 'antd';
import { find } from 'lodash';
import HtmlReactParse from 'html-react-parser';
import { FormComponentProps } from 'antd/lib/form';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { v4 as uuidV4 } from 'uuid';
import JobCandidateService from '../../api/job-candidates/JobCandidatesService';
import { JobCandidateAddType, SearchedCandidate } from '../../api/job-candidates/model';
import { JobStatusEnum } from '../../api/jobs/enums';
import { Job } from '../../api/jobs/model';
import { CandidateSearchModel } from '../../api/shared/models';
import { URLs } from '../../config/enums';
import { APP_INSTITUTIONAL_URL } from '../../config/general-config';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import SearchedCandidateTile from '../Candidates/SearchedCandidateTile';
import JobCandidateSelectModal from '../Jobs/JobCandidateSelectModal';
import { JobsContext } from '../Jobs/JobsContext';
import LoadingSpinner from '../Loading/LoadingSpinner';
import SuggestCandidateAddContactModal from './SuggestCandidateAddContactModal';
import { useAppContext } from '../../layout/AppContext';

interface FormValues {
  name: string;
  company?: string;
}

interface Props extends FormComponentProps<FormValues> {
  isLoadingJob: boolean;
  job?: Job;
  showBack?: boolean;
  isPublic?: boolean;
}

const SuggestCandidate: React.FC<Props> = ({
  isLoadingJob,
  job,
  showBack = false,
  isPublic = true,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const qs = queryString.parse(window.location.search) as { p?: string };
  const { fetchJobs } = useContext(JobsContext);
  const { changeLanguage, user } = useAppContext();
  const [isSearching, setIsSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [isVisibleAddContactModal, setIsVisibleAddContactModal] = useState(false);
  const [newJobCandidateRecommendationIds, setNewJobCandidateRecommendationIds] = useState<
    number[]
  >([]);
  const [newContactId, setNewContactId] = useState<number>();
  const [searchedCandidates, setSearchedCandidates] = useState<SearchedCandidate[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<SearchedCandidate>();
  const [suggestedCandidates, setSuggestedCandidates] = useState<JobCandidateAddType[]>([]);
  const [isVisibleCandidateModal, setIsVisibleCandidateModal] = useState(false);
  const [nameSearch, setNameSearch] = useState('');
  const [nameSearchDebounced] = useDebounce(nameSearch, 500);
  const [companySearch, setCompanySearch] = useState('');
  const [companyDebouncedSearch] = useDebounce(companySearch, 500);
  const { isLoggedIn } = useAppContext();

  const handleSearch = useCallback(() => {
    const fetch = async () => {
      try {
        if (nameSearchDebounced || companyDebouncedSearch) {
          setIsSearching(true);
          setSearchedCandidates([]);

          const model: CandidateSearchModel = {
            name: nameSearchDebounced,
            company: companyDebouncedSearch,
          };
          const response = await JobCandidateService.search(model);

          response.forEach((searchedCandidate) => {
            searchedCandidate.uuid = uuidV4();
          });

          setSearchedCandidates(response);
          setSearched(true);
        } else {
          setSearched(false);
          setSearchedCandidates([]);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsSearching(false);
      }
    };

    fetch();
  }, [nameSearchDebounced, companyDebouncedSearch]);

  useEffect(() => {
    if (selectedCandidate) {
      setIsVisibleCandidateModal(true);
    } else {
      setIsVisibleCandidateModal(false);
    }
  }, [selectedCandidate]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    if (job && !user) changeLanguage(job.language);
  }, [changeLanguage, job, user]);

  useEffect(() => {
    if (newJobCandidateRecommendationIds.length) {
      setIsVisibleAddContactModal(true);
    }
  }, [newJobCandidateRecommendationIds]);

  useEffect(() => {
    const linkRecommendationsToContact = async () => {
      try {
        if (newContactId && newJobCandidateRecommendationIds.length) {
          await JobCandidateService.linkRecommendationsToContact(
            newContactId,
            newJobCandidateRecommendationIds
          );
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      }
    };
    linkRecommendationsToContact();
  }, [newContactId, newJobCandidateRecommendationIds]);

  if (isLoadingJob) {
    return <LoadingSpinner />;
  }

  if (!job || job.jobStatus !== JobStatusEnum.OPEN) {
    return <NotFoundPage hideHeader />;
  }

  const showThankYouModal = () => {
    Modal.info({
      title: `${t('Thanks')}!`,
      content: (
        <p style={{ textAlign: 'center' }}>
          {t('You successfully suggested the candidates for the job')}{' '}
          <strong>{job.jobTitle}</strong>
        </p>
      ),
      onOk: () => {
        if (isPublic) {
          window.location.replace(APP_INSTITUTIONAL_URL);
        } else {
          setNameSearch('');
          setCompanySearch('');
          setSearched(false);
          setSearchedCandidates([]);
          if (fetchJobs) {
            fetchJobs();
          }
          history.push(URLs.JOBS_DETAIL.replace(':id', job.id.toString()));
        }
      },
    });
  };

  const suggestCandidate = async (candidateToSuggest: JobCandidateAddType) => {
    const disposeLoadingMessage = message.loading(t('Suggesting candidate'));
    try {
      const response = await JobCandidateService.suggestMultiple([candidateToSuggest], qs.p);
      if (response.isAnonymous) {
        setNewJobCandidateRecommendationIds(response.jobRecommendationIds);
      }
      setSuggestedCandidates([...suggestedCandidates, candidateToSuggest]);
      message.success(t('Candidate suggested successfully!'));
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      disposeLoadingMessage();
    }
  };

  const handleJobCandidateSelectModalOk = (jobCandidateModel: JobCandidateAddType) => {
    setSelectedCandidate(undefined);
    setIsVisibleCandidateModal(false);
    suggestCandidate(jobCandidateModel);
  };

  const handleViewCandidatesButtonClick = () => {
    history.push(URLs.JOBS_DETAIL_CANDIDATES.replace(':id', job.id.toString()));
  };

  const showFullDescription = () => {
    Modal.info({
      icon: null,
      width: 600,
      title: job.jobTitle,
      content: (
        <div style={{ maxHeight: 600, overflowY: 'auto', whiteSpace: 'pre-line' }}>
          {job.description}
        </div>
      ),
    });
  };

  const renderJobDescription = () => {
    if (!job.description) {
      return null;
    }
    if (job.description.length < 500) {
      return job.description;
    }

    return (
      <>
        <span>{job.description.substr(0, 499)}...</span>
        <Button
          size="small"
          type="link"
          style={{ textTransform: 'none' }}
          onClick={() => showFullDescription()}
        >
          {t('View full description')}
        </Button>
      </>
    );
  };

  const renderCandidatesFound = () => {
    if (isSearching) {
      return <LoadingSpinner />;
    }

    if (searched && (nameSearchDebounced || companyDebouncedSearch) && !searchedCandidates.length) {
      return <Empty description={t('No results found')} />;
    }

    if (!searched) {
      return null;
    }

    return (
      <>
        <Typography.Title level={4}>{t('People found')}</Typography.Title>
        <Alert
          type="info"
          showIcon
          message={t(
            'Select the candidates you want to suggest and confirm them with the confirm button'
          )}
          style={{ marginBottom: '2rem' }}
        />
        <List
          dataSource={searchedCandidates}
          itemLayout="vertical"
          renderItem={(searchedCandidate) => (
            <SearchedCandidateTile
              isSuggested={!!find(suggestedCandidates, { uuid: searchedCandidate.uuid })}
              searchedCandidate={searchedCandidate}
              onClickSelect={() => setSelectedCandidate(searchedCandidate)}
            />
          )}
        />
      </>
    );
  };

  /**
   * Component rendering
   */

  const shouldDisplayViewCandidatesButton = !!suggestedCandidates.length && isLoggedIn;

  return (
    <>
      <Card style={{ height: '100%' }} headStyle={{ backgroundColor: '#fff' }}>
        <div>
          {showBack && (
            <Tooltip title={t('Go Back')}>
              <Button
                type="link"
                icon="left"
                style={{ padding: 0 }}
                onClick={() => history.goBack()}
              />
            </Tooltip>
          )}
        </div>
        {!job.hideSuggestMessage && (
          <Typography.Paragraph className="suggest-candidate__message">
            {HtmlReactParse(job.suggestPageMessageWithReplacements || '')}
          </Typography.Paragraph>
        )}

        {!job.hideDescription && (
          <Typography.Paragraph className="suggest-candidate__description">
            {renderJobDescription()}
          </Typography.Paragraph>
        )}

        <Form hideRequiredMark onSubmit={handleSearch}>
          <div className="ant-typography">
            <span>
              {' '}
              {t('Please, enter the names of the people that you would like to propose')}
            </span>
          </div>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item>
                <Input.Search
                  size="large"
                  autoFocus
                  placeholder={`${t('First Name')} & ${t('Last Name')}`}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={20} md={8}>
              <Form.Item>
                <Input.Search
                  size="large"
                  placeholder={`${t('Company')} (${t('optional')})`}
                  value={companySearch}
                  onChange={(e) => setCompanySearch(e.target.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={4} md={4} style={{ textAlign: 'center' }}>
              <Form.Item>
                <MediaQuery maxDeviceWidth={699}>
                  <Tooltip title={t('Add manually')}>
                    <Button
                      style={{ top: 5 }}
                      type="primary"
                      icon="plus"
                      size="large"
                      onClick={() => setIsVisibleCandidateModal(true)}
                    />
                  </Tooltip>
                </MediaQuery>
                <MediaQuery minDeviceWidth={700}>
                  <Button
                    style={{ top: 5 }}
                    type="primary"
                    icon="plus"
                    size="large"
                    onClick={() => setIsVisibleCandidateModal(true)}
                  >
                    {t('Add')}
                  </Button>
                </MediaQuery>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {searched && (
          <Alert
            style={{ bottom: 5 }}
            type="info"
            showIcon
            message={
              <span>
                {t(
                  'If you cant find the person youre looking for in the list, click the button to add them manually'
                )}
              </span>
            }
          />
        )}

        {renderCandidatesFound()}

        {!!suggestedCandidates.length && (
          <>
            <Typography.Title level={4}>{t('Suggested candidates')}</Typography.Title>
            <List
              dataSource={suggestedCandidates}
              itemLayout="vertical"
              renderItem={(jobCandidate) => (
                <SearchedCandidateTile isSuggested={true} searchedCandidate={jobCandidate} />
              )}
            />
          </>
        )}

        {!!shouldDisplayViewCandidatesButton && (
          <Affix offsetBottom={20}>
            <div style={{ textAlign: 'center' }}>
              <Button type="primary" onClick={handleViewCandidatesButtonClick}>
                {t('View candidates')}
              </Button>
            </div>
          </Affix>
        )}
      </Card>

      <JobCandidateSelectModal
        token={qs.p!}
        isVisible={isVisibleCandidateModal}
        onCancel={() => {
          setSelectedCandidate(undefined);
          setIsVisibleCandidateModal(false);
        }}
        onOk={handleJobCandidateSelectModalOk}
        job={job}
        jobCandidate={selectedCandidate ? { ...selectedCandidate, id: 0, jobId: 0 } : undefined}
      />

      <SuggestCandidateAddContactModal
        token={qs.p!}
        job={job}
        isVisible={isVisibleAddContactModal}
        onCancel={() => {
          setIsVisibleAddContactModal(false);
          showThankYouModal();
        }}
        onOk={(contactId) => {
          setNewContactId(contactId);
          setIsVisibleAddContactModal(false);
          showThankYouModal();
        }}
      />
    </>
  );
};

export default Form.create<Props>()(SuggestCandidate);
