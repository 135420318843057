import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Button, List, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Contact, LinkedInCommonContact } from '../../api/contacts/model';
import UsersService from '../../api/users/UsersService';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';
import LoadingSpinner from '../Loading/LoadingSpinner';
import ContactDetailModal from '../Contacts/ContactDetailModal';

const gridParams = createBaseGridParams({
  sortField: 'lastName',
  pageSize: 6,
});

interface Props {
  isVisible: boolean;
  linkedinUrl: string;
  selectedContacts: Contact[];
  addToLinkedInContactList: (linkedinContact: Contact) => void;
  onClose: () => void;
}

const ImportLinkedInCommonContactsModal: React.FC<Props> = ({
  isVisible,
  linkedinUrl,
  addToLinkedInContactList,
  selectedContacts,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [commonLinkedInContacts, setCommonLinkedInContacts] = useState<LinkedInCommonContact[]>([]);
  const [isContactDetailModalVisible, setIsContactDetailModalVisible] = useState(false);
  const [selectedLinkedInContact, setSelectedLinkedInContact] = useState<LinkedInCommonContact>();
  const [createdContacts, setCreatedContacts] = useState<Contact[]>([]);
  const [total, setTotal] = useState(0);

  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
  });

  const handlePaginationChange = (pageNumber: number, pageSize: number | undefined) => {
    setGridState({
      ...gridState,
      page: pageNumber - 1,
      pageSize: pageSize || 24,
    });
  };

  const handleContactDetailOk = async (contact: Contact) => {
    setSelectedLinkedInContact(undefined);
    setIsContactDetailModalVisible(false);
    setCreatedContacts([...createdContacts, contact]);
    addToLinkedInContactList(contact);
    await fetchCommonContacts(true);
  };

  const handleContactDetailCancel = () => {
    setSelectedLinkedInContact(undefined);
    setCreatedContacts([]);
    setIsContactDetailModalVisible(false);
  };

  const selectedContact: Partial<Contact> = {
    firstName: selectedLinkedInContact?.firstName,
    lastName: selectedLinkedInContact?.lastName,
    linkedinUrl: selectedLinkedInContact?.linkedinUrl,
    pictureUrl: selectedLinkedInContact?.profilePicture,
  };

  const fetchCommonContacts = useCallback(
    async (force: boolean = false) => {
      if (!force && (!isVisible || commonLinkedInContacts.length > 0)) {
        return;
      }
      try {
        setIsLoading(true);
        const response = await UsersService.findLinkedInCommonContacts(linkedinUrl);
        setCommonLinkedInContacts(response);
        setTotal(response.length);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [isVisible, commonLinkedInContacts.length, linkedinUrl]
  );

  useEffect(() => {
    fetchCommonContacts();
  }, [fetchCommonContacts]);

  return (
    <>
      <Modal visible={isVisible} footer={null} onCancel={onClose}>
        {!isLoading ? (
          <>
            <h1>{t('Contacts in common from LinkedIn')}</h1>
            <List
              dataSource={commonLinkedInContacts}
              pagination={{
                current: gridState.page! + 1,
                total,
                pageSize: gridState.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['6', '12', '24', '36', '48'],
                onChange: handlePaginationChange,
                onShowSizeChange: handlePaginationChange,
                showTotal: (totalRecords, rangeRecords) =>
                  `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
              }}
              renderItem={(commonLinkedInContact) => (
                <List.Item
                  key={commonLinkedInContact.linkedinGuid}
                  extra={
                    <Button
                      disabled={
                        selectedContacts.find(
                          (contact) => contact.linkedinUrl === commonLinkedInContact.linkedinUrl
                        ) !== undefined
                      }
                      onClick={() => {
                        setSelectedLinkedInContact(commonLinkedInContact);
                        setIsContactDetailModalVisible(true);
                      }}
                    >
                      {t('Create contact')}
                    </Button>
                  }
                >
                  <List.Item.Meta
                    avatar={<Avatar src={commonLinkedInContact.profilePicture} />}
                    title={
                      <a
                        href={commonLinkedInContact.linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {commonLinkedInContact.firstName} {commonLinkedInContact.lastName}
                      </a>
                    }
                    description={commonLinkedInContact.occupation}
                  ></List.Item.Meta>
                </List.Item>
              )}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Modal>
      <ContactDetailModal
        isVisible={isContactDetailModalVisible}
        contact={selectedContact}
        isEditing={false}
        onOk={handleContactDetailOk}
        onCancel={handleContactDetailCancel}
      />
    </>
  );
};

export default ImportLinkedInCommonContactsModal;
