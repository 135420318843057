import React, { ReactNode, useEffect, useState } from 'react';
import { Input, List } from 'antd';
import { useDebounce } from 'use-debounce/lib';
import { useTranslation } from 'react-i18next';
import { Survey } from '../../api/surveys/models';
import { createBaseGridParams, getUrlQueryString, GridParams } from '../../helpers/grid-helpers';
import LoadingSpinner from '../Loading/LoadingSpinner';
import SurveysService from '../../api/surveys/SurveysService';

const gridParams = createBaseGridParams({ sortField: 'title', pageSize: 6 });

interface Props {
  renderItem: (survey: Survey) => ReactNode;
}

const SurveysList: React.FC<Props> = ({ renderItem }) => {
  const { t } = useTranslation();
  const [isLoadingSurveys, setIsLoadingSurveys] = useState(false);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [total, setTotal] = useState(0);

  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearch] = useDebounce(searchInput, 500);
  const urlParams = getUrlQueryString();
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    ...urlParams,
  });

  const handlePaginationChange = (pageNumber: number, pageSize: number | undefined) => {
    setGridState({ ...gridState, page: pageNumber - 1, pageSize: pageSize || 6 });
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingSurveys(true);
        const response = await SurveysService.find({ ...gridState, search: debouncedSearch });
        setSurveys(response.list);
        setTotal(response.count);
      } catch (error) {
      } finally {
        setIsLoadingSurveys(false);
      }
    };
    fetch();
  }, [debouncedSearch, gridState]);

  if (isLoadingSurveys) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Input.Search
        placeholder={`${t('Type to search a survey')}...`}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        allowClear
        autoFocus
      />
      <List
        dataSource={surveys}
        pagination={{
          current: gridState.page! + 1,
          total,
          pageSize: gridState.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['6', '12', '24', '36', '48'],
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          showTotal: (totalRecords, rangeRecords) =>
            `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
        }}
        renderItem={renderItem}
      />
    </>
  );
};

export default SurveysList;
