import { Card, message, Table, Tooltip } from 'antd';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { UserActivity } from '../../api/user-activity/model';
import UserActivitiesService from '../../api/user-activity/UserActivitiesService';
import { User } from '../../api/users/model';
import { formatDateTime } from '../../helpers/date-helpers';
import { createBaseGridParams, getUrlQueryString, updateGridUrl } from '../../helpers/grid-helpers';

const gridParams = createBaseGridParams({
  sortField: 'createdDate',
  sortOrder: 'descend',
});

const UserActivitiesGrid: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{ userId?: string }>();
  const urlParams = getUrlQueryString();

  const [isLoading, setIsLoading] = useState(true);
  const [userActivities, setUserActivities] = useState<UserActivity[]>([]);
  const [total, setTotal] = useState(0);
  const [gridState, setGridState] = useState({ ...gridParams, ...urlParams });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await UserActivitiesService.findGrid(gridState, match.params.userId);
        setUserActivities(response.list);
        setTotal(response.count);
        updateGridUrl(history, match.url, gridState);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [gridState, history, match.url, match.params.userId]);

  const handleTableOnChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<any>
  ) => {
    setGridState({
      ...gridState,
      sortField: sorter.columnKey || 'createdDate',
      sortOrder: sorter.order || 'descend',
      page: +pagination.current! - 1,
      pageSize: +pagination.pageSize!,
    });
  };

  return (
    <div className="container">
      <Card title={t('User Activity')}>
        <Table
          rowKey="id"
          bordered
          showHeader
          loading={isLoading}
          onChange={handleTableOnChange}
          pagination={{
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            current: gridState.page! + 1,
            pageSize: gridState.pageSize,
            total,
          }}
          columns={[
            {
              title: t('Date'),
              dataIndex: 'createdDate',
              sorter: true,
              defaultSortOrder:
                gridState.sortField === 'createdDate' ? gridState.sortOrder : undefined,
              render(createdDate: string) {
                return formatDateTime(new Date(createdDate));
              },
            },
            {
              title: t('Event'),
              dataIndex: 'event',
              align: 'center',
              render(event: string) {
                return t(event);
              },
            },
            {
              title: t('Sub Event'),
              dataIndex: 'subEvent',
              align: 'center',
              render(subEvent: string) {
                return t(subEvent);
              },
            },
            {
              title: t('Message'),
              dataIndex: 'message',
              render(message: string) {
                if (message.length > 50) {
                  return <Tooltip title={message}>{message.substr(0, 50)}...</Tooltip>;
                }
                return message;
              },
            },
            {
              title: t('User'),
              dataIndex: 'user',
              align: 'center',
              render(user?: User) {
                return user ? `${user.firstName} ${user.lastName}` : undefined;
              },
            },
          ]}
          dataSource={userActivities}
        />
      </Card>
    </div>
  );
};

export default UserActivitiesGrid;
