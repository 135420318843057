import { useState, useCallback } from 'react';

export const useSubHeader = () => {
  const [height, setHeight] = useState(0);

  const setSubHeaderHeight = useCallback((h: number) => {
    setHeight(h);
  }, []);

  return {
    subHeaderHeight: height,
    setSubHeaderHeight,
  };
};
