import { useEffect, useState } from 'react';
import { ConfigurationSmtp } from '../api/configurations/interfaces';
import { ConfigurationsService } from '../api/configurations/ConfigurationsService';
import { message } from 'antd';

export function useSmtpConfiguration(): {
  configurationSmtp?: ConfigurationSmtp;
  isLoading: boolean;
} {
  const [configurationSmtp, setConfigurationSmtp] = useState<ConfigurationSmtp | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response: ConfigurationSmtp = await ConfigurationsService.getSmtpConfiguration();
        setConfigurationSmtp(response);
      } catch (error) {
        message.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return { configurationSmtp, isLoading };
}
