import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, List, message, Modal, Popconfirm, Tooltip } from 'antd';
import { Survey, SurveyConfig } from '../../api/surveys/models';
import { useTranslation } from 'react-i18next';
import { URLs } from '../../config/enums';
import SurveysList from './SurveysList';
import { EmailTemplate } from '../../api/shared/models';
import { EmailTemplateEnum } from '../../api/shared/enums';
import { find } from 'lodash';
import SurveysService from '../../api/surveys/SurveysService';
import TextArea from 'antd/lib/input/TextArea';
import ReactQuill from 'react-quill';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { useJob } from '../../hooks/jobs';
import { useLists } from '../../hooks/contacts';

interface Props {
  jobId: string;
  isVisible: boolean;
  onCancel: () => void;
  onOk: (survey: Survey) => void;
  selectedContacts: number;
  listId: number | undefined;
  isSelectingAllContacts: boolean;
}
const SelectSurveyModal: React.FC<Props> = ({
  isVisible,
  onOk,
  onCancel,
  selectedContacts,
  jobId,
  listId,
  isSelectingAllContacts,
}) => {
  const { t } = useTranslation();

  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [surveyConfig, setSurveyConfig] = useState<SurveyConfig>();
  const { isLoadingJob, job } = useJob({ jobId: parseInt(jobId) });
  const { lists } = useLists();

  useEffect(() => {
    if (isVisible) {
      fetchConfig();
    }
  }, [isVisible]);

  const fetchConfig = async () => {
    try {
      setIsLoadingConfig(true);
      const response = await SurveysService.findConfig();
      setSurveyConfig(response);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsLoadingConfig(false);
    }
  };

  const findEmailTemplate = (lang: string, code: string): EmailTemplate => {
    return (
      (find(surveyConfig?.userEmailTemplates, { code, lang }) as EmailTemplate) ||
      (find(surveyConfig?.defaultEmailTemplates, { code, lang }) as EmailTemplate)
    );
  };

  return (
    <Modal
      visible={isVisible}
      title={t('Select Survey')}
      destroyOnClose
      footer={[
        <Button key="close-button" onClick={onCancel}>
          {t('Cancel')}
        </Button>,
      ]}
      onCancel={onCancel}
    >
      <SurveysList
        renderItem={(survey) => (
          <List.Item
            key={survey.id}
            actions={[
              <a
                href={URLs.SURVEYS_EDIT.replace(':id', survey.id.toString())}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Details')}
              </a>,
            ]}
          >
            <Popconfirm
              title={t('Send surveyName to selected contacts?', {
                surveyName: survey.title,
                interpolation: { escapeValue: false },
              })}
              placement="left"
              onConfirm={() => onOk(survey)}
            >
              <Button type="link">{survey.title}</Button>
            </Popconfirm>
          </List.Item>
        )}
      />
      <Alert
        style={{ marginTop: '1rem', textAlign: 'center' }}
        message={
          isSelectingAllContacts
            ? (listId &&
                t('The survey will be sent to the contacts in the list', {
                  listName: find(lists, { id: listId })?.name,
                })) ||
              t('The survey will be sent to all your contacts')
            : t('Selected contacts', { contactsCount: selectedContacts })
        }
      />

      <Alert
        style={{ marginTop: '1rem' }}
        message={
          isLoadingConfig || isLoadingJob ? (
            <LoadingSpinner />
          ) : (
            <>
              {' '}
              <span
                style={{ marginTop: '1rem', display: 'flex' }}
                className="job-email-collapse__subject"
              >
                {t('Survey Email')}
                <Tooltip title={t('Reload Email and Message')}>
                  <Button icon="reload" style={{ marginLeft: 'auto' }} onClick={fetchConfig} />
                </Tooltip>
              </span>
              <Form.Item label={t('Subject')} style={{ marginBottom: '0.5rem' }}>
                <Input
                  readOnly
                  value={
                    findEmailTemplate(job?.language!, EmailTemplateEnum.SEND_SURVEY)?.subject || ''
                  }
                />
              </Form.Item>
              <Form.Item label={t('Email')}>
                <ReactQuill
                  className="jobs-new__editor"
                  readOnly
                  value={
                    findEmailTemplate(job?.language!, EmailTemplateEnum.SEND_SURVEY)?.body || ''
                  }
                />
              </Form.Item>
              <span style={{ marginBottom: '1rem' }} className="job-email-collapse__subject">
                {t('LinkedIn and Facebook message')}
              </span>
              <Form.Item>
                <TextArea
                  rows={7}
                  readOnly
                  value={
                    findEmailTemplate(job?.language!, EmailTemplateEnum.SEND_SURVEY)?.cleanBody ||
                    ''
                  }
                ></TextArea>
              </Form.Item>
            </>
          )
        }
      />
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Button type="primary" target="_blank" href={`${URLs.SURVEYS_CUSTOMIZE_MESSAGES}`}>
          {t('Update messages')}
        </Button>
      </div>
    </Modal>
  );
};

export default SelectSurveyModal;
