import { API } from "../../../config/api";
import { GridParams, transformGridParams } from "../../../helpers/grid-helpers";
import { FetchedList } from "../../shared/models";
import FetchService from "../../shared/services/FetchService";
import { ContactImport, ContactImportLog } from "../model";

class ContactImportsService {

    static async fetchContactImports(gridFetchParams: GridParams): Promise<FetchedList<ContactImport>>{
        const gridParams = transformGridParams(gridFetchParams);
        const response = FetchService.get<FetchedList<ContactImport>>({
            url: API.CONTACTS_IMPORTS,
            gridParams
        });
        return response;
    }

    static async fetchContactImportLogs(contactImportId: number, gridFetchparams: GridParams): Promise<ContactImportLog[]>{
        const gridParams = transformGridParams(gridFetchparams);
        const response = FetchService.get<ContactImportLog[]>({
            url: `${API.CONTACTS_IMPORT_LOGS.replace(":id", contactImportId.toString())}`,
            gridParams
        });
        return response;
    }
}

export default ContactImportsService;