import { API } from '../../config/api';
import { API_URL } from '../../config/general-config';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import { ApiWarning, FetchedList, IServerError } from '../shared/models';
import FetchService from '../shared/services/FetchService';
import { ContactList } from './lists/model';
import {
  Contact,
  ContactAddType,
  ContactUpdateType,
  Duplicate,
  ImportContactsCheckModel,
  SuggestedContactIgnoreType,
  SuggestedContactMergeType,
  ContactBulkDeleteModel,
} from './model';

class ContactsService {
  static async findAll(): Promise<Contact[]> {
    const response = await FetchService.get<Contact[]>({
      url: API.CONTACTS,
    });
    return response;
  }

  static async findGrid(params: GridParams): Promise<FetchedList<Contact>> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<FetchedList<Contact>>({
      url: API.CONTACTS,
      gridParams,
    });
    return response;
  }

  static async find(params: GridParams): Promise<FetchedList<Contact>> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<FetchedList<Contact>>({
      url: API.CONTACTS,
      gridParams,
    });
    return response;
  }

  static async findDuplicates(): Promise<Duplicate[]> {
    const response = await FetchService.get<Duplicate[]>({
      url: API.CONTACTS_DUPLICATES,
    });
    return response;
  }

  static async findOne(contactId: number): Promise<Contact> {
    const response = await FetchService.get<Contact>({
      url: `${API.CONTACTS}/${contactId}`,
    });
    return response;
  }

  static async findLists(contactId: number): Promise<ContactList[]> {
    const response = await FetchService.get<ContactList[]>({
      url: API.CONTACTS_CONTACT_LISTS.replace(':id', contactId.toString()),
    });
    return response;
  }

  static async add(model: ContactAddType): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.CONTACTS,
      body: model,
    });
    return response;
  }

  static async update(model: ContactUpdateType): Promise<number> {
    const response = await FetchService.put<number>({
      url: `${API.CONTACTS}/${model.id}`,
      body: model,
    });
    return response;
  }

  static async merge(model: SuggestedContactMergeType): Promise<void> {
    const response = await FetchService.post<void>({
      url: API.CONTACTS_DUPLICATES_MERGE,
      body: model,
    });
    return response;
  }

  static async dismiss(model: SuggestedContactIgnoreType) {
    await FetchService.put<void>({
      url: API.CONTACTS_DUPLICATES_DISMISS,
      body: model,
    });
  }

  static async dismissAll(duplicateId: number) {
    await FetchService.put<void>({
      url: API.CONTACTS_DUPLICATES_DISMISS_ALL,
      body: {
        duplicateId,
      },
    });
  }

  static async uploadImportCSV(formData: FormData): Promise<ApiWarning[]> {
    const headers = FetchService.getHeaders();
    headers.delete('Content-Type');

    const response = await fetch(`${API_URL}${API.CONTACTS_UPLOAD_IMPORT_FILE}`, {
      method: 'POST',
      body: formData,
      headers,
    });

    return FetchService.processResponse(response);
  }

  static async importCSVCheckColumns(data: FormData) {
    const headers = FetchService.getHeaders();
    headers.delete('Content-Type');

    const response = await fetch(`${API_URL}${API.CONTACTS_IMPORT_CHECK_COLUMNS}`, {
      method: 'POST',
      body: data,
      headers,
    });

    return FetchService.processResponse<ImportContactsCheckModel>(response);
  }

  static async delete(contactId: number): Promise<IServerError[]> {
    const response = await FetchService.delete<IServerError[]>({
      url: API.CONTACTS_DELETE.replace(':id', contactId.toString()),
    });
    return response;
  }

  static async deleteBulk(model: ContactBulkDeleteModel): Promise<IServerError[]> {
    const response = await FetchService.delete<IServerError[]>({
      url: API.CONTACTS_DELETE_BULK,
      body: model,
    });
    return response;
  }
}

export default ContactsService;
