import { Factory } from 'fishery';
import faker from 'faker';
import { MatchedCustomField } from './model';

export const MatchedCustomFieldFactory = Factory.define<MatchedCustomField>(() => {
    return {
        id: faker.random.number(),
        value: faker.random.word(),
        customFieldId: faker.random.number(),
        contactId: faker.random.number(),
        customField: {
            id: faker.random.number(),
            name: faker.random.word()
        }
     
    };
  });