import { Card, Col, Icon, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { RolesEnum, ResourcesEnum, PatentsEnum } from '../../api/roles/enums';
import { ReactComponent as IconContacts } from '../../assets/icon-contacts.svg';
import { ReactComponent as IconSurveys } from '../../assets/icon-surveys.svg';
import { ReactComponent as IconJobs } from '../../assets/icon-jobs.svg';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';
import { hasPermissions } from '../../helpers/permissions-helpers';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAppContext();

  if (!user || !user.role) {
    return <Redirect to={URLs.LOGIN} />;
  }

  if (user.role.code !== RolesEnum.RECRUITER && user.role.code !== RolesEnum.ADMIN) {
    return null;
  }

  return (
    <>
      <div className="container" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <p style={{ fontSize: '2.5rem', marginBottom: '3rem' }}>{t('Welcome to Talentlinkr')}</p>

        <Row gutter={[16, 16]} style={{ marginBottom: '3rem' }}>
          {hasPermissions(ResourcesEnum.JOBS, PatentsEnum.VIEW) && (
            <Col xs={12} sm={6}>
              <Card className="dashboard-tile" onClick={() => history.push(URLs.JOBS)}>
                <Icon className="dashboard-tile__icon" component={IconJobs} />
                <span className="dashboard-tile__title">{t('Jobs')}</span>
              </Card>
            </Col>
          )}
          {hasPermissions(ResourcesEnum.CONTACTS, PatentsEnum.VIEW) && (
            <Col xs={12} sm={6}>
              <Card className="dashboard-tile" onClick={() => history.push(URLs.CONTACTS)}>
                <Icon className="dashboard-tile__icon" component={IconContacts} />
                <span className="dashboard-tile__title">{t('Contacts')}</span>
              </Card>
            </Col>
          )}
          {hasPermissions(ResourcesEnum.EMAILS, PatentsEnum.VIEW) && (
            <Col xs={12} sm={6}>
              <Card className="dashboard-tile" onClick={() => history.push(URLs.EMAILS)}>
                <Icon className="dashboard-tile__icon" type="mail" />
                <span className="dashboard-tile__title">{t('Messages')}</span>
              </Card>
            </Col>
          )}
          {hasPermissions(ResourcesEnum.SURVEYS, PatentsEnum.VIEW) && (
            <Col xs={12} sm={6}>
              <Card
                className="dashboard-tile"
                onClick={() => history.push(URLs.SURVEYS)}
                data-testid="surveys"
              >
                <Icon className="dashboard-tile__icon" component={IconSurveys} />
                <span className="dashboard-tile__title">{t('Surveys')}</span>
              </Card>
            </Col>
          )}
          {hasPermissions(ResourcesEnum.RECRUITER_CONFIGURATIONS, PatentsEnum.VIEW) && (
            <Col xs={12} sm={6}>
              <Card
                className="dashboard-tile"
                onClick={() => history.push(URLs.RECRUITER_CONFIGURATIONS)}
              >
                <Icon className="dashboard-tile__icon" type="setting" />
                <span className="dashboard-tile__title">{t('Configurations')}</span>
              </Card>
            </Col>
          )}
          <Col xs={12} sm={6}>
            <Card className="dashboard-tile" onClick={() => history.push(URLs.PROFILE)}>
              <Icon className="dashboard-tile__icon" type="user" />
              <span className="dashboard-tile__title">{t('Profile')}</span>
            </Card>
          </Col>
          {hasPermissions(ResourcesEnum.CAMPAIGNS, PatentsEnum.VIEW) && (
          <Col xs={12} sm={6}>
            <Card
              className="dashboard-tile"
              onClick={() => history.push(URLs.CAMPAIGNS)}
              data-testid="campaigns"
            >
              <Icon className="dashboard-tile__icon" component={IconSurveys} />
              <span className="dashboard-tile__title">{t('Campaigns')}</span>
            </Card>
          </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
