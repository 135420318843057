import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store from '../state';
import App from './App';

const AppWrapper: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  );
};

export default AppWrapper;
