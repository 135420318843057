import { Tabs, PageHeader } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfigurationsSmtp from './ConfigurationsSmtp';

const ConfigurationsTabs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <PageHeader title={t('Configurations')} />
      <Tabs defaultActiveKey="smtp">
        <Tabs.TabPane tab={t('SMTP')} key="smtp">
          <ConfigurationsSmtp />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default ConfigurationsTabs;
