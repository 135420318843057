import { Card } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { useTranslation } from 'react-i18next';
import { Campaign } from '../../api/campaigns/model';
import { CampaignStatusEnum } from '../../api/campaigns/enum';

interface Props {
  campaign: Campaign;
}

const CampaignTile: React.FC<Props> = ({ campaign }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const match = useRouteMatch<{ id: string }>([URLs.CAMPAIGNS_DETAIL]);

  const handleClick = () => {
    if (campaign.campaignStatus == CampaignStatusEnum.Completed) {
      history.push(URLs.CAMPAIGNS_COMPLETED.replace(':id', campaign.id.toString()))
    } else {
      history.push(URLs.CAMPAIGNS_DETAIL.replace(':id', campaign.id.toString()));
    }
  };

  return (
    <Card
      className={classNames('tile cmpai tile__job', {
        'tile__job--active': match?.params.id === campaign.id.toString(),
        // 'tile__job--disabled': campaign.campaignStatus == CampaignStatusEnum.Archived,
      })}
      onClick={handleClick}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="tile__campaigns__title">{campaign.name}</span>
        <span
          className={classNames('tile__campaigns__status', {
            'tile__campaigns__status--draft': campaign.campaignStatus === CampaignStatusEnum.Draft,
            'tile__campaigns__status--scheduled': campaign.campaignStatus === CampaignStatusEnum.Scheduled,
            'tile__campaigns__status--started': campaign.campaignStatus === CampaignStatusEnum.Started,
            'tile__campaigns__status--archived': campaign.campaignStatus === CampaignStatusEnum.Archived,
            'tile__campaigns__status--paused': campaign.campaignStatus === CampaignStatusEnum.Paused,
            'tile__campaigns__status--stopped': campaign.campaignStatus === CampaignStatusEnum.Stopped,
            'tile__campaigns__status--completed': campaign.campaignStatus === CampaignStatusEnum.Completed,
          })}
        >
          {t(campaign.campaignStatus)}
        </span>
      </div>
    </Card>
  );
};

export default CampaignTile;
