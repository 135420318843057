import { API } from '../../config/api';
import FetchService from '../shared/services/FetchService';
import { CandidateSearchModel } from '../shared/models';
import queryString from 'query-string';
import {
  JobCandidate,
  JobCandidateAddType,
  JobCandidateComment,
  JobCandidateCommentAddType,
  JobCandidateRecommendation,
  SearchedCandidate,
  JobCandidateUpdateType,
  JobCandidateFMUpdateType,
} from './model';
import { JobCandidateStatusEnum } from './enums';
import { ContactAddType } from '../contacts/model';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';

class JobCandidateService {
  static async find(jobId: number, params?: GridParams) {
    let gridParams;
    if (params)
      gridParams = transformGridParams(params);

    const response = await FetchService.get<JobCandidate[]>({
      url: API.JOBS_CANDIDATES.replace(':id', jobId.toString()),
      gridParams
    });
    return response;
  }

  static async findOne(jobCandidateId: number): Promise<JobCandidate> {
    return FetchService.get<JobCandidate>({
      url: API.JOB_CANDIDATES_GET_ONE.replace(':id', jobCandidateId.toString()),
    });
  }

  static async findComments(jobCandidateId: number) {
    const response = await FetchService.get<JobCandidateComment[]>({
      url: API.JOB_CANDIDATES_COMMENTS.replace(':id', jobCandidateId.toString()),
    });
    return response;
  }

  static async findRecommendations(jobCandidateId: number) {
    const response = await FetchService.get<JobCandidateRecommendation[]>({
      url: API.JOB_CANDIDATES_RECOMMENDATIONS.replace(':id', jobCandidateId.toString()),
    });
    return response;
  }

  static async changeStatus(params: {
    jobId: number;
    status: JobCandidateStatusEnum;
    jobCandidateIds: number[];
  }) {
    const response = await FetchService.put({
      url: API.JOB_CANDIDATES_CHANGE_STATUS,
      body: params,
    });
    return response;
  }

  static async search(model: CandidateSearchModel) {
    const query = queryString.stringify(model);

    const response = await FetchService.get<SearchedCandidate[]>({
      url: `${API.JOB_CANDIDATES_SEARCH}?${query}`,
    });

    return response;
  }

  static async suggestMultiple(
    data: JobCandidateAddType[],
    token?: string
  ): Promise<{ jobRecommendationIds: number[]; isAnonymous: boolean }> {
    let url = API.JOB_CANDIDATES_LIST.toString();

    if (token) {
      url = `${url}?token=${token}`;
    }

    const response = await FetchService.post<{
      jobRecommendationIds: number[];
      isAnonymous: boolean;
    }>({
      url: `${url}`,
      body: data,
    });

    return response;
  }

  static async fileMakerExport(jobId : number,jobCandidates: JobCandidateFMUpdateType[]): Promise<any>{
    const response = FetchService.put<any>({
      url: API.JOB_CANDIDATES_FILEMAKER_EXPORT.replace(":jobId",jobId.toString()),
      body: jobCandidates,
    });
    return response;
  }

  static async update(model: JobCandidateUpdateType) {
    const response = await FetchService.put({
      url: API.JOB_CANDIDATES_UPDATE.replace(':id', model.id.toString()),
      body: model,
    });

    return response;
  }

  static async addComment(jobCandidateId: number, data: JobCandidateCommentAddType) {
    const response = await FetchService.post({
      url: API.JOB_CANDIDATES_COMMENTS.replace(':id', jobCandidateId.toString()),
      body: data,
    });

    return response;
  }

  static async toggleCommentHide(jobCandidateCommentId: number, hide: boolean) {
    const response = await FetchService.post({
      url: API.JOB_CANDIDATES_COMMENTS_HIDE.replace(':id', jobCandidateCommentId.toString()),
      body: {
        hideComment: hide,
      },
    });

    return response;
  }

  static async toggleRecommendation(
    jobCandidateId: number,
    jobCandidateRecommendationId: number,
    hide: boolean
  ) {
    const response = await FetchService.post({
      url: API.JOB_CANDIDATES_RECOMMENDATION_HIDE.replace(':id', jobCandidateId.toString()).replace(
        ':recommendationId',
        jobCandidateRecommendationId.toString()
      ),
      body: {
        hideRecommendation: hide,
      },
    });

    return response;
  }

  static async addContactToJob(jobId: number, model: ContactAddType): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.JOB_CANDIDATES_ADD_CONTACT_TO_JOB.replace(':jobId', jobId.toString()),
      body: model,
    });

    return response;
  }

  static async linkRecommendationsToContact(contactId: number, jobRecommendationIds: number[]) {
    const response = await FetchService.post({
      url: API.JOB_CANDIDATES_LINK_RECOMMENDATIONS_TO_CONTACT.replace(
        ':contactId',
        contactId.toString()
      ),
      body: {
        jobRecommendationIds,
      },
    });
    return response;
  }

  static async delete(jobCandidateId: number) {
    const response = await FetchService.delete({
      url: API.JOB_CANDIDATES_DELETE.replace(':id', jobCandidateId.toString()),
    });
    return response;
  }
}

export default JobCandidateService;
