import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Campaign } from '../../api/campaigns/model';

interface ICampaignsContext {
  fetchCampaigns?: () => void;
  fetchArchivedCampaigns?: () => void;

}
export const CampaignsContext = React.createContext<ICampaignsContext>({});

interface ICampaignsNewContext {
  fetchCampaign?: () => void;
  campaign?: Campaign;
  isLoadingCampaign: boolean;
  setCampaign?: React.Dispatch<React.SetStateAction<Campaign | undefined>>;
}
export const CampaignsNewContext = React.createContext<ICampaignsNewContext>({
  isLoadingCampaign: true,
});

interface ICampaignsEditContext {
  isSubmittingForm: boolean;
  form?: WrappedFormUtils;
  campaigns?: Campaign;
}
export const CampaignsEditContext = React.createContext<ICampaignsEditContext>({
  isSubmittingForm: false,
});
