import { Avatar, Button, Card, List } from 'antd';
import { formatDistance } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../api/jobs/model';
import { SurveyInstance } from '../../api/surveys/models';
import { getQuestionsLabel } from '../../helpers/string-helpers';
import SurveyInstanceDetailModal from '../Surveys/SurveyInstanceDetailModal';
import SurveyInstancesList from '../Surveys/SurveyInstancesList';

interface Props {
  job: Job;
}

const JobDetailSurveysListTab: React.FC<Props> = ({ job }) => {
  const { t } = useTranslation();
  const [selectedSurveyInstance, setSelectedSurveyInstance] = useState<SurveyInstance>();
  const [isSurveyInstanceDetailModalVisible, setIsSurveyInstanceDetailModalVisible] = useState(
    false
  );

  const handleSurveyInstancesListItemClick = (surveyInstance: SurveyInstance) => {
    setSelectedSurveyInstance(surveyInstance);
    setIsSurveyInstanceDetailModalVisible(true);
  };
  const handleSurveyInstanceDetailModalCancel = () => {
    setIsSurveyInstanceDetailModalVisible(false);
    setSelectedSurveyInstance(undefined);
  };

  return (
    <Card className="job-offer__surveys">
      <h1>{t('Surveys list')}</h1>
      <SurveyInstancesList
        jobId={job.id}
        completedOnly={true}
        renderItem={(surveyInstance) => (
          <List.Item
            key={surveyInstance.id}
            extra={
              <Button
                type="link"
                onClick={() => handleSurveyInstancesListItemClick(surveyInstance)}
              >
                {t('Details')}
              </Button>
            }
          >
            <List.Item.Meta
              avatar={<Avatar icon="user" src={surveyInstance.contactModel.pictureUrl} />}
              title={`${t('Survey for jobCandidateFullName', {
                jobCandidateFullName: `${surveyInstance.jobCandidateModel.firstName} ${surveyInstance.jobCandidateModel.lastName}`,
                interpolation: { escapeValue: false },
              })} (${surveyInstance.surveyInstanceQuestionModels.length} ${getQuestionsLabel(
                surveyInstance,
                t
              )})`}
              description={`${t('Answered by contactFullName', {
                contactFullName: `${surveyInstance.contactModel.firstName} ${surveyInstance.contactModel.lastName}`,
              })} (${formatDistance(new Date(surveyInstance.completedDate!), new Date(), {
                addSuffix: true,
              })})`}
            ></List.Item.Meta>
          </List.Item>
        )}
      />

      <SurveyInstanceDetailModal
        isVisible={isSurveyInstanceDetailModalVisible}
        surveyInstance={selectedSurveyInstance}
        onCancel={handleSurveyInstanceDetailModalCancel}
      />
    </Card>
  );
};

export default JobDetailSurveysListTab;
