import React from 'react';
import { Switch } from 'react-router-dom';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import JobCustomMessageTemplateDetail from '../../components/JobCustomMessageTemplates/JobCustomMessageTemplateDetail';
import JobCustomMessageTemplatesGrid from '../../components/JobCustomMessageTemplates/JobCustomMessageTemplatesGrid';
import { URLs } from '../../config/enums';

const JobCustomMessageTemplatesPage: React.FC = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={URLs.JOB_CUSTOM_MESSAGE_TEMPLATES}
        resource={ResourcesEnum.JOB_MESSAGE_TEMPLATE}
        patent={PatentsEnum.VIEW}
      >
        <JobCustomMessageTemplatesGrid />
      </AuthenticatedRoute>

      <AuthenticatedRoute
        exact
        path={URLs.JOB_CUSTOM_MESSAGE_TEMPLATES_NEW}
        resource={ResourcesEnum.JOB_MESSAGE_TEMPLATE}
        patent={PatentsEnum.ADD}
      >
        <JobCustomMessageTemplateDetail />
      </AuthenticatedRoute>

      <AuthenticatedRoute
        exact
        path={URLs.JOB_CUSTOM_MESSAGE_TEMPLATES_DETAIL}
        resource={ResourcesEnum.JOB_MESSAGE_TEMPLATE}
        patent={PatentsEnum.EDIT}
      >
        <JobCustomMessageTemplateDetail />
      </AuthenticatedRoute>
    </Switch>
  );
};

export default JobCustomMessageTemplatesPage;
