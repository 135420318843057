import { Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { validateAntForm } from '../../helpers/ant-form';

interface FormValues {
  name: string;
}

interface Props extends FormComponentProps<FormValues> {
  isVisible: boolean;
  onOk: (clientCompanyName: string) => Promise<void>;
  isSubmitting: boolean;
  onCancel: () => void;
}

const AddClientCompanyModal: React.FC<Props> = ({
  form,
  isVisible,
  onCancel,
  onOk,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const values = await validateAntForm(form);
    const { name } = values;
    await onOk(name);
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title={t('Add Client Company')}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={handleSubmit}
      confirmLoading={isSubmitting}
    >
      <Form layout="vertical">
        <Form.Item>
          {form.getFieldDecorator('name', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input autoFocus onPressEnter={handleSubmit} placeholder={t('Name')} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<Props>()(AddClientCompanyModal);
