import React from 'react';
import SecurityLayout from '../../layout/SecurityLayout';
import ConfirmEmail from '../../components/ConfirmEmail/ConfirmEmail';

const ConfirmEmailPage: React.FC = () => {
  return (
    <SecurityLayout>
      <ConfirmEmail />
    </SecurityLayout>
  );
};

export default ConfirmEmailPage;
