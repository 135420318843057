import { format as dateFnsFormat } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import i18n from 'i18next';
import { LanguagesEnum } from '../api/shared/enums';
import AuthService from '../api/shared/services/AuthService';

const dateFnsLocales = {
  [LanguagesEnum.EN]: enUS,
  [LanguagesEnum.FR]: fr,
};

export const formatDateTime = (
  date: Date | number,
  format?: string,
  options: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  } = {
    locale: enUS,
  }
): string => {
  const user = AuthService.getCurrentUser();
  if (user) {
    options.locale = dateFnsLocales[user.language as LanguagesEnum];
  }

  if (!format) {
    if (user?.language === LanguagesEnum.EN) {
      format = `MM/dd/yyyy '${i18n.t('at')}' HH:mm`;
    } else {
      format = `dd/MM/yyyy '${i18n.t('at')}' HH:mm`;
    }
  }

  const formatted = dateFnsFormat(date, format, options);
  return formatted;
};
