import { Avatar, Checkbox, Col, List, message, Modal, Row, Tag, Typography } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobCandidateStatusEnum } from '../../api/job-candidates/enums';
import JobCandidateService from '../../api/job-candidates/JobCandidatesService';
import {
  JobCandidate,
  JobCandidateFM,
  JobCandidateFMUpdateType,
} from '../../api/job-candidates/model';
import { Job } from '../../api/jobs/model';
import { validateAntForm } from '../../helpers/ant-form';

interface FormValues {
  jobCandidatesFm: JobCandidateFM[];
}

const formItemLayout = {
  labelCol: { span: 19 },
  wrapperCol: { span: 4 },
};

const formFirstItemLayout = {
  labelCol: { span: 15 },
  wrapperCol: { span: 4 },
};

interface Props extends FormComponentProps<FormValues> {
  isVisible: boolean;
  isLoadingFilemakerCandidates: boolean;
  onCancel: () => void;
  onSuccessExport: () => void;
  selectedCandidates: JobCandidate[];
  job: Job;
}

const JobCandidateFileMakerSelModal: React.FC<Props> = ({
  form,
  isVisible,
  onCancel,
  onSuccessExport,
  isLoadingFilemakerCandidates,
  selectedCandidates,
  job,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const values = await validateAntForm(form);

      const candidatesToSend: JobCandidateFMUpdateType[] = [];

      selectedCandidates.map((candidate) => {
        let entity: JobCandidateFMUpdateType = {
          id: candidate.jobCandidateFM?.id || 0,
          fileMakerRecordId: candidate.jobCandidateFM?.fileMakerRecordId || 0,
          isSel1: values.jobCandidatesFm[candidate.id].isSel1,
          isSel2: values.jobCandidatesFm[candidate.id].isSel2,
          isSel3: values.jobCandidatesFm[candidate.id].isSel3,
          isSel4: values.jobCandidatesFm[candidate.id].isSel4,
          isEliminated: values.jobCandidatesFm[candidate.id].isEliminated,
          isToPresent: values.jobCandidatesFm[candidate.id].isToPresent,
          jobCandidateId: candidate.id,
        };
        return candidatesToSend.push(entity);
      });

      await JobCandidateService.fileMakerExport(job.id, candidatesToSend);
      message.success(t('Job candidates successfully exported'));
      onSuccessExport();
    } catch (error) {
      if (error.message) {
        message.error(t(error.message), 7);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      visible={isVisible}
      title={t('Set selection status')}
      onCancel={onCancel}
      onOk={onSubmit}
      width={850}
      maskClosable={false}
      okButtonProps={{ disabled: isLoadingFilemakerCandidates || isSubmitting }}
      cancelButtonProps={{ disabled: isLoadingFilemakerCandidates || isSubmitting }}
    >
      {/* New Candidates */}
      <Row gutter={16} style={{ marginBottom: '1rem' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          {' '}
          <Tag className="job-offer__candidates-tag job-offer__candidates-tag--new">{t(`New`)}</Tag>
        </Col>
      </Row>

      <List
        grid={{ gutter: 12, column: 2 }}
        loading={isLoadingFilemakerCandidates || isSubmitting}
        dataSource={selectedCandidates.filter(
          (candidate) => candidate.status === JobCandidateStatusEnum.NEW
        )}
        renderItem={(candidate, index) => (
          <List.Item className={classNames('job-offer__candidates-export-list-item')}>
            <List.Item.Meta
              avatar={
                candidate.pictureUrl || candidate.publicProfilePictureUrl ? (
                  <Avatar
                    style={{ backgroundColor: '#b1b1b1' }}
                    src={candidate.pictureUrl || candidate.publicProfilePictureUrl}
                  />
                ) : (
                  <Avatar>
                    {candidate.firstName[0]} {candidate.lastName[0]}
                  </Avatar>
                )
              }
              title={
                <Typography.Text className="job-offer__candidates-list-item-title">
                  <span>
                    {candidate.firstName} {candidate.lastName}
                  </span>
                </Typography.Text>
              }
              description={
                <Form>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 1" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel1`, {
                          initialValue: candidate.jobCandidateFM?.isSel1 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 2" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel2`, {
                          initialValue: candidate.jobCandidateFM?.isSel2 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 3" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel3`, {
                          initialValue: candidate.jobCandidateFM?.isSel3 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 4" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel4`, {
                          initialValue: candidate.jobCandidateFM?.isSel4 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('Eliminated')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isEliminated`, {
                          initialValue: candidate.jobCandidateFM?.isEliminated || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('To present')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isToPresent`, {
                          initialValue: candidate.jobCandidateFM?.isToPresent || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              }
            />
          </List.Item>
        )}
      />

      {/* Rejected Candidates */}
      <Row gutter={16} style={{ marginBottom: '1rem' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          {' '}
          <Tag className="job-offer__candidates-tag job-offer__candidates-tag--rejected">
            {t(`Rejected`)}
          </Tag>
        </Col>
      </Row>

      <List
        grid={{ gutter: 12, column: 2 }}
        loading={isLoadingFilemakerCandidates || isSubmitting}
        dataSource={selectedCandidates.filter(
          (candidate) =>
            candidate.status === JobCandidateStatusEnum.REJECTED_CLIENT ||
            candidate.status === JobCandidateStatusEnum.REJECTED_RECRUITER
        )}
        renderItem={(candidate, index) => (
          <List.Item className={classNames('job-offer__candidates-export-list-item')}>
            <List.Item.Meta
              avatar={
                candidate.pictureUrl || candidate.publicProfilePictureUrl ? (
                  <Avatar
                    style={{ backgroundColor: '#b1b1b1' }}
                    src={candidate.pictureUrl || candidate.publicProfilePictureUrl}
                  />
                ) : (
                  <Avatar>
                    {candidate.firstName[0]} {candidate.lastName[0]}
                  </Avatar>
                )
              }
              title={
                <Typography.Text className="job-offer__candidates-list-item-title">
                  <span>
                    {candidate.firstName} {candidate.lastName}
                  </span>
                </Typography.Text>
              }
              description={
                <Form>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 1" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel1`, {
                          initialValue: candidate.jobCandidateFM?.isSel1 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 2" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel2`, {
                          initialValue: candidate.jobCandidateFM?.isSel2 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 3" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel3`, {
                          initialValue: candidate.jobCandidateFM?.isSel3 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 4" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel4`, {
                          initialValue: candidate.jobCandidateFM?.isSel4 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('Eliminated')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isEliminated`, {
                          initialValue: candidate.jobCandidateFM?.isEliminated || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('To present')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isToPresent`, {
                          initialValue: candidate.jobCandidateFM?.isToPresent || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              }
            />
          </List.Item>
        )}
      />

      {/* Sent to Client Candidates */}
      <Row gutter={16} style={{ marginBottom: '1rem' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          {' '}
          <Tag className="job-offer__candidates-tag job-offer__candidates-tag--sent">
            {t(`Sent to client`)}
          </Tag>
        </Col>
      </Row>

      <List
        grid={{ gutter: 12, column: 2 }}
        loading={isLoadingFilemakerCandidates || isSubmitting}
        dataSource={selectedCandidates.filter(
          (candidate) => candidate.status === JobCandidateStatusEnum.SENT_CLIENT
        )}
        renderItem={(candidate, index) => (
          <List.Item className={classNames('job-offer__candidates-export-list-item')}>
            <List.Item.Meta
              avatar={
                candidate.pictureUrl || candidate.publicProfilePictureUrl ? (
                  <Avatar
                    style={{ backgroundColor: '#b1b1b1' }}
                    src={candidate.pictureUrl || candidate.publicProfilePictureUrl}
                  />
                ) : (
                  <Avatar>
                    {candidate.firstName[0]} {candidate.lastName[0]}
                  </Avatar>
                )
              }
              title={
                <Typography.Text className="job-offer__candidates-list-item-title">
                  <span>
                    {candidate.firstName} {candidate.lastName}
                  </span>
                </Typography.Text>
              }
              description={
                <Form>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 1" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel1`, {
                          initialValue: candidate.jobCandidateFM?.isSel1 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 2" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel2`, {
                          initialValue: candidate.jobCandidateFM?.isSel2 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 3" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel3`, {
                          initialValue: candidate.jobCandidateFM?.isSel3 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 4" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel4`, {
                          initialValue: candidate.jobCandidateFM?.isSel4 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('Eliminated')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isEliminated`, {
                          initialValue: candidate.jobCandidateFM?.isEliminated || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('To present')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isToPresent`, {
                          initialValue: candidate.jobCandidateFM?.isToPresent || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              }
            />
          </List.Item>
        )}
      />

      {/* Accepted Candidates */}
      <Row gutter={16} style={{ marginBottom: '1rem' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          {' '}
          <Tag className="job-offer__candidates-tag job-offer__candidates-tag--accepted">
            {t(`Accepted`)}
          </Tag>
        </Col>
      </Row>

      <List
        grid={{ gutter: 12, column: 2 }}
        loading={isLoadingFilemakerCandidates || isSubmitting}
        dataSource={selectedCandidates.filter(
          (candidate) => candidate.status === JobCandidateStatusEnum.ACCEPTED
        )}
        renderItem={(candidate, index) => (
          <List.Item className={classNames('job-offer__candidates-export-list-item')}>
            <List.Item.Meta
              avatar={
                candidate.pictureUrl || candidate.publicProfilePictureUrl ? (
                  <Avatar
                    style={{ backgroundColor: '#b1b1b1' }}
                    src={candidate.pictureUrl || candidate.publicProfilePictureUrl}
                  />
                ) : (
                  <Avatar>
                    {candidate.firstName[0]} {candidate.lastName[0]}
                  </Avatar>
                )
              }
              title={
                <Typography.Text className="job-offer__candidates-list-item-title">
                  <span>
                    {candidate.firstName} {candidate.lastName}
                  </span>
                </Typography.Text>
              }
              description={
                <Form>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 1" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel1`, {
                          initialValue: candidate.jobCandidateFM?.isSel1 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 2" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel2`, {
                          initialValue: candidate.jobCandidateFM?.isSel2 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Sel 3" {...formItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel3`, {
                          initialValue: candidate.jobCandidateFM?.isSel3 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Sel 4" {...formFirstItemLayout} style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isSel4`, {
                          initialValue: candidate.jobCandidateFM?.isSel4 || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('Eliminated')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isEliminated`, {
                          initialValue: candidate.jobCandidateFM?.isEliminated || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={t('To present')}
                        {...formItemLayout}
                        style={{ marginBottom: 0 }}
                      >
                        {form.getFieldDecorator(`jobCandidatesFm[${candidate.id}].isToPresent`, {
                          initialValue: candidate.jobCandidateFM?.isToPresent || false,
                          valuePropName: 'checked',
                        })(<Checkbox />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default Form.create<Props>()(JobCandidateFileMakerSelModal);
