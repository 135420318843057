import {
  Alert,
  Avatar,
  Button,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  List,
  Menu,
  message,
  Modal,
  Result,
  Row,
  Tag,
  Tooltip,
  Checkbox,
  InputNumber,
} from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import { find, get, isArray, reject } from 'lodash';
import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Contact } from '../../api/contacts/model';
import JobsService from '../../api/jobs/JobsService';
import { EmailTemplateEnum, ErrorsEnum, Languages, LanguagesEnum } from '../../api/shared/enums';
import { EmailTemplate } from '../../api/shared/models';
import UsersService from '../../api/users/UsersService';
import { ReactComponent as IconInfo } from '../../assets/icon-info.svg';
import { validateAntForm, validateAntFormFields } from '../../helpers/ant-form';
import { useAppContext } from '../../layout/AppContext';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import SelectContactModal from '../Contacts/SelectContactModal';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { JobsNewContext } from './JobsContext';
import { ReactQuillToolbar } from '../../helpers/react-quill-helpers';
import { useAllFields } from '../../hooks/custom-fields';
import TextArea from 'antd/lib/input/TextArea';

import { JobCustomMessageTemplatesService } from '../../api/job-custom-message-templates/JobCustomMessageTemplatesService';
import { IJobCustomMessageTemplate } from '../../api/job-custom-message-templates/models';
import JobCustomMessageTemplateModal from './JobCustomMessageTemplateModal';
import { JobCustomMessageTemplateTypes } from '../../api/job-custom-message-templates/enums';

const formItemLayout: FormItemProps = {
  labelCol: { sm: 4, md: 3 },
  wrapperCol: { sm: 20, md: 21 },
};

const formInputNumberItemLayout: FormItemProps = {
  labelCol: { sm: 5, md: 3 },
  wrapperCol: { sm: 12, md: 10 },
};

const CollapseKeys = {
  EMAIL_DELAY: 'EMAIL_DELAY',
  INVITE_RECRUITER_EMAIL: 'INVITE_RECRUITER_EMAIL',
  THANK_RECRUITER_EMAIL: 'THANK_RECRUITER_EMAIL',
  SEND_CUSTOMER_EMAIL: 'SEND_CUSTOMER_EMAIL',
  SENDER: 'SENDER',
  SUGGEST_PAGE_MESSAGES: 'SUGGEST_PAGE_MESSAGES',
};

interface Props extends FormComponentProps {
  onSubmit: () => void;
}

const JobsEditCustomizeMessages: React.FC<Props> = ({ form, onSubmit }) => {
  /**
   * Contexts
   */
  const { user } = useAppContext();
  const { t } = useTranslation();
  const { config, isLoadingConfig, isLoadingJob, job, setJob, fetchJob } = useContext(
    JobsNewContext
  );

  const { fields } = useAllFields();
  /**
   * Loaders states
   */
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingEmailDelay, setIsSubmittingEmailDelay] = useState(false);
  const [isSubmittingInviteEmail, setIsSubmittingInviteEmail] = useState(false);
  const [isSubmittingThankYouEmail, setIsSubmittingThankYouEmail] = useState(false);
  const [isSubmittingSendCustomerEmail, setIsSubmittingSendCustomerEmail] = useState(false);
  const [isLoadingSenderContact, setIsLoadingSenderContact] = useState(false);

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [isVisibleTemplateModal, setIsVisibleTemplateModal] = useState(false);

  /**
   * Editors states
   */
  const [inviteEmailEditor, setInviteEmailEditor] = useState<Quill>();
  const inviteEmailEditorRef: RefObject<ReactQuill> = useRef(null);
  const [suggestPageMessageEditor, setSuggestPageMessageEditor] = useState<Quill>();
  const suggestPageMessageEditorRef: RefObject<ReactQuill> = useRef(null);
  const [inviteEmailLang, setInviteEmailLang] = useState(
    (job?.requestJobEmailLang as LanguagesEnum) ||
      (job?.language as LanguagesEnum) ||
      LanguagesEnum.EN
  );
  const [thankYouEmailEditor, setThankYouEmailEditor] = useState<Quill>();
  const thankYouEmailEditorRef: RefObject<ReactQuill> = useRef(null);
  const [thankYouEmailLang, setThankYouEmailLang] = useState(
    (job?.thankYouJobEmailLang as LanguagesEnum) ||
      (job?.language as LanguagesEnum) ||
      LanguagesEnum.EN
  );
  const [sendCustomerEmailEditor, setSendCustomerEmailEditor] = useState<Quill>();
  const sendCustomerEmailEditorRef: RefObject<ReactQuill> = useRef(null);
  const [sendCustomerEmailLang, setSendCustomerYouEmailLang] = useState(
    (job?.sendCustomerEmailLang as LanguagesEnum) ||
      (job?.language as LanguagesEnum) ||
      LanguagesEnum.EN
  );

  /**
   * Others
   */
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[] | undefined>([]);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    CollapseKeys.INVITE_RECRUITER_EMAIL,
  ]);
  const [senderContact, setSenderContact] = useState<Contact | undefined>(
    job?.overrideSenderContact
  );
  const [isVisibleSelectContactModal, setIsVisibleSelectContactModal] = useState(false);
  const [isSubmittingCustomMessage, setIsSubmittingCustomMessage] = useState(false);

  const [templatesFetched, setTemplatesFetched] = useState<IJobCustomMessageTemplate[]>([]);
  const [selectedCustomMessage, setSelectedCustomMessage] = useState<string>('');

  useEffect(() => {
    if (!isLoadingConfig && !isLoadingJob) {
      if (inviteEmailEditorRef.current) {
        setInviteEmailEditor(inviteEmailEditorRef.current.getEditor());
      }
      if (thankYouEmailEditorRef.current) {
        setThankYouEmailEditor(thankYouEmailEditorRef.current.getEditor());
      }
      if (sendCustomerEmailEditorRef.current) {
        setSendCustomerEmailEditor(sendCustomerEmailEditorRef.current.getEditor());
      }

      if (suggestPageMessageEditorRef.current) {
        setSuggestPageMessageEditor(suggestPageMessageEditorRef.current.getEditor());
      }
      if (job?.emailTemplates.length) {
        setEmailTemplates(job.emailTemplates);
      } else if (config?.userEmailTemplates.length) {
        setEmailTemplates(config.userEmailTemplates);
      } else if (config?.defaultEmailTemplates.length) {
        setEmailTemplates(config.defaultEmailTemplates);
      }
    }
  }, [isLoadingConfig, isLoadingJob, job, config]);

  const fetchTemplates = async (type: string) => {
    try {
      setIsLoadingTemplates(true);
      const response = await JobCustomMessageTemplatesService.fetch({
        page: 0,
        pageSize: 0,
        sortField: 'createdDate',
        sortOrder: 'descend',
        filters: [{ key: 'type', value: type }],
      });
      setTemplatesFetched(response.list);
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  if (isLoadingConfig || isLoadingJob) {
    return <LoadingSpinner />;
  }

  if (!config) {
    return <Result status="error" title={t('Error fetching the config')} />;
  }

  if (!job) {
    return <NotFoundPage hideHeader />;
  }

  if (!user) {
    return null;
  }

  const setOwnerAsSender = async () => {
    try {
      setIsLoadingSenderContact(true);
      await JobsService.updateSenderContact(job.id);
      message.success(t('Success!'));
      if (fetchJob) {
        fetchJob();
      }
    } catch (error) {
      if (error.message) {
        message.error(t(error.message));
      }
    } finally {
      setIsLoadingSenderContact(false);
    }
  };

  const changeSenderContact = async (contact: Contact) => {
    try {
      setIsLoadingSenderContact(true);
      const response = await JobsService.updateSenderContact(job.id, contact.id);
      setSenderContact(response);
      setIsLoadingSenderContact(false);
      setIsVisibleSelectContactModal(false);
      message.success(t('Contact successfully associated'));
    } catch (error) {
      if (error.message === ErrorsEnum.EMAIL_NEEDS_VALIDATION) {
        setIsLoadingSenderContact(false);
        promptSendValidationSenderEmail(contact);
      } else if (error.message) {
        setIsLoadingSenderContact(false);
        message.error(t(error.message));
      }
    }
  };

  const promptSendValidationSenderEmail = (contact: Contact) => {
    Modal.info({
      title: t('Validate email address'),
      content: (
        <>
          <p style={{ textAlign: 'center' }}>
            {t(
              'For changing the sender contact email we have to send an email to the address of the contact to validate it.'
            )}
          </p>
        </>
      ),
      async onOk() {
        try {
          await UsersService.requestEmailValidation(contact.email);
          setSenderContact(contact);
          message.success('An email was sent to validate it.');
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        } finally {
          setIsVisibleSelectContactModal(false);
        }
      },
      okText: t('Send validation email'),
      onCancel() {
        setIsVisibleSelectContactModal(false);
      },
    });
  };

  const getEmailTemplateDefault = (
    code: EmailTemplateEnum,
    lang: LanguagesEnum
  ): EmailTemplate | undefined => {
    const defaultEmail = find(config.defaultEmailTemplates, {
      code,
      lang,
    });
    return defaultEmail;
  };

  const getEmailTemplate = (
    code: EmailTemplateEnum,
    lang: LanguagesEnum
  ): EmailTemplate | undefined => {
    let email: EmailTemplate | undefined;

    /**
     * First we try to use the current state of email templates
     * because we update this state after going to next step
     */
    email = find(emailTemplates, { code, lang });

    /**
     * We try to find the email template from the array
     * of the job email templates
     */
    if (!email) {
      email = find(job.emailTemplates, { code, lang });
    }

    /**
     * If we could not find then we try to find the template from the users
     * configuration
     */
    if (!email) {
      email = find(config.userEmailTemplates, { code, lang });
    }

    /**
     * Lastly if there is no template for the job nor the user,
     * we use the default template for the platform
     */
    if (!email) {
      email = find(config.defaultEmailTemplates, { code, lang });
    }

    return email;
  };

  const handleAddField = (
    field: string,
    editor: Quill,
    emailTemplateCode: EmailTemplateEnum | undefined
  ) => {
    const cursorIndex = editor.getSelection(true)?.index;
    if (field === '[job-link]') {
      if (emailTemplateCode === EmailTemplateEnum.SEND_CUSTOMER) {
        editor.insertText(cursorIndex, t('Click here to view candidates'), 'link', field);
      } else {
        editor.insertText(cursorIndex, t('Click here to give a recommendation'), 'link', field);
      }
    } else {
      editor.insertText(cursorIndex, field);
    }
  };

  const getEmailTemplatesToUpdate = () => {
    const inviteEmailTemplate = createEmailTemplate(
      EmailTemplateEnum.INVITE_RECRUITER,
      'inviteEmail',
      inviteEmailLang
    );
    const thankYouEmailTemplate = createEmailTemplate(
      EmailTemplateEnum.THANK_RECRUITER,
      'thankYouEmail',
      thankYouEmailLang
    );
    const sendCustomerEmailTemplate = createEmailTemplate(
      EmailTemplateEnum.SEND_CUSTOMER,
      'sendCustomerEmail',
      sendCustomerEmailLang
    );
    const templatesToUpdate = [
      inviteEmailTemplate,
      thankYouEmailTemplate,
      sendCustomerEmailTemplate,
    ];

    return templatesToUpdate;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const collapseKeysToOpen = [];

    try {
      e.preventDefault();
      await validateAntForm(form);

      const inviteEmail = getEmailTemplate(EmailTemplateEnum.INVITE_RECRUITER, inviteEmailLang);
      if (!inviteEmail) {
        message.error(t('Please save the invite email template'));
        collapseKeysToOpen.push(CollapseKeys.INVITE_RECRUITER_EMAIL);
      }

      const thankYouEmail = getEmailTemplate(EmailTemplateEnum.THANK_RECRUITER, thankYouEmailLang);
      if (!thankYouEmail) {
        message.error(t('Please save the thank you email template'));
        collapseKeysToOpen.push(CollapseKeys.THANK_RECRUITER_EMAIL);
      }

      const sendCustomerEmail = getEmailTemplate(
        EmailTemplateEnum.SEND_CUSTOMER,
        sendCustomerEmailLang
      );
      if (!sendCustomerEmail) {
        message.error(t('Please save the send customer email template'));
        collapseKeysToOpen.push(CollapseKeys.SEND_CUSTOMER_EMAIL);
      }

      if (collapseKeysToOpen.length) {
        setCollapseActiveKeys(collapseKeysToOpen);
        return;
      }

      setIsSubmitting(true);
      const templatesToUpdate = getEmailTemplatesToUpdate();

      await JobsService.updateEmailTemplates(job.id, templatesToUpdate);
      setEmailTemplates(templatesToUpdate);
      setIsSubmitting(false);
      onSubmit();
      if (fetchJob) {
        fetchJob();
      }
    } catch (error) {
      setIsSubmitting(false);
      const inviteEmailErrors = get(error, 'inviteEmail.subject.errors');
      if (inviteEmailErrors && inviteEmailErrors.length) {
        collapseKeysToOpen.push(CollapseKeys.INVITE_RECRUITER_EMAIL);
      }
      const thankYouEmailErrors = get(error, 'thankYouEmail.subject.errors');
      if (thankYouEmailErrors && thankYouEmailErrors.length) {
        collapseKeysToOpen.push(CollapseKeys.THANK_RECRUITER_EMAIL);
      }
      const sendCustomerEmailErrors = get(error, 'sendCustomerEmail.subject.errors');
      if (sendCustomerEmailErrors && sendCustomerEmailErrors.length) {
        collapseKeysToOpen.push(CollapseKeys.SEND_CUSTOMER_EMAIL);
      }

      if (collapseKeysToOpen.length) {
        setCollapseActiveKeys(collapseKeysToOpen);
      }

      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const createEmailTemplate = (templateCode: string, field: string, lang: LanguagesEnum) => {
    let body: string = form.getFieldValue(`${field}.body`);
    let message: string =
      form.getFieldValue(`${field}.message`) ||
      getEmailTemplateDefault(templateCode as EmailTemplateEnum, lang)?.message;

    const template: EmailTemplate = {
      subject: form.getFieldValue(`${field}.subject`),
      body,
      code: templateCode,
      lang,
      message,
    };
    return template;
  };

  const saveEmail = async (
    templateCode: string,
    field: string,
    isSubmitting: (value: boolean) => void,
    lang: LanguagesEnum
  ) => {
    try {
      await validateAntFormFields(form, [`${field}.subject`]);
      isSubmitting(true);
      const template = createEmailTemplate(templateCode, field, lang);
      await JobsService.updateEmailTemplates(job.id, [template]);
      message.success(t('Email successfully saved'));
      setEmailTemplates([
        ...reject(emailTemplates, {
          code: templateCode,
          lang,
        }),
        template,
      ]);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      isSubmitting(false);
    }
  };

  const sendTestEmail = async () => {
    try {
      setIsSendingTestEmail(true);

      const templatesToUpdate = getEmailTemplatesToUpdate();
      await JobsService.updateEmailTemplates(job.id, templatesToUpdate);
      setEmailTemplates(templatesToUpdate);

      await JobsService.sendTestEmail(job.id);
      message.success(`${t('Emails sent')}!`);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSendingTestEmail(false);
    }
  };

  const updateSendEmailDelay = async () => {
    try {
      setIsSubmittingEmailDelay(true);

      const sendEmailDelay: number | undefined = form.getFieldValue('emailSendDelay');
      await JobsService.updateSendEmailDelay(job.id, sendEmailDelay);

      message.success(t('Delay updated successfully'));
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmittingEmailDelay(false);
    }
  };

  const updateSuggestPageMessage = async () => {
    try {
      setIsSubmittingCustomMessage(true);
      const suggestPageMessage: string = form.getFieldValue('suggestPageMessage');
      const hideSuggestMessage: boolean = form.getFieldValue('hideSuggestMessage');
      await JobsService.updateSuggestPageMessage(job.id, suggestPageMessage, hideSuggestMessage);
      message.success(`${t('Message Updated!')}!`);

      if (setJob) {
        setJob({ ...job, suggestPageMessage });
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmittingCustomMessage(false);
    }
  };

  const setTemplateMessageInField = (templateId: number, selectedCustomMessage: string) => {
    const selectedTemplate = templatesFetched.find((i) => i.id === templateId);

    if (selectedTemplate) {
      switch (selectedCustomMessage) {
        case EmailTemplateEnum.INVITE_RECRUITER:
          form.setFieldsValue({
            'inviteEmail.subject': selectedTemplate.subject,
            'inviteEmail.body': selectedTemplate.body,
          });
          break;
        case EmailTemplateEnum.INVITE_RECRUITER_MESSAGE:
          form.setFieldsValue({ 'inviteEmail.message': selectedTemplate.body });
          break;
        case EmailTemplateEnum.THANK_RECRUITER:
          form.setFieldsValue({
            'thankYouEmail.subject': selectedTemplate.subject,
            'thankYouEmail.body': selectedTemplate.body,
          });
          break;
        case EmailTemplateEnum.SEND_CUSTOMER:
          form.setFieldsValue({
            'sendCustomerEmail.subject': selectedTemplate.subject,
            'sendCustomerEmail.body': selectedTemplate.body,
          });
          break;
        case EmailTemplateEnum.WELCOME_MESSAGE:
          form.setFieldsValue({ suggestPageMessage: selectedTemplate.body });
          break;
      }
      setIsVisibleTemplateModal(false);
      setSelectedCustomMessage('');
    }
  };

  const inviteEmail = getEmailTemplate(EmailTemplateEnum.INVITE_RECRUITER, inviteEmailLang);
  const thankYouEmail = getEmailTemplate(EmailTemplateEnum.THANK_RECRUITER, thankYouEmailLang);
  const sendCustomerEmail = getEmailTemplate(
    EmailTemplateEnum.SEND_CUSTOMER,
    sendCustomerEmailLang
  );

  form.getFieldDecorator('inviteEmail.subject');
  form.getFieldDecorator('inviteEmail.body', { initialValue: inviteEmail?.body || '' });
  form.getFieldDecorator('inviteEmail.message', {
    initialValue: inviteEmail?.message || undefined,
  });
  form.getFieldDecorator('thankYouEmail.subject');
  form.getFieldDecorator('thankYouEmail.body', { initialValue: thankYouEmail?.body || '' });
  form.getFieldDecorator('sendCustomerEmail.subject');
  form.getFieldDecorator('sendCustomerEmail.body', { initialValue: sendCustomerEmail?.body || '' });

  form.getFieldDecorator('suggestPageMessage', {
    initialValue: job.suggestPageMessage || '',
  });

  return (
    <>
      <Form onSubmit={handleSubmit} hideRequiredMark>
        <Alert
          message={t('Customize Recruiter’s Messages')}
          description={t('The platform generates editable default emails')}
          type="info"
          showIcon
          icon={<IconInfo />}
          className="job-alert"
        />

        <Collapse
          activeKey={collapseActiveKeys}
          expandIconPosition="right"
          className="job-email-collapse"
          onChange={(keys) => setCollapseActiveKeys(isArray(keys) ? keys : [keys])}
        >
          <Collapse.Panel
            key={CollapseKeys.EMAIL_DELAY}
            className="job-email-collapse__item"
            forceRender
            header={
              <>
                <span className="job-email-collapse__subject">{t('Email Delay')}:</span>
                <span className="job-email-collapse__subject-help">{t('In minutes')} </span>
                <Tooltip title={t('Leave empty if you dont want to delay messages')}>
                  <Icon type="question-circle" />
                </Tooltip>
              </>
            }
          >
            <Form.Item label={t('Delay')} {...formInputNumberItemLayout}>
              {form.getFieldDecorator('emailSendDelay', {
                initialValue: job.emailSendDelay || undefined,
              })(<InputNumber maxLength={3} min={1} formatter={(value) => `${value}`} />)}
            </Form.Item>
            <Button
              type="primary"
              disabled={isSubmittingEmailDelay || isSubmitting}
              className="jobs-new__btn"
              onClick={updateSendEmailDelay}
            >
              {t('Save')}
            </Button>
          </Collapse.Panel>

          <Collapse.Panel
            key={CollapseKeys.INVITE_RECRUITER_EMAIL}
            className="job-email-collapse__item"
            forceRender
            extra={
              <Dropdown
                overlay={
                  <Menu
                    selectedKeys={[inviteEmailLang]}
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      setInviteEmailLang(e.key as LanguagesEnum);
                      const email = getEmailTemplate(
                        EmailTemplateEnum.INVITE_RECRUITER,
                        e.key as LanguagesEnum
                      );
                      form.setFieldsValue({
                        'inviteEmail.subject': email?.subject,
                        'inviteEmail.body': email?.body || '',
                        'inviteEmail.message': email?.message || email?.cleanBody || '',
                      });
                    }}
                  >
                    {Object.keys(LanguagesEnum).map((key) => (
                      <Menu.Item key={key}>{Languages[key as LanguagesEnum]}</Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <span>
                  <Icon type="flag" onClick={(e) => e.stopPropagation()} />{' '}
                  {Languages[inviteEmailLang]}
                </span>
              </Dropdown>
            }
            header={
              <>
                <span className="job-email-collapse__subject">{t('Request Email')}:</span>
                <span className="job-email-collapse__subject-help">
                  {t('Sent to recruiter contacts')}{' '}
                </span>
                <Tooltip title={t('This text will appear on that page')}>
                  <Icon type="question-circle" />
                </Tooltip>
              </>
            }
          >
            <Form.Item label={t('Subject')} {...formItemLayout}>
              {form.getFieldDecorator('inviteEmail.subject', {
                initialValue: inviteEmail?.subject,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label={t('Email')} {...formItemLayout}>
              <ReactQuill
                ref={inviteEmailEditorRef}
                className="jobs-new__editor"
                value={form.getFieldValue('inviteEmail.body')}
                onChange={(value) => form.setFieldsValue({ 'inviteEmail.body': value })}
                modules={{
                  toolbar: ReactQuillToolbar,
                }}
              />
            </Form.Item>
            <Form.Item label={t('Fields')} {...formItemLayout}>
              {config.emailFields.map((field) => (
                <Tag
                  onClick={() =>
                    handleAddField(
                      `[${field}]`,
                      inviteEmailEditor!,
                      EmailTemplateEnum.INVITE_RECRUITER
                    )
                  }
                  style={{ cursor: 'pointer' }}
                  key={field}
                >
                  {`[${field}]`}
                </Tag>
              ))}
              {fields
                .map((f) => f.name)
                .map((field) => (
                  <Tag
                    onClick={() =>
                      handleAddField(
                        `[${field}]`,
                        inviteEmailEditor!,
                        EmailTemplateEnum.INVITE_RECRUITER
                      )
                    }
                    style={{ cursor: 'pointer' }}
                    key={field}
                  >
                    {`[${field}]`}
                  </Tag>
                ))}
            </Form.Item>

            <Row gutter={16} type="flex">
              <Col xs={24} sm={8}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  onClick={() => {
                    const defaultEmail = getEmailTemplateDefault(
                      EmailTemplateEnum.INVITE_RECRUITER,
                      inviteEmailLang
                    );
                    form.setFieldsValue({
                      'inviteEmail.subject': defaultEmail?.subject,
                      'inviteEmail.body': defaultEmail?.body || '',
                    });
                  }}
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col xs={24} sm={8}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  loading={isLoadingTemplates}
                  onClick={async () => {
                    await fetchTemplates(JobCustomMessageTemplateTypes.EMAIL);
                    setIsVisibleTemplateModal(true);
                    setSelectedCustomMessage(EmailTemplateEnum.INVITE_RECRUITER);
                  }}
                >
                  {t('Load Template')}
                </Button>
              </Col>
              <Col xs={24} sm={8}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    const email = getEmailTemplate(
                      EmailTemplateEnum.INVITE_RECRUITER,
                      inviteEmailLang
                    );
                    form.setFieldsValue({
                      'inviteEmail.subject': email?.subject,
                      'inviteEmail.body': email?.body || '',
                    });
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Col>
            </Row>
            <Divider />

            <div style={{ marginBottom: '1.5rem' }}>
              <span className="job-email-collapse__subject">
                {t('LinkedIn and Facebook message')}:
              </span>
            </div>

            <Form.Item label={t('Message')} {...formItemLayout}>
              {form.getFieldDecorator('inviteEmail.message', {
                initialValue: inviteEmail?.message || undefined,
              })(<TextArea autosize />)}
            </Form.Item>

            <Form.Item label={t('Fields')} {...formItemLayout}>
              {config.emailFields.map((field) => (
                <Tag
                  onClick={() => {
                    form.setFieldsValue({
                      'inviteEmail.message': `${form.getFieldValue(
                        'inviteEmail.message'
                      )} [${field}]`,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                  key={field}
                >
                  {`[${field}]`}
                </Tag>
              ))}
              {fields.map((field) => (
                <Tag
                  style={{ cursor: 'pointer' }}
                  key={field.name}
                  onClick={() => {
                    form.setFieldsValue({
                      'inviteEmail.message': `${form.getFieldValue('inviteEmail.message')} [${
                        field.name
                      }]`,
                    });
                  }}
                >
                  {`[${field.name}]`}
                </Tag>
              ))}
            </Form.Item>
            <Row gutter={16} type="flex">
              <Col xs={24} sm={12} md={12} lg={6}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  onClick={() => {
                    const defaultEmail = getEmailTemplateDefault(
                      EmailTemplateEnum.INVITE_RECRUITER,
                      inviteEmailLang
                    );
                    form.setFieldsValue({
                      'inviteEmail.message': defaultEmail?.cleanBody || '',
                    });
                  }}
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  loading={isLoadingTemplates}
                  onClick={async () => {
                    await fetchTemplates(JobCustomMessageTemplateTypes.SOCIAL_NETWORK_MESSAGE);
                    setIsVisibleTemplateModal(true);
                    setSelectedCustomMessage(EmailTemplateEnum.INVITE_RECRUITER_MESSAGE);
                  }}
                >
                  {t('Load Template')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    const email = getEmailTemplate(
                      EmailTemplateEnum.INVITE_RECRUITER,
                      inviteEmailLang
                    );
                    form.setFieldsValue({
                      'inviteEmail.message': email?.message || '',
                    });
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  loading={isSubmittingInviteEmail}
                  type="primary"
                  className="jobs-new__btn"
                  onClick={() =>
                    saveEmail(
                      EmailTemplateEnum.INVITE_RECRUITER,
                      'inviteEmail',
                      setIsSubmittingInviteEmail,
                      inviteEmailLang
                    )
                  }
                >
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel
            key={CollapseKeys.THANK_RECRUITER_EMAIL}
            className="job-email-collapse__item"
            forceRender
            extra={
              <Dropdown
                overlay={
                  <Menu
                    selectedKeys={[thankYouEmailLang]}
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      setThankYouEmailLang(e.key as LanguagesEnum);
                      const email = getEmailTemplate(
                        EmailTemplateEnum.THANK_RECRUITER,
                        e.key as LanguagesEnum
                      );
                      form.setFieldsValue({
                        'thankYouEmail.subject': email?.subject,
                        'thankYouEmail.body': email?.body || '',
                      });
                    }}
                  >
                    {Object.keys(LanguagesEnum).map((key) => (
                      <Menu.Item key={key}>{Languages[key as LanguagesEnum]}</Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <span>
                  <Icon type="flag" onClick={(e) => e.stopPropagation()} />{' '}
                  {Languages[thankYouEmailLang]}
                </span>
              </Dropdown>
            }
            header={
              <>
                <span className="job-email-collapse__subject">{t('Thank You Email')}:</span>
                <span className="job-email-collapse__subject-help">
                  {t('After received recommendations')}{' '}
                </span>
                <Tooltip title={t('This text will appear on that page')}>
                  <Icon type="question-circle" />
                </Tooltip>
              </>
            }
          >
            <Form.Item label={t('Subject')} {...formItemLayout}>
              {form.getFieldDecorator('thankYouEmail.subject', {
                initialValue: thankYouEmail?.subject,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label={t('Email')} {...formItemLayout}>
              <ReactQuill
                ref={thankYouEmailEditorRef}
                className="jobs-new__editor"
                value={form.getFieldValue('thankYouEmail.body')}
                onChange={(value) => form.setFieldsValue({ 'thankYouEmail.body': value })}
                modules={{
                  toolbar: ReactQuillToolbar,
                }}
              />
            </Form.Item>

            <Form.Item label={t('Fields')} {...formItemLayout}>
              {config.emailFields.map((field) => (
                <Tag
                  onClick={() =>
                    handleAddField(
                      `[${field}]`,
                      thankYouEmailEditor!,
                      EmailTemplateEnum.THANK_RECRUITER
                    )
                  }
                  style={{ cursor: 'pointer' }}
                  key={field}
                >
                  {`[${field}]`}
                </Tag>
              ))}
              {fields
                .map((f) => f.name)
                .map((field) => (
                  <Tag
                    onClick={() =>
                      handleAddField(
                        `[${field}]`,
                        thankYouEmailEditor!,
                        EmailTemplateEnum.THANK_RECRUITER
                      )
                    }
                    style={{ cursor: 'pointer' }}
                    key={field}
                  >
                    {`[${field}]`}
                  </Tag>
                ))}
            </Form.Item>

            <Row gutter={16} type="flex">
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingThankYouEmail}
                  className="jobs-new__btn"
                  onClick={() => {
                    const defaultEmail = getEmailTemplateDefault(
                      EmailTemplateEnum.THANK_RECRUITER,
                      thankYouEmailLang
                    );
                    form.setFieldsValue({
                      'thankYouEmail.subject': defaultEmail?.subject,
                      'thankYouEmail.body': defaultEmail?.body || '',
                    });
                  }}
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  loading={isLoadingTemplates}
                  onClick={async () => {
                    await fetchTemplates(JobCustomMessageTemplateTypes.EMAIL);
                    setIsVisibleTemplateModal(true);
                    setSelectedCustomMessage(EmailTemplateEnum.THANK_RECRUITER);
                  }}
                >
                  {t('Load Template')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingThankYouEmail}
                  className="jobs-new__btn"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    const email = getEmailTemplate(
                      EmailTemplateEnum.THANK_RECRUITER,
                      thankYouEmailLang
                    );
                    form.setFieldsValue({
                      'thankYouEmail.subject': email?.subject,
                      'thankYouEmail.body': email?.body || '',
                    });
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingThankYouEmail}
                  loading={isSubmittingThankYouEmail}
                  type="primary"
                  className="jobs-new__btn"
                  onClick={() =>
                    saveEmail(
                      EmailTemplateEnum.THANK_RECRUITER,
                      'thankYouEmail',
                      setIsSubmittingThankYouEmail,
                      thankYouEmailLang
                    )
                  }
                >
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel
            key={CollapseKeys.SEND_CUSTOMER_EMAIL}
            className="job-email-collapse__item"
            forceRender
            extra={
              <Dropdown
                overlay={
                  <Menu
                    selectedKeys={[sendCustomerEmailLang]}
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      setSendCustomerYouEmailLang(e.key as LanguagesEnum);
                      const email = getEmailTemplate(
                        EmailTemplateEnum.SEND_CUSTOMER,
                        e.key as LanguagesEnum
                      );
                      form.setFieldsValue({
                        'sendCustomerEmail.subject': email?.subject,
                        'sendCustomerEmail.body': email?.body || '',
                      });
                    }}
                  >
                    {Object.keys(LanguagesEnum).map((key) => (
                      <Menu.Item key={key}>{Languages[key as LanguagesEnum]}</Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <span>
                  <Icon type="flag" onClick={(e) => e.stopPropagation()} />{' '}
                  {Languages[sendCustomerEmailLang]}
                </span>
              </Dropdown>
            }
            header={
              <>
                <span className="job-email-collapse__subject">{t('Send Customer Email')}</span>
                <span className="job-email-collapse__subject-help">
                  {t('When sending candidates to customer')}{' '}
                </span>
                <Tooltip title={t('This text will appear on that page')}>
                  <Icon type="question-circle" />
                </Tooltip>
              </>
            }
          >
            <Form.Item label={t('Subject')} {...formItemLayout}>
              {form.getFieldDecorator('sendCustomerEmail.subject', {
                initialValue: sendCustomerEmail?.subject,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label={t('Email')} {...formItemLayout}>
              <ReactQuill
                ref={sendCustomerEmailEditorRef}
                className="jobs-new__editor"
                value={form.getFieldValue('sendCustomerEmail.body')}
                onChange={(value) => form.setFieldsValue({ 'sendCustomerEmail.body': value })}
                modules={{
                  toolbar: ReactQuillToolbar,
                }}
              />
            </Form.Item>

            <Form.Item label={t('Fields')} {...formItemLayout}>
              {config.emailFields.map((field) => (
                <Tag
                  onClick={() =>
                    handleAddField(
                      `[${field}]`,
                      sendCustomerEmailEditor!,
                      EmailTemplateEnum.SEND_CUSTOMER
                    )
                  }
                  style={{ cursor: 'pointer' }}
                  key={field}
                >
                  {`[${field}]`}
                </Tag>
              ))}
              {fields
                .map((f) => f.name)
                .map((field) => (
                  <Tag
                    onClick={() =>
                      handleAddField(
                        `[${field}]`,
                        sendCustomerEmailEditor!,
                        EmailTemplateEnum.SEND_CUSTOMER
                      )
                    }
                    style={{ cursor: 'pointer' }}
                    key={field}
                  >
                    {`[${field}]`}
                  </Tag>
                ))}
            </Form.Item>

            <Row gutter={16} type="flex">
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingSendCustomerEmail}
                  className="jobs-new__btn"
                  onClick={() => {
                    const defaultEmail = getEmailTemplateDefault(
                      EmailTemplateEnum.SEND_CUSTOMER,
                      sendCustomerEmailLang
                    );
                    form.setFieldsValue({
                      'sendCustomerEmail.subject': defaultEmail?.subject,
                      'sendCustomerEmail.body': defaultEmail?.body || '',
                    });
                  }}
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  loading={isLoadingTemplates}
                  onClick={async () => {
                    await fetchTemplates(JobCustomMessageTemplateTypes.EMAIL);
                    setIsVisibleTemplateModal(true);
                    setSelectedCustomMessage(EmailTemplateEnum.SEND_CUSTOMER);
                  }}
                >
                  {t('Load Template')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingSendCustomerEmail}
                  className="jobs-new__btn"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    const email = getEmailTemplate(
                      EmailTemplateEnum.SEND_CUSTOMER,
                      thankYouEmailLang
                    );
                    form.setFieldsValue({
                      'sendCustomerEmail.subject': email?.subject,
                      'sendCustomerEmail.body': email?.body || '',
                    });
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Button
                  disabled={isSubmittingSendCustomerEmail}
                  loading={isSubmittingSendCustomerEmail}
                  type="primary"
                  className="jobs-new__btn"
                  onClick={() =>
                    saveEmail(
                      EmailTemplateEnum.SEND_CUSTOMER,
                      'sendCustomerEmail',
                      setIsSubmittingSendCustomerEmail,
                      sendCustomerEmailLang
                    )
                  }
                >
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel
            key={CollapseKeys.SENDER}
            className="job-email-collapse__item"
            forceRender
            style={{ backgroundColor: '#fff' }}
            header={
              <>
                <span className="job-email-collapse__subject">{t('Sender')}</span>
                <span className="job-email-collapse__subject-help">
                  {t('Who is sending the email')}
                </span>
              </>
            }
          >
            <Row gutter={16}>
              <Col xs={24} sm={15}>
                {isLoadingSenderContact && <LoadingSpinner />}

                {!isLoadingSenderContact && (
                  <List.Item
                    className="jobs-new__list-item"
                    actions={[
                      <Button
                        className="jobs-new__btn jobs-new__btn--primary"
                        type="link"
                        onClick={() => setIsVisibleSelectContactModal(true)}
                      >
                        {t('Change')}
                      </Button>,
                    ]}
                  >
                    {senderContact ? (
                      <List.Item.Meta
                        avatar={
                          <Avatar size="large">
                            {senderContact.firstName[0]}
                            {senderContact.lastName[0]}
                          </Avatar>
                        }
                        title={`${senderContact.firstName} ${senderContact.lastName}`}
                        description={senderContact.email}
                      />
                    ) : (
                      <List.Item.Meta
                        avatar={
                          <Avatar size="large">
                            {job.senderFirstName[0]}
                            {job.senderLastName[0]}
                          </Avatar>
                        }
                        title={`${job.senderFirstName} ${job.senderLastName}`}
                        description={job.senderEmailAddress}
                      />
                    )}
                  </List.Item>
                )}
                <Button
                  disabled={isLoadingSenderContact || !senderContact}
                  className="jobs-new__btn"
                  type="primary"
                  onClick={setOwnerAsSender}
                >
                  {t('Set default sender')}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            key={CollapseKeys.SUGGEST_PAGE_MESSAGES}
            className="job-email-collapse__item"
            forceRender
            header={
              <>
                <span className="job-email-collapse__subject">{t('Suggest page message')}:</span>
                <span className="job-email-collapse__subject-help">
                  {t('Message in suggestion')}{' '}
                </span>
                <Tooltip title={t('This text will appear on that page')}>
                  <Icon type="question-circle" />
                </Tooltip>
              </>
            }
          >
            <Form.Item label={t('Message')} {...formItemLayout}>
              <ReactQuill
                ref={suggestPageMessageEditorRef}
                className="jobs-new__editor"
                value={form.getFieldValue('suggestPageMessage')}
                onChange={(value) => form.setFieldsValue({ suggestPageMessage: value })}
                modules={{
                  toolbar: ReactQuillToolbar,
                }}
              />
            </Form.Item>

            <Form.Item label={t('Fields')} {...formItemLayout}>
              {config.emailFields.map((field) => (
                <Tag
                  onClick={() =>
                    handleAddField(
                      `[${field}]`,
                      suggestPageMessageEditor!,
                      EmailTemplateEnum.SEND_CUSTOMER
                    )
                  }
                  style={{ cursor: 'pointer' }}
                  key={field}
                >
                  {`[${field}]`}
                </Tag>
              ))}
              {fields
                .map((f) => f.name)
                .map((field) => (
                  <Tag
                    onClick={() =>
                      handleAddField(`[${field}]`, suggestPageMessageEditor!, undefined)
                    }
                    style={{ cursor: 'pointer' }}
                    key={field}
                  >
                    {`[${field}]`}
                  </Tag>
                ))}
            </Form.Item>

            <div>
              <Form.Item>
                {form.getFieldDecorator('hideSuggestMessage', {
                  initialValue: job?.hideSuggestMessage,
                  valuePropName: 'checked',
                })(<Checkbox>{`${t('Hide suggest page message')}`}</Checkbox>)}
              </Form.Item>
            </div>

            <Row gutter={16} type="flex">
              <Col xs={24} sm={8}>
                <Button
                  disabled={isSubmittingCustomMessage}
                  className="jobs-new__btn"
                  onClick={() => {
                    form.setFieldsValue({ suggestPageMessage: job.suggestPageMessage || '' });
                  }}
                >
                  {t('Reset')}
                </Button>
              </Col>
              <Col xs={24} sm={10}>
                <Button
                  disabled={isSubmittingInviteEmail}
                  className="jobs-new__btn"
                  loading={isLoadingTemplates}
                  onClick={async () => {
                    await fetchTemplates(JobCustomMessageTemplateTypes.EMAIL);
                    setIsVisibleTemplateModal(true);
                    setSelectedCustomMessage(EmailTemplateEnum.WELCOME_MESSAGE);
                  }}
                >
                  {t('Load Template')}
                </Button>
              </Col>
              <Col xs={24} sm={6}>
                <Button
                  disabled={isSubmittingCustomMessage}
                  loading={isSubmittingCustomMessage}
                  type="primary"
                  className="jobs-new__btn"
                  onClick={() => updateSuggestPageMessage()}
                >
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>

        <Divider />

        <Row gutter={16} type="flex">
          <Col xs={24} sm={18}>
            <Button
              className="jobs-new__btn"
              size="large"
              onClick={() => sendTestEmail()}
              loading={isSendingTestEmail}
              disabled={
                isSubmittingInviteEmail ||
                isSubmittingThankYouEmail ||
                isSubmittingSendCustomerEmail ||
                isSendingTestEmail ||
                isSubmitting
              }
            >
              {t('Send Test Emails')}
            </Button>
          </Col>
          <Col xs={24} sm={6}>
            <Button
              className="jobs-new__btn"
              htmlType="submit"
              size="large"
              type="primary"
              loading={isSubmitting}
              disabled={
                isSubmittingInviteEmail ||
                isSubmittingThankYouEmail ||
                isSendingTestEmail ||
                isSendingTestEmail ||
                isSubmitting
              }
            >
              {t('Continue')}
            </Button>
          </Col>
        </Row>
      </Form>

      <SelectContactModal
        senderContactId={senderContact?.id}
        isVisible={isVisibleSelectContactModal}
        onOk={(contact) => changeSenderContact(contact)}
        onCancel={() => setIsVisibleSelectContactModal(false)}
      />

      <JobCustomMessageTemplateModal
        templates={templatesFetched}
        visible={isVisibleTemplateModal}
        handleCancel={() => setIsVisibleTemplateModal(!isVisibleTemplateModal)}
        handleSetTemplate={(templateId: number, selectedCustomMessage: string) =>
          setTemplateMessageInField(templateId, selectedCustomMessage)
        }
        selectedCustomMessage={selectedCustomMessage}
      />
    </>
  );
};

export default Form.create<Props>()(JobsEditCustomizeMessages);
