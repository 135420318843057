import { Avatar, Form, Input, message, Modal, Upload, Button, Row, Col, Tooltip } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { FormComponentProps } from 'antd/lib/form';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useState, useEffect } from 'react';
import { JobCandidate, JobCandidateUpdateType } from '../../api/job-candidates/model';
import { Job } from '../../api/jobs/model';
import { validateAntForm } from '../../helpers/ant-form';
import { getBase64 } from '../../helpers/image-helpers';
import { MimeTypes } from '../../api/shared/enums';
import { useTranslation } from 'react-i18next';
import JobCandidateService from '../../api/job-candidates/JobCandidatesService';
import FilesService, { FORM_DATA_KEY } from '../../api/files/FilesService';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  jobTitle: string;
  phone: string;
  linkedinUrl: string;
  viadeoUrl: string;
  facebookUrl: string;
}

interface Props extends FormComponentProps<FormValues> {
  isVisible: boolean;
  onOk: (model: JobCandidateUpdateType) => void;
  onCancel: () => void;
  job: Job;
  jobCandidate?: JobCandidate;
}

const JobCandidateEditModal: React.FC<Props> = ({
  form,
  isVisible,
  onCancel,
  onOk,
  job,
  jobCandidate,
}) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<UploadFile>();
  const [resumeFile, setResumeFile] = useState<UploadFile>();
  const [imgPreview, setImgPreview] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasResume, setHasResume] = useState(false);
  const [hasProfilePicture, setHasProfilePicture] = useState(false);

  useEffect(() => {
    if (jobCandidate) {
      setHasResume(!!jobCandidate.cvFileId);
      setHasProfilePicture(!!jobCandidate.pictureFileId);
    }
  }, [jobCandidate]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const values = await validateAntForm(form);
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        companyName,
        linkedinUrl,
        viadeoUrl,
        facebookUrl,
      } = values;

      let cvFileId: number | undefined = jobCandidate?.cvFileId;
      const isRemovingResume: boolean = !!jobCandidate?.cvFileId && !hasResume;
      const isUploadingNewResume: boolean =
        !isRemovingResume && !jobCandidate?.cvFileId && !!resumeFile;
      const isUpdatingResume: boolean =
        !isUploadingNewResume && !!jobCandidate?.cvFileId && !!hasResume && !!resumeFile;

      if (isRemovingResume) {
        cvFileId = undefined;
      } else if (isUploadingNewResume || isUpdatingResume) {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY, (resumeFile as unknown) as File);
        const file = await FilesService.add(formData);
        cvFileId = file.id;
      }

      let pictureFileId: number | undefined = jobCandidate?.pictureFileId;
      const isRemovingProfilePicture: boolean = !!jobCandidate?.pictureFileId && !hasProfilePicture;
      const isUploadingNewProfilePicture: boolean =
        !isRemovingProfilePicture && !jobCandidate?.pictureFileId && !!imageFile;
      const isUpdatingProfilePicture: boolean =
        !isUploadingNewProfilePicture &&
        !!jobCandidate?.pictureFileId &&
        !!hasProfilePicture &&
        !!imageFile;

      if (isRemovingProfilePicture) {
        pictureFileId = undefined;
      } else if (isUploadingNewProfilePicture || isUpdatingProfilePicture) {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY, (imageFile as unknown) as File);
        const file = await FilesService.add(formData);
        pictureFileId = file.id;
      }

      const model: JobCandidateUpdateType = {
        id: jobCandidate!.id,
        jobId: job.id,
        firstName,
        lastName,
        email,
        phone,
        company: companyName,
        jobTitle: jobTitle,
        linkedinUrl,
        viadeoUrl,
        facebookUrl,
        pictureFileId,
        cvFileId,
        jobCandidateFM: jobCandidate?.jobCandidateFM,
      };

      await JobCandidateService.update(model);
      setIsSubmitting(false);

      onOk(model);
      form.resetFields();
      setImageFile(undefined);
      setImgPreview(undefined);
      setResumeFile(undefined);
    } catch (error) {
      setIsSubmitting(false);
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const handleImageFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setImageFile(undefined);
      setImgPreview(undefined);
      setHasProfilePicture(false);
    } else {
      const response = await getBase64((info.file as unknown) as File);
      if (response) {
        setImgPreview(response.toString());
      }
      setImageFile(info.file);
      setHasResume(true);
    }
  };

  const handleResumeFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setResumeFile(undefined);
      setHasResume(false);
    } else {
      setResumeFile(info.file);
      setHasResume(true);
    }
  };

  const avatarProps: AvatarProps = {
    className: 'avatar-upload__img',
  };

  if (imgPreview) {
    avatarProps.src = imgPreview;
  } else if (jobCandidate) {
    avatarProps.src = jobCandidate.pictureUrl;
  } else {
    avatarProps.icon = 'user';
  }

  return (
    <Modal
      style={{ top: '2rem' }}
      visible={isVisible}
      title={`${t('Update')} ${jobCandidate?.firstName} ${jobCandidate?.lastName}`}
      onCancel={() => {
        form.resetFields();
        setImgPreview(undefined);
        setImageFile(undefined);
        onCancel();
      }}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={handleSubmit}
            className="jobs-new__btn"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('Update candidate')}
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        <Row gutter={16} type="flex" align="middle">
          <Col xs={24} sm={8}>
            <Form.Item>
              <div className="avatar-upload">
                <Upload
                  fileList={imageFile ? [imageFile] : []}
                  beforeUpload={() => false}
                  onChange={handleImageFileOnChange}
                  accept={`${MimeTypes.IMAGE_JPEG}, ${MimeTypes.IMAGE_PNG}`}
                  showUploadList={false}
                >
                  {imgPreview && (
                    <Tooltip title={t('Remove')}>
                      <Button
                        className="avatar-upload__btn avatar-upload__btn--remove"
                        size="small"
                        type="danger"
                        shape="circle"
                        icon="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          setImageFile(undefined);
                          setImgPreview(undefined);
                        }}
                      />
                    </Tooltip>
                  )}
                  <Avatar {...avatarProps}>
                    {jobCandidate?.firstName[0]} {jobCandidate?.lastName[0]}
                  </Avatar>
                </Upload>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} sm={16}>
            <Row gutter={16}>
              <Col xs={12}>
                <Form.Item label={t('First Name')}>
                  {form.getFieldDecorator('firstName', {
                    initialValue: jobCandidate?.firstName,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: t('This field is required'),
                      },
                    ],
                  })(<Input autoFocus onPressEnter={handleSubmit} />)}
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t('Last Name')}>
                  {form.getFieldDecorator('lastName', {
                    initialValue: jobCandidate?.lastName,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: t('This field is required'),
                      },
                    ],
                  })(<Input onPressEnter={handleSubmit} />)}
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label={t('Position')}>
                  {form.getFieldDecorator('jobTitle', {
                    initialValue: jobCandidate?.jobTitle,
                    rules: [
                      {
                        whitespace: true,
                        message: t('Field cannot be empty'),
                      },
                    ],
                  })(<Input onPressEnter={handleSubmit} />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label={t('Company')}>
              {form.getFieldDecorator('companyName', {
                initialValue: jobCandidate?.company,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                ],
              })(<Input onPressEnter={handleSubmit} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Email')}>
              {form.getFieldDecorator('email', {
                initialValue: jobCandidate?.email,
                rules: [
                  {
                    type: 'email',
                    message: t('The entered email is not valid'),
                  },
                ],
              })(<Input type="email" onPressEnter={handleSubmit} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Phone')}>
              {form.getFieldDecorator('phone', {
                initialValue: jobCandidate?.phone,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                ],
              })(<Input onPressEnter={handleSubmit} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('LinkedIn URL')}>
              {form.getFieldDecorator('linkedinUrl', {
                initialValue: jobCandidate?.linkedinUrl,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                ],
              })(<Input onPressEnter={handleSubmit} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Facebook URL')}>
              {form.getFieldDecorator('facebookUrl', {
                initialValue: jobCandidate?.facebookUrl,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                  {
                    type: 'url',
                    message: t('Field must be a URL'),
                  },
                ],
              })(<Input onPressEnter={handleSubmit} />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Viadeo URL')}>
              {form.getFieldDecorator('viadeoUrl', {
                initialValue: jobCandidate?.viadeoUrl,
                rules: [
                  {
                    whitespace: true,
                    message: t('Field cannot be empty'),
                  },
                ],
              })(<Input onPressEnter={handleSubmit} />)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={t('Resume')}>
          {!hasResume && (
            <Upload
              fileList={resumeFile ? [resumeFile] : []}
              beforeUpload={() => false}
              onChange={handleResumeFileOnChange}
              accept={`${MimeTypes.DOCX}, ${MimeTypes.PDF}`}
            >
              <Button icon="upload">{t('Select a file')}</Button>
            </Upload>
          )}
          {hasResume && (
            <>
              <Button type="link" href={jobCandidate?.cvUrl} target="_blank">
                {t('View Resume')}
              </Button>
              <Tooltip title={t('Remove')}>
                <Button
                  icon="delete"
                  type="danger"
                  shape="circle"
                  onClick={() => setHasResume(false)}
                  size="small"
                />
              </Tooltip>
            </>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<Props>()(JobCandidateEditModal);
