export function downloadDataAsCSV(data: string, filename: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const blob: Blob = new Blob([data], { type: 'octet/stream' });
  const url: string = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}
