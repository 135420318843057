import { TFunction } from 'i18next';
import { Job } from '../api/jobs/model';
import { SurveyQuestionType } from '../api/surveys/enums';
import { Survey, SurveyInstance } from '../api/surveys/models';

export const stripTagsFromText = (text: string): string => {
  if (!text) {
    return '';
  }
  return text.replace(/<[^>]+>/g, '');
};

export const getQuestionsLabel = (survey: SurveyInstance | Survey, t: TFunction) => {
  let questions: any[];
  if ('surveyInstanceQuestionModels' in survey) {
    questions = survey.surveyInstanceQuestionModels;
  } else {
    questions = survey.surveyTemplateQuestionModels;
  }
  return questions.length > 1 ? t('questions') : t('question');
};

export const htmlDecode = (str: string) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
};

export const setQuestionExplanationText = (typeCode: any, t: TFunction) => {
  switch (typeCode) {
    case SurveyQuestionType.SCALE: return t('Rating 1 to 10, 10 meaning to be the best')
    case SurveyQuestionType.RATING: return t('1 to 5 stars, 5 is the best')
    case SurveyQuestionType.PICTURE: return t('Select the picture that best fits the question')
    case SurveyQuestionType.TEXT: return t('Write a text that answers the question')
    case SurveyQuestionType.YESNO: return t('Select wether in favour or against the question')
    default: return t('Survey Question')
  }
}

export const applyFieldsToQuestions =  (surveyInstance: SurveyInstance, questionTitle?: string, job?: Job) => {
  
  if (questionTitle) {
    return questionTitle.replace('[talent-first-name]',`${surveyInstance.jobCandidateModel.firstName || 'Talent John'}`)
                        .replace('[talent-last-name]',`${surveyInstance.jobCandidateModel.lastName || 'Talent Doe'}`)
                        .replace('[talent-name]',`${surveyInstance.jobCandidateModel.firstName || 'Talent John'} ${surveyInstance.jobCandidateModel.lastName || 'Doe'}`)
                        .replace('[talent-linkedin-url]', `${surveyInstance.jobCandidateModel.linkedinUrl || ''}`)
                        .replace('[talent-facebook-url]', `${surveyInstance.jobCandidateModel.facebookUrl || ''}`)
                        .replace('[talent-company]', `${surveyInstance.jobCandidateModel.company || ''}`)
                        .replace('[talent-position]', `${surveyInstance.jobCandidateModel.jobTitle || ''}`)

                        .replace('[offer-title]',`${job?.jobTitle || 'Job Title'}`)
                        .replace('[offer-description]',`${job?.description || 'Job Description'}`)
                        .replace('[client-first-name]', `${job?.clientContact.firstName || 'Client John'} ${job?.clientContact.lastName || 'Doe'}`)

                        .replace('[contact-first-name]', `${surveyInstance.contactModel.firstName || 'Contact John'}`)
                        .replace('[contact-last-name]', `${surveyInstance.contactModel.lastName || 'Contact Doe'}`)
                        .replace('[contact-linkedin-url]', `${surveyInstance.contactModel.linkedinUrl || ''}`)
                        .replace('[contact-facebook-url]', `${surveyInstance.contactModel.facebookUrl || ''}`)
                        .replace('[contact-company]', `${surveyInstance.contactModel.companyName || ''}`)
                        .replace('[contact-position]', `${surveyInstance.contactModel.jobTitle || ''}`);       
  }
};
