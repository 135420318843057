import React from 'react';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import RolesDetail from '../../components/Roles/RolesDetail';
import RolesGrid from '../../components/Roles/RolesGrid';
import { URLs } from '../../config/enums';
import { Switch } from 'react-router-dom';

interface RolesPageProps {}

const RolesPage: React.FC<RolesPageProps> = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={URLs.ROLES}
      resource={ResourcesEnum.ROLES}
      patent={PatentsEnum.VIEW}
    >
      <RolesGrid />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      exact
      path={URLs.ROLES_DETAIL}
      resource={ResourcesEnum.ROLES}
      patent={PatentsEnum.EDIT}
    >
      <RolesDetail />
    </AuthenticatedRoute>
  </Switch>
);

export default RolesPage;
