import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import {
  SmtpConfigurationAuthenticationType,
  SmtpConfigurationAuthenticationLabel,
  SmtpConfigurationGroupingEmailsType,
  SmtpConfigurationGroupingEmaisLabel,
} from '../../api/recruiter-configurations/enums';
import {
  SmtpCustomConfig,
  SmtpCustomConfigAddType,
  SmtpCustomConfigDetailType,
  SmtpCustomConfigUpdateType,
} from '../../api/recruiter-configurations/models';
import { RecruiterConfigurationsService } from '../../api/recruiter-configurations/RecruiterConfigurationsService';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import BackButton from '../BackButton/BackButton';
import RecruiterSmtpCustomConfigTestEmailModal from './RecruiterSmtpCustomConfigTestEmailModal';

interface FormValues {
  id: number;
  name: string;

  domainNames: string;
  fromAddress: string;
  smtpServer: string;
  smtpPort: number;
  smtpUsername: string;
  smtpPassword: string;
  limitAmount?: number;
  limitType: string;
  sslEnabled: boolean;
  smtpAuthentication: string;
  intervalLimit: number;
}

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

interface RecruiterSmtpCustomConfigsDetailProps extends FormComponentProps<FormValues> { }

const RecruiterSmtpCustomConfigsDetail: React.FC<RecruiterSmtpCustomConfigsDetailProps> = ({
  form,
}) => {
  const [smtpCustomConfig, setSmtpCustomConfig] = useState<SmtpCustomConfigDetailType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTestEmailModalOpen, setIsTestEmailModalOpen] = useState(false);
  const [selectedSmtpCustomConfig, setSelectedSmtpCustomConfig] = useState<any>({});

  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      if (match.params.id) {
        try {
          setIsLoading(true);
          const response = await RecruiterConfigurationsService.findSmtpCustomConfig(
            +match.params.id
          );
          setSmtpCustomConfig(response);
          setIsLoading(false);
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        }
      }
    };
    fetch();
  }, [match.params.id]);

  const handleSMTP = async () => {
    let values = await validateAntForm(form);
    const entity: any = {
      name: values.name,
      domainNames: values.domainNames,
      smtpServer: values.smtpServer,
      smtpPort: values.smtpPort,
      smtpUsername: values.smtpUsername || "",
      smtpPassword: values.smtpPassword || "",
      limitAmount: values.limitAmount || undefined,
      limitType: values.limitType || undefined,
      sslEnabled: values.sslEnabled,
      ownerUserId: 0,
      smtpAuthentication: values.smtpAuthentication,
      intervalLimit: values.intervalLimit,
    };
    let response;
    if (window.location.pathname === URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_NEW) {
      response = await RecruiterConfigurationsService.addSmtpCustomConfig(entity);
    } else {
      response = match.params.id
    }
    setSelectedSmtpCustomConfig({ ...entity, id: response })
    setIsTestEmailModalOpen(true);

  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let values = await validateAntForm(form);

    setIsSubmitting(true);
    try {
      if (match.params.id) {
        const entity: SmtpCustomConfigUpdateType = {
          id: +match.params.id,
          name: values.name,
          domainNames: values.domainNames,
          smtpServer: values.smtpServer,
          smtpPort: values.smtpPort,
          smtpUsername: values.smtpUsername || "",
          smtpPassword: values.smtpPassword || "",
          sslEnabled: values.sslEnabled,
          limitAmount: values.limitAmount || undefined,
          limitType: values.limitType || undefined,
          smtpAuthentication: values.smtpAuthentication,
          intervalLimit: values.intervalLimit
        };

        await RecruiterConfigurationsService.updateSmtpCustomConfig(entity.id, entity);
        setSelectedSmtpCustomConfig(entity)

      } else {
        const entity: SmtpCustomConfigAddType = {
          name: values.name,
          domainNames: values.domainNames,
          smtpServer: values.smtpServer,
          smtpPort: values.smtpPort,
          smtpUsername: values.smtpUsername || "",
          smtpPassword: values.smtpPassword || "",
          limitAmount: values.limitAmount || undefined,
          limitType: values.limitType || undefined,
          sslEnabled: values.sslEnabled,
          ownerUserId: 0,
          smtpAuthentication: values.smtpAuthentication,
          intervalLimit: values.intervalLimit,
        };

        await RecruiterConfigurationsService.addSmtpCustomConfig(entity);
        setSelectedSmtpCustomConfig(entity)
      }
      message.success(t('Success!'));
      history.goBack();
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsSubmitting(true);
      await RecruiterConfigurationsService.deleteSmtpCustomConfig(+match.params.id);
      setIsSubmitting(false);
      message.success(t('Success!'));
      history.goBack();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container">
      <Card
        loading={isLoading}
        title={
          <>
            <BackButton /> <span>{t('SMTP Custom Configuration')}</span>
          </>
        }
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label={t('Name')} {...formItemLayout}>
            {form.getFieldDecorator('name', {
              rules: [{ required: true, message: t('This field is required') }],
              initialValue: smtpCustomConfig?.name || undefined,
            })(<Input />)}
          </Form.Item>

          <Form.Item
            label={t('Domain Names separated with')}
            className={'two-rows-label'}
            {...formItemLayout}
          >
            {form.getFieldDecorator('domainNames', {
              rules: [{ required: true, message: t('This field is required') }],
              initialValue: smtpCustomConfig?.domainNames || undefined,
            })(<Input />)}
          </Form.Item>

          <Form.Item label={t('SMTP Server')} {...formItemLayout}>
            {form.getFieldDecorator('smtpServer', {
              rules: [{ required: true, message: t('This field is required') }],
              initialValue: smtpCustomConfig?.smtpServer || undefined,
            })(<Input />)}
          </Form.Item>

          <Form.Item label={t('SMTP Port')} {...formItemLayout}>
            {form.getFieldDecorator('smtpPort', {
              rules: [{ required: true, message: 'This field is required' }],
              initialValue: smtpCustomConfig?.smtpPort || undefined,
            })(<InputNumber />)}
          </Form.Item>

          <Form.Item label={t('SMTP Authentication')} {...formItemLayout}>
            {form.getFieldDecorator('smtpAuthentication', {
              rules: [{ required: true, message: 'This field is required' }],
              initialValue: smtpCustomConfig?.smtpAuthentication || undefined,
            })(
              <Select placeholder={t('Select Authentication')} allowClear>
                {Object.keys(SmtpConfigurationAuthenticationType).map((authenticationType) => (
                  <Select.Option key={authenticationType} value={authenticationType}>
                    {t(SmtpConfigurationAuthenticationLabel[authenticationType as SmtpConfigurationAuthenticationType])}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          {form.getFieldValue('smtpAuthentication') === SmtpConfigurationAuthenticationType.Authentication && (
            <>
              <Form.Item label={t('SMTP Username')} {...formItemLayout}>
                {form.getFieldDecorator('smtpUsername', {
                  rules: [{ required: true, message: t('This field is required.11251151') }],
                  initialValue: smtpCustomConfig?.smtpUsername || undefined,
                })(<Input />)}
              </Form.Item>

              <Form.Item label={t('SMTP Password')} {...formItemLayout}>
                {form.getFieldDecorator('smtpPassword', {
                  rules: [{ required: true, message: t('This field is required') }],
                  initialValue: smtpCustomConfig?.smtpPassword || undefined,
                })(<Input.Password />)}
              </Form.Item>
            </>
          )}

          <Form.Item label={<><span className='ant-form-item-required'>{t('Email Limit')}</span></>} {...formItemLayout}>
            <>
              <Row>
                <Col xs={24} md={18} lg={14} xl={12}>
                  <Col xs={6} >
                    <Form.Item style={{ marginBottom: '0px' }}>
                      {form.getFieldDecorator('limitAmount' || 'intervalLimit', {
                        rules: [{ required: true, message: t('This field is required') }],
                        initialValue: smtpCustomConfig?.limitAmount || undefined,
                      })(<InputNumber min={1} style={{ marginRight: '10px', width: 'auto' }} />)}
                    </Form.Item>
                    <span style={{ marginLeft: '20px' }}>{t('Qty')}</span>
                  </Col>

                  <Col xs={6} >
                    {t('every')}
                  </Col>

                  <Col xs={12} >
                    <Form.Item className='smtpConfForm' style={{ marginBottom: '0px' }}>
                      {form.getFieldDecorator('intervalLimit', {
                        rules: [{ required: true, message: t('This field is required') }],
                        initialValue: smtpCustomConfig?.intervalLimit || undefined,
                      })(<InputNumber min={1} style={{ marginRight: '10px' }} />)}

                      {form.getFieldDecorator('limitType', {
                        initialValue: smtpCustomConfig?.limitType || 'Hour',
                        // rules: [{ required: true, message: t('This field is required') }],
                      })(
                        <Select placeholder={t('Select Interval')} >
                          {Object.keys(SmtpConfigurationGroupingEmailsType).map((limitType) => (
                            <Select.Option key={limitType} value={limitType}>
                              {t(SmtpConfigurationGroupingEmaisLabel[limitType as SmtpConfigurationGroupingEmailsType])}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <span style={{ marginLeft: '35px' }}>{t('Interval')}</span>
                  </Col>
                </Col>
              </Row>
            </>
          </Form.Item>

          <Form.Item label={t('SMTP Enable SSL')} {...formItemLayout}>
            {form.getFieldDecorator('sslEnabled', {
              initialValue: smtpCustomConfig?.sslEnabled || false,
            })(<Switch checked={form.getFieldValue('sslEnabled')} />)}
          </Form.Item>
          <Divider />
          {match.params.id && (
            <Popconfirm
              title={t('Delete')}
              okText={t('Yes')}
              cancelText={t('No')}
              onConfirm={handleDelete}
            >
              <Button type="danger" loading={isSubmitting} disabled={isSubmitting}>
                {t('Delete')}
              </Button>
            </Popconfirm>
          )}

          <Button
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            style={{ marginLeft: 5, float: 'right' }}
            onClick={handleSMTP}
          >{window.location.pathname == URLs.RECRUITER_CONFIGURATIONS_SMTP_CUSTOM_CONFIG_NEW ? t('Save and Send Test Email') : t('Send Test Email')}</Button>

          <Button
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            style={{ float: 'right' }}
            htmlType="submit"
          >
            {t('Save')}
          </Button>
        </Form>
      </Card>
      <RecruiterSmtpCustomConfigTestEmailModal
        visible={isTestEmailModalOpen}
        setVisible={(visible) => setIsTestEmailModalOpen(visible)}
        smtpCustomConfiguration={selectedSmtpCustomConfig}
      />
    </div>
  );
};

export default Form.create<RecruiterSmtpCustomConfigsDetailProps>()(
  RecruiterSmtpCustomConfigsDetail
);
