import { Avatar, Dropdown, Icon, Menu, message } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { User, UpdateProfile } from '../api/users/model';
import { URLs } from '../config/enums';
import { LanguagesEnum, Languages } from '../api/shared/enums';
import { useDispatch } from 'react-redux';
import UsersService from '../api/users/UsersService';
import { updateProfile } from '../state/auth';
import AuthService from '../api/shared/services/AuthService';
import { useTranslation } from 'react-i18next';
import { PatentsEnum, ResourcesEnum } from '../api/roles/enums';
import { hasPermissions } from '../helpers/permissions-helpers';

interface Props {
  user: User;
}

const HeaderActions: React.FC<Props> = ({ user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const changeLanguage = async (language: string) => {
    try {
      const model: UpdateProfile = {
        firstName: user.firstName,
        lastName: user.lastName,
        profilePictureId: user.profilePictureId,
        linkedinJSessionId: user.linkedinJSessionId,
        linkedinSessionCookie: user.linkedinSessionCookie,
        facebookSessionCookie_c_user: user.facebookSessionCookie_c_user,
        facebookSessionCookie_xs: user.facebookSessionCookie_xs,
        language,
        facebookMessageLimitType: user.facebookMessageLimitType,
        linkedinMessageLimitType: user.linkedinMessageLimitType,
        linkedinMessageLimitAmount: user.linkedinMessageLimitAmount,
        facebookMessageLimitAmount: user.facebookMessageLimitAmount,
      };
      await UsersService.updateProfile(model);
      const newUser = { ...user, ...model };
      dispatch(updateProfile(newUser));
      AuthService.saveUserToLocalStorage(newUser);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const selectedKeys = [];

  if (user?.language) {
    selectedKeys.push(user.language.toLowerCase());
  }

  return (
    <>
      <Dropdown
        overlay={
          <Menu onClick={(e) => changeLanguage(e.key)} selectedKeys={selectedKeys}>
            {Object.keys(LanguagesEnum).map((languageKey) => (
              <Menu.Item key={languageKey.toLowerCase()}>
                {Languages[languageKey as LanguagesEnum]}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Icon type="flag" style={{ marginRight: '2rem', fontSize: '1.6rem' }} />
      </Dropdown>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key={URLs.PROFILE}>
              <Link to={URLs.PROFILE}>
                <Icon type="user" style={{ marginRight: 5 }} />
                <span>{t('Profile')}</span>
              </Link>
            </Menu.Item>
            {hasPermissions(ResourcesEnum.RECRUITER_CONFIGURATIONS, PatentsEnum.VIEW) && (
              <Menu.Item key={URLs.RECRUITER_CONFIGURATIONS}>
                <Link to={URLs.RECRUITER_CONFIGURATIONS}>
                  <Icon type="setting" style={{ marginRight: 5 }} />
                  <span>{t('Configurations')}</span>
                </Link>
              </Menu.Item>
            )}

            <Menu.Item key={URLs.LOGOUT}>
              <Link to={URLs.LOGOUT}>
                <Icon type="logout" style={{ marginRight: 5 }} />
                <span>{t('Logout')}</span>
              </Link>
            </Menu.Item>
          </Menu>
        }
      >
        <span>
          <Avatar style={{ backgroundColor: '#424A57' }}>
            {user && `${user.firstName[0]}${user.lastName[0]}`}
          </Avatar>
        </span>
      </Dropdown>
    </>
  );
};

export default HeaderActions;
