import {
  Alert,
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Icon,
  Input,
  List,
  Menu,
  Row,
  Tooltip,
} from 'antd';
import classNames from 'classnames';
import { find, includes } from 'lodash';
import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { CampaignStatusEnum } from '../../api/campaigns/enum';
import { ContactDefaultRoute, ContactDefaultRouteLabel } from '../../api/contacts/enums';
import { Contact } from '../../api/contacts/model';
import { SurveySendRoute } from '../../api/surveys/enums';
import { createBaseGridParams, getUrlQueryString, GridParams } from '../../helpers/grid-helpers';
import { useContacts, useLists } from '../../hooks/contacts';
import { CampaignsNewContext } from '../Campaigns/CampaignsContext';
import LoadingSpinner from '../Loading/LoadingSpinner';
import ContactDetailModal from './ContactDetailModal';

interface Props {
  selectedContactIds?: number[];
  currentlySelectedContactId?: number;
  invitedContactIds?: number[];
  excludedContacts?: Contact[];
  isSelectingAllContacts?: boolean;
  showDefaultRoute?: boolean;
  extraButtons?: ReactNode;
  shouldRefetch?: boolean;
  selectedEmailContacts: Contact[];
  selectedLinkedinContacts: Contact[];
  selectedFacebookContacts: Contact[];
  onSelect: (contact: Contact, listName: string) => void;
  onSelectAll?: (contacts: Contact[]) => void;
  onRemoveAll?: () => void;
  onSelectAllContacts?: () => void;
  onSelectContactListId?: (contactListId: number) => void;
  onRefetchCompleted?: () => void;
}

const gridParams = createBaseGridParams({ sortField: 'firstName', pageSize: 12 });

const ContactsListSend: React.FC<Props> = ({
  selectedContactIds = [],
  currentlySelectedContactId,
  excludedContacts,
  isSelectingAllContacts,
  extraButtons,
  shouldRefetch = false,
  showDefaultRoute = false,
  selectedEmailContacts = [],
  selectedLinkedinContacts = [],
  selectedFacebookContacts = [],
  invitedContactIds = [],
  onSelect,
  onSelectAll,
  onRemoveAll,
  onSelectAllContacts,
  onSelectContactListId,
  onRefetchCompleted,
}) => {
  const { t } = useTranslation();
  const urlParams = getUrlQueryString();
  const { campaign } = useContext(CampaignsNewContext);
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearch] = useDebounce(searchInput, 500);
  const [contactListId, setContactListId] = useState(0);
  const [isVisibleContactDetailModal, setIsVisibleContactDetailModal] = useState(false);
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    ...urlParams,
  });
  const { isLoadingContacts, contacts, total, fetchContacts } = useContacts({
    search: debouncedSearch,
    listId: contactListId,
    params: gridState,
  });
  const { isLoadingLists, lists } = useLists();

  useEffect(() => {
    if (onSelectContactListId) {
      onSelectContactListId(contactListId);
    }
  }, [contactListId, onSelectContactListId]);

  useEffect(() => {
    if (shouldRefetch && onRefetchCompleted) {
      fetchContacts();
      onRefetchCompleted();
    }
  }, [fetchContacts, onRefetchCompleted, shouldRefetch]);

  const handlePaginationChange = (pageNumber: number, pageSize: number | undefined) => {
    setGridState({ ...gridState, page: pageNumber - 1, pageSize: pageSize || 24 });
  };

  const renderHowManyContactsSelected = () => {
    if (!selectedContactIds.length || !isSelectingAllContacts) {
      let selectedContactsCount: number = 0;

      if (isSelectingAllContacts) {
        selectedContactsCount = total;
        if (excludedContacts) {
          selectedContactsCount -= excludedContacts.length;
        }
      } else {
        selectedContactsCount = selectedContactIds.length;
      }

      return (
        <Alert
          style={{ marginBottom: '1rem', textAlign: 'center' }}
          message={
            <>
              <span style={{ display: 'inline-block', padding: '0 2rem' }}>
                {t('Total selected contacts', { contactsCount: selectedContactsCount })}
              </span>
            </>
          }
        />
      );
    }
    return null;
  };

  const renderContactsList = (contactList: Contact[], listName: string) => {
    return (
      <List
        dataSource={contactList}
        renderItem={(contact, index) => {
          return (
            <List.Item
              key={index}
              className="jobs-new__list-item"
              extra={
                <>
                  {invitedContactIds.includes(contact?.id) && (
                    <span className="jobs-new__btn jobs-new__btn--primary">
                      {t('Already Invited')}
                    </span>
                  )}
                  <Icon
                    type="close"
                    theme="outlined"
                    style={{ paddingRight: '1rem', fontSize: '1.9rem', display: (contact?.emailStatusCode == 'UNQUEUED' || !contact?.emailStatusCode) ? '' : 'none' }}
                    onClick={() => onSelect(contact, listName)}
                  />
                </>
              }
            >
              <List.Item.Meta
                avatar={
                  contact.pictureUrl ? (
                    <Avatar src={contact.contact?.pictureUrl} />
                  ) : (
                    <Avatar icon={'user'} />
                  )
                }
                title={`${contact.contact?.firstName || contact.firstName} ${contact.contact?.lastName || contact.lastName}`}
                description={
                  <>
                    <span>{(contact.id || contact?.contact?.id) ? (contact.email || contact?.contact?.email) : contact.jobTitle}</span>
                    {showDefaultRoute && (
                      <>
                        <br />
                        <span>{`${t('Default campaign route')}: ${ContactDefaultRouteLabel[
                          contact?.defaultCampaignSendRoute as ContactDefaultRoute
                        ] || t('Not set')
                          }`}</span>
                      </>
                    )}
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
    );
  };

  return (
    <>
      <Input.Search
        placeholder={`${t('Type to search a contact')}...`}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="jobs-new__search-contact"
        allowClear
        autoFocus
      />

      {isLoadingLists && <LoadingSpinner />}

      {!isLoadingLists && (
        <>
          <div
            style={{
              marginBottom: '2rem',
            }}
          >
            <Dropdown
              overlay={
                <Menu selectedKeys={[contactListId.toString()]}>
                  <Menu.Item key={0} onClick={() => setContactListId(0)}>
                    {t('All')}
                  </Menu.Item>
                  {lists.map((list) => (
                    <Menu.Item onClick={() => setContactListId(list.id)} key={list.id}>
                      {list.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <span style={{ fontSize: '2rem' }}>
                {!contactListId
                  ? t('All Contacts')
                  : find(lists, {
                    id: contactListId,
                  })?.name}{' '}
                ({isLoadingContacts ? '-' : total}){' '}
                <Icon style={{ fontSize: '1.6rem' }} type="down" />
              </span>
            </Dropdown>
          </div>

          <div
            style={{
              justifyContent: 'space-between',
              marginBottom: '2rem',
            }}
          >
            <Row style={{ marginBottom: '2rem' }}>
              <Col md={24} xl={24}>
                <Tooltip title={t('Add Contact')}>
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={() => setIsVisibleContactDetailModal(true)}
                    disabled={isSelectingAllContacts}
                  />
                </Tooltip>
                {extraButtons}
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                {onSelectAllContacts && (
                  <>
                    {!isSelectingAllContacts && (
                      <Button onClick={() => onSelectAllContacts()} disabled={!contacts.length}>
                        {t('Select all in list')}
                      </Button>
                    )}
                    {isSelectingAllContacts && (
                      <Button type="link" onClick={() => onSelectAllContacts()}>
                        {t('Remove selection')}
                      </Button>
                    )}
                  </>
                )}

                <Button.Group style={{ float: 'right' }}>
                  {onSelectAll && (
                    <Button disabled={isSelectingAllContacts} onClick={() => onSelectAll(contacts)}>
                      {t('Select all in page')}
                    </Button>
                  )}
                  {onRemoveAll && (
                    <Button
                      disabled={isSelectingAllContacts || !selectedContactIds.length}
                      onClick={() => onRemoveAll()}
                    >
                      {t('Remove all')}
                    </Button>
                  )}
                </Button.Group>
              </Col>
            </Row>
          </div>
        </>
      )}

      {isLoadingContacts && <LoadingSpinner />}

      {renderHowManyContactsSelected()}

      {!isLoadingContacts && !!contacts.length && (
        <List
          dataSource={contacts}
          pagination={{
            onChange: handlePaginationChange,
            current: gridState.page! + 1,
            total,
            pageSize: gridState.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['12', '24', '36', '48'],
            onShowSizeChange: handlePaginationChange,
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
          }}
          renderItem={(contact) => {
            return (
              <List.Item
                className={classNames('jobs-new__list-item', {
                  'jobs-new__list-item--selected':
                    selectedContactIds.includes(contact.id) ||
                    (isSelectingAllContacts && !includes(excludedContacts, contact)) ||
                    selectedLinkedinContacts.find((c) => c.linkedinUrl === contact.linkedinUrl) ||
                    selectedFacebookContacts.find((c) => c.facebookUrl === contact.facebookUrl) ||
                    selectedEmailContacts.find((c) => c?.email == contact?.email || c.contact?.email == contact?.email),
                })}
                extra={
                  <div style={{ paddingRight: '1rem' }}>
                    {invitedContactIds.includes(contact.id) && (
                      <span className="jobs-new__btn jobs-new__btn--primary">
                        {/* {t('Already Invited')} */}
                        {campaign && campaign.campaignStatus == CampaignStatusEnum.Completed ? `${t('Campaign Sent')}` : `${t('Already Invited')}`}
                      </span>
                    )}
                    <Button.Group>
                      {contact.linkedinUrl && (
                        <Button
                          icon="linkedin"
                          onClick={() => onSelect(contact, SurveySendRoute.LINKEDIN)}
                          size="small"
                          className="btn btn--linkedin i-linkedin"
                          disabled={
                            isSelectingAllContacts ||
                            currentlySelectedContactId === contact.id ||
                            selectedContactIds.includes(contact.id) ||
                            selectedLinkedinContacts.find(
                              (c) => c.linkedinUrl === contact.linkedinUrl
                            ) !== undefined ||
                            selectedFacebookContacts.find(
                              (c) => c.facebookUrl === contact.facebookUrl
                            ) !== undefined ||
                            selectedEmailContacts.find((c) => c?.email === contact?.email) !==
                            undefined
                          }
                        />
                      )}
                      {contact.facebookUrl && (
                        <Button
                          icon="facebook"
                          onClick={() => onSelect(contact, SurveySendRoute.FACEBOOK)}
                          size="small"
                          className="btn btn--linkedin i-facebook"
                          disabled={
                            isSelectingAllContacts ||
                            currentlySelectedContactId === contact.id ||
                            selectedContactIds.includes(contact.id) ||
                            selectedLinkedinContacts.find(
                              (c) => c.linkedinUrl === contact.linkedinUrl
                            ) !== undefined ||
                            selectedFacebookContacts.find(
                              (c) => c.facebookUrl === contact.facebookUrl
                            ) !== undefined ||
                            selectedEmailContacts.find((c) => c.email === contact.email) !==
                            undefined
                          }
                        />
                      )}
                      {contact.email && (
                        <Button
                          icon="mail"
                          onClick={() => onSelect(contact, SurveySendRoute.EMAIL)}
                          size="small"
                          className="btn btn--linkedin"
                          disabled={
                            isSelectingAllContacts ||
                            currentlySelectedContactId === contact.id ||
                            selectedContactIds.includes(contact.id) ||
                            !!selectedLinkedinContacts.find(
                              (c) => c.linkedinUrl === contact.linkedinUrl
                            ) ||
                            !!selectedFacebookContacts.find(
                              (c) => c.facebookUrl === contact.facebookUrl
                            ) ||
                            !!selectedEmailContacts.find((c) => c?.email === contact?.email)
                          }
                        />
                      )}
                    </Button.Group>
                  </div>
                }
              >
                <List.Item.Meta
                  avatar={
                    contact.pictureUrl ? (
                      <Avatar src={contact.pictureUrl} />
                    ) : (
                      <Avatar icon="user" />
                    )
                  }
                  title={`${contact.firstName} ${contact.lastName}`}
                  description={
                    <>
                      <span>{contact.id ? contact?.email : contact.jobTitle}</span>
                      {showDefaultRoute && (
                        <>
                          <br />
                          <span>{`${t('Default campaign route')}: ${ContactDefaultRouteLabel[
                            contact?.defaultCampaignSendRoute as ContactDefaultRoute
                          ] || t('Not set')
                            }`}</span>
                        </>
                      )}
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      )}

      {!isLoadingContacts && searchInput && contacts.length === 0 && <Empty />}
      {!isSelectingAllContacts && (
        <>
          <Divider />
          <h1 style={{ textAlign: 'center' }}>
            {t('Selected Email contacts', { contactsCount: selectedEmailContacts.length })}
          </h1>
          {selectedEmailContacts.length > 0 &&
            renderContactsList(selectedEmailContacts, SurveySendRoute.EMAIL)}
          <h1 className='i-linkedin' style={{ textAlign: 'center' }}>
            {t('Selected LinkedIn contacts', { contactsCount: selectedLinkedinContacts.length })}
          </h1>
          {selectedLinkedinContacts.length > 0 &&
            renderContactsList(selectedLinkedinContacts, SurveySendRoute.LINKEDIN)}
          <h1 className='i-facebook' style={{ textAlign: 'center' }}>
            {t('Selected Facebook contacts', { contactsCount: selectedFacebookContacts.length })}
          </h1>
          {selectedFacebookContacts.length > 0 &&
            renderContactsList(selectedFacebookContacts, SurveySendRoute.FACEBOOK)}
        </>
      )}

      <Divider />
      <ContactDetailModal
        isVisible={isVisibleContactDetailModal}
        onOk={(contact) => {
          setIsVisibleContactDetailModal(false);
          onSelect(contact, contact.surveySendRoute || SurveySendRoute.EMAIL);
          fetchContacts();
        }}
        onCancel={() => setIsVisibleContactDetailModal(false)}
      />
    </>
  );
};

export default ContactsListSend;
