import { Button, Card, Checkbox, Form, Input, message, Popconfirm, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Role } from '../../api/roles/model';
import RolesService from '../../api/roles/RolesService';
import { UserAddType, UserDetailType, UserUpdateType } from '../../api/users/model';
import UsersService from '../../api/users/UsersService';
import { validateAntForm } from '../../helpers/ant-form';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import BackButton from '../BackButton/BackButton';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

interface MatchProps {
  id: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  roleId: number;
  isActive: boolean;
}

interface Props extends FormComponentProps<FormValues> {}

const UsersDetail: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<MatchProps>();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [user, setUser] = useState<UserDetailType>();
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const roles = await RolesService.find();

        if (match.params.id) {
          const response = await UsersService.findOne(match.params.id);

          if (response) {
            setUser({
              id: response.id,
              firstName: response.firstName,
              lastName: response.lastName,
              isActive: response.isActive,
              email: response.email,
              roleId: response.roleId,
            });
          }
        } else {
          setUser({
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            isActive: false,
            roleId: roles[0].roleId,
          });
        }
        setRoles(roles);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [match.params.id]);

  if (isLoading) {
    return (
      <div className="container">
        <Card loading />
      </div>
    );
  }

  if (!user) {
    return <NotFoundPage />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const { firstName, lastName, email, roleId, isActive } = await validateAntForm(form);

      if (!user.id) {
        const entity: UserAddType = {
          email,
          firstName,
          lastName,
          roleId,
        };
        await UsersService.create(entity);
      } else {
        const entity: UserUpdateType = {
          id: user.id,
          firstName,
          lastName,
          isActive,
          roleId,
        };
        await UsersService.update(entity);
      }
      history.goBack();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setIsDeleting(true);
      await UsersService.delete(id);
      setIsDeleting(false);
      history.goBack();
    } catch (error) {
      setIsDeleting(false);
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className="container">
      <Card
        title={
          <>
            <BackButton />
            <span>
              {user.id ? `${user.firstName} ${user.lastName}` : `${t('New')} ${t('User')}`}
            </span>
          </>
        }
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label={t('First Name')} {...formItemLayout}>
            {form.getFieldDecorator('firstName', {
              initialValue: user.firstName,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            })(<Input autoFocus />)}
          </Form.Item>
          <Form.Item label={t('Last Name')} {...formItemLayout}>
            {form.getFieldDecorator('lastName', {
              initialValue: user.lastName,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            })(<Input />)}
          </Form.Item>
          {!user.id && (
            <Form.Item label={t('Email')} {...formItemLayout}>
              {form.getFieldDecorator('email', {
                initialValue: user.email,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t('This field is required'),
                  },
                  {
                    type: 'email',
                    message: t('The entered email is not valid'),
                  },
                ],
              })(<Input type="email" />)}
            </Form.Item>
          )}

          <Form.Item label={t('Role')} {...formItemLayout}>
            {form.getFieldDecorator('roleId', {
              initialValue: user.roleId || undefined,
              rules: [
                {
                  required: true,
                  message: t('This field is required'),
                },
              ],
            })(
              <Select placeholder={t('Select an option')}>
                {roles.map((role) => (
                  <Select.Option value={role.roleId} key={role.roleId}>
                    {role.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          {!!user.id && (
            <Form.Item label={t('Is Active')} {...formItemLayout}>
              {form.getFieldDecorator('isActive', {
                initialValue: user.isActive,
                valuePropName: 'checked',
              })(<Checkbox />)}
            </Form.Item>
          )}

          <Button.Group>
            <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>
              {t('Save')}
            </Button>
          </Button.Group>
          {!!user.id && (
            <Popconfirm
              title={t('Are you sure you want to delete this user?')}
              onConfirm={() => handleDelete(user.id.toString())}
            >
              <Button
                style={{ float: 'right' }}
                type="danger"
                htmlType="button"
                loading={isDeleting}
                disabled={isDeleting || isSubmitting}
              >
                {t('Delete')}
              </Button>
            </Popconfirm>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default Form.create()(UsersDetail);
