import React, { useState } from 'react';
import { Modal, Form, message, Input, Button, Popconfirm } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  ContactList,
  ContactListAddType,
  ContactListUpdateType,
} from '../../api/contacts/lists/model';
import { validateAntForm } from '../../helpers/ant-form';
import ContactsListsService from '../../api/contacts/lists/ContactsListsService';
import { useTranslation } from 'react-i18next';

interface FormValues {
  name: string;
}

interface Props extends FormComponentProps {
  list?: ContactList;
  isVisible: boolean;
  onOk: (contactList: ContactList) => void;
  onDeleted?: () => void;
  onCancel: () => void;
}

const FormListModal: React.FC<Props> = ({ form, isVisible, onCancel, onDeleted, onOk, list }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmit = async () => {
    try {
      const values = await validateAntForm<FormValues>(form);
      setIsSubmitting(true);

      let returnList: ContactList;

      if (!list) {
        const data: ContactListAddType = { name: values.name };
        const response = await ContactsListsService.add(data);
        returnList = {
          id: response,
          name: values.name,
        };
      } else {
        const data: ContactListUpdateType = { id: list.id, name: values.name };
        await ContactsListsService.update(data);
        returnList = list;
      }

      onOk(returnList);
      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      if (list) {
        setIsDeleting(true);
        await ContactsListsService.delete(list.id);
        setIsDeleting(false);
        if (onDeleted) {
          onDeleted();
        }
      }
    } catch (error) {
      setIsDeleting(false);
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  let title;
  if (list?.id) {
    title = t('Update List');
  } else {
    title = t('Add List');
  }

  return (
    <Modal
      visible={isVisible}
      title={title}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      cancelButtonProps={{ disabled: isSubmitting || isDeleting }}
      okButtonProps={{ disabled: isSubmitting || isDeleting }}
      confirmLoading={isSubmitting}
      onOk={handleSubmit}
    >
      <Form layout="vertical">
        <Form.Item label={t('Name')}>
          {form.getFieldDecorator('name', {
            initialValue: list?.name,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input autoFocus onPressEnter={handleSubmit} />)}
        </Form.Item>
      </Form>
      {list && (
        <Popconfirm
          title={t('This will leave all the contacts without this list')}
          onConfirm={() => handleDelete()}
        >
          <Button disabled={isDeleting} loading={isDeleting} type="danger">
            {t('Delete')}
          </Button>
        </Popconfirm>
      )}
    </Modal>
  );
};

export default Form.create<Props>()(FormListModal);
