import { Layout as AntLayout } from 'antd';
import React from 'react';
import Content from './Content';
import Header from './Header';

const Layout: React.FC = ({ children }) => (
  <AntLayout style={{ minHeight: '100vh' }}>
    <Header />
    <Content>{children}</Content>
  </AntLayout>
);

export default Layout;
