import { Button, Col, Divider, Form, Icon, Input, List, Row, Select, Tag, Tooltip } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { SurveyQuestionType, SurveyQuestionTypeLabel } from '../../api/surveys/enums';
import { SurveyQuestion } from '../../api/surveys/models';
import { setQuestionExplanationText } from '../../helpers/string-helpers';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { SurveysContext, SurveysEditContext } from './SurveysContext';
import {
  QUESTION_KEYS_FORM_FIELD_VALUE_KEY,
  FormValues as SurveysEditFormValues,
} from './SurveysEdit';
import SurveysQuestionsItemCollectionFormItems from './SurveysQuestionsItemCollectionFormItems';

interface Props {
  onRemoveQuestion: (k: number) => void;
  onSortEnd: (obj: any) => void;
}

const shouldDisplayItemCollectionForm = (
  form: WrappedFormUtils<SurveysEditFormValues>,
  k: number
) => {
  return form.getFieldValue(`questions[${k}].typeCode`) === SurveyQuestionType.PICTURE;
};

const DragHandle = SortableHandle(() => (
  <Icon type="drag" style={{ fontSize: '2rem', cursor: 'move' }} />
));

const SortableItem = SortableElement(({ value }: { value: any }) => (
  <div>
    <DragHandle />
    {value}
  </div>
));

const SortableList = SortableContainer(({ children }: { children: any[] }) => {
  return <List>{children}</List>;
});

const SurveysQuestionsFormItems: React.FC<Props> = ({ onRemoveQuestion, onSortEnd }) => {
  const { t } = useTranslation();
  const { isSubmittingForm, survey } = useContext(SurveysEditContext);
  const form = useContext(SurveysEditContext).form!;
  const surveyConfig = useContext(SurveysContext).config!;

  const jobCandidateFields = [
    'talent-first-name',
    'talent-last-name',
    'talent-name',
    'talent-linkedin-url',
    'talent-facebook-url',
    'talent-company',
    'talent-position',
  ];

  const shouldDisableQuestionTypeInput = (question: SurveyQuestion | undefined) => {
    return !!question?.typeCode.length;
  };

  const questionKeys = form.getFieldValue(QUESTION_KEYS_FORM_FIELD_VALUE_KEY) as number[];
  return !surveyConfig ? (
    <LoadingSpinner />
  ) : (
    <SortableList useDragHandle onSortEnd={onSortEnd}>
      {questionKeys.map((questionIndex, index) => (
        <SortableItem
          key={`questions[${questionIndex}]`}
          index={index}
          value={
            <>
              <Row
                key={`questions[${questionIndex}]`}
                type="flex"
                align="top"
                gutter={{ xs: 8, md: 16 }}
                style={{ position: 'relative' }}
              >
                <Col xs={22}>
                  <Form.Item label={t('Question Title')}>
                    {form.getFieldDecorator(`questions[${questionIndex}].title`, {
                      initialValue: survey?.surveyTemplateQuestionModels?.[questionIndex]?.title,
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: t('This field is required'),
                        },
                      ],
                    })(<Input placeholder={t('Question Title')} />)}
                  </Form.Item>
                  <Form.Item label={t('Fields')}>
                    {[...surveyConfig.emailFields, ...jobCandidateFields]
                      .filter((item) => item !== 'survey-link' && item !== 'sender-first-name')
                      .map((field) => (
                        <Tag
                          onClick={() => {
                            form.setFieldsValue({
                              [`questions[${questionIndex}].title`]: `${form.getFieldValue(
                                `questions[${questionIndex}].title`
                              )} [${field}] `,
                            });
                          }}
                        >
                          {`[${field}]`}
                        </Tag>
                      ))}
                  </Form.Item>
                  <Form.Item label={t('Question Explanation')}>
                    {form.getFieldDecorator(`questions[${questionIndex}].explanation`, {
                      initialValue:
                        survey?.surveyTemplateQuestionModels?.[questionIndex]?.explanation ||
                        setQuestionExplanationText(
                          survey?.surveyTemplateQuestionModels?.[questionIndex]?.typeCode,
                          t
                        ),
                      rules: [
                        {
                          whitespace: true,
                          message: t('This field is required'),
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label={t('Question Type')}>
                    {form.getFieldDecorator(`questions[${questionIndex}].typeCode`, {
                      initialValue: survey?.surveyTemplateQuestionModels?.[questionIndex]?.typeCode,
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: t('This field is required'),
                        },
                      ],
                    })(
                      <Select
                        placeholder={t('Question Type')}
                        disabled={shouldDisableQuestionTypeInput(
                          survey?.surveyTemplateQuestionModels?.[questionIndex]
                        )}
                        onChange={(value: any) =>
                          form.setFieldsValue({
                            [`questions[${questionIndex}].explanation`]: setQuestionExplanationText(
                              value,
                              t
                            ),
                          })
                        }
                      >
                        {Object.values(SurveyQuestionType).map((type) => (
                          <Select.Option key={type} value={type}>
                            {SurveyQuestionTypeLabel[type]}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  {shouldDisplayItemCollectionForm(form, questionIndex) && (
                    <SurveysQuestionsItemCollectionFormItems questionIndex={questionIndex} />
                  )}
                </Col>
                <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  {questionKeys.length > 1 ? (
                    <Tooltip title={t('Remove question')}>
                      <Button
                        loading={isSubmittingForm}
                        icon="minus-circle-o"
                        shape="circle-outline"
                        type="danger"
                        onClick={() => onRemoveQuestion(questionIndex)}
                      />
                    </Tooltip>
                  ) : null}
                </Col>

                {questionKeys.length > 1 && index < questionKeys.length - 1 ? <Divider /> : null}
              </Row>
            </>
          }
        />
      ))}
    </SortableList>
  );
};

export default SurveysQuestionsFormItems;
