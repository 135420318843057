import { API } from '../../config/api';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import { CustomField } from '../custom-fields/model';
import FetchService from '../shared/services/FetchService';

class CustomFieldsService {
  static async findOne(id: number) {
    const response = await FetchService.get<CustomField>({
      url: `${API.CUSTOM_FIELDS}/${id}`,
    });
    return response;
  }

  static async find(params: GridParams): Promise<CustomField[]> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<CustomField[]>({
      url: API.CUSTOM_FIELDS,
      gridParams,
    });
    return response;
  }

  static async findAll(): Promise<CustomField[]> {
    const response = await FetchService.get<CustomField[]>({
      url: API.CUSTOM_FIELDS_ALL,
    });
    return response;
  }

  static async edit(data: CustomField): Promise<number> {
    const response = await FetchService.post<number>({
      url: API.CUSTOM_FIELDS,
      body: data,
    });
    return response;
  }

  static async delete(data: CustomField): Promise<number> {
    const response = await FetchService.delete<number>({
      url: API.CUSTOM_FIELDS,
      body: data,
    });
    return response;
  }
  static async add(data: CustomField): Promise<number> {
    const response = await FetchService.put<number>({
      url: API.CUSTOM_FIELDS,
      body: data,
    });
    return response;
  }
}

export default CustomFieldsService;
