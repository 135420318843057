import queryString from 'query-string';
import React from 'react';
import { useJobByToken } from '../../hooks/jobs';
import SuggestCandidate from './SuggestCandidate';
import { JobStatusEnum } from '../../api/jobs/enums';
import { Redirect } from 'react-router-dom';
import { URLs } from '../../config/enums';

const SuggestCandidatePublic: React.FC = () => {
  const qs = queryString.parse(window.location.search) as { p: string };
  const { isLoadingJob, job } = useJobByToken(qs.p);

  if (job?.jobStatus === JobStatusEnum.ARCHIVED) {
    return <Redirect to={URLs.LOGIN} />;
  }

  return <SuggestCandidate isLoadingJob={isLoadingJob} job={job} />;
};

export default SuggestCandidatePublic;
