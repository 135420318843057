import {
  Alert,
  Avatar,
  Button,
  Divider,
  Dropdown,
  Empty,
  Icon,
  Input,
  List,
  Menu,
  Tooltip,
} from 'antd';
import classNames from 'classnames';
import { find, includes } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { ContactDefaultRoute, ContactDefaultRouteLabel } from '../../api/contacts/enums';
import { Contact } from '../../api/contacts/model';
import { createBaseGridParams, getUrlQueryString, GridParams } from '../../helpers/grid-helpers';
import { useContacts, useLists } from '../../hooks/contacts';
import LoadingSpinner from '../Loading/LoadingSpinner';
import ContactDetailModal from './ContactDetailModal';

interface Props {
  selectedContactIds?: number[];
  invitedContactIds?: number[];
  currentlySelectedContactId?: number;
  excludedContacts?: Contact[];
  isSelectingAllContacts?: boolean;
  extraButtons?: ReactNode;
  shouldRefetch?: boolean;
  showDefaultRoute?: boolean;
  onSelect: (contact: Contact) => void;
  onSelectAll?: (contacts: Contact[]) => void;
  onRemoveAll?: () => void;
  onSelectAllContacts?: () => void;
  onSelectContactListId?: (contactListId: number) => void;
  onRefetchCompleted?: () => void;
}

const gridParams = createBaseGridParams({ sortField: 'firstName', pageSize: 12 });

const ContactsList: React.FC<Props> = ({
  selectedContactIds = [],
  invitedContactIds = [],
  currentlySelectedContactId,
  excludedContacts,
  isSelectingAllContacts,
  extraButtons,
  shouldRefetch = false,
  showDefaultRoute = false,
  onSelect,
  onSelectAll,
  onRemoveAll,
  onSelectAllContacts,
  onSelectContactListId,
  onRefetchCompleted,
}) => {
  const { t } = useTranslation();
  const urlParams = getUrlQueryString();
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearch] = useDebounce(searchInput, 500);
  const [contactListId, setContactListId] = useState(0);
  const [isVisibleContactDetailModal, setIsVisibleContactDetailModal] = useState(false);
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    ...urlParams,
  });
  const { isLoadingContacts, contacts, total, fetchContacts } = useContacts({
    search: debouncedSearch,
    listId: contactListId,
    params: gridState,
  });
  const { isLoadingLists, lists } = useLists();

  useEffect(() => {
    if (onSelectContactListId) {
      onSelectContactListId(contactListId);
    }
  }, [contactListId, onSelectContactListId]);

  useEffect(() => {
    if (shouldRefetch && onRefetchCompleted) {
      fetchContacts();
      onRefetchCompleted();
    }
  }, [fetchContacts, onRefetchCompleted, shouldRefetch]);

  const handlePaginationChange = (pageNumber: number, pageSize: number | undefined) => {
    setGridState({ ...gridState, page: pageNumber - 1, pageSize: pageSize || 24 });
  };

  const renderActionButtonText = (contact: Contact) => {
    if (invitedContactIds.includes(contact.id)) {
      return t('Already Invited');
    }
    if (selectedContactIds.includes(contact.id) || isSelectingAllContacts) {
      return t('Selected');
    }
    return t('Select');
  };

  const renderHowManyContactsSelected = () => {
    if (!selectedContactIds.length || !isSelectingAllContacts) {
      let selectedContactsCount: number = 0;

      if (isSelectingAllContacts) {
        selectedContactsCount = total;
        if (excludedContacts) {
          selectedContactsCount -= excludedContacts.length;
        }
      } else {
        selectedContactsCount = selectedContactIds.length;
      }

      return (
        <Alert
          style={{ marginBottom: '1rem', textAlign: 'center' }}
          message={
            <>
              <span style={{ display: 'inline-block', padding: '0 2rem' }}>
                {t('Selected contacts', { contactsCount: selectedContactsCount })}
              </span>
            </>
          }
        />
      );
    }
    return null;
  };

  return (
    <>
      <Input.Search
        placeholder={`${t('Type to search a contact')}...`}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="jobs-new__search-contact"
        allowClear
        autoFocus
      />

      {isLoadingLists && <LoadingSpinner />}

      {!isLoadingLists && (
        <>
          <div
            style={{
              marginBottom: '2rem',
            }}
          >
            <Dropdown
              overlay={
                <Menu selectedKeys={[contactListId.toString()]}>
                  <Menu.Item key={0} onClick={() => setContactListId(0)}>
                    {t('All')}
                  </Menu.Item>
                  {lists.map((list) => (
                    <Menu.Item onClick={() => setContactListId(list.id)} key={list.id}>
                      {list.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <span style={{ fontSize: '2rem' }}>
                {!contactListId
                  ? t('All Contacts')
                  : find(lists, {
                      id: contactListId,
                    })?.name}{' '}
                ({isLoadingContacts ? '-' : total}){' '}
                <Icon style={{ fontSize: '1.6rem' }} type="down" />
              </span>
            </Dropdown>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '2rem',
            }}
          >
            {onSelectAllContacts && (
              <>
                {!isSelectingAllContacts && (
                  <Button onClick={() => onSelectAllContacts()}>{t('Select all in list')}</Button>
                )}
                {isSelectingAllContacts && (
                  <Button type="link" onClick={() => onSelectAllContacts()}>
                    {t('Remove selection')}
                  </Button>
                )}
              </>
            )}

            <Button.Group>
              {onSelectAll && (
                <Button disabled={isSelectingAllContacts} onClick={() => onSelectAll(contacts)}>
                  {t('Select all in page')}
                </Button>
              )}
              {onRemoveAll && (
                <Button
                  disabled={isSelectingAllContacts || !selectedContactIds.length}
                  onClick={() => onRemoveAll()}
                >
                  {t('Remove all')}
                </Button>
              )}
              <Tooltip title={t('Add Contact')}>
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => setIsVisibleContactDetailModal(true)}
                  disabled={isSelectingAllContacts}
                />
              </Tooltip>
              {extraButtons}
            </Button.Group>
          </div>
        </>
      )}

      {isLoadingContacts && <LoadingSpinner />}

      {renderHowManyContactsSelected()}

      {!isLoadingContacts && !!contacts.length && (
        <List
          dataSource={contacts}
          pagination={{
            onChange: handlePaginationChange,
            current: gridState.page! + 1,
            total,
            pageSize: gridState.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['12', '24', '36', '48'],
            onShowSizeChange: handlePaginationChange,
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
          }}
          renderItem={(contact) => {
            return (
              <List.Item
                className={classNames('jobs-new__list-item', {
                  'jobs-new__list-item--selected':
                    selectedContactIds.includes(contact.id) ||
                    (isSelectingAllContacts && !includes(excludedContacts, contact)),
                })}
                extra={
                  <Button.Group>
                    <Button
                      className={classNames('jobs-new__btn jobs-new__btn--primary', {
                        'jobs-new__btn--selected':
                          selectedContactIds.includes(contact.id) ||
                          (isSelectingAllContacts && !includes(excludedContacts, contact)),
                      })}
                      type="link"
                      onClick={() => onSelect(contact)}
                      disabled={
                        currentlySelectedContactId === contact.id ||
                        invitedContactIds.includes(contact.id)
                      }
                    >
                      {renderActionButtonText(contact)}
                    </Button>
                    {invitedContactIds.includes(contact.id) && (
                      <Button
                        className={classNames('jobs-new__btn jobs-new__btn--primary', {
                          'jobs-new__btn--selected':
                            selectedContactIds.includes(contact.id) ||
                            (isSelectingAllContacts && !includes(excludedContacts, contact)),
                        })}
                        type="link"
                        onClick={() => onSelect(contact)}
                        disabled={currentlySelectedContactId === contact.id}
                        loading={currentlySelectedContactId === contact.id}
                      >
                        {t('Invite again')}
                      </Button>
                    )}
                  </Button.Group>
                }
              >
                <List.Item.Meta
                  avatar={
                    contact.pictureUrl ? (
                      <Avatar src={contact.pictureUrl} />
                    ) : (
                      <Avatar icon="user" />
                    )
                  }
                  title={`${contact.firstName} ${contact.lastName}`}
                  description={
                    <>
                      <span>{contact.email}</span> <br />
                      {showDefaultRoute && (
                        <span>{`${t('Default campaign route')}: ${
                          ContactDefaultRouteLabel[
                            contact?.defaultCampaignSendRoute as ContactDefaultRoute
                          ] || t('Not set')
                        }`}</span>
                      )}
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      )}

      {!isLoadingContacts && searchInput && contacts.length === 0 && <Empty />}

      <Divider />

      <ContactDetailModal
        isVisible={isVisibleContactDetailModal}
        onOk={(contact) => {
          setIsVisibleContactDetailModal(false);
          onSelect(contact);
          fetchContacts();
        }}
        onCancel={() => setIsVisibleContactDetailModal(false)}
      />
    </>
  );
};

export default ContactsList;
