import React, { useEffect } from 'react';
import { DispatchProp } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';
import store from '../../state';
import { logout } from '../../state/auth';

interface Props extends DispatchProp {}

const Logout: React.FC<Props> = () => {
  const { user } = useAppContext();

  useEffect(() => {
    if (user) {
      store.dispatch(logout());
    }
  }, [user]);

  if (!user) {
    return <Redirect to={URLs.LOGIN} />;
  }

  return <Loading />;
};

export default Logout;
