import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/header-logo.png';
import { URLs } from '../config/enums';

const HeaderLogo: React.FC = () => {
  return (
    <Link to={URLs.ROOT}>
      <img
        src={logo}
        alt="Talentlinkr"
        style={{
          padding: 5,
          display: 'block',
          maxHeight: 80,
          maxWidth: '100%',
        }}
      />
    </Link>
  );
};

export default HeaderLogo;
