import { FacebookProfileResponse } from '../models';

class FacebookService {
  static login() {
    return new Promise<fb.AuthResponse>((resolve, reject) => {
      FB.login(
        (response) => {
          if (response.authResponse) {
            resolve(response.authResponse);
          } else {
            reject();
          }
        },
        {
          auth_type: 'rerequest',
          scope: 'public_profile,email',
        }
      );
    });
  }

  static getProfile() {
    return new Promise<FacebookProfileResponse>((resolve) => {
      FB.api<
        {
          fields: string;
        },
        FacebookProfileResponse
      >(
        '/me',
        {
          fields: 'first_name,last_name,picture,email',
        },
        (response) => {
          resolve(response);
        }
      );
    });
  }
}

export default FacebookService;
