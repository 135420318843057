import { message, Row, Col, Button, List, Collapse } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Route, Switch } from 'react-router-dom';
import { ReactComponent as IconSurveys } from '../../assets/icon-surveys.svg';
import { FetchedList } from '../../api/shared/models';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';
import { Campaign } from '../../api/campaigns/model';
import CampaignTile from './CampaignTile';
import CampaignsService from '../../api/campaigns/CampaignsService';
import { CampaignStatusEnum } from '../../api/campaigns/enum';
import { CampaignsContext } from './CampaignsContext';
import CampaignDetail from './CampaignDetail';
import CampaignsEdit from './CampaignsEdit';
import CampaignsEditCustomizeMessage from './CampaignsEditCustomizeMessage';
import CampaignsSelectOne from './CampaignsSelectOne';

const Campaigns: React.FC = () => {
  const { mainHeaderHeight, subHeaderHeight, setSubHeaderHeight } = useAppContext();
  const subHeaderRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const history = useHistory();
  const [campaigns, setCampaigns] = useState<Campaign[]>();
  const [completedCampaigns, setCompletedCampaigns] = useState<Campaign[]>();
  const [archivedCampaigns, setArchivedCampaigns] = useState<Campaign[]>();
  const [, setTotal] = useState(0);
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
  const [isLoadingArchivedCampaigns, setIsLoadingArchivedCampaigns] = useState(true);

  const fetchCampaigns = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingCampaigns(true);
        const response: FetchedList<Campaign> = await CampaignsService.findAll();

        setCampaigns(response.list.filter((data) => data.campaignStatus != CampaignStatusEnum.Completed));
        setCompletedCampaigns(response.list.filter((data) => data.campaignStatus == CampaignStatusEnum.Completed))
        setTotal(response.count);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingCampaigns(false);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  const fetchArchivedCampaigns = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingArchivedCampaigns(true);
        const response: FetchedList<Campaign> = await CampaignsService.findArchiveCampaigns();

        setArchivedCampaigns(response.list);
        setTotal(response.count);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingArchivedCampaigns(false);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    fetchArchivedCampaigns();
  }, [fetchArchivedCampaigns])

  useEffect(() => {
    setSubHeaderHeight(subHeaderRef.current!.clientHeight);
  }, [setSubHeaderHeight]);

  return (
    <>
      <div className="sub-header" ref={subHeaderRef} style={{ height: '5rem' }} />

      <div className="container" style={{ paddingTop: 0 }}>
        <Row gutter={16}>
          <Col xs={24} md={10} lg={7} xl={7} xxl={6} style={{ padding: 0 }}>
            <div
              className="column-list"
              style={{
                height: `calc(100vh - ${mainHeaderHeight + subHeaderHeight + 10}px)`,
              }}
            >
              <Button
                onClick={() => history.push(URLs.CAMPAIGNS_NEW)}
                block
                className="add-new-button"
              >
                <span className="add-new-button__icon-bg">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <IconSurveys fill="white" width="32px" height="32px" />
                  </div>
                </span>
                <p className="add-new-button__text">{t('Add new campaign')}</p>
              </Button>
              <Collapse expandIconPosition="right" className="job-email-collapse">
                <Collapse.Panel
                  disabled={isLoadingCampaigns}
                  key='1'
                  header={<span className="job-email-collapse__subject">{t('Campaigns')}</span>}
                  className="job-email-collapse__item"
                >
                  <List
                    dataSource={campaigns}
                    loading={isLoadingCampaigns}
                    renderItem={(campaign) => (
                      <List.Item style={{ borderBottom: 0, padding: '.5rem 0' }} key={campaign.id}>
                        <CampaignTile campaign={campaign} />
                      </List.Item>
                    )}
                  ></List>
                </Collapse.Panel>
              </Collapse>
              <Collapse expandIconPosition="right" className="job-email-collapse">
                <Collapse.Panel
                  disabled={isLoadingCampaigns}
                  key={'CampaignStatusEnum.Archived'}
                  header={<span className="job-email-collapse__subject">{t('Archived Campaigns')}</span>}
                  className="job-email-collapse__item"
                >
                  <List
                    dataSource={archivedCampaigns}
                    loading={isLoadingArchivedCampaigns}
                    renderItem={(archivedCampaign) => (
                      <List.Item style={{ borderBottom: 0, padding: '.5rem 0' }} key={archivedCampaign.id}>
                        <CampaignTile campaign={archivedCampaign} />
                      </List.Item>
                    )}
                  ></List>
                </Collapse.Panel>
              </Collapse>
              <Collapse expandIconPosition="right" className="job-email-collapse">
                <Collapse.Panel
                  disabled={isLoadingCampaigns}
                  key={'CampaignStatusEnum.Archived'}
                  header={<span className="job-email-collapse__subject">{t('Completed Campaigns')}</span>}
                  className="job-email-collapse__item"
                >
                  <List
                    dataSource={completedCampaigns}
                    loading={isLoadingArchivedCampaigns}
                    renderItem={(campaign) => (
                      <List.Item style={{ borderBottom: 0, padding: '.5rem 0' }} key={campaign.id}>
                        <CampaignTile campaign={campaign} />
                      </List.Item>
                    )}
                  ></List>
                </Collapse.Panel>
              </Collapse>
            </div>
          </Col>
          <Col xs={24} md={14} lg={17} xl={17} xxl={18}>
            <div
              style={{
                paddingTop: '1.5rem',
              }}
            >
              <CampaignsContext.Provider value={{ fetchCampaigns, fetchArchivedCampaigns }}>
                <Switch>
                  <Route exact path={URLs.CAMPAIGNS_NEW} component={CampaignsEdit} />
                  <Route exact path={URLs.CAMPAIGNS_DETAIL} component={CampaignDetail} />
                  <Route exact path={URLs.CAMPAIGNS_EDIT} component={CampaignsEdit} />
                  <Route exact path={URLs.CAMPAIGNS_COMPLETED} component={CampaignDetail} />
                  <Route exact path={URLs.CAMPAIGNS_DRAFT} component={CampaignsEdit} />
                  <Route exact path={URLs.CAMPAIGNS} component={CampaignsSelectOne} />
                  <Route exact path={URLs.CAMPAIGNS_CUSTOMIZE_MESSAGES} component={CampaignsEditCustomizeMessage} />
                </Switch>
              </CampaignsContext.Provider>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Campaigns;
