export enum JobCandidateStatusEnum {
  NEW = 'NEW',
  REJECTED_RECRUITER = 'REJECTED_RECRUITER',
  REJECTED_CLIENT = 'REJECTED_CLIENT',
  SENT_CLIENT = 'SENT_CLIENT',
  ACCEPTED = 'ACCEPTED',
}

export const JobCandidateStatusEnumLabel = {
  [JobCandidateStatusEnum.NEW]: 'New',
  [JobCandidateStatusEnum.REJECTED_RECRUITER]: 'Rejected by recruiter',
  [JobCandidateStatusEnum.REJECTED_CLIENT]: 'Rejected by client',
  [JobCandidateStatusEnum.SENT_CLIENT]: 'Sent Client',
  [JobCandidateStatusEnum.ACCEPTED]: 'Accepted',
};

export enum JobCandidateOrdersEnum {
  NAME = 'NAME',
  RECOMMENDATION_COUNT = 'RECOMMENDATION_COUNT',
  RECOMMENDATION_DATE = 'RECOMMENDATION_DATE'
}

export const JobCandidateOrdersLabels = {
  [JobCandidateOrdersEnum.NAME] : 'Name',
  [JobCandidateOrdersEnum.RECOMMENDATION_COUNT] : 'Recommendation Count',
  [JobCandidateOrdersEnum.RECOMMENDATION_DATE] : 'Recommendation Date'
}

export const JobCandidateOrdersValues = {
  [JobCandidateOrdersEnum.NAME] : 'name',
  [JobCandidateOrdersEnum.RECOMMENDATION_COUNT] : 'recommendationCount',
  [JobCandidateOrdersEnum.RECOMMENDATION_DATE] : 'createdDate'
}
