/* eslint-disable no-restricted-globals */
import {
  Card,
  Button,
  Row,
  Col,
  Tag,
  Checkbox,
  List,
  message,
  Tooltip,
  Popconfirm,
  Dropdown,
  Menu,
  Icon,
} from 'antd';
import { SortOrder } from 'antd/lib/table';
import { filter, map, without } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  JobCandidateOrdersEnum,
  JobCandidateOrdersLabels,
  JobCandidateOrdersValues,
  JobCandidateStatusEnum,
} from '../../api/job-candidates/enums';
import JobCandidateService from '../../api/job-candidates/JobCandidatesService';
import { JobCandidate } from '../../api/job-candidates/model';
import { JobStatusEnum } from '../../api/jobs/enums';
import { Job } from '../../api/jobs/model';
import FetchService from '../../api/shared/services/FetchService';
import { API } from '../../config/api';
import { URLs } from '../../config/enums';
import { API_URL } from '../../config/general-config';
import { createBaseGridParams, transformGridParams } from '../../helpers/grid-helpers';
import JobCandidateDetailModal from './JobCandidateDetailModal';
import JobCandidateEditModal from './JobCandidateEditModal';
import JobCandidateFileMakerSelModal from './JobCandidateFileMakerSelModal';
import JobCandidateTile from './JobCandidateTile';

interface Props {
  job: Job;
}

const JobDetailCandidatesTab: React.FC<Props> = ({ job }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false);
  const [candidates, setCandidates] = useState<JobCandidate[]>([]);
  const [isSendingToClient, setIsSendingToClient] = useState(false);
  const [selectedNewCandidateIds, setSelectedNewCandidateIds] = useState<number[]>([]);
  const [selectedRejectedCandidateIds, setSelectedRejectedCandidateIds] = useState<number[]>([]);
  const [selectedSentCandidateIds, setSelectedSentCandidateIds] = useState<number[]>([]);
  const [selectedAcceptedCandidateIds, setSelectedAcceptedCandidateIds] = useState<number[]>([]);
  const [selectedAllNew, setSelectedAllNew] = useState(false);
  const [selectedAllAccepted, setSelectedAllAccepted] = useState(false);
  const [selectedAllRejected, setSelectedAllRejected] = useState(false);
  const [selectedAllSent, setSelectedAllSent] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [isDeletingCandidateIds, setIsDeletingCandidateIds] = useState<number[]>([]);
  const [selectedCandidateToView, setSelectedCandidateToView] = useState<JobCandidate>();
  const [selectedCandidateToEdit, setSelectedCandidateToEdit] = useState<JobCandidate>();
  const [isVisibleCandidateViewModal, setIsVisibleCandidateViewModal] = useState(false);
  const [isVisibleCandidateEditModal, setIsVisibleCandidateEditModal] = useState(false);
  const [isVisibleCandidateFileMakerSelModal, setIsVisibleCandidateFileMakerSelModal] = useState(
    false
  );

  const [isLoadingFilemakerCandidates, setIsLoadingFilemakerCandidates] = useState(false);
  const [selectedFilemakerExportCandidates, setSelectedFilemakerExportCandidates] = useState<
    JobCandidate[]
  >([]);

  const [isOrderedBy, setIsOrderedBy] = useState(JobCandidateOrdersEnum.NAME);
  const gridState = createBaseGridParams({
    sortField: JobCandidateOrdersValues[isOrderedBy as JobCandidateOrdersEnum],
    sortOrder: 'ascend',
    pageSize: 0,
  });
  const [gridParams, setGridPrams] = useState(gridState);

  const setFilemakerCandidates = () => {
    setIsLoadingFilemakerCandidates(true);
    let chosenCandidates = [
      ...selectedNewCandidateIds,
      ...selectedRejectedCandidateIds,
      ...selectedSentCandidateIds,
      ...selectedAcceptedCandidateIds,
    ];
    setSelectedFilemakerExportCandidates(
      filter(candidates, (candidate) => chosenCandidates.includes(candidate.id))
    );
    setIsLoadingFilemakerCandidates(false);
  };

  const fetchCandidates = useCallback(() => {
    const fetch = async () => {
      try {
        if (job) {
          setIsLoadingCandidates(true);
          const response = await JobCandidateService.find(job.id, gridParams);
          setCandidates(response);
          setSelectedAllNew(false);
          setSelectedAllRejected(false);
          setSelectedAllSent(false);
        } else {
          setCandidates([]);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingCandidates(false);
      }
    };
    fetch();
  }, [job, gridParams]);

  const sendToClient = async () => {
    try {
      setIsSendingToClient(true);
      await JobCandidateService.changeStatus({
        jobId: job.id,
        status: JobCandidateStatusEnum.SENT_CLIENT,
        jobCandidateIds: selectedNewCandidateIds,
      });
      fetchCandidates();
      setSelectedNewCandidateIds([]);
    } catch (error) {
    } finally {
      setIsSendingToClient(false);
    }
  };

  const restoreCandidates = async () => {
    try {
      setIsRestoring(true);
      await JobCandidateService.changeStatus({
        jobId: job.id,
        status: JobCandidateStatusEnum.NEW,
        jobCandidateIds: [
          ...selectedRejectedCandidateIds,
          ...selectedSentCandidateIds,
          ...selectedAcceptedCandidateIds,
        ],
      });
      fetchCandidates();
      setSelectedRejectedCandidateIds([]);
      setSelectedSentCandidateIds([]);
      setSelectedAcceptedCandidateIds([]);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsRestoring(false);
    }
  };

  const rejectCandidates = async () => {
    try {
      setIsRejecting(true);
      await JobCandidateService.changeStatus({
        jobId: job.id,
        status: JobCandidateStatusEnum.REJECTED_RECRUITER,
        jobCandidateIds: selectedNewCandidateIds,
      });
      fetchCandidates();
      setSelectedNewCandidateIds([]);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsRejecting(false);
    }
  };

  const deleteCandidate = async (candidate: JobCandidate) => {
    try {
      setIsDeletingCandidateIds([...isDeletingCandidateIds, candidate.id]);
      await JobCandidateService.delete(candidate.id);
      fetchCandidates();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsDeletingCandidateIds(without(isDeletingCandidateIds, candidate.id));
    }
  };

  const handleCreateSurveyClick = (jobCandidate: JobCandidate) => {
    history.push(
      URLs.JOBS_DETAIL_CREATE_SURVEY.replace(':jobId', job.id.toString()).replace(
        ':candidateId',
        jobCandidate.id.toString()
      )
    );
  };

  const handleSelectNewCandidate = (checked: boolean, candidateId: number) => {
    if (checked) {
      setSelectedNewCandidateIds([...selectedNewCandidateIds, candidateId]);
      if (newCandidates.length === selectedNewCandidateIds.length + 1) {
        setSelectedAllNew(true);
      }
    } else {
      if (selectedNewCandidateIds.length === 1) {
        setSelectedAllNew(false);
      }
      setSelectedNewCandidateIds(without(selectedNewCandidateIds, candidateId));
    }
  };

  const handleSelectRejectedCandidate = (checked: boolean, candidateId: number) => {
    if (checked) {
      setSelectedRejectedCandidateIds([...selectedRejectedCandidateIds, candidateId]);
      if (rejectedCandidates.length === selectedRejectedCandidateIds.length + 1) {
        setSelectedAllRejected(true);
      }
    } else {
      if (setSelectedRejectedCandidateIds.length === 1) {
        setSelectedAllRejected(false);
      }
      setSelectedRejectedCandidateIds(without(selectedRejectedCandidateIds, candidateId));
    }
  };

  const handleSelectSentCandidate = (checked: boolean, candidateId: number) => {
    if (checked) {
      setSelectedSentCandidateIds([...selectedSentCandidateIds, candidateId]);
      if (sentCandidates.length === selectedSentCandidateIds.length + 1) {
        setSelectedAllSent(true);
      }
    } else {
      if (selectedSentCandidateIds.length === 1) {
        setSelectedAllSent(false);
      }
      setSelectedSentCandidateIds(without(selectedSentCandidateIds, candidateId));
    }
  };

  const handleSelectAcceptedCandidate = (checked: boolean, candidateId: number) => {
    if (checked) {
      setSelectedAcceptedCandidateIds([...selectedAcceptedCandidateIds, candidateId]);
      if (acceptedCandidates.length === selectedAcceptedCandidateIds.length + 1) {
        setSelectedAllAccepted(true);
      }
    } else {
      if (selectedAcceptedCandidateIds.length === 1) {
        setSelectedAllAccepted(false);
      }
      setSelectedAcceptedCandidateIds(without(selectedAcceptedCandidateIds, candidateId));
    }
  };

  useEffect(() => {
    fetchCandidates();
    return () => {
      setCandidates([]);
    };
  }, [job, fetchCandidates]);

  useEffect(() => {
    if (selectedCandidateToView) {
      setIsVisibleCandidateViewModal(true);
    } else {
      setIsVisibleCandidateViewModal(false);
    }
  }, [selectedCandidateToView]);

  useEffect(() => {
    if (selectedCandidateToEdit) {
      setIsVisibleCandidateEditModal(true);
    } else {
      setIsVisibleCandidateEditModal(false);
    }
  }, [selectedCandidateToEdit]);

  const newCandidates = filter(candidates, {
    status: JobCandidateStatusEnum.NEW,
  });
  const rejectedCandidates = filter(
    candidates,
    (candidate) =>
      candidate.status === JobCandidateStatusEnum.REJECTED_CLIENT ||
      candidate.status === JobCandidateStatusEnum.REJECTED_RECRUITER
  );
  const sentCandidates = filter(candidates, {
    status: JobCandidateStatusEnum.SENT_CLIENT,
  });
  const acceptedCandidates = filter(candidates, {
    status: JobCandidateStatusEnum.ACCEPTED,
  });

  const exportCandidates = async () => {
    setIsLoadingCandidates(true);
    try {
      let selectedCandidateIds = [
        ...selectedNewCandidateIds,
        ...selectedRejectedCandidateIds,
        ...selectedSentCandidateIds,
        ...selectedAcceptedCandidateIds,
      ];

      let fetchParams = createBaseGridParams({
        ...gridParams,
        filters: [
          { key: 'jobId', value: job.id },
          { key: 'jobCandidateIds', value: selectedCandidateIds },
        ],
      });

      let params = transformGridParams(fetchParams);
      let queryString = `/?${params.filters}&sortField=${
        gridParams.sortField
      }&sortDirection=${FetchService._adjustSortDirection(params.sortDirection)}`;
      location.href = `${API_URL}${API.JOB_CANDIDATES_EXPORT}${queryString}`;
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoadingCandidates(false);
    }
  };

  return (
    <>
      <Card className="job-offer__candidates">
        <div className="job-offer__candidates-header">
          <Button.Group>
            <Button
              type="primary"
              onClick={sendToClient}
              disabled={
                !selectedNewCandidateIds.length ||
                isSendingToClient ||
                isRejecting ||
                isRestoring ||
                selectedAcceptedCandidateIds.length > 0 ||
                selectedRejectedCandidateIds.length > 0 ||
                selectedSentCandidateIds.length > 0
              }
            >
              {t('Send to client')}
            </Button>
            <Button
              type="danger"
              onClick={rejectCandidates}
              disabled={
                !selectedNewCandidateIds.length ||
                isRejecting ||
                isSendingToClient ||
                isRestoring ||
                selectedAcceptedCandidateIds.length > 0 ||
                selectedRejectedCandidateIds.length > 0 ||
                selectedSentCandidateIds.length > 0
              }
            >
              {t('Reject')}
            </Button>
            <Button
              onClick={restoreCandidates}
              disabled={
                (!selectedRejectedCandidateIds.length &&
                  !selectedSentCandidateIds.length &&
                  !selectedAcceptedCandidateIds.length) ||
                isRestoring ||
                isRejecting ||
                isSendingToClient ||
                selectedNewCandidateIds.length > 0
              }
            >
              {t('Restore status')}
            </Button>
          </Button.Group>
          <Button.Group>
            <Tooltip
              title={
                (!job.fileMakerIdMission &&
                  !job.fileMakerJobTitle &&
                  t('The job must be associated with a FMPRO Job before exporting')) ||
                (!selectedNewCandidateIds.length &&
                  !selectedRejectedCandidateIds.length &&
                  !selectedSentCandidateIds.length &&
                  !selectedAcceptedCandidateIds.length &&
                  t('You must choose at least one candidate'))
              }
            >
              {(job.fileMakerIdMission && (
                <Button
                  onClick={() => {
                    setIsVisibleCandidateFileMakerSelModal(true);
                    setFilemakerCandidates();
                  }}
                  disabled={
                    !candidates.length ||
                    isLoadingCandidates ||
                    (!job.fileMakerJobTitle && !job.fileMakerIdMission) ||
                    (!selectedNewCandidateIds.length &&
                      !selectedRejectedCandidateIds.length &&
                      !selectedSentCandidateIds.length &&
                      !selectedAcceptedCandidateIds.length)
                  }
                >
                  {t('Export to FileMaker')}
                </Button>
              )) || (
                <Popconfirm
                  title={t('Job not fully associated')}
                  disabled={
                    !candidates.length ||
                    isLoadingCandidates ||
                    (!job.fileMakerJobTitle && !job.fileMakerIdMission) ||
                    (!selectedNewCandidateIds.length &&
                      !selectedRejectedCandidateIds.length &&
                      !selectedSentCandidateIds.length &&
                      !selectedAcceptedCandidateIds.length)
                  }
                  onConfirm={() => {
                    setIsVisibleCandidateFileMakerSelModal(true);
                    setFilemakerCandidates();
                  }}
                >
                  <Button
                    disabled={
                      !candidates.length ||
                      isLoadingCandidates ||
                      (!job.fileMakerJobTitle && !job.fileMakerIdMission) ||
                      (!selectedNewCandidateIds.length &&
                        !selectedRejectedCandidateIds.length &&
                        !selectedSentCandidateIds.length &&
                        !selectedAcceptedCandidateIds.length)
                    }
                  >
                    {t('Export to FileMaker')}
                  </Button>
                </Popconfirm>
              )}
            </Tooltip>
            <Tooltip
              title={
                !selectedNewCandidateIds.length &&
                !selectedRejectedCandidateIds.length &&
                !selectedSentCandidateIds.length &&
                !selectedAcceptedCandidateIds.length &&
                t('You must choose at least one candidate')
              }
            >
              <Button
                type="primary"
                onClick={exportCandidates}
                target="_blank"
                disabled={
                  !candidates.length ||
                  isLoadingCandidates ||
                  (!selectedNewCandidateIds.length &&
                    !selectedRejectedCandidateIds.length &&
                    !selectedSentCandidateIds.length &&
                    !selectedAcceptedCandidateIds.length)
                }
              >
                {t('Export Candidates')}
              </Button>
            </Tooltip>
          </Button.Group>
        </div>

        <Row>
          <Col xs={24} sm={24} md={24} lg={10}>
            <Dropdown
              overlay={
                <Menu selectedKeys={[isOrderedBy as JobCandidateOrdersEnum]}>
                  {Object.keys(JobCandidateOrdersValues).map((key) => (
                    <Menu.Item
                      key={key}
                      onClick={(e: any) => {
                        setIsOrderedBy(e.key as JobCandidateOrdersEnum);
                        setGridPrams({
                          ...gridParams,
                          sortField: JobCandidateOrdersValues[e.key as JobCandidateOrdersEnum],
                        });
                      }}
                    >
                      {t(JobCandidateOrdersLabels[key as JobCandidateOrdersEnum])}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button
                block
                className="add-new-button"
                onClick={() => {
                  let sortOrder: SortOrder =
                    gridParams.sortOrder === 'ascend' ? 'descend' : 'ascend';
                  setGridPrams({ ...gridParams, sortOrder });
                }}
              >
                <span className="add-new-button__text">
                  {t('Order By')}:{' '}
                  {t(JobCandidateOrdersLabels[isOrderedBy as JobCandidateOrdersEnum])}
                  {'  '}
                  <Icon
                    style={{ fontSize: '1.7rem' }}
                    type={gridParams.sortOrder === 'ascend' ? 'arrow-up' : 'arrow-down'}
                  />
                </span>
              </Button>
            </Dropdown>
          </Col>
        </Row>
        {/* New Candidates */}
        <Row gutter={16} style={{ marginBottom: '2rem' }}>
          <Col xs={24}>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '1rem',
                position: 'relative',
              }}
            >
              <Tag className="job-offer__candidates-tag job-offer__candidates-tag--new">
                {t('New')}
              </Tag>
              <Checkbox
                className="job-offer__candidates-tag-check-all"
                disabled={
                  !newCandidates.length ||
                  job.jobStatus === JobStatusEnum.ARCHIVED ||
                  isLoadingCandidates
                }
                checked={selectedAllNew}
                onChange={(e) => {
                  setSelectedAllNew(e.target.checked);
                  if (e.target.checked) {
                    setSelectedNewCandidateIds(map(newCandidates, 'id'));
                  } else {
                    setSelectedNewCandidateIds([]);
                  }
                }}
              >
                {t('Select all')}
              </Checkbox>
            </div>
            <List
              grid={{ gutter: 16, column: 2 }}
              dataSource={newCandidates}
              loading={isLoadingCandidates}
              renderItem={(jobCandidate) => (
                <JobCandidateTile
                  job={job}
                  onClickView={() => setSelectedCandidateToView(jobCandidate)}
                  onClickEdit={() => setSelectedCandidateToEdit(jobCandidate)}
                  onClickDelete={() => deleteCandidate(jobCandidate)}
                  onClickCreateSurvey={() => handleCreateSurveyClick(jobCandidate)}
                  jobCandidate={jobCandidate}
                  selectedCandidateIds={selectedNewCandidateIds}
                  deletingCandidateIds={isDeletingCandidateIds}
                  onSelect={handleSelectNewCandidate}
                />
              )}
            />
          </Col>

          {/* Rejected Candidates */}
          <Col xs={24}>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '1rem',
                position: 'relative',
              }}
            >
              <Tag className="job-offer__candidates-tag job-offer__candidates-tag--rejected">
                {t('Rejected')}
              </Tag>
              <Checkbox
                className="job-offer__candidates-tag-check-all"
                disabled={
                  !rejectedCandidates.length ||
                  job.jobStatus === JobStatusEnum.ARCHIVED ||
                  isLoadingCandidates
                }
                checked={selectedAllRejected}
                onChange={(e) => {
                  setSelectedAllRejected(e.target.checked);
                  if (e.target.checked) {
                    setSelectedRejectedCandidateIds(map(rejectedCandidates, 'id'));
                  } else {
                    setSelectedRejectedCandidateIds([]);
                  }
                }}
              >
                {t('Select all')}
              </Checkbox>
            </div>
            <List
              grid={{ gutter: 16, column: 2 }}
              dataSource={rejectedCandidates}
              loading={isLoadingCandidates}
              renderItem={(candidate) => (
                <JobCandidateTile
                  job={job}
                  onClickView={() => setSelectedCandidateToView(candidate)}
                  onClickEdit={() => setSelectedCandidateToEdit(candidate)}
                  onClickDelete={() => deleteCandidate(candidate)}
                  onClickCreateSurvey={() => handleCreateSurveyClick(candidate)}
                  jobCandidate={candidate}
                  selectedCandidateIds={selectedRejectedCandidateIds}
                  deletingCandidateIds={isDeletingCandidateIds}
                  onSelect={handleSelectRejectedCandidate}
                />
              )}
            />
          </Col>

          {/* Sent Candidates */}
          <Col xs={24}>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '1rem',
                position: 'relative',
              }}
            >
              <Tag className="job-offer__candidates-tag job-offer__candidates-tag--sent">
                {t('Sent to client')}
              </Tag>
              <Checkbox
                className="job-offer__candidates-tag-check-all"
                disabled={!sentCandidates.length || job.jobStatus === JobStatusEnum.ARCHIVED}
                checked={selectedAllSent}
                onChange={(e) => {
                  setSelectedAllSent(e.target.checked);
                  if (e.target.checked) {
                    setSelectedSentCandidateIds(map(sentCandidates, 'id'));
                  } else {
                    setSelectedSentCandidateIds([]);
                  }
                }}
              >
                {t('Select all')}
              </Checkbox>
            </div>
            <List
              grid={{ gutter: 16, column: 2 }}
              dataSource={sentCandidates}
              loading={isLoadingCandidates}
              renderItem={(candidate) => (
                <JobCandidateTile
                  job={job}
                  onClickView={() => setSelectedCandidateToView(candidate)}
                  onClickEdit={() => setSelectedCandidateToEdit(candidate)}
                  onClickDelete={() => deleteCandidate(candidate)}
                  onClickCreateSurvey={() => handleCreateSurveyClick(candidate)}
                  jobCandidate={candidate}
                  selectedCandidateIds={selectedSentCandidateIds}
                  deletingCandidateIds={isDeletingCandidateIds}
                  onSelect={handleSelectSentCandidate}
                />
              )}
            />
          </Col>

          {/* Accepted Candidates */}
          <Col xs={24}>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '1rem',
                position: 'relative',
              }}
            >
              <Tag className="job-offer__candidates-tag job-offer__candidates-tag--accepted">
                {t('Accepted')}
              </Tag>
              <Checkbox
                className="job-offer__candidates-tag-check-all"
                disabled={!acceptedCandidates.length || job.jobStatus === JobStatusEnum.ARCHIVED}
                checked={selectedAllAccepted}
                onChange={(e) => {
                  setSelectedAllAccepted(e.target.checked);
                  if (e.target.checked) {
                    setSelectedAcceptedCandidateIds(map(acceptedCandidates, 'id'));
                  } else {
                    setSelectedAcceptedCandidateIds([]);
                  }
                }}
              >
                {t('Select all')}
              </Checkbox>
            </div>

            <List
              grid={{ gutter: 16, column: 2 }}
              dataSource={acceptedCandidates}
              loading={isLoadingCandidates}
              renderItem={(candidate) => (
                <JobCandidateTile
                  job={job}
                  onClickView={() => setSelectedCandidateToView(candidate)}
                  onClickEdit={() => setSelectedCandidateToEdit(candidate)}
                  onClickDelete={() => deleteCandidate(candidate)}
                  onClickCreateSurvey={() => handleCreateSurveyClick(candidate)}
                  jobCandidate={candidate}
                  selectedCandidateIds={selectedAcceptedCandidateIds}
                  deletingCandidateIds={isDeletingCandidateIds}
                  onSelect={handleSelectAcceptedCandidate}
                />
              )}
            />
          </Col>
        </Row>
      </Card>
      <JobCandidateDetailModal
        isVisible={isVisibleCandidateViewModal}
        onCancel={() => {
          setSelectedCandidateToView(undefined);
          setIsVisibleCandidateViewModal(false);
        }}
        jobCandidate={selectedCandidateToView}
        job={job}
      />
      <JobCandidateEditModal
        isVisible={isVisibleCandidateEditModal}
        onCancel={() => {
          setSelectedCandidateToEdit(undefined);
          setIsVisibleCandidateEditModal(false);
        }}
        onOk={() => {
          setSelectedCandidateToEdit(undefined);
          setIsVisibleCandidateEditModal(false);
          fetchCandidates();
        }}
        jobCandidate={selectedCandidateToEdit}
        job={job}
      />
      <JobCandidateFileMakerSelModal
        isVisible={isVisibleCandidateFileMakerSelModal}
        onCancel={() => setIsVisibleCandidateFileMakerSelModal(false)}
        selectedCandidates={selectedFilemakerExportCandidates}
        isLoadingFilemakerCandidates={isLoadingFilemakerCandidates}
        job={job}
        onSuccessExport={() => {
          setIsVisibleCandidateFileMakerSelModal(false);
          fetchCandidates();
        }}
      />
    </>
  );
};

export default JobDetailCandidatesTab;
