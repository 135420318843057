import { Button, Result } from 'antd';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';
import Layout from '../../layout/Layout';

interface Props {
  hideHeader?: boolean;
}

const NotFoundPage: React.FC<Props> = ({ hideHeader = false }) => {
  const history = useHistory();
  const { isLoggedIn } = useAppContext();
  if (!isLoggedIn) {
    return <Redirect to={URLs.LOGIN} />;
  }

  const content = (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist"
        extra={
          <Button type="primary" onClick={() => history.push(URLs.ROOT)}>
            Go Home
          </Button>
        }
      />
    </div>
  );

  if (!isLoggedIn || hideHeader) {
    return content;
  }

  return <Layout>{content}</Layout>;
};

export default NotFoundPage;
