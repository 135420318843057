import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  List,
  Icon,
  Avatar,
  Alert,
  Typography,
  Button,
  message,
  Select,
  Row,
  Col,
} from 'antd';
import { formatDistance } from 'date-fns';
import { JobStatusEnum, JobStatusLabel } from '../../api/jobs/enums';
import { URLs } from '../../config/enums';
import { AvatarProps } from 'antd/lib/avatar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import JobsService from '../../api/jobs/JobsService';
import { Job, JobConfig } from '../../api/jobs/model';
import { JobsContext } from './JobsContext';
import JobFileMakerAssociationModal from './JobFileMakerAssociationModal';

interface Props {
  job: Job;
  fetchJob: () => void;
}

const JobDetailDescriptionTab: React.FC<Props> = ({ job, fetchJob }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchJobs } = useContext(JobsContext);

  const [config, setConfig] = useState<JobConfig>();
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [isUpdatingJobStatus, setIsUpdatingJobStatus] = useState(false);

  const [
    isVisibleJobFileMakerAssociationModal,
    setIsVisibleJobFileMakerAssociationModal,
  ] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoadingConfig(true);
        const response = await JobsService.findConfig();
        setConfig(response);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingConfig(false);
      }
    };
    fetchConfig();
  }, []);

  const updateJobStatus = async (jobStatus: string) => {
    setIsUpdatingJobStatus(true);
    try {
      await JobsService.updateStatus(job.id, jobStatus);
      if (fetchJob) {
        fetchJob();
      }
      if (fetchJobs) {
        fetchJobs();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsUpdatingJobStatus(false);
    }
  };

  const avatarProps: AvatarProps = {
    size: 'large',
  };

  if (job.imageUrl) {
    avatarProps.src = job.imageUrl;
  } else {
    avatarProps.icon = 'project';
  }

  return (
    <>
      <Card className="job-offer__description">
        <List.Item
          className="job-offer__description-title"
          extra={
            <>
              <Icon type="clock-circle" style={{ marginRight: 7 }} />{' '}
              {formatDistance(new Date(job.createdDate), new Date(), {
                addSuffix: true,
              })}
            </>
          }
        >
          <List.Item.Meta
            avatar={<Avatar {...avatarProps} />}
            title={job.jobTitle}
            description={job.location}
          />
        </List.Item>
        {job.jobStatus === JobStatusEnum.OPEN && (
          <Alert
            style={{ marginBottom: '2rem' }}
            message={
              <>
                <div style={{ display: 'block' }}>
                  Permalink:{' '}
                  <Typography.Text copyable={{ text: job.permalink }}>
                    <a href={job.permalink} target="_blank" rel="noopener noreferrer">
                      {job.permalink}
                    </a>
                  </Typography.Text>
                </div>
                <div style={{ display: 'block' }}>
                  {t('Candidates')}:{' '}
                  <Typography.Text
                    copyable={{
                      text: job.permalink.replace(URLs.SUGGEST_CANDIDATE, URLs.VIEW_CANDIDATES),
                    }}
                  >
                    <a
                      href={job.permalink.replace(URLs.SUGGEST_CANDIDATE, URLs.VIEW_CANDIDATES)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {job.permalink.replace(URLs.SUGGEST_CANDIDATE, URLs.VIEW_CANDIDATES)}
                    </a>
                  </Typography.Text>
                </div>
              </>
            }
          />
        )}
        <p className="job-offer__description-content">{job.description}</p>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={8} style={{ marginBottom: '1.5rem' }}>
            <Button type="primary" onClick={() => setIsVisibleJobFileMakerAssociationModal(true)}>
              {t('Link Filemaker Job')}
            </Button>
          </Col>

          {job.jobStatus === JobStatusEnum.OPEN && (
            <Col xs={24} sm={24} md={24} lg={8} style={{ marginBottom: '1.5rem' }}>
              <Button
                type="primary"
                onClick={() =>
                  history.push(URLs.JOBS_DETAIL_SUGGEST.replace(':id', job.id.toString()))
                }
              >
                {t('Suggest Candidate')}
              </Button>
            </Col>
          )}
          {
            <Col xs={24} sm={24} md={24} lg={8} style={{ marginBottom: '1.5rem' }}>
              <Select
                disabled={isLoadingConfig || isUpdatingJobStatus}
                loading={isLoadingConfig || isUpdatingJobStatus}
                style={{ display: 'block', width: '50%' }}
                defaultValue={job && t(JobStatusLabel[job.jobStatus])}
                value={job && t(JobStatusLabel[job.jobStatus])}
                onChange={(value: string) => updateJobStatus(value)}
              >
                {config &&
                  config.jobStatus.map((status) => (
                    <Select.Option key={status} value={status} disabled={job.jobStatus === status}>
                      {t(JobStatusLabel[status])}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
          }
        </Row>
      </Card>
      <JobFileMakerAssociationModal
        isVisible={isVisibleJobFileMakerAssociationModal}
        handleOk={() => setIsVisibleJobFileMakerAssociationModal(false)}
        handleCancel={() => setIsVisibleJobFileMakerAssociationModal(false)}
        job={job}
        fetchJob={fetchJob}
      />
    </>
  );
};

export default JobDetailDescriptionTab;
