import React from 'react';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import { URLs } from '../../config/enums';
import { ResourcesEnum, PatentsEnum } from '../../api/roles/enums';
import Campaigns from '../../components/Campaigns/Campaigns';

const CampaignsPage: React.FC = () => {
  return (
    <Switch>
      <AuthenticatedRoute path={URLs.CAMPAIGNS} resource={ResourcesEnum.CAMPAIGNS} patent={PatentsEnum.VIEW}>
        <Campaigns />
      </AuthenticatedRoute>
    </Switch>
  );
};

export default CampaignsPage;
