export enum EmailTemplateEnum {
  INVITE_RECRUITER = 'INVITE_RECRUITER',
  INVITE_RECRUITER_MESSAGE = 'INVITE_RECRUITER_MESSAGE',
  THANK_RECRUITER = 'THANK_RECRUITER',
  SEND_CUSTOMER = 'SEND_CUSTOMER',
  SEND_SURVEY = 'SEND_SURVEY',
  SEND_SURVEY_MESSAGE = 'SEND_SURVEY_MESSAGE',
  WELCOME_MESSAGE = 'WELCOME_MESSAGE',
}

export enum MimeTypes {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
}

export enum LanguagesEnum {
  EN = 'EN',
  FR = 'FR',
}

export const Languages = {
  [LanguagesEnum.EN]: 'English',
  [LanguagesEnum.FR]: 'Français',
};

export enum ErrorsEnum {
  EMAIL_NEEDS_VALIDATION = 'EMAIL_NEEDS_VALIDATION',
}

export enum SignalRMethodsEnum {
  UPDATE_IMPORT_STATUS = 'UPDATE_IMPORT_STATUS',
}

export const SignalRMethodNames = {
  [SignalRMethodsEnum.UPDATE_IMPORT_STATUS]: 'UpdateImportStatus',
};
