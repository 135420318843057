import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Survey, SurveyConfig } from '../../api/surveys/models';

interface ISurveysContext {
  fetchSurveys?: () => void;
  isLoadingConfig?: boolean;
  config?: SurveyConfig;

}
export const SurveysContext = React.createContext<ISurveysContext>({
  isLoadingConfig: true,
});

interface ISurveysEditContext {
  isSubmittingForm: boolean;
  form?: WrappedFormUtils;
  survey?: Survey;
}
export const SurveysEditContext = React.createContext<ISurveysEditContext>({
  isSubmittingForm: false,
});
