import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Icon, Upload, Form, Input, Row, Col, Tooltip, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { getBase64 } from '../../helpers/image-helpers';
import { MimeTypes } from '../../api/shared/enums';
import { last } from 'lodash';
import { SurveysEditContext } from './SurveysContext';

interface Props {
  questionIndex: number;
}

const SurveysQuestionsItemCollectionFormItems: React.FC<Props> = ({ questionIndex }) => {
  const { t } = useTranslation();
  const { isSubmittingForm, survey } = useContext(SurveysEditContext);
  const question = survey?.surveyTemplateQuestionModels?.[questionIndex];
  const questionPreviewImages =
    question?.surveyTemplateQuestionItemModels?.map((questionItem) => questionItem?.pictureUrl) ||
    [];
  const [previewImages, setPreviewImages] = useState<(string | undefined)[]>(questionPreviewImages);
  const form = useContext(SurveysEditContext).form!;

  const questionItemsCount = useRef(0);

  const addQuestionItem = useCallback(() => {
    const keys = form.getFieldValue(
      `questions[${questionIndex}].surveyTemplateQuestionItemModelKeys`
    ) as number[];
    const nextKeys = keys.concat(questionItemsCount.current++);

    form.setFieldsValue({
      [`questions[${questionIndex}].surveyTemplateQuestionItemModelKeys`]: nextKeys,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionIndex]);

  const removeQuestionItem = (questionItemKey: number) => {
    const keys = form.getFieldValue(
      `questions[${questionIndex}].surveyTemplateQuestionItemModelKeys`
    ) as number[];

    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      [`questions[${questionIndex}].surveyTemplateQuestionItemModelKeys`]: keys.filter(
        (key) => key !== questionItemKey
      ),
    });
  };

  const onUploadChange = async (info: any, questionItemIndex: number) => {
    const preview = (await getBase64(info.file)) as string;
    previewImages[questionItemIndex] = preview;
    setPreviewImages([...previewImages]);
  };

  const getFileListFromEvent = (e: any) => {
    return [last(e?.fileList)];
  };

  const shouldImagesFieldBeRequired = (questionItemKey: number) => {
    return !previewImages[questionItemKey];
  };

  const validateUploadInput = (_: any, value: any, callback: any) => {
    const questionItemKeys = form.getFieldValue(
      `questions[${questionIndex}].surveyTemplateQuestionItemModelKeys`
    ) as number[];

    if (questionItemKeys.length < 2) {
      callback(t('Picture question should have at least two pictures'));
    }

    callback();
  };

  useEffect(() => {
    if (question?.surveyTemplateQuestionItemModels?.length) {
      question?.surveyTemplateQuestionItemModels.forEach((_questionItem, _index) => {
        addQuestionItem();
      });
    } else {
      addQuestionItem();
    }
  }, [question, questionIndex, addQuestionItem]);

  form.getFieldDecorator(`questions[${questionIndex}].surveyTemplateQuestionItemModelKeys`, {
    initialValue: [],
  });

  const questionItemKeys = form.getFieldValue(
    `questions[${questionIndex}].surveyTemplateQuestionItemModelKeys`
  ) as number[];
  return (
    <>
      <Row type="flex" justify="end" style={{ marginBottom: '2rem' }}>
        <Tooltip title={t('Add question item')}>
          <Button
            loading={isSubmittingForm}
            icon="plus"
            shape="circle-outline"
            type="primary"
            onClick={addQuestionItem}
          />
        </Tooltip>
      </Row>
      {questionItemKeys.map((questionItemKey) => (
        <Card
          key={`questions[${questionIndex}].surveyTemplateQuestionItemModels[${questionItemKey}]`}
        >
          <Row>
            <Col xs={22}>
              <Row gutter={16}>
                <Col xs={24} lg={20}>
                  <Form.Item style={{ display: 'none' }}>
                    {form.getFieldDecorator(
                      `questions[${questionIndex}].surveyTemplateQuestionItemModels[${questionItemKey}].fileId`,
                      {
                        initialValue:
                          question?.surveyTemplateQuestionItemModels?.[questionItemKey]?.fileId,
                      }
                    )(<Input />)}
                  </Form.Item>
                  <Form.Item label={t('Title')}>
                    {form.getFieldDecorator(
                      `questions[${questionIndex}].surveyTemplateQuestionItemModels[${questionItemKey}].title`,
                      {
                        initialValue:
                          question?.surveyTemplateQuestionItemModels?.[questionItemKey]?.title,
                        rules: [{ required: true, message: t('This field is required') }],
                      }
                    )(<Input />)}
                  </Form.Item>
                </Col>

                <Col xs={24} lg={4}>
                  <Form.Item label={t('Image')}>
                    {form.getFieldDecorator(
                      `questions[${questionIndex}].surveyTemplateQuestionItemModels[${questionItemKey}].images`,
                      {
                        rules: [
                          {
                            required: shouldImagesFieldBeRequired(questionItemKey),
                            message: t('Please select a file'),
                          },
                          {
                            validator: validateUploadInput,
                          },
                        ],
                        validateFirst: true,
                        valuePropName: 'fileList',
                        getValueFromEvent: getFileListFromEvent,
                      }
                    )(
                      <Upload
                        listType="picture-card"
                        showUploadList={false}
                        accept={`${MimeTypes.IMAGE_JPEG}, ${MimeTypes.IMAGE_PNG}`}
                        beforeUpload={() => false}
                        onChange={(info) => onUploadChange(info, questionItemKey)}
                      >
                        {previewImages[questionItemKey] ? (
                          <img
                            src={previewImages[questionItemKey]}
                            style={{ width: '100%' }}
                            alt={t('Question item image')}
                          />
                        ) : (
                          <div>
                            <Icon type="plus" />
                            <div className="ant-upload-text">{t('Upload')}</div>
                          </div>
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={2}>
              {questionItemKeys.length > 1 ? (
                <Tooltip title={t('Remove question item')}>
                  <Button
                    loading={isSubmittingForm}
                    icon="minus-circle-o"
                    shape="circle-outline"
                    type="danger"
                    onClick={() => {
                      removeQuestionItem(questionItemKey);
                    }}
                  />
                </Tooltip>
              ) : null}
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );
};

export default SurveysQuestionsItemCollectionFormItems;
