export enum EmailStatus {
  QUEUED = 'QUEUED',
  SENT = 'SENT',
  ERROR = 'ERROR',
}

export const EmailStatusLabel = {
  [EmailStatus.QUEUED]: 'Queued',
  [EmailStatus.SENT]: 'Sent',
  [EmailStatus.ERROR]: 'Error',
};

export const EmailStatusColorLabel = {
  [EmailStatus.QUEUED]: '#FFB900',
  [EmailStatus.SENT]: '#7BE56C',
  [EmailStatus.ERROR]: '#FF0400',
};
