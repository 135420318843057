import React, { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  title?: string;
  goTo?: string;
}

const BackButton: React.FC<Props> = ({ title, goTo }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (goTo) {
      history.replace(goTo);
    } else {
      history.goBack();
    }
  }, [history, goTo]);
  return (
    <>
      <Tooltip title={t('Back')}>
        <Button
          style={{ marginRight: 15 }}
          onClick={handleClick}
          shape="circle"
          icon="arrow-left"
        />
      </Tooltip>
      {title && <span>{title}</span>}
    </>
  );
};

export default BackButton;
