import { Button, Card, Input, message, Table, Tooltip } from 'antd';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { reject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { User } from '../../api/users/model';
import UsersService from '../../api/users/UsersService';
import { URLs } from '../../config/enums';
import {
  createBaseGridParams,
  getUrlQueryString,
  GridParams,
  updateGridUrl,
} from '../../helpers/grid-helpers';
import { useAppContext } from '../../layout/AppContext';

const gridParams = createBaseGridParams({
  sortField: 'firstName',
});

const UsersGrid: React.FC = () => {
  const { user } = useAppContext();
  const { t } = useTranslation();
  const urlParams = getUrlQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [searchInput, setSearchInput] = useState(urlParams.search || '');
  const [total, setTotal] = useState(0);
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    ...urlParams,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await UsersService.findGrid(gridState);
        setUsers(reject(response.list, { id: user?.id }));
        setTotal(response.count);
        updateGridUrl(history, match.url, gridState);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [gridState, history, match.url, user]);

  const handleTableOnChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<any>
  ) => {
    setGridState({
      ...gridState,
      sortField: sorter.columnKey || 'firstName',
      sortOrder: sorter.order || 'ascend',
      page: +pagination.current! - 1,
      pageSize: +pagination.pageSize!,
    });
  };

  const handleSearch = (value: string) => {
    setGridState({ ...gridState, search: value, page: 0 });
  };

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  return (
    <div className="container">
      <Card
        title={t('Users')}
        extra={
          <Tooltip title={t('New')}>
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              onClick={() => history.push(URLs.USERS_NEW)}
            />
          </Tooltip>
        }
      >
        <Table
          rowKey="id"
          bordered
          showHeader
          loading={isLoading}
          onChange={handleTableOnChange}
          title={() => (
            <Input.Search
              placeholder={`${t('Search')}...`}
              enterButton
              onChange={handleOnSearchChange}
              value={searchInput}
              onSearch={handleSearch}
            />
          )}
          pagination={{
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            current: gridState.page! + 1,
            pageSize: gridState.pageSize,
            total,
          }}
          columns={[
            {
              title: t('Name'),
              dataIndex: 'firstName',
              sorter: true,
              defaultSortOrder: gridState.sortField === 'userId' ? gridState.sortOrder : undefined,
              render(firstName, user) {
                return (
                  <Link to={URLs.USERS_DETAIL.replace(':id', user.id ? user.id.toString() : '')}>
                    {firstName} {user.lastName}
                  </Link>
                );
              },
            },
            {
              title: t('Email'),
              dataIndex: 'email',
            },
            {
              title: t('Role'),
              dataIndex: 'role.name',
              width: 100,
              align: 'center',
            },
            {
              title: t('Actions'),
              align: 'center',
              render(_, user) {
                return (
                  <Button.Group size="small">
                    <Tooltip title={t('User Activities')}>
                      <Button
                        type="primary"
                        shape="circle"
                        icon="search"
                        onClick={() =>
                          history.push(
                            URLs.USERS_ACTIVITIES_BY_USER.replace(':userId', user.id.toString())
                          )
                        }
                      />
                    </Tooltip>
                  </Button.Group>
                );
              },
            },
          ]}
          dataSource={users}
        />
      </Card>
    </div>
  );
};

export default UsersGrid;
