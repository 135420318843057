import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { useAppContext } from '../../layout/AppContext';

interface Props {
  path: string;
  exact?: boolean;
}

const LoggedInRoute: React.FC<Props> = ({ path, exact = false, children }) => {
  const { user } = useAppContext();
  const isLoggedIn = !!user;

  if (!isLoggedIn) {
    const from = window.location.pathname !== URLs.ROOT ? `?from=${window.location.pathname}` : '';
    return <Redirect to={{ pathname: URLs.LOGIN, search: from }} />;
  }

  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

export default LoggedInRoute;
