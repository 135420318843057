import React from 'react';
import {
    Card, Col, Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Job } from '../../api/jobs/model';
import { SmtpConfigurationGroupingEmailsType, SmtpConfigurationGroupingEmaisLabel, SmtpConfigurationLimitType, SmtpConfigurationLimitTypeLabel } from '../../api/recruiter-configurations/enums';

interface GeneralSummary {
    smtpConfiguration: string;
    noOfContacts: number;
    intervalLimit: number;
    emailLimit: number;
    intervalType: string;
}
interface EmailStatistics {
    bounced: number;
    error: number;
    open: number;
    queued: number;
    received: number;
    sent: number;
}

interface SocialMessageStatistics {
    noOfFacebookCounts: number;
    noOfLinkedinCounts: number;
}

interface Props {
    emailStatistics: EmailStatistics;
    socialMessageStatistics: SocialMessageStatistics;
    generalSummary: GeneralSummary;
}


const JobMessageSummary: React.FC<Props> = ({ emailStatistics, socialMessageStatistics, generalSummary }) => {
    const { t } = useTranslation();
    return (
        <div>
            <Row className='job-message-tab'>
                <Col md={8} xl={8} sm={8} style={{ marginRight: 10 }}>
                    <Card className="job-offer__description" style={{ height: 'calc(100% + 30px)' }}>
                        <div>
                            <h3 className='tab_title' style={{ marginBottom: '5px' }}>{t('General')}</h3>
                            <Row>
                                <Col>
                                    <div>
                                        <div style={{ fontWeight: 700, paddingBottom: '10px' }}>{t('Current SMTP configuration')}</div>
                                        {(generalSummary?.emailLimit != null || generalSummary?.intervalLimit != null || generalSummary?.intervalType != null) ? (
                                            <div style={{ wordWrap: 'break-word' }}>
                                                <div >
                                                    <p style={{ fontWeight: 500, marginRight: '3px' }}>{t('Configuration name')}: </p>
                                                    <p>{generalSummary?.smtpConfiguration}</p>
                                                </div>
                                                <div>
                                                    <p style={{ fontWeight: 500, marginRight: '3px' }}>{t('E-mail limit')}: </p>
                                                    <p>{`${generalSummary?.emailLimit} ${generalSummary?.emailLimit > 1 ? t('e-mails') : t('e-mail')} ${t('every')} ${generalSummary?.intervalLimit} ${t(SmtpConfigurationGroupingEmaisLabel[generalSummary?.intervalType as SmtpConfigurationGroupingEmailsType]) || t(
                                                        SmtpConfigurationLimitTypeLabel[generalSummary?.intervalType as SmtpConfigurationLimitType])} `}</p>
                                                </div>
                                            </div>
                                        ) : '-'}
                                    </div>

                                    <div>
                                        <div style={{ fontWeight: 500 }}>{t('No of contacts')}: </div>
                                        <div> {generalSummary?.noOfContacts} </div>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col md={8} xl={8} sm={8} style={{ marginRight: 10 }}>
                    <Card className="job-offer__description">
                        <div>
                            <h3 className='tab_title'>{t('Emails Summary')}</h3>
                            <Row>
                                <Col>
                                    <div className='view-summary'>
                                        <div>{t('Queued')}:</div>
                                        <div>{emailStatistics?.queued}</div>
                                    </div>
                                    <div className='view-summary'>
                                        <div>{t('Sent')}:</div>
                                        <div>{emailStatistics?.sent}</div>
                                    </div>
                                    <div className='view-summary'>
                                        <div>{t('Received')}:</div>
                                        <div> {emailStatistics?.received} </div>
                                    </div>
                                    <div className='view-summary'>
                                        <div>{t('Open')}:</div>
                                        <div> {emailStatistics?.open} </div>
                                    </div>
                                    <div className='view-summary'>
                                        <div>{t('Bounced')}: </div>
                                        <div> {emailStatistics?.bounced} </div>
                                    </div>
                                    <div className='view-summary'>
                                        <div>{t('Error')}: </div>
                                        <div> {emailStatistics?.error} </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col md={8} xl={8} sm={8}>
                    <Card className="job-offer__description" style={{ height: 'calc(100% + 30px)' }}>
                        <div>
                            <h3 className='tab_title'>{t('Social Messages')}</h3>
                            <Row>
                                <Col>
                                    <div className='view-summary'>
                                        <div>{t('Linkedin contacts')}:</div>
                                        <div>{socialMessageStatistics?.noOfLinkedinCounts}</div>
                                    </div>
                                    <div className='view-summary'>
                                        <div style={{ width: '20px' }}>
                                            <div>{t('Facebook contacts')}:</div>
                                        </div>
                                        <div>{socialMessageStatistics?.noOfFacebookCounts}</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default JobMessageSummary;
