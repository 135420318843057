import { Button, Col, Form, Input, message, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthService from '../../api/shared/services/AuthService';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { useTranslation } from 'react-i18next';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
interface Props extends FormComponentProps<FormValues> {}

const SignUp: React.FC<Props> = ({ form }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const values = await validateAntForm(form);
      await AuthService.register(values);
      message.success(`${t('We sent you an email, please check it to activate your account')}!`);
      history.push(URLs.LOGIN);
    } catch (error) {
      setIsSubmitting(false);
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  return (
    <>
      <h1>{t('Sign Up')}</h1>
      <p>{t('Join to TalentLinkr with your mail and password')}</p>
      <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item>
              {form.getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input autoFocus placeholder={t('Name')} />)}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item>
              {form.getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t('This field is required'),
                  },
                ],
              })(<Input placeholder={t('Last Name')} />)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          {form.getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: t('The entered email is not valid'),
              },
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input placeholder={t('Email')} />)}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('password', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input.Password placeholder={t('Password')} />)}
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={isSubmitting}
          disabled={isSubmitting}
          style={{ marginBottom: 20 }}
        >
          {t('Sign Up')}
        </Button>
        <p style={{ textAlign: 'center' }}>
          <Link to={URLs.LOGIN}>{t('Go back to Log In page')}</Link>
        </p>
      </Form>
    </>
  );
};

export default Form.create()(SignUp);
