import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import { IAuthState, LOGOUT } from './auth';
import { authReducer } from './auth/reducer';
import { errorMiddleware, unauthorizedMiddleware } from './middlewares';

export type IGlobalState = {
  auth: IAuthState;
};

export const reducers = combineReducers<IGlobalState>({
  auth: authReducer,
});

export const rootReducer = (state: IGlobalState | undefined, action: AnyAction): IGlobalState => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return reducers(state, action);
};

const store = createStore(rootReducer, applyMiddleware(unauthorizedMiddleware, errorMiddleware));

export default store;
