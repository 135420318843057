import { Button, Card, Form, Input, InputNumber, message } from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import { toNumber } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationsService } from '../../api/configurations/ConfigurationsService';
import { validateAntForm } from '../../helpers/ant-form';
import { useSmtpConfiguration as useConfigurationSmtp } from '../../hooks/configurations';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import LoadingSpinner from '../Loading/LoadingSpinner';

interface FormValues {
  emailingEnabled: boolean;
  fromAddress: string;
  smtpServer: string;
  smtpPort: string;
  smtpUsername: string;
  smtpPassword: string;
  smtpUseDefaultCredentials: boolean;
  smtpEnableSsl: boolean;
  testingEmails: string;
}

interface Props extends FormComponentProps<FormValues> {}

const formItemLayout: FormItemProps = {
  labelCol: {
    xs: 24,
    sm: 24,
    md: 5,
  },
  wrapperCol: {
    xs: 24,
    sm: 24,
    md: 19,
  },
};

const ConfigurationsSmtp: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { configurationSmtp, isLoading } = useConfigurationSmtp();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!configurationSmtp) {
    return <NotFoundPage />;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const values: FormValues = await validateAntForm(form);
      await ConfigurationsService.updateSmtpConfiguration({
        fromAddress: values.fromAddress,
        smtpUsername: values.smtpUsername,
        smtpPassword: values.smtpPassword,
        smtpPort: toNumber(values.smtpPort),
        smtpServer: values.smtpServer,
      });
      message.success(t('Updated'));
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Card>
      <Form layout="horizontal" onSubmit={handleSubmit} hideRequiredMark>
        <Form.Item label={t('From Address')} {...formItemLayout}>
          {form.getFieldDecorator('fromAddress', {
            initialValue: configurationSmtp.fromAddress,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
              {
                type: 'email',
                message: t('The entered email is not valid'),
              },
            ],
          })(<Input type="email" />)}
        </Form.Item>
        <Form.Item label={t('SMTP server')} {...formItemLayout}>
          {form.getFieldDecorator('smtpServer', {
            initialValue: configurationSmtp.smtpServer,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('SMTP port')} {...formItemLayout}>
          {form.getFieldDecorator('smtpPort', {
            initialValue: configurationSmtp.smtpPort,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
                type: 'number',
              },
            ],
          })(<InputNumber />)}
        </Form.Item>
        <Form.Item label={t('SMTP username')} {...formItemLayout}>
          {form.getFieldDecorator('smtpUsername', {
            initialValue: configurationSmtp.smtpUsername,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('SMTP password')} {...formItemLayout}>
          {form.getFieldDecorator('smtpPassword', {
            initialValue: configurationSmtp.smtpPassword,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input.Password />)}
        </Form.Item>

        <Button type="primary" disabled={isSubmitting} loading={isSubmitting} htmlType="submit">
          {t('Save')}
        </Button>
      </Form>
    </Card>
  );
};

export default Form.create()(ConfigurationsSmtp);
