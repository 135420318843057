export enum SmtpConfigurationLimitType {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  HOUR='HOUR',
  MINUTE='MINUTE',
  SECOND='SECOND'
};

export const SmtpConfigurationLimitTypeLabel = {
  [SmtpConfigurationLimitType.HOURLY]: 'Hourly',
  [SmtpConfigurationLimitType.DAILY]: 'Daily',
  [SmtpConfigurationLimitType.HOUR]: 'Hour',
  [SmtpConfigurationLimitType.MINUTE]: 'Minutes',
  [SmtpConfigurationLimitType.SECOND]: 'Seconds',
};

export enum SmtpConfigurationAuthenticationType {
  None = 'None',
  Authentication = 'Authentication',
};

export const SmtpConfigurationAuthenticationLabel = {
  [SmtpConfigurationAuthenticationType.None]: 'None',
  [SmtpConfigurationAuthenticationType.Authentication]: 'Authentication',
};

export enum SmtpConfigurationGroupingEmailsType  {
  Hour = 'Hour',
  Minute = 'Minute',
  Second = 'Second'
};

export const SmtpConfigurationGroupingEmaisLabel = {
  [SmtpConfigurationGroupingEmailsType.Hour]: 'Hour',
  [SmtpConfigurationGroupingEmailsType.Minute]: 'Minute',
  [SmtpConfigurationGroupingEmailsType.Second]: 'Second',
};