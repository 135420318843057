import { Card, Col, Input, message, Row, Select, Table, Tag } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import CampaignsService from '../../api/campaigns/CampaignsService';
import { CampaignEmailStatus, CampaignEmailStatusColorLabel, CampaignEmailStatusLabel } from '../../api/campaigns/enum';
import { ROWS_PER_PAGES } from '../../config/general-config';
import { createBaseGridParams, getUrlQueryString, GridParams } from '../../helpers/grid-helpers';


export const CampaignsGrid: React.FC<any> = () => {
  const gridParams = createBaseGridParams({
    sortOrder: 'descend',
    sortField: 'createdDate',
    pageSize: ROWS_PER_PAGES,
  });
  const { t } = useTranslation();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match?.params;
  const urlParams = getUrlQueryString();
  const [gridState, setGridState] = useState<GridParams>({
    ...gridParams,
    filters: [],
    CampaignId: +id
  });
  const [searchInput, setSearchInput] = useState(urlParams.search || '');
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [campaignsFetched, setCampaignsFetched] = useState<any>();

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };
  const handleSearch = (value: string) => {
    setGridState({ ...gridState, search: value, page: 0 });
  };

  const columns = [
    {
      title: t('Send Date'),
      dataIndex: 'createdDate',
      align: 'center' as 'center',
      render: (cell: any) => (cell ? cell?.toString()?.replace('T', " ")?.replace('Z', '')?.replaceAll('-', '/')?.split('.')[0] : '-'),
    },
    {
      title: t('Subject'),
      dataIndex: 'subject',
      align: 'center' as 'center',
    },
    {
      title: t('Sender Email'),
      dataIndex: 'sender',
      align: 'center' as 'center',
      render: (text: any, record: any) => (
        <>{record?.senderName ? `${record?.senderName}` : '-'} <br />
          {record?.sender ? `${record?.sender}` : '-'}
        </>
      ),
    },
    {
      title: t('Recipients'),
      dataIndex: 'recipients',
      align: 'center' as 'center',
    },
    {
      title: t('Status'),
      dataIndex: 'emailStatusCode',
      align: 'center' as 'center',
      render: (status: string) => (
        <Tag color={CampaignEmailStatusColorLabel[status as CampaignEmailStatus]}>
          {t(status)}
        </Tag>
      ),
    },

  ];

  useEffect(() => {
    fetchEmailsData()
  }, [gridState])

  const fetchEmailsData = async () => {
    try {
      setIsLoading(true);
      const response = await CampaignsService.emailsData(gridState);
      setCampaignsFetched(response?.list);
      setTotal(response?.count);
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (filter: string, value: any) => {
    if (!value)
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter)],
      });
    else
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter), { key: filter, value }],
      });
  };

  const renderFilters = () => {
    return (
      <Row gutter={12}>
        <Col xs={24} sm={24} md={7} style={{ marginBottom: 10 }}>
          <Input.Search
            placeholder={`${t('Search')}...`}
            enterButton
            onChange={handleOnSearchChange}
            value={searchInput}
            onSearch={handleSearch}
          />
        </Col>

        <Col xs={24} sm={24} md={6}>
          <Select
            allowClear
            placeholder={t('Status')}
            style={{ width: '100%' }}
            onChange={(value: any) => handleFilterChange('status', value)}
          >
            {Object.keys(CampaignEmailStatus).map((status) => (
              <Select.Option key={status} value={status}>
                {t(CampaignEmailStatusLabel[status as CampaignEmailStatus])}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  };

  const handleTableOnChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<any>
  ) => {
    setGridState({
      ...gridState,
      sortField: sorter.columnKey || 'createdDate',
      sortOrder: sorter.order || 'descend',
      page: +pagination.current! - 1,
      pageSize: +pagination.pageSize!,
    });
  };

  return (
    <>
      <Card className="job-offer__description">
        <Table
          key="id"
          rowKey="id"
          bordered
          loading={isLoading}
          columns={columns}
          dataSource={campaignsFetched}
          title={renderFilters}
          onChange={handleTableOnChange}
          pagination={{
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            current: gridState.page! + 1,
            pageSize: gridState.pageSize,
            total,
          }}
        />
      </Card>
    </>
  );
};
