export enum SurveyQuestionType {
  SCALE = 'SCALE',
  RATING = 'RATING',
  PICTURE = 'PICTURE',
  TEXT = 'TEXT',
  YESNO = 'YESNO',
}

export const SurveyQuestionTypeLabel = {
  [SurveyQuestionType.SCALE]: 'Scale',
  [SurveyQuestionType.RATING]: 'Rating',
  [SurveyQuestionType.PICTURE]: 'Picture',
  [SurveyQuestionType.TEXT]: 'Text',
  [SurveyQuestionType.YESNO]: 'Yes/No',
};

export const SCALE_MAX_VALUE = 10;

export enum SurveySendRoute {
  EMAIL = 'EMAIL',
  LINKEDIN = 'LINKEDIN',
  FACEBOOK = 'FACEBOOK',
}

export enum SurveyThankYouInfoDisposition {
  TEXT_FIRST = 'TEXT_FIRST',
  IMAGE_FIRST = 'IMAGE_FIRST',
}
