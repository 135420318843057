import { API } from '../../config/api';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import { FacebookCommonContact, LinkedInCommonContact } from '../contacts/model';
import FetchService from '../shared/services/FetchService';
import {
  User,
  UserAddType,
  UsersFetched,
  UserUpdateType,
  UpdateProfile,
  UpdatePassword,
} from './model';

export default class UsersService {
  static async confirmVerifyEmail(hash: string) {
    const response = await FetchService.post({
      url: `${API.USERS_VALIDATE_SEND_EMAIL}/${hash}`,
    });
    return response;
  }

  static async find(): Promise<UsersFetched> {
    const response = await FetchService.get<UsersFetched>({ url: API.USERS });
    return response;
  }

  static async findGrid(params: GridParams): Promise<UsersFetched> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<UsersFetched>({
      url: API.USERS,
      gridParams,
    });
    return response;
  }

  static async findOne(id: string): Promise<User> {
    const response = await FetchService.get<User>({
      url: `${API.USERS}/${id}`,
    });
    return response;
  }

  static async create(entity: UserAddType) {
    await FetchService.post({ url: API.USERS, body: entity });
  }

  static async update(entity: UserUpdateType) {
    await FetchService.post({ url: API.USERS, body: entity });
  }

  static async updateProfile(model: UpdateProfile) {
    model.language = model.language.toUpperCase();
    await FetchService.put({ url: API.USERS_UPDATE_PROFILE, body: model });
  }

  static async delete(id: string) {
    await FetchService.delete({ url: API.USERS_DELETE.replace(':id', id) });
  }

  static async requestEmailValidation(email: string) {
    const response = await FetchService.post({
      url: API.USERS_REQUEST_EMAIL_VALIDATION,
      body: {
        email,
      },
    });
    return response;
  }

  static async updatePassword(model: UpdatePassword) {
    await FetchService.put({
      url: API.USERS_UPDATE_PASSWORD,
      body: {
        oldPassword: model.currentPassword,
        newPassword: model.newPassword,
      },
    });
  }

  static findLinkedInCommonContacts(url: string): Promise<LinkedInCommonContact[]> {
    return FetchService.post<LinkedInCommonContact[]>({
      url: API.USERS_FIND_MUTUAL_LINKEDIN,
      body: { url },
    });
  }

  static findFacebookCommonContacts(url: string): Promise<FacebookCommonContact[]> {
    return FetchService.post<FacebookCommonContact[]>({
      url: API.USERS_FIND_MUTUAL_FACEBOOK,
      body: { url },
    });
  }
}
