import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Upload,
  Tooltip,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { get } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClientCompaniesService from '../../api/client-companies/ClientCompaniesService';
import { ClientCompany, ClientCompanyAddType } from '../../api/client-companies/model';
import ClientCompanyContactService from '../../api/client-company-contacts/ClientCompanyContactsService';
import { ClientCompanyContact } from '../../api/client-company-contacts/model';
import JobsService from '../../api/jobs/JobsService';
import { JobAddType, JobUpdateType } from '../../api/jobs/model';
import { ReactComponent as IconUploadPhoto } from '../../assets/icon-uploadPhoto.svg';
import { URLs } from '../../config/enums';
import { validateAntForm } from '../../helpers/ant-form';
import { getBase64 } from '../../helpers/image-helpers';
import AddClientCompanyModal from '../ClientCompany/AddClientCompanyModal';
import SelectContactModal from '../Contacts/SelectContactModal';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { JobsContext, JobsNewContext } from './JobsContext';
import { IFile } from '../../api/files/model';
import FilesService, { FORM_DATA_KEY } from '../../api/files/FilesService';
import { MimeTypes, LanguagesEnum, Languages } from '../../api/shared/enums';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../layout/AppContext';

interface Props extends FormComponentProps {
  onSubmit: () => void;
}

interface FormValues {
  clientCompanyId: number;
  clientContactId: number;
  jobTitle: string;
  location: string;
  salaryRangeCode: string;
  currencyCode: string;
  description: string;
  hideDescription: boolean;
  language: string;
}

const ADD_CLIENT_COMPANY = 'ADD_CLIENT_COMPANY';

const JobsEditOffer: React.FC<Props> = ({ form, onSubmit }) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const history = useHistory();
  const { fetchJobs } = useContext(JobsContext);
  const { config, isLoadingConfig, job, fetchJob, setJob } = useContext(JobsNewContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<UploadFile>();
  const [imgPreview, setImgPreview] = useState<string>();
  const [clientCompanies, setClientCompanies] = useState<ClientCompany[]>([]);
  const [isLoadingClientCompanies, setIsLoadingClientCompanies] = useState(true);
  const [clientCompanyContacts, setClientCompanyContacts] = useState<ClientCompanyContact[]>([]);
  const [isLoadingClientCompanyContacts, setIsLoadingClientCompanyContacts] = useState(false);
  const [isVisibleAddClientCompanyModal, setIsVisibleAddClientCompanyModal] = useState(false);
  const [isVisibleSelectContactModal, setIsVisibleSelectContactModal] = useState(false);

  //Client Company and Contact related states
  const [clientCompanySearchValue, setClientCompanySearchValue] = useState('');
  const [isSubmittingClientCompany, setIsSubmittingClientCompany] = useState(false);

  const fetchClientCompanies = async () => {
    try {
      setIsLoadingClientCompanies(true);
      const response = await ClientCompaniesService.find();
      setClientCompanies(response);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsLoadingClientCompanies(false);
    }
  };

  useEffect(() => {
    fetchClientCompanies();
  }, []);

  form.getFieldDecorator('clientCompanyId', {
    initialValue: !isLoadingClientCompanies ? job?.clientCompanyId.toString() : undefined,
  });
  const clientCompanyId = form.getFieldValue('clientCompanyId');

  const fetchClientCompanyContacts = useCallback(() => {
    const fetch = async () => {
      try {
        if (clientCompanyId) {
          setIsLoadingClientCompanyContacts(true);
          const response = await ClientCompanyContactService.find(clientCompanyId);
          setClientCompanyContacts(response);
        } else {
          setClientCompanyContacts([]);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingClientCompanyContacts(false);
      }
    };
    fetch();
  }, [clientCompanyId]);

  useEffect(() => {
    if (Number(clientCompanyId) && clientCompanies.find((i) => i.id === +clientCompanyId))
      fetchClientCompanyContacts();
  }, [clientCompanyId, fetchClientCompanyContacts, clientCompanies]);

  if (isLoadingConfig) {
    return <LoadingSpinner />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    let dbFile: IFile | undefined;

    try {
      e.preventDefault();
      setIsSubmitting(true);
      const {
        clientCompanyId,
        clientContactId,
        jobTitle,
        location,
        salaryRangeCode,
        currencyCode,
        description,
        hideDescription,
        language,
      } = await validateAntForm<FormValues>(form);

      if (job && !job.imageFileId && file) {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY, (file as unknown) as File);
        dbFile = await FilesService.add(formData);
      }

      if (!job) {
        const data: JobAddType = {
          clientCompanyId,
          clientContactId,
          jobTitle,
          location,
          salaryRangeCode,
          currencyCode,
          description,
          imageFileId: dbFile?.id,
          hideDescription,
          language,
        };

        const jobId = await JobsService.add(data);
        setIsSubmitting(false);
        history.push(URLs.JOBS_DRAFT.replace(':id', jobId.toString()));
      } else {
        const {
          id,
          clientPage,
          jobStatus,
          overrideSenderContactId,
          requireClientLogin,
          imageFileId,
        } = job;
        const data: JobUpdateType = {
          id,
          clientCompanyId,
          clientContactId,
          jobTitle,
          location,
          salaryRangeCode,
          currencyCode,
          description,
          clientPage,
          jobStatus,
          overrideSenderContactId,
          requireClientLogin,
          hideDescription,
          imageFileId: dbFile?.id || imageFileId,
          language,
          requestJobEmailLang: language,
          thankYouJobEmailLang: language,
          sendCustomerEmailLang: language,
          fileMakerIdMission: job.fileMakerIdMission,
        };
        await JobsService.update(data);
        if (fetchJob) {
          fetchJob();
        }
      }

      onSubmit();
      if (fetchJobs) {
        fetchJobs();
      }
    } catch (error) {
      setIsSubmitting(false);
      if (error.message) {
        message.error(error.message);
      }
      if (dbFile) {
        await FilesService.delete(dbFile.id);
      }
    }
  };

  const handleFileOnChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'removed') {
      setFile(undefined);
      setImgPreview(undefined);
    } else {
      const response = await getBase64((info.file as unknown) as File);
      if (response) {
        setImgPreview(response.toString());
      }
      setFile(info.file);
    }

    if (job && setJob) {
      setJob({ ...job, imageFileId: undefined });
    }
  };

  form.getFieldDecorator('salaryRangeCode', {
    initialValue: job?.salaryRangeCode || undefined,
  });
  form.getFieldDecorator('currencyCode', {
    initialValue: job?.currencyCode || undefined,
  });

  const handleAddClientCompany = async (companyName: string) => {
    setIsSubmittingClientCompany(true);
    try {
      const data: ClientCompanyAddType = {
        name: companyName,
        logoFileId: undefined,
      };
      const response = await ClientCompaniesService.add(data);
      await fetchClientCompanies();
      form.setFieldsValue({
        clientCompanyId: response.toString(),
        clientContactId: undefined,
      });
      if (isVisibleAddClientCompanyModal) setIsVisibleAddClientCompanyModal(false);
    } catch (error) {
      if (error.message) message.error(error.message);
    } finally {
      setIsSubmittingClientCompany(false);
    }
  };

  return (
    <div style={{ padding: '4rem 2rem' }}>
      <Form onSubmit={handleSubmit} hideRequiredMark>
        <Row type="flex" gutter={16}>
          <Col xs={24} md={8}>
            <div
              className={classNames('upload-photo avatar-upload', {
                'upload-photo--no-image': !imgPreview && !job?.imageUrl,
              })}
            >
              <Upload
                fileList={file ? [file] : []}
                beforeUpload={() => false}
                onChange={handleFileOnChange}
                accept={`${MimeTypes.IMAGE_JPEG}, ${MimeTypes.IMAGE_PNG}`}
                showUploadList={false}
              >
                {imgPreview || job?.imageUrl ? (
                  <>
                    <Tooltip title={t('Remove')}>
                      <Button
                        className="avatar-upload__btn avatar-upload__btn--remove"
                        size="small"
                        type="danger"
                        shape="circle"
                        icon="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          setFile(undefined);
                          setImgPreview(undefined);
                          if (job && setJob) {
                            setJob({
                              ...job,
                              imageFileId: undefined,
                              imageUrl: '',
                            });
                          }
                        }}
                      />
                    </Tooltip>
                    <Avatar src={imgPreview || job?.imageUrl} className="avatar-upload__img" />
                  </>
                ) : (
                  <>
                    <IconUploadPhoto className="upload-photo__placeholder-icon" />
                    <span className="upload-photo__placeholder-text">{t('Upload Photo')}</span>
                  </>
                )}
              </Upload>
            </div>
          </Col>
          <Col xs={22} md={16}>
            <Row gutter={12}>
              <Col xs={22} md={21}>
                <Form.Item>
                  {form.getFieldDecorator('clientCompanyId', {
                    rules: [
                      {
                        required: true,
                        message: t('This field is required'),
                      },
                    ],
                  })(
                    <Select
                      onSearch={(value: string) => setClientCompanySearchValue(value)}
                      notFoundContent={
                        <>
                          <span style={{ display: 'block' }}>
                            {t('The client company was not found')}
                          </span>
                          {clientCompanySearchValue.trim() && (
                            <Button
                              icon="plus"
                              type="link"
                              onClick={(e: any) =>
                                handleAddClientCompany(clientCompanySearchValue!)
                              }
                            >
                              {t('Add')} {clientCompanySearchValue}
                            </Button>
                          )}
                        </>
                      }
                      onChange={() => {
                        form.setFieldsValue({ clientContactId: undefined });
                      }}
                      loading={isLoadingClientCompanies || isSubmittingClientCompany}
                      disabled={isSubmittingClientCompany}
                      size="large"
                      placeholder={t('Select a client company')}
                      showSearch
                      optionFilterProp="children"
                    >
                      {clientCompanies.map((clientCompany) => (
                        <Select.Option key={clientCompany.id}>{clientCompany.name}</Select.Option>
                      ))}
                      <Select.Option
                        style={{ display: 'none' }}
                        className="jobs-new__select-add"
                        key={ADD_CLIENT_COMPANY}
                        value={ADD_CLIENT_COMPANY}
                      >
                        {t('Add')}
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={2} md={3}>
                <Button
                  loading={isLoadingClientCompanies || isSubmittingClientCompany}
                  type="primary"
                  icon="plus"
                  size="large"
                  style={{ top: 5 }}
                  onClick={() => setIsVisibleAddClientCompanyModal(true)}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={22} md={21}>
                <Form.Item>
                  {form.getFieldDecorator('clientContactId', {
                    initialValue:
                      !isLoadingClientCompanies && !isLoadingClientCompanyContacts
                        ? job?.clientContactId.toString()
                        : undefined,
                    rules: [
                      {
                        required: true,
                        message: t('This field is required'),
                      },
                    ],
                  })(
                    <Select
                      loading={isLoadingClientCompanyContacts || isSubmittingClientCompany}
                      disabled={!form.getFieldValue('clientCompanyId') || isSubmittingClientCompany}
                      size="large"
                      style={{ width: '100%' }}
                      placeholder={t("Select your client's contact")}
                      showSearch
                      optionFilterProp="children"
                    >
                      {clientCompanyContacts.map((clientCompanyContact) => (
                        <Select.Option key={clientCompanyContact.id}>
                          {clientCompanyContact.firstName} {clientCompanyContact.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={2} md={3}>
                <Button
                  type="primary"
                  disabled={!form.getFieldValue('clientCompanyId')}
                  loading={isLoadingClientCompanyContacts || isSubmittingClientCompany}
                  icon="plus"
                  size="large"
                  style={{ top: 5 }}
                  onClick={() => setIsVisibleSelectContactModal(true)}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          {form.getFieldDecorator('jobTitle', {
            initialValue: job?.jobTitle,
            rules: [
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ],
          })(<Input size="large" placeholder={t('Job Title')} />)}
        </Form.Item>

        <Row gutter={16}>
          <Col span={18}>
            <Form.Item>
              {form.getFieldDecorator('location', {
                initialValue: job?.location,
              })(<Input size="large" placeholder={t('Location')} />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              {form.getFieldDecorator('language', {
                initialValue: job?.language || user?.language.toString(),
                rules: [
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                ],
              })(
                <Select size="large" placeholder={t('Language')}>
                  {Object.keys(LanguagesEnum).map((languageCode) => (
                    <Select.Option key={languageCode}>
                      {Languages[languageCode as LanguagesEnum]}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={18}>
            <Form.Item>
              {form.getFieldDecorator('salaryRangeCode', {
                rules: [
                  {
                    required: !!form.getFieldValue('currencyCode'),
                    message: t('This field is required'),
                  },
                ],
              })(
                <Select allowClear size="large" placeholder={t('Salary Range')}>
                  {Object.keys(config?.salaryRanges || {}).map((salaryRange) => (
                    <Select.Option key={salaryRange}>
                      {get(config, `salaryRanges[${salaryRange}]`)}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              {form.getFieldDecorator('currencyCode', {
                rules: [
                  {
                    required: !!form.getFieldValue('salaryRangeCode'),
                    message: t('This field is required'),
                  },
                ],
              })(
                <Select
                  loading={isLoadingConfig}
                  allowClear
                  size="large"
                  placeholder={t('Currency')}
                >
                  {Object.keys(config?.currencies || {}).map((currency) => (
                    <Select.Option key={currency}>
                      {get(config, `currencies[${currency}]`)} ({currency})
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          {form.getFieldDecorator('description', {
            initialValue: job?.description,
          })(<TextArea placeholder={t('Description')} rows={9} />)}
        </Form.Item>

        <Form.Item>
          {form.getFieldDecorator('hideDescription', {
            initialValue: job?.hideDescription,
            valuePropName: 'checked',
          })(<Checkbox>{`${t('Hide description')}`}</Checkbox>)}
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            className="jobs-new__btn"
          >
            {t('Continue')}
          </Button>
        </div>
      </Form>

      <AddClientCompanyModal
        isSubmitting={isSubmittingClientCompany}
        isVisible={isVisibleAddClientCompanyModal}
        onCancel={() => {
          setIsVisibleAddClientCompanyModal(false);
        }}
        onOk={async (clientCompanyName: string) => {
          await handleAddClientCompany(clientCompanyName);
        }}
      />

      <SelectContactModal
        isVisible={isVisibleSelectContactModal}
        onCancel={() => {
          setIsVisibleSelectContactModal(false);
          form.setFieldsValue({ clientContactId: undefined });
        }}
        onOk={(contact) => {
          setIsVisibleSelectContactModal(false);
          fetchClientCompanyContacts();
          form.setFieldsValue({
            clientContactId: contact.id.toString(),
          });
        }}
        clientCompanyId={form.getFieldValue('clientCompanyId')}
      />
    </div>
  );
};

export default Form.create<Props>()(JobsEditOffer);
