import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoggedInRoute from '../components/LoggedInRoute/LoggedInRoute';
import PublicRoute from '../components/PublicRoute/PublicRoute';
import { URLs } from '../config/enums';
import CompleteSurveyPage from '../pages/CompleteSurvey/CompleteSurveyPage';
import ConfigurationsPage from '../pages/Configurations/ConfigurationsPage';
import ConfirmEmailPage from '../pages/ConfirmEmail/ConfirmEmailPage';
import ConfirmVerifyEmailPage from '../pages/ConfirmVerifyEmail/ConfirmVerifyEmailPage';
import ContactsPage from '../pages/Contacts/ContactsPage';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import EmailsPage from '../pages/Emails/EmailsPage';
import JobsPage from '../pages/Jobs/JobsPage';
import LoginPage from '../pages/Login/LoginPage';
import Logout from '../pages/Logout/Logout';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import ProfilePage from '../pages/Profile/ProfilePage';
import RecoverPasswordPage from '../pages/RecoverPassword/RecoverPasswordPage';
import { RecruiterConfigurationsPage } from '../pages/RecruiterConfigurations/RecruiterConfigurationsPage';
import ResetPasswordPage from '../pages/ResetPassword/ResetPasswordPage';
import RolesPage from '../pages/Roles/RolesPage';
import SignUpPage from '../pages/SignUp/SignUpPage';
import SuggestCandidatePage from '../pages/SuggestCandidate/SuggestCandidatePage';
import SurveysPage from '../pages/Surveys/SurveysPage';
import UserActivityPage from '../pages/UserActivity/UserActivityPage';
import UsersPage from '../pages/Users/UsersPage';
import ViewCandidatesPage from '../pages/ViewCandidates/ViewCandidatesPage';
import SocialNetworkMessagesPage from '../pages/SocialNetworkMessages/SocialNetworkMessagesPage';
import JobCustomMessageTemplatesPage from '../pages/JobCustomMessageTemplate/JobCustomMessageTemplatePage';
import CampaignsPage from '../pages/Campaigns/CampaignsPage';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <LoggedInRoute exact path={URLs.ROOT}>
          <DashboardPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.USERS}>
          <UsersPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.USERS_ACTIVITIES}>
          <UserActivityPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.ROLES}>
          <RolesPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.EMAILS}>
          <EmailsPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.SOCIAL_NETWORK_MESSAGES}>
          <SocialNetworkMessagesPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.JOB_CUSTOM_MESSAGE_TEMPLATES}>
          <JobCustomMessageTemplatesPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.CONFIGURATIONS}>
          <ConfigurationsPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.RECRUITER_CONFIGURATIONS}>
          <RecruiterConfigurationsPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.JOBS}>
          <JobsPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.CONTACTS}>
          <ContactsPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.SURVEYS}>
          <SurveysPage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.PROFILE}>
          <ProfilePage />
        </LoggedInRoute>
        <LoggedInRoute path={URLs.CAMPAIGNS}>
          <CampaignsPage />
        </LoggedInRoute>

        <Route path={URLs.LOGIN} component={LoginPage} />
        <PublicRoute exact path={URLs.COMPLETE_SURVEY}>
          <CompleteSurveyPage />
        </PublicRoute>
        <PublicRoute exact path={URLs.RECOVER_PASSWORD}>
          <RecoverPasswordPage />
        </PublicRoute>
        <PublicRoute exact path={URLs.RESET_PASSWORD}>
          <ResetPasswordPage />
        </PublicRoute>
        <PublicRoute exact path={URLs.CONFIRM_EMAIL}>
          <ConfirmEmailPage />
        </PublicRoute>
        <PublicRoute exact path={URLs.CONFIRM_VERIFY_EMAIL}>
          <ConfirmVerifyEmailPage />
        </PublicRoute>
        <PublicRoute exact path={URLs.SIGN_UP}>
          <SignUpPage />
        </PublicRoute>
        <Route path={URLs.SUGGEST_CANDIDATE} component={SuggestCandidatePage} />
        <Route path={URLs.VIEW_CANDIDATES} component={ViewCandidatesPage} />
        <Route path={URLs.LOGOUT} component={Logout} />
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
