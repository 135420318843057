import { Button, Col, Collapse, Dropdown, Icon, List, Menu, message, Row } from 'antd';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import JobsService from '../../api/jobs/JobsService';
import { Job } from '../../api/jobs/model';
import { FetchedList } from '../../api/shared/models';
import { ReactComponent as IconAddNewJob } from '../../assets/icon-newJob.svg';
import { URLs } from '../../config/enums';
import JobDetail from './JobDetail';
import { JobsContext } from './JobsContext';
import JobsEdit from './JobsEdit';
import JobsSelectOffer from './JobsSelectOffer';
import JobSuggestCandidate from './JobSuggestCandidate';
import JobTile from './JobTile';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../layout/AppContext';
import JobsCreateSurvey from './JobsCreateSurvey';
import { JobOrdersEnum, JobOrdersValues, JobStatusEnum } from '../../api/jobs/enums';

import { JobOrders } from '../../api/jobs/enums';
import { createBaseGridParams, GridParams } from '../../helpers/grid-helpers';
import { SortOrder } from 'antd/lib/table';

const Jobs: React.FC = () => {
  const { mainHeaderHeight, subHeaderHeight, setSubHeaderHeight } = useAppContext();
  const subHeaderRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const history = useHistory();
  const [jobs, setJobs] = useState<Job[]>();
  const [total, setTotal] = useState(0);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [isViewingAllJobs, setIsViewingAllJobs] = useState(false);
  const [isOrderedBy, setIsOrderedBy] = useState(JobOrdersEnum.CREATED_DATE);
  const gridState = createBaseGridParams({
    sortField: JobOrdersValues[isOrderedBy as JobOrdersEnum],
    sortOrder: 'descend',
    pageSize: 0,
    page: 0,
  });
  const [gridParams, setGridParams] = useState<GridParams>(gridState);

  const fetchJobs = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingJobs(true);
        let response: FetchedList<Job>;

        if (isViewingAllJobs) {
          response = await JobsService.findAll(gridParams);
        } else {
          response = await JobsService.find(gridParams);
        }
        setJobs(response.list);
        setTotal(response.count);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingJobs(false);
      }
    };

    fetch();
  }, [isViewingAllJobs, gridParams]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  useEffect(() => {
    setSubHeaderHeight(subHeaderRef.current!.clientHeight);
  }, [setSubHeaderHeight]);

  return (
    <>
      <div className="sub-header" ref={subHeaderRef}>
        <div className="container">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={10} lg={7} xl={7} xxl={6} style={{ textAlign: 'center' }}>
              <Button
                shape="round"
                type={isViewingAllJobs ? 'primary' : 'default'}
                style={{
                  marginRight: '1.5rem',
                  textTransform: 'uppercase',
                  paddingLeft: '3rem',
                  paddingRight: '3rem',
                  border: 'none',
                  boxShadow: 'none',
                }}
                onClick={() => {
                  setIsViewingAllJobs(true);
                  history.push(URLs.JOBS_ALL);
                }}
              >
                {t('All Jobs')} {isViewingAllJobs && `(${isLoadingJobs ? '-' : total})`}
              </Button>
              <Button
                type={!isViewingAllJobs ? 'primary' : 'default'}
                shape="round"
                style={{
                  textTransform: 'uppercase',
                  paddingLeft: '3rem',
                  paddingRight: '3rem',
                  border: 'none',
                  boxShadow: 'none',
                }}
                onClick={() => {
                  setIsViewingAllJobs(false);
                  history.push(URLs.JOBS);
                }}
              >
                {t('My Jobs')} {!isViewingAllJobs && `(${isLoadingJobs ? '-' : total})`}
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container" style={{ paddingTop: 0 }}>
        <Row gutter={16}>
          <Col xs={24} md={10} lg={7} xl={7} xxl={6} style={{ padding: 0 }}>
            <div
              className="column-list"
              style={{
                height: `calc(100vh - ${mainHeaderHeight + subHeaderHeight + 10}px)`,
              }}
            >
              <>
                <Button
                  onClick={() => history.push(URLs.JOBS_NEW)}
                  block
                  className="add-new-button"
                >
                  <span className="add-new-button__icon-bg">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                      }}
                    >
                      <IconAddNewJob />
                    </div>
                  </span>
                  <span className="add-new-button__text">{t('Add new job')}</span>
                </Button>
                <Dropdown
                  overlay={
                    <Menu
                      selectedKeys={[isOrderedBy]}
                      onClick={(e) => {
                        setIsOrderedBy(e.key as JobOrdersEnum);
                        setGridParams({
                          ...gridParams,
                          sortField: JobOrdersValues[e.key as JobOrdersEnum],
                        });
                      }}
                    >
                      {Object.keys(JobOrdersEnum).map((key) => (
                        <Menu.Item key={key}>{t(JobOrders[key as JobOrdersEnum])}</Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button
                    block
                    className="add-new-button"
                    onClick={() => {
                      let sortOrder: SortOrder =
                        gridParams.sortOrder === 'ascend' ? 'descend' : 'ascend';
                      setGridParams({ ...gridParams, sortOrder });
                    }}
                  >
                    <span className="add-new-button__text">
                      {t('Order By')}: {t(JobOrders[isOrderedBy as JobOrdersEnum])}
                      {'  '}
                      <Icon
                        style={{ fontSize: '1.7rem' }}
                        type={gridParams.sortOrder === 'ascend' ? 'arrow-up' : 'arrow-down'}
                      />
                    </span>
                  </Button>
                </Dropdown>
              </>

              <List
                dataSource={jobs?.filter((job) => job.jobStatus !== JobStatusEnum.ARCHIVED)}
                loading={isLoadingJobs}
                renderItem={(job) => (
                  <List.Item style={{ borderBottom: 0, padding: '.5rem 0' }} key={job.id}>
                    <JobTile job={job} />
                  </List.Item>
                )}
              ></List>
              <Collapse expandIconPosition="right" className="job-email-collapse">
                <Collapse.Panel
                  disabled={isLoadingJobs}
                  key={JobStatusEnum.ARCHIVED}
                  header={<span className="job-email-collapse__subject">{t('Archived jobs')}</span>}
                  className="job-email-collapse__item"
                >
                  <List
                    dataSource={jobs?.filter((job) => job.jobStatus === JobStatusEnum.ARCHIVED)}
                    loading={isLoadingJobs}
                    renderItem={(job) => (
                      <List.Item style={{ borderBottom: 0, padding: '.5rem 0' }} key={job.id}>
                        <JobTile job={job} />
                      </List.Item>
                    )}
                  ></List>
                </Collapse.Panel>
              </Collapse>
            </div>
          </Col>
          <Col xs={24} md={14} lg={17} xl={17} xxl={18}>
            <div
              style={{
                paddingTop: '1.5rem',
              }}
            >
              <JobsContext.Provider value={{ fetchJobs }}>
                <Switch>
                  <Route exact path={URLs.JOBS_DETAIL} component={JobDetail} />
                  <Route exact path={URLs.JOBS_DETAIL_CANDIDATES} component={JobDetail} />
                  <Route exact path={URLs.JOBS_DETAIL_SURVEYS} component={JobDetail} />
                  <Route exact path={URLs.JOBS_DRAFT} component={JobsEdit} />
                  <Route exact path={URLs.JOBS_NEW} component={JobsEdit} />
                  <Route exact path={URLs.JOBS_EDIT} component={JobsEdit} />
                  <Route exact path={URLs.JOBS_DETAIL_SUGGEST} component={JobSuggestCandidate} />
                  <Route exact path={URLs.JOBS} component={JobsSelectOffer} />
                  <Route exact path={URLs.JOBS_ALL} component={JobsSelectOffer} />
                  <Route exact path={URLs.JOBS_DETAIL_CREATE_SURVEY} component={JobsCreateSurvey} />
                </Switch>
              </JobsContext.Provider>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Jobs;
