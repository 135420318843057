import { Card, Divider } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { DispatchProp } from 'react-redux';
import ProfileBasicInfo from './ProfileBasicInfo';
import ProfileChangePassword from './ProfileChangePassword';

interface FormValues {
  firstName: string;
  lastName: string;
  currentPassword: string;
  newPassword: string;
}

interface Props extends FormComponentProps<FormValues>, DispatchProp {}

const Profile: React.FC<Props> = () => {
  return (
    <div className="container">
      <Card style={{ maxWidth: '50rem', margin: '0 auto' }}>
        <ProfileBasicInfo />
        <Divider />
        <ProfileChangePassword />
      </Card>
    </div>
  );
};

export default Profile;
