export enum CampaignStatusEnum {
  Archived = 'Archived',
  Scheduled = 'Scheduled',
  Draft = 'Draft',
  Started = 'Started',
  Paused = 'Paused',
  Stopped = 'Stopped',
  Completed = 'Completed',
}

export const CampaignsStatusLabel = {
  [CampaignStatusEnum.Archived]: 'Archived',
  [CampaignStatusEnum.Scheduled]: 'Scheduled',
  [CampaignStatusEnum.Draft]: 'Draft',
  [CampaignStatusEnum.Started]: 'Started',
  [CampaignStatusEnum.Paused]: 'Paused',
  [CampaignStatusEnum.Stopped]: 'Stopped',
  [CampaignStatusEnum.Completed]: 'Completed',
};

export enum CampaignEmailStatus {
  QUEUED = 'QUEUED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  BOUNCED = 'BOUNCED',
  OPEN = 'OPEN',
  ERROR = 'ERROR',
}

export const CampaignEmailStatusLabel = {
  [CampaignEmailStatus.QUEUED]: 'Queued',
  [CampaignEmailStatus.SENT]: 'Sent',
  [CampaignEmailStatus.RECEIVED]: 'Received',
  [CampaignEmailStatus.BOUNCED]: 'Bounced',
  [CampaignEmailStatus.OPEN]: 'Open',
  [CampaignEmailStatus.ERROR]: 'Error',
};

export const CampaignEmailStatusColorLabel = {
  [CampaignEmailStatus.QUEUED]: '#FFB900',
  [CampaignEmailStatus.SENT]: '#7BE56C',
  [CampaignEmailStatus.RECEIVED]: '#7BE56C',
  [CampaignEmailStatus.BOUNCED]: '#FF0400',
  [CampaignEmailStatus.OPEN]: '',
  [CampaignEmailStatus.ERROR]: '#FF0400',
};
