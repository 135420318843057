import { Role, RoleUpdate, RolesFetched } from './model';
import { GridParams, transformGridParams } from '../../helpers/grid-helpers';
import FetchService from '../shared/services/FetchService';
import { API } from '../../config/api';

export default class RolesService {
  static async findGrid(params: GridParams): Promise<RolesFetched> {
    const gridParams = transformGridParams(params);
    const response = await FetchService.get<RolesFetched>({
      url: API.ROLES,
      gridParams,
    });
    return response;
  }

  static async findOne(id: string): Promise<Role> {
    const response = await FetchService.get<Role>({
      url: `${API.ROLES}/${id}`,
    });
    return response;
  }

  static async find(): Promise<Role[]> {
    const response = await FetchService.get<RolesFetched>({
      url: API.ROLES,
    });
    return response.list;
  }

  static async update(entity: RoleUpdate): Promise<void> {
    await FetchService.post({
      url: API.ROLES,
      body: entity,
    });
  }
}
