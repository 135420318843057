import { useState, useCallback } from 'react';

export const useHeader = () => {
  const [height, setHeight] = useState(0);

  const setHeaderHeight = useCallback((h: number) => {
    setHeight(h);
  }, []);

  return {
    headerHeight: height,
    setHeaderHeight,
  };
};
