import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { PatentsEnum, ResourcesEnum } from '../../api/roles/enums';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import Contacts from '../../components/Contacts/Contacts';
import { URLs } from '../../config/enums';
import { Duplicate } from '../../api/contacts/model';
import { ContactsContext } from '../../components/Contacts/ContactsContext';
import { message } from 'antd';
import ContactsService from '../../api/contacts/ContactsService';
import ContactsDuplicates from '../../components/Contacts/ContactsDuplicates';
import ContactImportsGrid from '../../components/ContactImports/ContactImportsGrid';

const ContactsPage: React.FunctionComponent = () => {
  const [duplicates, setDuplicates] = useState<Duplicate[]>([]);
  const [isLoadingDuplicateContacts, setIsLoadingDuplicateContacts] = useState(true);

  const fetchDuplicateContacts = async () => {
    try {
      setIsLoadingDuplicateContacts(true);
      const response = await ContactsService.findDuplicates();
      setDuplicates(response);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsLoadingDuplicateContacts(false);
    }
  };

  useEffect(() => {
    fetchDuplicateContacts();
  }, []);

  return (
    <ContactsContext.Provider
      value={{
        duplicates,
        isLoadingDuplicateContacts,
        fetchDuplicateContacts,
        setDuplicateContacts: setDuplicates,
      }}
    >
      <Switch>
        <AuthenticatedRoute
          exact
          path={URLs.CONTACTS}
          resource={ResourcesEnum.CONTACTS}
          patent={PatentsEnum.VIEW}
        >
          <Contacts />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={URLs.CONTACTS_DETAIL}
          resource={ResourcesEnum.CONTACTS}
          patent={PatentsEnum.VIEW}
        >
          <Contacts />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={URLs.CONTACTS_DUPLICATES}
          resource={ResourcesEnum.CONTACTS}
          patent={PatentsEnum.VIEW}
        >
          <ContactsDuplicates />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={URLs.CONTACTS_IMPORTS}
          resource={ResourcesEnum.CONTACTS}
          patent={PatentsEnum.VIEW}
        >
          <ContactImportsGrid />
        </AuthenticatedRoute>
      </Switch>
    </ContactsContext.Provider>
  );
};

export default ContactsPage;
