import React, { useEffect, useState } from 'react';
import {
    Card, Col, Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SmtpConfigurationGroupingEmailsType, SmtpConfigurationGroupingEmaisLabel, SmtpConfigurationLimitType, SmtpConfigurationLimitTypeLabel } from '../../api/recruiter-configurations/enums';

interface campaignDetail {
    subject: string;
    from: string;
    includedList: string;
    deliveredOn: string;
    campaignNo: number;
    campaignStatus: string;
    message: string;
    smtpConfiguration: string;
    emailLimit: number;
    intervalLimit: string;
    intervalType: string;
    selectedContacts: number;
    campaignStartDate: string;
}

interface emailStatisticsDetail {
    sent: string;
    received: string;
    open: string;
    bounced: string;
    error: number;
    queued: number;
}

interface Props {
    campaign: campaignDetail;
    emailStatistics: emailStatisticsDetail;
};

const CampaignsSummary: React.FC<Props> = ({ campaign, emailStatistics }) => {
    const { t } = useTranslation();

    return (
        <div >
            <Row className='campaign-tab'>
                <Col md={16} xl={16} sm={16}>
                    <Card className="job-offer__description" style={{ height: 'calc(100% + 30px)' }}>
                        <div>
                            <Row>
                                <Col>
                                    <div className='view-campaign'>
                                        <div>{t('Subject')}:</div>
                                        <div>{t(campaign?.subject)}</div>
                                    </div>
                                    <div className='view-campaign'>
                                        <div className='responsive-word'>{t('Campaign start date')}: </div>
                                        <div> {campaign?.campaignStartDate} </div>
                                    </div>
                                    <div className='view-campaign'>
                                        <div>{t('SMTP configuration')}</div>
                                        {(campaign?.emailLimit != null || campaign?.intervalLimit != null || campaign?.intervalType != null) ? (
                                            <div>
                                                <div style={{ display: 'flex' }}><p style={{ fontWeight: 500, marginRight: '3px' }}>{t('Configuration name')}: </p> <p>{campaign?.smtpConfiguration}</p></div>
                                                <div style={{ display: 'flex' }}><p style={{ fontWeight: 500, marginRight: '3px' }}>{t('E-mail limit')}: </p><p>{`${campaign?.emailLimit} ${campaign?.emailLimit > 1 ? 'emails' : 'email'} every ${campaign?.intervalLimit} ${t(SmtpConfigurationGroupingEmaisLabel[campaign?.intervalType as SmtpConfigurationGroupingEmailsType]) || t(
                                                    SmtpConfigurationLimitTypeLabel[campaign?.intervalType as SmtpConfigurationLimitType])} `}</p></div>
                                            </div>
                                        ) : '-'}
                                    </div>

                                    <div className='view-campaign'>
                                        <div style={{ width: "20px" }}>
                                            <div>{t('Selected contacts')} </div>
                                        </div>
                                        <div>{campaign?.selectedContacts} </div>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col md={8} xl={8} sm={8}>
                    <Card className="job-offer__description" style={{ marginLeft: '5px', height: 'calc(100% + 30px)' }}>
                        <div>
                            <Row>
                                <Col>
                                    <div className='view-campaign'>
                                        <div>{t('Queued')}:</div>
                                        <div>{emailStatistics?.queued}</div>
                                    </div>
                                    <div className='view-campaign'>
                                        <div>{t('Sent')}:</div>
                                        <div>{emailStatistics?.sent}</div>
                                    </div>
                                    <div className='view-campaign'>
                                        <div>{t('Received')}:</div>
                                        <div> {emailStatistics?.received} </div>
                                    </div>
                                    <div className='view-campaign'>
                                        <div>{t('Open')}:</div>
                                        <div> {emailStatistics?.open} </div>
                                    </div>
                                    <div className='view-campaign'>
                                        <div>{t('Bounced')}: </div>
                                        <div> {emailStatistics?.bounced} </div>
                                    </div>
                                    <div className='view-campaign'>
                                        <div>{t('Error')}: </div>
                                        <div> {emailStatistics?.error} </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CampaignsSummary;
