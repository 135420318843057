import {
  Button,
  Card,
  Col,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { ContactDefaultRoute } from '../../api/contacts/enums';
import { EmailStatus, EmailStatusColorLabel, EmailStatusLabel } from '../../api/emails/enums';
import { RolesEnum } from '../../api/roles/enums';
import { ISocialNetworkMessage } from '../../api/social-network-messages/models';
import SocialNetworkMessagesService from '../../api/social-network-messages/SocialNetworkMessagesService';
import { URLs } from '../../config/enums';
import { formatDateTime } from '../../helpers/date-helpers';
import {
  createBaseGridParams,
  getUrlQueryString,
  GridFilter,
  updateGridUrl,
} from '../../helpers/grid-helpers';
import { useAppContext } from '../../layout/AppContext';

interface SocialNetworkMessagesGridProps {}

const SocialNetworkMessagesGrid: React.FC<SocialNetworkMessagesGridProps> = () => {
  const gridParams = createBaseGridParams({
    sortField: 'createdDate',
    sortOrder: 'descend',
    filters: [],
  });

  const { t } = useTranslation();
  const urlParams = getUrlQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const { user } = useAppContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [socialNetworkMessages, setSocialNetworkMessages] = useState<ISocialNetworkMessage[]>();
  const [total, setTotal] = useState(0);
  const [gridState, setGridState] = useState({
    ...gridParams,
    ...urlParams,
    filters: [] as GridFilter[],
  });
  const [searchInput, setSearchInput] = useState(urlParams.search || '');
  const [selectedSocialNetworkMessageId, setSelectedSocialNetworkMessageId] = useState<
    number | undefined
  >();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setIsLoading(true);
        const response = await SocialNetworkMessagesService.findGrid(gridState);
        setSocialNetworkMessages(response.list);
        setTotal(response.count);
        updateGridUrl(history, match.url, gridState);
      } catch (error) {
        if (error.message) message.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMessages();
  }, [gridState, history, match.url]);

  useEffect(() => {
    if (selectedSocialNetworkMessageId) {
      const message = find(socialNetworkMessages, { id: selectedSocialNetworkMessageId })!;
      Modal.info({
        title: t('Message'),
        content: <Input.TextArea value={message.body} readOnly autosize />,
        width: 750,
        onOk: () => setSelectedSocialNetworkMessageId(undefined),
        onCancel: () => setSelectedSocialNetworkMessageId(undefined),
      });
    }
  }, [selectedSocialNetworkMessageId, socialNetworkMessages, t]);

  const handleTableOnChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<any>
  ) => {
    setGridState({
      ...gridState,
      sortField: sorter.columnKey || 'createdDate',
      sortOrder: sorter.order || 'descend',
      page: +pagination.current! - 1,
      pageSize: +pagination.pageSize!,
    });
  };

  const columns = [
    {
      title: t('Date'),
      dataIndex: 'createdDate',
      sorter: true,
      defaultSortOrder: gridState.sortField === 'createdDate' ? gridState.sortOrder : undefined,
      align: 'center' as 'center',
      render: (cell: any, row: ISocialNetworkMessage) => (
        <Button type="link" onClick={() => setSelectedSocialNetworkMessageId(row.id)}>
          {formatDateTime(new Date(cell))}
        </Button>
      ),
    },
    {
      title: t('Send Date'),
      dataIndex: 'editedDate',
      align: 'center' as 'center',
      render: (cell: any) => (cell ? formatDateTime(new Date(cell)) : '-'),
    },
    {
      title: t('Social Network'),
      dataIndex: 'socialMediaCode',
      align: 'center' as 'center',
      render: (cell: any) => (
        <Icon
          style={{ fontSize: 30 }}
          theme="filled"
          type={cell === ContactDefaultRoute.LINKEDIN ? 'linkedin' : 'facebook'}
        />
      ),
    },
    {
      title: t('Sender'),
      key: 'sender',
      align: 'center' as 'center',
      render: (cell: any, row: ISocialNetworkMessage) => (
        <>
          <p>
            <Link to={`${URLs.USERS_DETAIL.replace(':id', row.senderUserId.toString())}`}>
              {row.senderFirstName} {row.senderLastName}
            </Link>
          </p>
          <p>{row.senderEmail}</p>
        </>
      ),
    },
    {
      title: t('Recipients'),
      dataIndex: 'recipients',
      align: 'center' as 'center',
      render: (cell: any) => (
        <a href={cell} target="_blank" rel="noopener noreferrer">
          {cell}
        </a>
      ),
    },
    { title: t('Job Name'), dataIndex: 'jobName', align: 'center' as 'center' },
    {
      title: t('Status'),
      dataIndex: 'status',
      align: 'center' as 'center',
      render: (cell: any, row: ISocialNetworkMessage) => (
        <Tooltip title={row.log}>
          <Tag color={EmailStatusColorLabel[cell as EmailStatus]}>{t(cell)}</Tag>
        </Tooltip>
      ),
    },
  ];

  const handleFilterChange = (filter: string, value: any) => {
    if (!value)
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter)],
      });
    else
      setGridState({
        ...gridState,
        filters: [...gridState.filters!.filter((i) => i.key !== filter), { key: filter, value }],
      });
  };

  const renderFilters = () => {
    return (
      <>
        <Row gutter={12}>
          <Col xs={24} md={7} style={{ marginBottom: 10 }}>
            <Input.Search
              enterButton
              placeholder={`${t('Search')}...`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchInput(e.target.value)}
              value={searchInput}
              onSearch={(value: string) => setGridState({ ...gridState, search: value, page: 0 })}
            />
          </Col>
          <Col xs={24} md={6}>
            <Select
              allowClear
              placeholder={t('Status')}
              style={{ width: '100%' }}
              onChange={(value: any) => handleFilterChange('status', value)}
            >
              {Object.keys(EmailStatus).map((status) => (
                <Select.Option key={status} value={status}>
                  {t(EmailStatusLabel[status as EmailStatus])}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="container">
      <Card title={t('Social Network Messages')}>
        <Table
          rowKey="id"
          bordered
          onChange={handleTableOnChange}
          title={renderFilters}
          showHeader
          loading={isLoading}
          columns={
            user?.role?.code === RolesEnum.SYSADMIN || user?.role?.code === RolesEnum.ADMIN
              ? columns
              : columns.filter((i) => i.key !== 'sender')
          }
          dataSource={socialNetworkMessages}
          pagination={{
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]} - ${rangeRecords[1]} ${t('of')} ${total} ${t('items')}`,
            current: gridState.page! + 1,
            pageSize: gridState.pageSize,
            total: total,
          }}
        ></Table>
      </Card>
    </div>
  );
};

export default SocialNetworkMessagesGrid;
