import { message } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { URLs } from '../../config/enums';
import { LINKEDIN_STATE_CODE } from '../../config/general-config';
import Loading from '../Loading/Loading';
import AuthService from '../../api/shared/services/AuthService';
import { DispatchProp, connect } from 'react-redux';
import { loginWithLinkedIn } from '../../state/auth';

interface Props extends DispatchProp {}

const LoginWithLinkedInRedirect: React.FC<Props> = ({ dispatch }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const params = queryString.parse(window.location.search) as {
    error?: 'user_cancelled_login' | 'user_cancelled_authorize';
    error_description?: string;
    state?: string;
    code?: string;
  };

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        if (params.code && params.state === LINKEDIN_STATE_CODE) {
          const response = await AuthService.loginWithLinkedIn(params.code);
          dispatch(loginWithLinkedIn(response));
        }
      } catch (error) {
        setError(true);
        if (error.message) {
          message.error(error.message);
        }
      }
    };

    getAccessToken();
  }, [params.code, params.state, dispatch]);

  if (error) {
    return <Redirect to={URLs.LOGIN} />;
  }

  if (params.error && params.error_description) {
    message.error(t(params.error_description));
    return <Redirect to={URLs.LOGIN} />;
  }

  if (params.state !== LINKEDIN_STATE_CODE) {
    return <Redirect to={URLs.LOGIN} />;
  }

  return <Loading />;
};

export default connect()(LoginWithLinkedInRedirect);
