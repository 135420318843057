import { Card, message, Table } from 'antd';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Role } from '../../api/roles/model';
import RolesService from '../../api/roles/RolesService';
import { URLs } from '../../config/enums';
import { createBaseGridParams, getUrlQueryString, updateGridUrl } from '../../helpers/grid-helpers';

const gridParams = createBaseGridParams({
  sortField: 'name',
});

const RolesGrid: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const urlParams = getUrlQueryString();

  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState<Role[]>([]);
  const [total, setTotal] = useState(0);
  const [gridState, setGridState] = useState({
    ...gridParams,
    ...urlParams,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await RolesService.findGrid(gridState);
        setRoles(response.list);
        setTotal(response.count);
        updateGridUrl(history, match.url, gridState);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [gridState, history, match.url]);

  const handleTableOnChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<any>
  ) => {
    setGridState({
      ...gridState,
      sortField: sorter.columnKey || 'name',
      sortOrder: sorter.order || 'ascend',
      page: +pagination.current! - 1,
      pageSize: +pagination.pageSize!,
    });
  };

  return (
    <div className="container">
      <Card title={t('Roles')}>
        <Table
          rowKey="roleId"
          bordered
          showHeader
          loading={isLoading}
          onChange={handleTableOnChange}
          pagination={{
            showTotal: (totalRecords, rangeRecords) =>
              `${rangeRecords[0]}-${rangeRecords[1]} ${t('of')} ${totalRecords} ${t('items')}`,
            current: gridState.page! + 1,
            pageSize: gridState.pageSize,
            total,
          }}
          columns={[
            {
              title: t('Name'),
              dataIndex: 'name',
              render(name, role) {
                return (
                  <Link
                    to={URLs.ROLES_DETAIL.replace(':id', role.roleId ? role.roleId.toString() : '')}
                  >
                    {name}
                  </Link>
                );
              },
            },
          ]}
          dataSource={roles}
        />
      </Card>
    </div>
  );
};

export default RolesGrid;
