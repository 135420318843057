import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import AuthService from '../../api/shared/services/AuthService';
import { FACEBOOK_APP_ID } from '../../config/general-config';
import { loginWithFacebook } from '../../state/auth';
import FacebookService from '../../api/shared/services/FacebookService';
import { FacebookRequestModel } from '../../api/shared/models';
import { ReactComponent as FacebookIcon } from '../../assets/icon-facebook.svg';
import { useTranslation } from 'react-i18next';

const loadSdk = () => {
  ((doc) => {
    const script = doc.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    document.body.appendChild(script);
  })(document);
};

interface Props extends DispatchProp {
  isLoggingApp: boolean;
}

const LoginWithFacebookButton: React.FC<Props> = ({ dispatch, isLoggingApp }) => {
  const { t } = useTranslation();
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    if (!isSdkLoaded) {
      window.fbAsyncInit = () => {
        FB.init({ appId: FACEBOOK_APP_ID!, version: 'v5.0' });
      };
      setIsSdkLoaded(true);
      loadSdk();
    }
  }, [isSdkLoaded]);

  const handleLogin = async () => {
    try {
      setIsLoggingIn(true);

      const authResponse = await FacebookService.login();
      const profile = await FacebookService.getProfile();

      const model: FacebookRequestModel = {
        userId: authResponse.userID,
        accessToken: authResponse.accessToken,
        firstName: profile.first_name,
        lastName: profile.last_name,
        email: profile.email,
        profilePictureUrl: profile.picture.data.url,
      };

      const user = await AuthService.loginWithFacebook(model);
      dispatch(loginWithFacebook(user));
    } catch (error) {
      setIsLoggingIn(false);
      if (error?.message === 'Email required') {
        FB.logout();
        message.error(`${t('We need your email')}!`);
      } else if (error?.message) {
        message.error(error.message);
      }
    }
  };

  if (!isSdkLoaded) {
    return null;
  }

  return (
    <Button
      onClick={handleLogin}
      disabled={isLoggingIn || isLoggingApp}
      loading={isLoggingIn}
      block
      size="large"
      style={{ marginBottom: '1.5rem' }}
    >
      <FacebookIcon
        style={{
          height: '2rem',
          position: 'absolute',
          left: '2rem',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />

      {t('Enter with Facebook')}
    </Button>
  );
};

export default connect()(LoginWithFacebookButton);
