export const APP_DISPLAY_NAME = 'Talentlinkr';
export const APP_INSTITUTIONAL_URL = 'http://talentlinkr.com/';
export const APP_URL = 'https://app.talentlinkr.com/';
export const LOCAL_STORAGE_PROFILE = 'Talentlinkr.PROFILE';
export const LOCAL_STORAGE_JWT = 'Talentlinkr.JWT';
export const LOCAL_STORAGE_GOOGLE_TOKEN = 'Talentlinkr.GOOGLE_TOKEN';
export const ROWS_PER_PAGES = 10;
export const API_URL = `${process.env.REACT_APP_API_URL!}/api/v1`;
export const SIGNALR_URL = `${process.env.REACT_APP_API_URL!}/importContactsHub`;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID!;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!;
export const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID!;
export const LINKEDIN_STATE_CODE = process.env.REACT_APP_LINKEDIN_STATE_CODE!;
export const IS_DEVELOPMENT =
  process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost';
