import React, { useState } from 'react';
import { Dropdown, Button, Icon, Row, Col } from 'antd';
import { ReactComponent as IconImport } from '../../assets/icon-import.svg';
import { ReactComponent as IconImportFile } from '../../assets/icon-importfile.svg';
import ImportContactsCSVModal from '../Contacts/ImportContactsCSVModal';
import ImportContactsGoogle from './ImportContactsGoogle';
import { useTranslation } from 'react-i18next';
import { ContactList } from '../../api/contacts/lists/model';

interface Props {
  contactLists: ContactList[];
  onOk: () => void;
}

const ImportContacts: React.FC<Props> = ({ onOk, contactLists }) => {
  const { t } = useTranslation();
  const [isVisibleImportCSVModal, setIsVisibleImportCSVModal] = useState(false);

  return (
    <>
      <Dropdown
        overlay={
          <div className="import-contacts">
            <p className="import-contacts__title">{t('Import contacts from')}...</p>
            <Row gutter={[30, 30]}>
              <Col xs={8} sm={6} style={{ textAlign: 'center' }}>
                <Button
                  className="import-contacts__btn"
                  onClick={() => setIsVisibleImportCSVModal(true)}
                >
                  <IconImportFile />
                </Button>
              </Col>
              <Col xs={8} sm={6} style={{ textAlign: 'center' }}>
                <ImportContactsGoogle onOk={onOk} />
              </Col>
            </Row>
          </div>
        }
      >
        <Button type="primary" style={{ marginRight: '1rem' }}>
          <Icon component={IconImport} />
          <span>{t('Import contacts')}</span>
        </Button>
      </Dropdown>

      <ImportContactsCSVModal
        contactLists={contactLists}
        isVisible={isVisibleImportCSVModal}
        onCancel={() => setIsVisibleImportCSVModal(false)}
        onOk={() => {
          setIsVisibleImportCSVModal(false);
          onOk();
        }}
      />
    </>
  );
};

export default ImportContacts;
