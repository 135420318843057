import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import ContactsService from '../api/contacts/ContactsService';
import { Contact } from '../api/contacts/model';
import { ContactList } from '../api/contacts/lists/model';
import ContactsListsService from '../api/contacts/lists/ContactsListsService';
import { GridParams } from '../helpers/grid-helpers';
import { FetchedList } from '../api/shared/models';
import { usePrevious } from './usePrevious';
import { MatchedCustomField } from '../api/custom-fields/model';

interface UseContactsProps {
  search?: string;
  listId?: number;
  fetchOnOpen?: boolean;
  params: GridParams;
}

interface UseContactProps {
  fetchOnOpen?: boolean;
  contactId?: number;
}

export const useContact = ({ contactId, fetchOnOpen = true }: UseContactProps) => {
  const [isLoadingContact, setIsLoadingContact] = useState(true);
  const [contact, setContact] = useState<Contact>();
  const [matchedCustomFields, setMatchedCustomFields] = useState<MatchedCustomField[]>([]);

  const fetchContact = useCallback(() => {
    const fetch = async () => {
      try {
        if (contactId) {
          setIsLoadingContact(true);
          const response = await ContactsService.findOne(contactId);
          setContact(response);
          setMatchedCustomFields(response.matchedCustomFields);
        } else {
          setContact(undefined);
          setMatchedCustomFields([]);
        }
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingContact(false);
      }
    };

    if (fetchOnOpen) {
      fetch();
    } else {
      setContact(undefined);
    }
  }, [contactId, fetchOnOpen]);

  useEffect(() => {
    fetchContact();
  }, [fetchContact]);

  return {
    isLoadingContact,
    contact,
    setContact,
    fetchContact,
    matchedCustomFields,
    setMatchedCustomFields,
  };
};

export const useContacts = ({ search, listId, fetchOnOpen = true, params }: UseContactsProps) => {
  const [isLoadingContacts, setIsLoadingContacts] = useState(true);
  const [total, setTotal] = useState(0);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const previousListId = usePrevious(listId);

  const fetchContacts = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingContacts(true);
        setContacts([]);
        let response: FetchedList<Contact>;

        if (!listId) {
          if (params.sortField === 'contact.firstName') {
            params.sortField = 'firstName';
          } else if (params.sortField === 'contact.lastName') {
            params.sortField = 'lastName';
          }

          response = await ContactsService.find({ ...params, search });
        } else {
          if (params.sortField === 'firstName') {
            params.sortField = 'contact.firstName';
          } else if (params.sortField === 'lastName') {
            params.sortField = 'contact.lastName';
          }

          if (previousListId !== listId) {
            params.page = 0;
          }

          response = await ContactsListsService.findContacts(listId, {
            ...params,
            search,
          });
        }

        setContacts(response.list);
        setTotal(response.count);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
      } finally {
        setIsLoadingContacts(false);
      }
    };

    if (fetchOnOpen) {
      fetch();
    } else {
      setContacts([]);
      setTotal(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId, fetchOnOpen, search, params]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  useEffect(() => {
    if (search) {
      setIsLoadingContacts(true);
      setContacts([]);
    }
  }, [search]);

  return {
    isLoadingContacts,
    contacts,
    fetchContacts,
    total,
  };
};

export const useLists = () => {
  const [isLoadingLists, setIsLoadingLists] = useState(true);
  const [lists, setLists] = useState<ContactList[]>([]);

  const fetchLists = async () => {
    try {
      setIsLoadingLists(true);
      const response = await ContactsListsService.findAll();
      setLists(response);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsLoadingLists(false);
    }
  };

  useEffect(() => {
    fetchLists();
  }, []);

  return {
    isLoadingLists,
    lists,
    fetchLists,
  };
};
