import { Button, Form, Input, message, Typography } from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { UpdatePassword } from '../../api/users/model';
import UsersService from '../../api/users/UsersService';
import { validateAntForm } from '../../helpers/ant-form';

const formItemLayout: FormItemProps = {
  labelCol: {
    xs: 24,
    sm: 8,
  },
  wrapperCol: {
    xs: 24,
    sm: 16,
  },
};

interface FormValues {
  firstName: string;
  lastName: string;
  currentPassword: string;
  newPassword: string;
}

interface Props extends FormComponentProps<FormValues>, DispatchProp {}

const ProfileChangePassword: React.FC<Props> = ({ form, dispatch }) => {
  const { t } = useTranslation();
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);

  const compareToFirstPassword = (
    rule: any,
    value: string,
    callback: (message?: string) => void
  ) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('The passwords do not match'));
    } else {
      callback();
    }
  };

  const handleSubmitPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const values = await validateAntForm(form);
      setIsSubmittingPassword(true);
      const { currentPassword, newPassword } = values;
      const model: UpdatePassword = { currentPassword, newPassword };
      await UsersService.updatePassword(model);
      message.success(`${t('Password updated')}!`);
      form.resetFields();
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    } finally {
      setIsSubmittingPassword(false);
    }
  };

  return (
    <Form onSubmit={handleSubmitPassword} layout="horizontal">
      <Typography.Paragraph
        style={{
          marginBottom: '2rem',
          fontWeight: 'bold',
          fontSize: '1.6rem',
          color: '#000',
        }}
      >
        {t('Change Password')}
      </Typography.Paragraph>
      <Form.Item label={t('Current Password')} {...formItemLayout}>
        {form.getFieldDecorator('currentPassword', {
          rules: [
            {
              required: true,
              whitespace: true,
              message: t('This field is required'),
            },
          ],
        })(<Input.Password type="password" />)}
      </Form.Item>
      <Form.Item label={t('New Password')} {...formItemLayout}>
        {form.getFieldDecorator('newPassword', {
          rules: [
            {
              required: true,
              whitespace: true,
              message: t('This field is required'),
            },
          ],
        })(<Input.Password type="password" />)}
      </Form.Item>
      <Form.Item label={t('Confirm')} {...formItemLayout}>
        {form.getFieldDecorator('confirmNewPassword', {
          rules: [
            {
              required: true,
              whitespace: true,
              message: t('This field is required'),
            },
            {
              validator: compareToFirstPassword,
            },
          ],
        })(<Input.Password type="password" />)}
      </Form.Item>
      <Button.Group>
        <Button
          disabled={isSubmittingPassword}
          loading={isSubmittingPassword}
          htmlType="submit"
          type="primary"
        >
          {t('Change Password')}
        </Button>
      </Button.Group>
    </Form>
  );
};

export default connect()(Form.create()(ProfileChangePassword));
